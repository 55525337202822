import React, { ReactNode } from 'react';

import {
  ContentRowContainer,
  ContentRowName,
} from '../../modules/dt-dashboard/modals/dt-dispatch-details-modal/dt-dispatch-details-modal.styled';

export type DtDetailsSummaryRowAlign = 'center' | 'baseline';

export interface DtSummaryRowItem {
  label: string;
  alignItems?: DtDetailsSummaryRowAlign;
  children: ReactNode;
}

const DtDetailsSummaryRow: React.FC<DtSummaryRowItem> = ({ label, children, alignItems }) => {
  return (
    <ContentRowContainer alignItems={alignItems}>
      <ContentRowName variant='body'>{label}</ContentRowName>
      {children}
    </ContentRowContainer>
  );
};

export default DtDetailsSummaryRow;
