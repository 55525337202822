import { AccordionDetails } from '@mui/material';
import React, { ReactNode, SyntheticEvent } from 'react';

import { AccordionHeader, CustomAccordion, AccordionHeaderTitle } from './dt-accordion.styled';
import DtIcon from '../dt-icon/dt-icon';

export interface DtAccordionProps {
  /**
   * @param {string} Defines the title text (page name) in the accordion header
   * @example General, 24th floor
   */
  title?: string;
  customTitle?: JSX.Element;
  contentPadding?: string;
  /**
   * @param {boolean} Allows to keep track on a current opened accordion. Related to a specific accordion id or  index in the array
   * @example true/false (expanded === index in a parent component)
   */
  expanded: boolean;
  /**
   * @param {ReactNode} Defines particular accordion content (section)
   * @example
   * {accordions.map(accordion => {
   *  <DtAccordion>
   *    {accordion.sections.map(section) => (
   *      <CustomAccordionContent section={section} />
   *  )}
   * </DtAccordion>
   * })}
   */
  children?: ReactNode;
  /**
   * @param {(_: SyntheticEvent, isExpanded: boolean)} onChange callback allows to. Event as parameter can be skipped
   * @param {boolean} isExpanded The `expanded` state of the accordion (related to AccordionProps of a Material UI itself)
   *
   * @example   
   * const handleChange = (accordionId: number) => (_: SyntheticEvent, isExpanded:    boolean) => {
        setExpanded(isExpanded ? accordionId : null);
    };
   */
  onChange: (_: SyntheticEvent, isExpanded: boolean) => void;
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
}

const DtAccordion: React.FC<DtAccordionProps> = ({
  title,
  customTitle,
  expanded,
  children,
  onChange,
  className,
  contentPadding,
}) => {
  const accordionExpandCollapseIcon = <DtIcon icon='chevron-down' size={20} color='secondaryIcon' />;

  return (
    <CustomAccordion expanded={expanded} onChange={onChange} className={className}>
      <AccordionHeader expandIcon={accordionExpandCollapseIcon}>
        {title && <AccordionHeaderTitle>{title}</AccordionHeaderTitle>}
        {customTitle && customTitle}
      </AccordionHeader>
      <AccordionDetails sx={{ padding: contentPadding ? contentPadding : '6px 0px 32px 24px' }}>
        <div style={{ padding: '' }}>{children}</div>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default DtAccordion;
