/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * AdminAssignmentApi - axios parameter creator
 * @export
 */
export const AdminAssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerAssignForm: async (
      companyId: number,
      propertyId: number,
      formId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignForm', 'companyId', companyId);
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignForm', 'propertyId', propertyId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignForm', 'formId', formId);
      const localVarPath = `/admin/assignment/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (formId !== undefined) {
        localVarQueryParameter['formId'] = formId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} userId
     * @param {string} formType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerAssignUser: async (
      companyId: number,
      propertyId: number,
      userId: number,
      formType: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignUser', 'companyId', companyId);
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignUser', 'propertyId', propertyId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignUser', 'userId', userId);
      // verify required parameter 'formType' is not null or undefined
      assertParamExists('adminAssignmentControllerAssignUser', 'formType', formType);
      const localVarPath = `/admin/assignment/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (formType !== undefined) {
        localVarQueryParameter['formType'] = formType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerUnassignForm: async (
      assignmentId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assignmentId' is not null or undefined
      assertParamExists('adminAssignmentControllerUnassignForm', 'assignmentId', assignmentId);
      const localVarPath = `/admin/assignment/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (assignmentId !== undefined) {
        localVarQueryParameter['assignmentId'] = assignmentId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerUnassignUser: async (
      assignmentId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assignmentId' is not null or undefined
      assertParamExists('adminAssignmentControllerUnassignUser', 'assignmentId', assignmentId);
      const localVarPath = `/admin/assignment/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (assignmentId !== undefined) {
        localVarQueryParameter['assignmentId'] = assignmentId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminAssignmentApi - functional programming interface
 * @export
 */
export const AdminAssignmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminAssignmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAssignmentControllerAssignForm(
      companyId: number,
      propertyId: number,
      formId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignmentControllerAssignForm(
        companyId,
        propertyId,
        formId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} userId
     * @param {string} formType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAssignmentControllerAssignUser(
      companyId: number,
      propertyId: number,
      userId: number,
      formType: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignmentControllerAssignUser(
        companyId,
        propertyId,
        userId,
        formType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAssignmentControllerUnassignForm(
      assignmentId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignmentControllerUnassignForm(
        assignmentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAssignmentControllerUnassignUser(
      assignmentId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAssignmentControllerUnassignUser(
        assignmentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminAssignmentApi - factory interface
 * @export
 */
export const AdminAssignmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminAssignmentApiFp(configuration);
  return {
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerAssignForm(
      companyId: number,
      propertyId: number,
      formId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .adminAssignmentControllerAssignForm(companyId, propertyId, formId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {number} propertyId
     * @param {number} userId
     * @param {string} formType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerAssignUser(
      companyId: number,
      propertyId: number,
      userId: number,
      formType: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .adminAssignmentControllerAssignUser(companyId, propertyId, userId, formType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerUnassignForm(assignmentId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminAssignmentControllerUnassignForm(assignmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} assignmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAssignmentControllerUnassignUser(assignmentId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminAssignmentControllerUnassignUser(assignmentId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminAssignmentApi - interface
 * @export
 * @interface AdminAssignmentApi
 */
export interface AdminAssignmentApiInterface {
  /**
   *
   * @param {number} companyId
   * @param {number} propertyId
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApiInterface
   */
  adminAssignmentControllerAssignForm(
    companyId: number,
    propertyId: number,
    formId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {number} companyId
   * @param {number} propertyId
   * @param {number} userId
   * @param {string} formType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApiInterface
   */
  adminAssignmentControllerAssignUser(
    companyId: number,
    propertyId: number,
    userId: number,
    formType: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {number} assignmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApiInterface
   */
  adminAssignmentControllerUnassignForm(assignmentId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} assignmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApiInterface
   */
  adminAssignmentControllerUnassignUser(assignmentId: number, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * AdminAssignmentApi - object-oriented interface
 * @export
 * @class AdminAssignmentApi
 * @extends {BaseAPI}
 */
export class AdminAssignmentApi extends BaseAPI implements AdminAssignmentApiInterface {
  /**
   *
   * @param {number} companyId
   * @param {number} propertyId
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApi
   */
  public adminAssignmentControllerAssignForm(
    companyId: number,
    propertyId: number,
    formId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminAssignmentApiFp(this.configuration)
      .adminAssignmentControllerAssignForm(companyId, propertyId, formId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {number} propertyId
   * @param {number} userId
   * @param {string} formType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApi
   */
  public adminAssignmentControllerAssignUser(
    companyId: number,
    propertyId: number,
    userId: number,
    formType: string,
    options?: AxiosRequestConfig
  ) {
    return AdminAssignmentApiFp(this.configuration)
      .adminAssignmentControllerAssignUser(companyId, propertyId, userId, formType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} assignmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApi
   */
  public adminAssignmentControllerUnassignForm(assignmentId: number, options?: AxiosRequestConfig) {
    return AdminAssignmentApiFp(this.configuration)
      .adminAssignmentControllerUnassignForm(assignmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} assignmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminAssignmentApi
   */
  public adminAssignmentControllerUnassignUser(assignmentId: number, options?: AxiosRequestConfig) {
    return AdminAssignmentApiFp(this.configuration)
      .adminAssignmentControllerUnassignUser(assignmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
