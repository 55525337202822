import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>`
  margin-right: 8px;

  &.Mui-checked {
    color: ${({ theme, color }) => (color ? color : theme.palette.main.emerald)};
  }

  &.MuiCheckbox-indeterminate {
    color: ${({ theme, color }) => (color ? color : theme.palette.main.emerald)};
  }
`;
