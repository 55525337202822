import { Typography } from '@mui/material';
import React from 'react';

import { AssigneeEmail, AssigneeNameContainer, AssigneesListItem } from './dt-dispatch-assignee.styled';
import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { AssigneeStatusType, DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';
import DtDispatchAssigneeStatus from '../dt-dispatch-assignee-status/dt-dispatch-assignee-status';

interface DtDispatchAssigneeProps {
  assignee: DtDispatchItemDetailsAssign;
  status: DtSupportedDispatchStatuses;
  onDelete: (userEmail: string) => void;
  onReassign: (userEmail: string) => void;
}

const DtDispatchAssignee: React.FC<DtDispatchAssigneeProps> = ({ assignee, status, onDelete, onReassign }) => {
  const isCompletedStatus = status === DtSupportedDispatchStatuses.Completed;
  const isDeclined = assignee.status === AssigneeStatusType.Declined;
  const isSubmitted = assignee.status === AssigneeStatusType.Completed;

  return (
    <AssigneesListItem disablePadding>
      <AssigneeNameContainer>
        <Typography variant='body'>{assignee.assigneeName}</Typography>
        <AssigneeEmail variant='bodySmall'>{assignee.assigneeEmail}</AssigneeEmail>
      </AssigneeNameContainer>

      <DtDispatchAssigneeStatus assignee={assignee} />

      {!isCompletedStatus && !isDeclined && !isSubmitted && (
        <DtButton variant='transparent' onClick={() => onDelete(assignee.assigneeEmail)}>
          <DtIcon icon='trash' size={20} />
        </DtButton>
      )}

      {!isCompletedStatus && isDeclined && !assignee.reassigned && (
        <DtButton variant='secondary' onClick={() => onReassign(assignee.assigneeEmail)}>
          Assign Again
        </DtButton>
      )}

      {!isCompletedStatus && isDeclined && assignee.reassigned && (
        <DtButton disabled variant='transparent' onClick={() => ({})}>
          Reassigned
        </DtButton>
      )}
    </AssigneesListItem>
  );
};

export default DtDispatchAssignee;
