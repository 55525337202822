/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum AssignmentItemType {
  User = 'USER',
  Organization = 'ORGANIZATION',
  Site = 'SITE',
  FormType = 'FORM_TYPE',
  Subtype = 'SUBTYPE',
  Asset = 'ASSET',
}
