import { addDays, differenceInCalendarDays } from 'date-fns';
import { FormikProps } from 'formik';
import { get, isArray, isEmpty, keys } from 'lodash';

import { DtFrequencyEditorFormRecurrenceType } from './dt-frequency-editor-form.enums';
import {
  DtNewDispatchFormInitialValues,
  DtWeeklyFrequencyFormValues,
  DtMonthlyFrequencyFormValues,
  DtFrequencyFieldModel,
} from './dt-new-dispatch-modal-form-interface';
import { DtCronModel, FrequencyType } from '../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import {
  DtCronItemModel,
  DtCronItemTimeModel,
  DtCronItemWithSpecialChars,
  DtCronItemWithWeekSpecialChars,
} from '../../../../cdk/utils/dt-cron/dt-cron.models';

/**
 * Validates form and reset any errors from other form groups
 */
export async function dtValidateFormGroup(
  formikProps: FormikProps<DtNewDispatchFormInitialValues>,
  group: string
): Promise<boolean> {
  const errors = await formikProps.validateForm();

  const isValid = isEmpty(get(errors, group, {}));

  // Reset error messages from other fields
  keys(formikProps.initialValues).forEach((key) => {
    if (key !== group) {
      formikProps.setFieldError(key, undefined);
    }
  });

  return isValid;
}

export function dtFormToCronModel(values: DtNewDispatchFormInitialValues): DtCronModel {
  const isNowDispatchScheduled =
    values.frequency.frequencyType === FrequencyType.None &&
    values.frequency.time?.length === 1 &&
    values.frequency.time[0] === -1;

  const isOnceFutureDispatchScheduled =
    values.frequency.frequencyType === FrequencyType.None &&
    (values.frequency.time?.length ?? 0) >= 1 &&
    values.frequency.time?.[0] !== -1;

  let model: DtCronModel | undefined;

  if (isNowDispatchScheduled) {
    model = {
      type: values.frequency.frequencyType,
      minute: new DtCronItemTimeModel(),
      hour: new DtCronItemWithSpecialChars(1),
      day: new DtCronItemWithSpecialChars(1),
      month: new DtCronItemModel(),
      week: new DtCronItemWithWeekSpecialChars(undefined, undefined, true),
    };
  } else if (isOnceFutureDispatchScheduled) {
    model = {
      type: FrequencyType.OnceFuture,
      minute: new DtCronItemTimeModel(),
      hour: new DtCronItemWithSpecialChars(undefined, undefined, undefined, values.frequency.time),
      day: new DtCronItemWithSpecialChars(values.frequency.startDate.getDate(), undefined, true),
      month: new DtCronItemModel(values.frequency.startDate.getMonth() + 1),
      week: new DtCronItemWithWeekSpecialChars(undefined, undefined, true),
      year: new DtCronItemWithSpecialChars(values.frequency.startDate.getFullYear()),
    };
  } else {
    model = {
      type: values.frequency.frequencyType,
      minute: new DtCronItemTimeModel(),
      hour: new DtCronItemWithSpecialChars(undefined, undefined, undefined, values.frequency.time),
      day: dtGetDaysCronModel(
        values.frequency,
        values.frequency.frequencyType,
        values.frequency.frequencyType === FrequencyType.Monthly ? values.frequency.repeatEvery : undefined
      ),
      month: new DtCronItemModel(
        undefined,
        values.frequency.frequencyType === FrequencyType.Monthly ? values.frequency.month : undefined
      ),
      week: dtGetWeekCronModel(
        values.frequency,
        values.frequency.frequencyType,
        values.frequency.frequencyType === FrequencyType.Monthly ? values.frequency.repeatEvery : undefined
      ),
    };
  }

  return model;
}

/**
 * Return weekly frequency in days recurrence
 * @param weekRepeat - recurrence in weeks
 */
export const dtGetWeeklyFrequencyDaysRecurrence = (weekRepeat: number): number => {
  const initialStep = 7;
  return initialStep * weekRepeat;
};

/**
 * Get day cron model from frequency form value
 * @param values
 * @param currentFrequencyType
 * @param monthFormLayoutType
 */
export const dtGetDaysCronModel = (
  values: DtFrequencyFieldModel,
  currentFrequencyType: FrequencyType,
  monthFormLayoutType?: DtFrequencyEditorFormRecurrenceType
): DtCronItemWithSpecialChars => {
  const dayMultiple = isArray(values.day);

  let value: number | undefined = values.day as number;
  let selection = undefined;
  if (dayMultiple) {
    value = undefined;
    selection = currentFrequencyType !== FrequencyType.Weekly ? values.weekday ?? [] : undefined;
  }

  let repeat = values.day;
  let notSpecific = false;
  if (currentFrequencyType === FrequencyType.Weekly) {
    repeat = dtGetWeeklyFrequencyDaysRecurrence((values as DtWeeklyFrequencyFormValues).weekRepeats as number);
  } else if (currentFrequencyType === FrequencyType.Monthly) {
    repeat = undefined;
    if (monthFormLayoutType === DtFrequencyEditorFormRecurrenceType.Specific) {
      value = undefined;
      notSpecific = true;
    }
  }

  return new DtCronItemWithSpecialChars(value, repeat as number, notSpecific, selection);
};

/**
 * Get week cron model from frequency form value
 * @param values
 * @param currentFrequencyType
 * @param monthFormLayoutType
 */
export const dtGetWeekCronModel = (
  values: DtFrequencyFieldModel,
  currentFrequencyType: FrequencyType,
  monthFormLayoutType?: DtFrequencyEditorFormRecurrenceType
): DtCronItemWithWeekSpecialChars => {
  const isMonthlyFrequencyWithSpecificRecurrence =
    currentFrequencyType === FrequencyType.Monthly &&
    monthFormLayoutType === DtFrequencyEditorFormRecurrenceType.Specific;

  let notSpecified =
    currentFrequencyType === FrequencyType.Daily || monthFormLayoutType === DtFrequencyEditorFormRecurrenceType.Each;

  let weekValue: number | undefined = undefined;
  let repeat: number | undefined = undefined;
  let selection: number[] | undefined = undefined;

  if (currentFrequencyType === FrequencyType.Weekly) {
    repeat = (values as DtWeeklyFrequencyFormValues)?.weekRepeats;
    selection = isArray((values as DtWeeklyFrequencyFormValues).weekday)
      ? (values as DtWeeklyFrequencyFormValues).weekday
      : [];
    notSpecified = true;
  } else if (isMonthlyFrequencyWithSpecificRecurrence) {
    weekValue = (values as DtMonthlyFrequencyFormValues).day;
    repeat = (values as DtMonthlyFrequencyFormValues).monthRepeats;
  }

  return new DtCronItemWithWeekSpecialChars(weekValue, repeat, notSpecified, selection);
};

/**
 * Get calculated difference between dispatch start and due dates
 * @param startDate
 * @param dueDate
 * @param customDueDate represents a value from a dat picker when a Custom option was selected
 */

export const dtCalculateStartAndDueDatesDifference = (
  startDate: Date,
  dueDate: string,
  customDueDate: Date | undefined
): string => {
  const calcDaysDifference = differenceInCalendarDays(customDueDate as Date, startDate);

  const transformedDayDifference = `${calcDaysDifference}-D`;

  return dueDate === 'Custom' ? transformedDayDifference : dueDate;
};

/**
 * Get calculated difference between dispatch start and due dates
 */
export const dtCalculateDueDateFromDiffAndStartDate = (
  startDate?: string,
  customDueDateDifference?: string
): Date | undefined => {
  if (startDate && customDueDateDifference?.match(/\d+-D/)) {
    const transformedDayDifference = parseInt(customDueDateDifference);

    const dueDate = addDays(new Date(startDate), transformedDayDifference);

    return dueDate;
  }
};
