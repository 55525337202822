import { some, isEmpty } from 'lodash';

export const dtIsUserHaveAccessToAssets = (
  properties: DtPropertyItem[],
  currentUserEmail: string | undefined
): boolean => {
  const isAssetsAvailable = some(
    properties.flatMap((property) =>
      property.formTypes.map(
        (formType) =>
          !isEmpty(formType.formUsers.find((user) => user.email === currentUserEmail)) &&
          !isEmpty(formType.formItems.find((form) => !isEmpty(form.assetList)))
      )
    ),
    (element) => element === true
  );

  return isAssetsAvailable;
};
