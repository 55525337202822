/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SiteListDto } from '../entities';
// @ts-ignore
import { SiteMutationDTO } from '../entities';
/**
 * AdminSiteApi - axios parameter creator
 * @export
 */
export const AdminSiteApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerCreate: async (
      siteMutationDTO: SiteMutationDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteMutationDTO' is not null or undefined
      assertParamExists('adminSiteControllerCreate', 'siteMutationDTO', siteMutationDTO);
      const localVarPath = `/admin/site`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(siteMutationDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSiteControllerDelete', 'id', id);
      const localVarPath = `/admin/site/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSiteControllerGet', 'id', id);
      const localVarPath = `/admin/site/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerGetAllCompanySites: async (
      id: number,
      siteId?: number,
      formType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSiteControllerGetAllCompanySites', 'id', id);
      const localVarPath = `/admin/site/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (siteId !== undefined) {
        localVarQueryParameter['siteId'] = siteId;
      }

      if (formType !== undefined) {
        localVarQueryParameter['formType'] = formType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    adminSiteControllerGetAllSites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/site`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerUpdate: async (
      id: number,
      siteMutationDTO: SiteMutationDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSiteControllerUpdate', 'id', id);
      // verify required parameter 'siteMutationDTO' is not null or undefined
      assertParamExists('adminSiteControllerUpdate', 'siteMutationDTO', siteMutationDTO);
      const localVarPath = `/admin/site/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(siteMutationDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminSiteApi - functional programming interface
 * @export
 */
export const AdminSiteApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminSiteApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSiteControllerCreate(
      siteMutationDTO: SiteMutationDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerCreate(siteMutationDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSiteControllerDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSiteControllerGet(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMutationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSiteControllerGetAllCompanySites(
      id: number,
      siteId?: number,
      formType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteListDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerGetAllCompanySites(
        id,
        siteId,
        formType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async adminSiteControllerGetAllSites(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteListDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerGetAllSites(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSiteControllerUpdate(
      id: number,
      siteMutationDTO: SiteMutationDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMutationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSiteControllerUpdate(id, siteMutationDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminSiteApi - factory interface
 * @export
 */
export const AdminSiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AdminSiteApiFp(configuration);
  return {
    /**
     *
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerCreate(siteMutationDTO: SiteMutationDTO, options?: any): AxiosPromise<number> {
      return localVarFp.adminSiteControllerCreate(siteMutationDTO, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.adminSiteControllerDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerGet(id: number, options?: any): AxiosPromise<SiteMutationDTO> {
      return localVarFp.adminSiteControllerGet(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerGetAllCompanySites(
      id: number,
      siteId?: number,
      formType?: string,
      options?: any
    ): AxiosPromise<Array<SiteListDto>> {
      return localVarFp
        .adminSiteControllerGetAllCompanySites(id, siteId, formType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    adminSiteControllerGetAllSites(options?: any): AxiosPromise<Array<SiteListDto>> {
      return localVarFp.adminSiteControllerGetAllSites(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {SiteMutationDTO} siteMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSiteControllerUpdate(
      id: number,
      siteMutationDTO: SiteMutationDTO,
      options?: any
    ): AxiosPromise<SiteMutationDTO> {
      return localVarFp
        .adminSiteControllerUpdate(id, siteMutationDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminSiteApi - interface
 * @export
 * @interface AdminSiteApi
 */
export interface AdminSiteApiInterface {
  /**
   *
   * @param {SiteMutationDTO} siteMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerCreate(siteMutationDTO: SiteMutationDTO, options?: AxiosRequestConfig): AxiosPromise<number>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerGet(id: number, options?: AxiosRequestConfig): AxiosPromise<SiteMutationDTO>;

  /**
   *
   * @param {number} id
   * @param {number} [siteId]
   * @param {string} [formType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerGetAllCompanySites(
    id: number,
    siteId?: number,
    formType?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<SiteListDto>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerGetAllSites(options?: AxiosRequestConfig): AxiosPromise<Array<SiteListDto>>;

  /**
   *
   * @param {number} id
   * @param {SiteMutationDTO} siteMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApiInterface
   */
  adminSiteControllerUpdate(
    id: number,
    siteMutationDTO: SiteMutationDTO,
    options?: AxiosRequestConfig
  ): AxiosPromise<SiteMutationDTO>;
}

/**
 * AdminSiteApi - object-oriented interface
 * @export
 * @class AdminSiteApi
 * @extends {BaseAPI}
 */
export class AdminSiteApi extends BaseAPI implements AdminSiteApiInterface {
  /**
   *
   * @param {SiteMutationDTO} siteMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerCreate(siteMutationDTO: SiteMutationDTO, options?: AxiosRequestConfig) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerCreate(siteMutationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerDelete(id: number, options?: AxiosRequestConfig) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerGet(id: number, options?: AxiosRequestConfig) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {number} [siteId]
   * @param {string} [formType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerGetAllCompanySites(
    id: number,
    siteId?: number,
    formType?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerGetAllCompanySites(id, siteId, formType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerGetAllSites(options?: AxiosRequestConfig) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerGetAllSites(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {SiteMutationDTO} siteMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSiteApi
   */
  public adminSiteControllerUpdate(id: number, siteMutationDTO: SiteMutationDTO, options?: AxiosRequestConfig) {
    return AdminSiteApiFp(this.configuration)
      .adminSiteControllerUpdate(id, siteMutationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
