import { FrequencyType } from '../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import { DtTabsItem } from '../../../../components/dt-toggle-button/dt-toggle-button';
import { DueDateType } from '../../dt-dispatches.enums';

export const DT_TAB_ITEMS: DtTabsItem<FrequencyType>[] = [
  {
    value: FrequencyType.None,
    label: 'None',
  },
  {
    value: FrequencyType.Daily,
    label: 'Daily',
  },
  {
    value: FrequencyType.Weekly,
    label: 'Weekly',
  },
  {
    value: FrequencyType.Monthly,
    label: 'Monthly',
  },
];

export const DT_REMINDER_OPTIONS = [
  { label: '1 Day Before the Due Date', value: '1-D' },
  { label: '2 Days Before the Due Date', value: '2-D' },
  { label: '3 Days Before the Due Date', value: '3-D' },
  { label: '5 Days Before the Due Date', value: '5-D' },
  { label: '1 Week Before the Due Date', value: '7-D' },
  { label: '2 Weeks Before the Due Date', value: '14-D' },
];

export const DT_DUE_DATE_MOCK_OPTIONS = [
  { label: 'End of Month', value: DueDateType.CME },
  { label: 'End of Quarter', value: DueDateType.QME },
  { label: 'End of Half Year', value: DueDateType.CHE },
  { label: 'End of Year', value: DueDateType.CYE },
  { label: 'Custom', value: DueDateType.CUSTOM },
];

export const DT_WEEK_INPUT_MIN_MAX_RESTRICTIONS = { min: 1, max: 7 };
export const DT_DAY_INPUT_MIN_MAX_RESTRICTIONS = { min: 1, max: 30 };
export const DT_MONTH_INPUT_MIN_MAX_RESTRICTIONS = { min: 1, max: 12 };
