import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';

import DtAssetsActionsTableCell from './dt-assets-actions-table-cell/dt-assets-actions-table-cell';
import { DtAssetRowItem } from './dt-assets-table';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { ActionSkeletonWrapper } from '../../../../components/dt-table/dt-actions-table-cell.styled';
import { CellSubtitle, CellTitle } from '../../../../components/dt-table/table-cell.styled';
import DtTableCellSkeleton from '../../../dt-administration/components/dt-table-cell-skeleton/dt-table-cell-skeleton';
import { CellContentWrapper } from '../../../dt-dashboard/components/dt-dispatches-table/dt-dispatch-assigner-table-cell/dt-dispatch-assigner-table-cell.styled';
import { DT_WIDTH_WITH_TWO_ICONS } from '../../../dt-dashboard/dt-dispatches.constants';

export function dtGenerateAssetsTableColumnsConfig(
  isLoading: boolean,
  openDeleteConfirmation: (id: string) => void
): GridColDef[] {
  const baseColumnConfig: GridColDef[] = [
    {
      field: 'property',
      headerName: 'Property',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.propertyName,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellSkeleton titleHeight={20} titleWidth={200} subtitleHeight={16} subtitleWidth={200} />
        ) : (
          <CellContentWrapper>
            <CellTitle titleBold={false}>{params.row.propertyName}</CellTitle>
            <CellSubtitle subtitleBold={false}>{params.row.propertyAddress}</CellSubtitle>
          </CellContentWrapper>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.formType,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.formType}</CellTitle>
        );
      },
    },
    {
      field: 'subtype',
      headerName: 'Subtype',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.formName,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.formName}</CellTitle>
        );
      },
    },
    {
      field: 'asset name',
      headerName: 'Asset Name',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.assetName,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.assetName}</CellTitle>
        );
      },
    },
    {
      field: 'asset floor',
      headerName: 'Asset Floor',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.assetFloor,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.assetFloor}</CellTitle>
        );
      },
    },
    {
      field: 'asset location',
      headerName: 'Asset Location',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.assetLocation,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.assetLocation}</CellTitle>
        );
      },
    },
    {
      field: 'last submitter',
      headerName: 'Last Submitter',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.lastSubmitter?.name || params.row.lastSubmitter?.email,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellSkeleton titleHeight={20} titleWidth={200} subtitleHeight={16} subtitleWidth={200} />
        ) : (
          <CellContentWrapper>
            <CellTitle titleBold={false}>{params.row.lastSubmitter?.name}</CellTitle>
            <CellSubtitle subtitleBold={false}>{params.row.lastSubmitter?.email}</CellSubtitle>
          </CellContentWrapper>
        );
      },
    },
    {
      field: 'date of last submission',
      headerName: 'Date of Last Submission',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.lastSubmissionDateTime,
      renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.lastSubmissionDateTime}</CellTitle>
        );
      },
    },
  ];

  const actionsColumn: GridColDef = {
    field: 'actions',
    headerName: '',
    width: DT_WIDTH_WITH_TWO_ICONS,
    sortable: false,
    align: 'right',
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<DtAssetRowItem>) => {
      return isLoading || params.row.isLoading ? (
        <ActionSkeletonWrapper>
          <DtSkeleton variant='rectangular' width={20} height={20} />
          <DtSkeleton variant='rectangular' width={20} height={20} />
        </ActionSkeletonWrapper>
      ) : (
        <DtAssetsActionsTableCell
          dtAssetId={params.row.id.toString()}
          openDeleteConfirmation={openDeleteConfirmation}
        />
      );
    },
  };

  return [...baseColumnConfig, actionsColumn];
}

export default dtGenerateAssetsTableColumnsConfig;
