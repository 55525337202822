import { format } from 'date-fns';
import * as XLSX from 'xlsx';

import { DispatchInspectionDetailsDto } from '../repositories/__generated__/v2';

export function dtExportInspectionDataToExcel(data: DispatchInspectionDetailsDto): void {
  // Prepare data
  const dataAsAoa = dtInspectionDetailsToAoa(data);

  // Extract Data (create a workbook object from the array of arrays)
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataAsAoa);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  XLSX.writeFile(workbook, 'Report.xlsx');
}

/**
 * Generates the array of arrays for the excel export for the dispatch inspection details
 */
function dtInspectionDetailsToAoa(formSubtypeData: DispatchInspectionDetailsDto): string[][] {
  // Prepare data
  const headers: string[][] = dtGenerateHeaderRows(formSubtypeData);
  const body: string[][] = [...headers];

  for (let i = 0; i < formSubtypeData.combinedData.answers.length; i++) {
    const datetime = format(new Date(formSubtypeData.combinedData.answers[i].timestamp), 'MM/dd/yy p');
    const row =
      i > 0 &&
      formSubtypeData.combinedData.answers[i].reportOrderIndex ===
        formSubtypeData.combinedData.answers[i - 1].reportOrderIndex
        ? ['']
        : [datetime];

    const answers = formSubtypeData.combinedData.questions.map((question) => {
      const answer = formSubtypeData.combinedData.answers[i].answers[question.id];
      // Render comment if there is a comment
      if (answer.comment) {
        return answer.comment;
      }

      return answer.answerContent?.toString() || '';
    });

    row.push(...answers);
    body.push(row);
  }

  return body;
}

/**
 * Generates the header rows for the excel export for the dispatch inspection details
 */
function dtGenerateHeaderRows(formSubtypeData: DispatchInspectionDetailsDto): string[][] {
  const headerPage = [''];
  const headerSection = [''];
  const headerQuestion = ['Date & Time'];
  const headers = [headerPage, headerSection, headerQuestion];

  let lastPage = '';
  let lastSection = '';
  for (const question of formSubtypeData.combinedData.questions) {
    if (question.page !== lastPage) {
      lastPage = question.page;
      headerPage.push(question.page);
    } else {
      headerPage.push('');
    }

    if (question.section !== lastSection) {
      lastSection = question.section;
      headerSection.push(question.section);
    } else {
      headerSection.push('');
    }

    headerQuestion.push(question.question);
  }
  return headers;
}
