import { isEmpty } from 'lodash';

import { DT_CRON_HOUR_BY_INDEX, DT_CRON_MONTH_BY_INDEX, DtCronModel, FrequencyType } from './dt-cron.interfaces';
import { DtCronItemModel, DtCronItemWithSpecialChars, DtCronItemWithWeekSpecialChars } from './dt-cron.models';
import { dtStringCharacterIsNumeric } from '../dt-string.utils';

/**
 * Utils-class for managing cron expressions
 * @description Cron structure:
 * ┌────────────── seconds(0 - 59)
 * │ ┌───────────── minute (0 - 59)
 * │ │ ┌───────────── hour (0 - 23)
 * │ │ │ ┌───────────── day of the month (1 - 31)
 * │ │ │ │ ┌───────────── month (1 - 12)
 * │ │ │ │ │ ┌───────────── day of the week (0 - 6) (Sunday to Saturday;
 * │ │ │ │ │ │                                   7 is also Sunday on some systems)
 * │ │ │ │ │ │ ┌───────────── year(optional) (EMPTY, 1970-2099)
 * │ │ │ │ │ │ │
 * * * * * * * * <command to execute>
 * @default
 * seconds - always 0
 * year - undefined(not included into cron expression)
 */
class DtCronExpressionUtils {
  /**
   * Convert CronModel to cron-string expression
   * @param model
   */
  dtCronModelToExpression(model: DtCronModel): string {
    const seconds = 0;
    const { minute, hour, day, month, week, year, type } = model;

    if (type === FrequencyType.Weekly) {
      const weekDays = !isEmpty(week.selection)
        ? week.selection?.map((day) => DT_CRON_MONTH_BY_INDEX[Number(day)]).join(',')
        : DT_CRON_MONTH_BY_INDEX[Number(week.strictValue)];

      const hours = !isEmpty(hour.selection)
        ? hour.selection?.map((item) => DT_CRON_HOUR_BY_INDEX[Number(item)])
        : DT_CRON_HOUR_BY_INDEX[Number(hour.strictValue)];

      return `${week.repeatEvery}#${weekDays}@${hours}`;
    }

    if (year) {
      return `${seconds} ${minute.toExpression()} ${hour.toExpression()} ${day.toExpression()} ${month.toExpression()} ${week.toExpression()} ${year.toExpression()}`;
    }

    return `${seconds} ${minute.toExpression()} ${hour.toExpression()} ${day.toExpression()} ${month.toExpression()} ${week.toExpression()}`;
  }

  /**
   * Convert cron-string expression to CronModel
   * @param expression
   */
  dtCronExpressionToCronModel(expression: string): DtCronModel {
    const type = DtCronExpressionUtils.getCronExpressionFrequencyType(expression);

    if (type === FrequencyType.Weekly) {
      const parsedExpr = DtCronExpressionUtils.splitWeeklyCronStrExpression(expression);
      const minute = DtCronItemModel.fromExpression('?');
      const hour = DtCronItemWithSpecialChars.fromExpression(parsedExpr[1], true);
      const day = DtCronItemWithSpecialChars.fromExpression('?');
      const month = DtCronItemWithSpecialChars.fromExpression('?');
      const week = DtCronItemWithWeekSpecialChars.fromExpression(parsedExpr[0], true);
      const year = undefined;

      return {
        type,
        minute,
        hour,
        day,
        month,
        week,
        year,
      };
    }
    const parsedExpr = DtCronExpressionUtils.splitCronStrExpression(expression);
    const minute = DtCronItemModel.fromExpression(parsedExpr[1]);
    const hour = DtCronItemWithSpecialChars.fromExpression(parsedExpr[2]);
    const day = DtCronItemWithSpecialChars.fromExpression(parsedExpr[3]);
    const month = DtCronItemWithSpecialChars.fromExpression(parsedExpr[4]);
    const week = DtCronItemWithWeekSpecialChars.fromExpression(parsedExpr[5]);
    const year = parsedExpr[6] ? DtCronItemWithSpecialChars.fromExpression(parsedExpr[6]) : undefined;

    return {
      type,
      minute,
      hour,
      day,
      month,
      week,
      year,
    };
  }

  /**
   * Get frequency type by cron-string expression.
   * @param expression
   * @private
   */
  private static getCronExpressionFrequencyType(expression: string): FrequencyType {
    const parsedExpr = DtCronExpressionUtils.splitCronStrExpression(expression);

    if (parsedExpr.length === 1 && parsedExpr[0].includes('@')) {
      return FrequencyType.Weekly;
    }

    const notSpecifiedDay = parsedExpr[3] === '?' || parsedExpr[3].includes('*');
    const repetition = parsedExpr[3].split('/');
    const minAllowedWeeklyRepetition = 14;
    const weekOccurrence = Number(repetition[1]) >= minAllowedWeeklyRepetition;
    const weekWithSpecialChars = ['*', '?', '-'].some((el) => parsedExpr[5].includes(el));

    if (notSpecifiedDay && weekWithSpecialChars && !weekOccurrence) {
      return FrequencyType.Daily;
    }

    const weekIsSpecified = Object.values(DT_CRON_MONTH_BY_INDEX).includes(parsedExpr[5]);
    if (weekOccurrence || weekIsSpecified || parsedExpr[5].includes(',')) {
      return FrequencyType.Weekly;
    }

    const dayIsNumeric = dtStringCharacterIsNumeric(parsedExpr[3]);
    const hasWeekOccurrence = parsedExpr[5].includes('#');
    if ((dayIsNumeric || hasWeekOccurrence) && !parsedExpr[6]) {
      return FrequencyType.Monthly;
    }

    return FrequencyType.Yearly;
  }

  /**
   * Split cron-string expression by spaces separator.
   * @param expression
   * @private
   */
  private static splitCronStrExpression(expression: string): string[] {
    return expression.split(' ');
  }

  /**
   * Split cron-string expression by @ separators.
   * @param expression
   * @private
   */
  private static splitWeeklyCronStrExpression(expression: string): string[] {
    return expression.split('@');
  }
}

export const dtCronExpressionUtils = new DtCronExpressionUtils();
