import { styled } from '@mui/material/styles';
import DtButton from '../../../../../../components/dt-button/dt-button';
import { DialogActionsContainer } from '../../../../components/dt-dispatches-table/dt-dispatch-confirmation-popup/dt-dispatch-confirmation-popup.styled';

export const DialogActionsWrapper = styled(DialogActionsContainer)`
  &.MuiDialogActions-root > :not(:first-of-type) {
    margin-left: 0;
  }

  margin-top: auto;
  padding: ${({ theme }) => `${theme.spacing(40)} ${theme.spacing(0)}`};
`;

export const DialogButton = styled(DtButton)`
  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(38)}`};

  &:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(24)};
  }
`;
