import { styled } from '@mui/material/styles';

export const FilterPanelContainer = styled('section')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const FiltersWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(16)};
`;
