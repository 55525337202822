import React from 'react';

import { LoginInputContainer } from './dt-login-page-skeleton.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';

const DtLoginFormSkeleton: React.FC = () => {
  return (
    <>
      <LoginInputContainer>
        <DtSkeleton variant='rectangular' height={40} />
      </LoginInputContainer>
      <LoginInputContainer>
        <DtSkeleton variant='rectangular' height={40} />
      </LoginInputContainer>
      <DtSkeleton variant='rectangular' width={148} height={40} />
    </>
  );
};

export default DtLoginFormSkeleton;
