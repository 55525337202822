import { isNil } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { DtAppRoutes } from '../../dt-enums';

type DtPrivateRouteProps = {
  hasAccess: boolean;
  redirectUrl: DtAppRoutes;
  hasSelectedCompany?: boolean;
  isUserSuperAdmin?: boolean;
  currentPathname?: string;
};

const DtPrivateRoute: React.FC<DtPrivateRouteProps> = ({
  hasAccess,
  redirectUrl,
  hasSelectedCompany,
  isUserSuperAdmin,
  currentPathname,
}) => {
  if (!hasAccess) {
    return <Navigate to={redirectUrl} />;
  }

  if (
    isUserSuperAdmin &&
    !hasSelectedCompany &&
    !isNil(hasSelectedCompany) &&
    currentPathname !== DtAppRoutes.OrganizationCreate
  ) {
    return <Navigate to={DtAppRoutes.OrganizationCreate} />;
  }

  return <Outlet />;
};

export default DtPrivateRoute;
