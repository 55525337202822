import { Field, FieldProps } from 'formik';
import React, { ReactElement } from 'react';

import DtSelect from './dt-select/dt-select';
import { DtSelectProps } from './dt-selects.interfaces';

const DtFormikSelect = <T,>({ name, ...props }: Partial<DtSelectProps<T>>): ReactElement => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <DtSelect<T>
          value={field.value}
          onChange={(value?: T) => {
            form.setFieldError(field.name, undefined);
            form.setFieldValue(field.name, value);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          withFlexProp={props.withFlexProp}
          disabled={props.disabled}
          label={props.label}
          labelPosition={props.labelPosition}
          className={props.className}
          fullWidth={props.fullWidth}
          minWidth={props.minWidth}
          placeholder={props.placeholder}
          name={field.name}
          disableClearable={props.disableClearable}
          options={props.options ?? []}
          error={meta.error}
        />
      )}
    </Field>
  );
};

export default DtFormikSelect;
