import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';

import DtSkeleton from './../../../../../components/dt-skeleton/dt-skeleton';
import DtTableCellActionsSkeleton from '../../../components/dt-table-cell-actions-skeleton/dt-table-cell-actions-skeleton';
import DtTableCellSkeleton from '../../../components/dt-table-cell-skeleton/dt-table-cell-skeleton';
import { DT_ACTIONS_COLUMN_WIDTH } from '../../../dt-administration.constants';
import DtManageUsersActionsTableCell from '../dt-manage-users-actions-table-cell/dt-manage-users-actions-table-cell';
import DtManageUsersLastLoginTableCell from '../dt-manage-users-last-login-table-cell/dt-manage-users-last-login-table-cell';
import DtUsersNameTableCell from '../dt-manage-users-name-table-cell/dt-manage-users-name-table-cell';
import DtManageUsersResendInviteButton from '../dt-manage-users-resend-invite-button';
import DtUserRoleBadge from '../dt-user-role-badge/dt-user-role-badge';

export function dtGenerateManageUsersTableColumnsConfig(isLoading: boolean): GridColDef[] {
  return [
    {
      field: 'user name',
      headerName: 'User Name',
      flex: 1.2,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueGetter: (params) => params.row.fullName || '',
      renderCell: (params: GridRenderCellParams) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellSkeleton titleHeight={20} titleWidth={150} subtitleHeight={8} subtitleWidth={150} />
        ) : (
          <DtUsersNameTableCell fullName={params.row.fullName} userEmail={params.row.email} />
        );
      },
    },
    {
      field: 'user type',
      headerName: 'User Type',
      flex: 1,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueGetter: (params) => params.row.role,
      renderCell: (params: GridRenderCellParams) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={140} height={24} />
        ) : (
          <DtUserRoleBadge
            userRole={params.row.role}
            hasMobileAppAccess={params.row.hasMobileAppAccess}
            hasWebAppAccess={params.row.hasWebAppAccess}
          />
        );
      },
    },
    {
      field: 'last login',
      headerName: 'Last Login',
      flex: 0.75,
      headerClassName: 'MuiDataGrid-LastLoginColumnHeader',
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueGetter: (params) => params.row.lastLogin,
      renderCell: (params: GridRenderCellParams) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellSkeleton
            titleHeight={20}
            titleWidth={90}
            subtitleHeight={8}
            subtitleWidth={90}
            withButton={true}
          />
        ) : (
          <>
            {params.row.lastLogin && (
              <DtManageUsersLastLoginTableCell
                lastLogin={params.row.lastLogin}
                amountOfLoggedInTimes={params.row.amountOfLoggedInTimes}
              />
            )}
            <DtManageUsersResendInviteButton userEmail={params.row.email} userStatus={params.row.status} />
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: DT_ACTIONS_COLUMN_WIDTH,
      sortable: false,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellActionsSkeleton />
        ) : (
          <DtManageUsersActionsTableCell dtUserEmail={params.row.email} isCurrentUser={params.row.isCurrentUser} />
        );
      },
    },
  ];
}

export default dtGenerateManageUsersTableColumnsConfig;
