import { dtAuthService } from './dt-auth.service';
import { DtOneMinuteObservedIntervalService } from './dt-one-minute-observed-interval.service';
import { dtToastService } from './dt-toast.service';
import { DtAppRoutes } from '../dt-enums';
import { dtRouter } from '../dt-routes';
import { dtStore } from '../dt-store';
import { dtClearFormTypes } from '../modules/dt-administration/dt-forms-tab/dt-forms.slice';
import { dtClearUsers } from '../modules/dt-administration/dt-manage-users-tab/dt-manage-users.slice';
import { dtClearOrganizations } from '../modules/dt-administration/dt-organizations-tab/dt-organizations.slice';
import { dtClearSites } from '../modules/dt-administration/dt-sites-tab/dt-sites.slice';
import { dtClearDispatches } from '../modules/dt-dashboard/dt-dispatches.slice';
import {
  dtClearNavigationEntities,
  dtUpdateNavigationPropertiesAndFormTypes,
} from '../modules/dt-navigation/dt-navigation.slice';
import { dtClearProperties } from '../modules/dt-properties/dt-properties.slice';
import { dtLoadUser } from '../modules/dt-user/dt-user.actions';
import { dtClearUser, dtSetUserData } from '../modules/dt-user/dt-user.slice';

/**
 * Service for app lifecycle management
 */
class DtAppLifecycleService {
  private ready = false;

  get isReady(): boolean {
    return this.ready;
  }

  /**
   * Method for user login lifecycle
   */
  async login(user: DtAWSCognitoUser | null = null, fromLoginForm = false): Promise<void> {
    // Stop global reusable interval
    DtOneMinuteObservedIntervalService.stopIfRunning();
    let cognitoUser = user;

    if (!cognitoUser) {
      try {
        cognitoUser = await dtAuthService.getUser();
      } catch (error) {
        console.warn('Cannot get current user from Cognito. Get user during login failed!');

        if (error !== 'The user is not authenticated') {
          // Logout if cannot get current user from Cognito
          this.logout();
        } else {
          this.resetDataOnLogout();
        }
        this.ready = true;
        return;
      }
    }

    if (cognitoUser) {
      // Start global reusable interval
      DtOneMinuteObservedIntervalService.startIfNotRunning();
      await this.initAppDataStorage(fromLoginForm);
    } else {
      dtRouter.navigate(DtAppRoutes.Login);
      this.ready = true;
      return;
    }
  }

  /**
   * Method for user logout lifecycle
   */
  async logout(): Promise<void> {
    try {
      dtToastService.disableAlerts();
      await dtAuthService.signOut();
      location.reload();
    } catch (e) {
      console.error('Logout failed!', e as Error);
    }
  }

  /**
   * Method for initialization of app lifecycle
   */
  async init(): Promise<void> {
    dtAuthService.init();

    await this.login();
  }

  async initAppDataStorage(fromLoginForm?: boolean): Promise<void> {
    await dtStore.dispatch(dtLoadUser(fromLoginForm));
    await dtStore.dispatch(dtUpdateNavigationPropertiesAndFormTypes());
    this.ready = true;
  }

  clearAppDataStorage(): void {
    dtStore.dispatch(dtClearUser());
    dtStore.dispatch(dtClearProperties());
    dtStore.dispatch(dtClearNavigationEntities());
    dtStore.dispatch(dtClearDispatches());
    dtStore.dispatch(dtClearUsers());
    dtStore.dispatch(dtClearOrganizations());
    dtStore.dispatch(dtClearSites());
    dtStore.dispatch(dtClearFormTypes());
  }

  private async resetDataOnLogout(): Promise<void> {
    await dtAuthService.signOut();
    dtStore.dispatch(dtSetUserData(null));
    this.clearAppDataStorage();
  }
}

export const dtAppLifecycleService = new DtAppLifecycleService();
