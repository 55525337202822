import React, { ReactNode } from 'react';

import DtAssetsTableSkeleton from '../../../../../components/dt-assets-table/dt-assets-table-skeleton';
import DtAssignmentSkeleton from '../../../../../components/dt-assignment-blocks/components/dt-assignment-skeleton/dt-assignment-skeleton';
import {
  DtAdministrationDetailsContent,
  DtAdministrationDetailsContentDivider,
} from '../dt-administration-details-layout.styled';

interface DtAdministrationDetailsSkeletonProps {
  children?: ReactNode;
  showAssetsTableSkeleton?: boolean;
  hideParentAssignButtonSkeleton?: boolean;
  hideChildAssignButtonSkeleton?: boolean;
}

const DtAdministrationDetailsSkeleton: React.FC<DtAdministrationDetailsSkeletonProps> = ({
  children,
  showAssetsTableSkeleton,
  hideParentAssignButtonSkeleton,
  hideChildAssignButtonSkeleton,
}) => {
  return (
    <>
      <DtAdministrationDetailsContent>{children}</DtAdministrationDetailsContent>

      <DtAdministrationDetailsContentDivider />

      <DtAdministrationDetailsContent>
        {showAssetsTableSkeleton ? (
          <DtAssetsTableSkeleton />
        ) : (
          <DtAssignmentSkeleton
            hideParentAssignButtonSkeleton={hideParentAssignButtonSkeleton}
            hideChildAssignButtonSkeleton={hideChildAssignButtonSkeleton}
          />
        )}
      </DtAdministrationDetailsContent>
    </>
  );
};

export default DtAdministrationDetailsSkeleton;
