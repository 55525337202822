import { ListItem, ListItemButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DtAssignmentModalOptions, DtAssignmentModalProps } from './dt-assignment-modal.interface';
import {
  AssignmentsList,
  AssignmentsListHeader,
  AssignModalCancelButton,
  SelectAllListItem,
} from './dt-assignment-modal.styled';
import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import {
  DialogContainer,
  DialogTitleContainer,
  ModalTitleMain,
  TitleSecondary,
  DialogContentContainer,
  DialogActionsContainer,
} from '../../../../modules/dt-dashboard/modals/dt-dispatch-details-modal/dt-dispatch-details-modal.styled';
import { CustomCheckbox } from '../../../dt-checkbox/dt-checkbox.styled';
import { EmptyResultsText } from '../../../dt-search-input-for-select/dt-search-input-for-select.styled';
import { AssignmentHeaderCounter, AssignmentSearchInput, ListItemsScrollbar } from '../../dt-assignment-blocks.styled';

const DtAssignmentModal: React.FC<DtAssignmentModalProps> = ({
  title,
  subtitle,
  onClose,
  onSubmit,
  options,
  selectedIds,
}) => {
  const [searchText, setSearchText] = useState('');
  const [checked, setChecked] = React.useState<(number | string)[]>(selectedIds ? selectedIds : []);
  const [assignOptions, setAssignOptions] = useState<DtAssignmentModalOptions[]>(options);

  const isCheckAll = checked.length === assignOptions.length;

  useEffect(() => {
    Boolean(searchText) ? setAssignOptions(listItemsBySearchedText) : setAssignOptions(options);
  }, [searchText]);

  const listItemsBySearchedText = options.filter((filteredItem) => {
    return searchText === ''
      ? filteredItem
      : filteredItem.displayValue?.toLowerCase().includes(searchText.toLowerCase());
  });

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    setSearchText(e.target.value);
  }

  function handleInputClear(): void {
    setSearchText('');
    setAssignOptions(options);
  }

  function handleToggle(value: number | string): void {
    checked.includes(value) ? setChecked(checked.filter((v) => v !== value)) : setChecked([...checked, value]);
  }

  function toggleSelectAll(): void {
    isCheckAll ? setChecked([]) : setChecked(assignOptions.map((li) => li.id));
  }

  const isEmptySearchList = Boolean(searchText) && !listItemsBySearchedText.length;

  const assignmentCounter =
    checked.length > 0 && !Boolean(searchText)
      ? `${checked.length} of ${options.length}`
      : Boolean(searchText)
      ? `${listItemsBySearchedText.length} of ${options.length}`
      : options.length;

  function handleSubmit(): void {
    const selectedOptions = options.filter((element) => checked.includes(element.id));
    onSubmit(selectedOptions);
    onClose();
  }

  return (
    <DialogContainer open={true} onClose={onClose} transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogTitleContainer>
        <section>
          <ModalTitleMain variant='h3'>Assign {title}</ModalTitleMain>
          <TitleSecondary variant='bodySmall'>{subtitle}</TitleSecondary>
        </section>
        <section>
          <DtButton variant='transparent' onClick={onClose}>
            <DtIcon icon='close' size={20} />
          </DtButton>
        </section>
      </DialogTitleContainer>
      <DialogContentContainer>
        <AssignmentSearchInput
          placeholder='Search'
          value={searchText}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: Boolean(searchText) && (
              <DtButton onClick={handleInputClear} variant='transparent'>
                <DtIcon icon='close' size={15} />
              </DtButton>
            ),
          }}
        />
        {isEmptySearchList && <EmptyResultsText>Not found</EmptyResultsText>}

        <AssignmentsList>
          <AssignmentsListHeader>
            {!Boolean(searchText) && listItemsBySearchedText.length && (
              <SelectAllListItem onClick={toggleSelectAll} dense style={{ paddingLeft: '16px' }}>
                <CustomCheckbox
                  edge='start'
                  checked={isCheckAll}
                  tabIndex={-1}
                  disableRipple
                  indeterminate={checked.length > 0 && checked.length < assignOptions.length}
                  size='small'
                />
                <Typography variant='bodySemibold'>Select All Available {title}</Typography>
              </SelectAllListItem>
            )}
            <AssignmentHeaderCounter
              label={assignmentCounter}
              style={{ marginLeft: 'auto', height: 'fit-content', alignSelf: 'center', marginRight: '15px' }}
            />
          </AssignmentsListHeader>
          <ListItemsScrollbar sx={{ maxHeight: '290px', paddingRight: '15px' }}>
            {assignOptions.map((item) => {
              return (
                <ListItem key={item.id} disablePadding selected={checked.includes(item.id)}>
                  <ListItemButton onClick={() => handleToggle(item.id)} style={{ padding: '10px 16px' }}>
                    <CustomCheckbox
                      edge='start'
                      checked={checked.includes(item.id)}
                      tabIndex={-1}
                      disableRipple
                      size='small'
                    />
                    <Typography variant='body'>{item.displayValue}</Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </ListItemsScrollbar>
        </AssignmentsList>
      </DialogContentContainer>
      <DialogActionsContainer>
        <AssignModalCancelButton variant='secondary' onClick={onClose}>
          Cancel
        </AssignModalCancelButton>
        <DtButton type='submit' variant='primary' onClick={handleSubmit}>
          Submit
        </DtButton>
      </DialogActionsContainer>
    </DialogContainer>
  );
};

export default DtAssignmentModal;
