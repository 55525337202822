import { GridRowParams, GridSortModel } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';

import dtGenerateManageUsersTableColumnsConfig from './dt-generate-manage-users-table-columns-config';
import { dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtSearchInput from '../../../../../components/dt-search-input/dt-search-input';
import DtTable from '../../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../../dt-constants';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import DtCreateButton from '../../../components/dt-create-button/dt-create-button';
import { DT_TABLE_ROW_HEIGHT } from '../../../dt-administration.constants';
import { dtSelectUsers, dtSelectUsersLoading } from '../../dt-manage-users.slice';

const DtManageUsersTable: React.FC = () => {
  const users = dtUseAppSelector(dtSelectUsers);
  const isLoading = dtUseAppSelector(dtSelectUsersLoading);

  const showLoaderWhenNoDataYet = isLoading && !users.length;

  const [searchQuery, setSearchQuery] = useState('');

  const matchedUsers = useMemo(
    () =>
      users.filter((user) => {
        const normalizedUserRole = user.role ? (user.role as string).replace(/_/g, ' ') : '';
        const userInfo = user.fullName + user.email + normalizedUserRole + user.companyName;
        return userInfo.toLowerCase().includes(searchQuery.toLowerCase());
      }),
    [users, searchQuery]
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'user name',
      sort: 'asc',
    },
  ]);

  const dtManageUsersTableColumns = dtGenerateManageUsersTableColumnsConfig(isLoading);

  function handleUserCreateRedirect(): void {
    dtRouter.navigate(DtAppRoutes.UserCreate);
  }

  function handleEditUser(params: GridRowParams): void {
    dtRouter.navigate(RoutesResolver.UserDetails(params.row.email));
  }

  const tableActionsComponent = (
    <div style={{ display: 'flex' }}>
      <DtSearchInput onChange={setSearchQuery} />
      <DtCreateButton onClick={handleUserCreateRedirect} variant='primary' iconSize={16} iconColor='white'>
        New User
      </DtCreateButton>
    </div>
  );

  return (
    <DtTable
      rows={matchedUsers}
      getRowId={(row) => row.email}
      columns={dtManageUsersTableColumns}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      sortModel={sortModel}
      onSortModelChange={(newSortModel: GridSortModel) => setSortModel(newSortModel)}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      loading={showLoaderWhenNoDataYet}
      actionsComponent={tableActionsComponent}
      onRowClick={handleEditUser}
    />
  );
};

export default DtManageUsersTable;
