import React from 'react';

import { CellTitle, TableCellContainer } from './dt-table-cell-skeleton.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';

interface DtTableCellSkeletonProps {
  titleHeight: number;
  titleWidth: number;
  subtitleHeight: number;
  subtitleWidth: number;
  withButton?: boolean;
}

const DtTableCellSkeleton: React.FC<DtTableCellSkeletonProps> = ({
  titleHeight,
  titleWidth,
  subtitleHeight,
  subtitleWidth,
  withButton,
}) => {
  return (
    <TableCellContainer style={{ marginLeft: withButton ? '24px' : 0 }}>
      <CellTitle>
        <DtSkeleton variant='rectangular' height={titleHeight} width={titleWidth} />
      </CellTitle>
      <DtSkeleton variant='text' height={subtitleHeight} width={subtitleWidth} />
    </TableCellContainer>
  );
};

export default DtTableCellSkeleton;
