import { createFilterOptions } from '@mui/material';
import _, { isEqual } from 'lodash';
import React from 'react';

import DtIcon from '../../dt-icon/dt-icon';
import DtSelectDropdown from '../components/dt-select-dropdown';
import { DtAvailableOptionItems, DtOptionItem, DtSelectProps } from '../dt-selects.interfaces';
import { CustomSelect, CustomSelectContainer, CustomTextField, Label, LabelInside } from '../dt-selects.styled';

const DtCreatableSelect: React.FC<DtSelectProps<DtAvailableOptionItems>> = ({
  value,
  onChange,
  options,
  disabled = false,
  label,
  labelPosition,
  placeholder,
  fullWidth,
  name,
  error,
  minWidth,
}) => {
  const disabledInput = disabled || options.length === 0;
  const getOutsideSelectLabel = labelPosition === 'top' && label && <Label disabled={disabledInput}>{label}</Label>;
  const getInnerSelectLabel = labelPosition === 'inside' && label && <LabelInside>{label}: </LabelInside>;

  const customCreateFilterOptions = createFilterOptions<DtOptionItem<DtAvailableOptionItems>>();

  function checkIfOptionEqualToValue(
    option: DtOptionItem<DtAvailableOptionItems>,
    selectedOption: DtOptionItem<DtAvailableOptionItems>
  ): boolean {
    return isEqual(option, selectedOption);
  }

  function handleOptionValueChange(newValue: string | DtOptionItem<DtAvailableOptionItems> | null): void {
    if (_.isString(newValue)) {
      onChange(newValue);
    } else {
      onChange(newValue?.value);
    }
  }

  const valueToPass = _.find(options, { value }) as DtOptionItem<string> | undefined;

  return (
    <CustomSelectContainer>
      {getOutsideSelectLabel}
      <CustomSelect<DtOptionItem<DtAvailableOptionItems>, false, false, true>
        value={valueToPass ?? null}
        disablePortal
        selectOnFocus
        clearOnBlur
        fullWidth={fullWidth}
        autoSelect
        blurOnSelect
        handleHomeEndKeys
        freeSolo
        options={options}
        onChange={(_, newValue) => handleOptionValueChange(newValue)}
        isOptionEqualToValue={(option, selectedOption) => checkIfOptionEqualToValue(option, selectedOption)}
        popupIcon={<DtIcon icon='drop-down' size={20} />}
        PaperComponent={({ children }) => <DtSelectDropdown>{children}</DtSelectDropdown>}
        filterOptions={(options, params) => {
          const optionsItems = customCreateFilterOptions(options, params);
          const { inputValue } = params;

          const isSearchedValueExisting = options.some((option) => inputValue === option.label);

          if (inputValue !== '' && !isSearchedValueExisting) {
            optionsItems.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
            });
          }

          return optionsItems;
        }}
        renderInput={(params) => {
          return (
            <CustomTextField
              {...params}
              variant='outlined'
              name={name}
              error={Boolean(error)}
              helperText={error}
              labelPosition={labelPosition}
              minWidth={minWidth}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: getInnerSelectLabel,
              }}
              disabled={disabledInput}
            />
          );
        }}
      />
    </CustomSelectContainer>
  );
};

export default DtCreatableSelect;
