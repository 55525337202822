import React from 'react';

import { CustomButton } from './dt-button.styled';

export interface DtButtonProps {
  /**
   * @param {string} variant - represent a specific variant for a particular button (specific styles applied)
   * @default specific variant: 'primary', 'secondary'
   * @example 'primary', 'secondary'
   */
  variant: 'primary' | 'secondary' | 'flat' | 'transparent' | 'raised';
  /**
   * @param {boolean} disabled - represent a disabled state of a button
   * @default undefined
   * @example 'true', 'false'
   */
  disabled?: boolean;
  /**
   * @param {React.ReactNode} startIcon - represent a left side position of an icon inside a button
   * @default undefined
   * @example startIcon={<DtIcon icon="check" size="16" />}
   */
  startIcon?: React.ReactNode;
  /**
   * @param {React.ReactNode} startIcon - represent a right side position of an icon inside a button
   * @default undefined
   * @example startIcon={<DtIcon icon="check" size="16" />}
   */
  endIcon?: React.ReactNode;
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
  /**
   * @param {string} type -button type
   * @default undefined
   */
  type?: 'button' | 'reset' | 'submit';
  children?: React.ReactNode;
  /**
   * @param { number} padding - define a custom padding for a particular button
   * @default undefined
   * @example padding={10};
   */
  padding?: number;
  onClick: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  css?: React.CSSProperties;
}

const DtButton: React.FC<DtButtonProps> = (props) => {
  function handleButtonClick(e: React.MouseEvent<Element, MouseEvent>): void {
    e.preventDefault();
    e.stopPropagation();
    props.onClick();
  }

  return (
    <CustomButton
      className={props.className}
      type={props.type}
      padding={props.padding}
      variant={props.variant}
      disabled={props.disabled}
      style={props.css}
      disableRipple
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      onClick={handleButtonClick}
    >
      {props.children}
    </CustomButton>
  );
};

export default DtButton;
