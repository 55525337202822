import { CSSObject, styled } from '@mui/material/styles';
import { TextField as FormikMuiTextField } from 'formik-mui';

const TRANSPARENT_BORDER = '1.5px solid transparent';

export const FormTextInputCustom = styled(FormikMuiTextField)`
  width: 100%;
  ${({ theme }) => theme.mixins.textInputCommonStylesMixin(theme)};
  position: relative;

  & .MuiFormHelperText-root {
    ${({ theme }) => theme.typography.caption as CSSObject};
    position: absolute;
    top: calc(100% + 1px);

    &.Mui-error {
      margin: 0;
      color: ${({ theme }) => theme.palette.main.red};
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border: ${TRANSPARENT_BORDER};
    }

    &:hover {
      fieldset {
        border: ${TRANSPARENT_BORDER};
      }
    }

    &.Mui-focused {
      fieldset {
        border: ${({ theme }) => `1.5px solid ${theme.palette.main.primary}`};
      }
    }

    &.Mui-error {
      background-color: #fef3f4;

      fieldset {
        border: ${({ theme }) => `1.5px solid ${theme.palette.main.red}`};
      }
    }
  }

  & input:-webkit-autofill::first-line {
    font-size: 14px;
  }
`;
