import React, { ReactNode, useCallback, useEffect } from 'react';
import SimpleBarCore from 'simplebar-core';

import { ScrollbarContainer } from './dt-scrollbar.styled';

export interface DtScrollbarProps {
  children: ReactNode;
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
  horizontal?: boolean;
  height?: string;
  minHeight?: string;
  ignoredSelector?: string;
  leftHorizontalMargin?: string;
  onScrollChange?: (atStart: boolean) => void;
}

const DtScrollbar: React.FC<DtScrollbarProps> = ({
  children,
  className,
  horizontal,
  onScrollChange,
  ignoredSelector,
  leftHorizontalMargin,
  height,
  minHeight,
}) => {
  const ref = React.useRef<SimpleBarCore>(null);

  // Invert the wheel direction for horizontal scrollbars
  const onWheel = useCallback(
    (e: WheelEvent) => {
      if (horizontal) {
        if (!ref.current) {
          return;
        }
        const parent = ref.current.getScrollElement();
        if (parent) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const isScrolledInParent = parent.contains(e.target as any);
          if (ignoredSelector) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ignoredDescendant = (e.target as any).closest(ignoredSelector);
            const isScrolledInIgnoredDescendant = parent.contains(ignoredDescendant);
            if (isScrolledInIgnoredDescendant) {
              return;
            }
          }
          if (!isScrolledInParent) {
            return;
          }
          parent.scrollLeft += e.deltaY;
          if (onScrollChange) {
            onScrollChange(parent.scrollLeft === 0);
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    [ref, horizontal]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.removeEventListener('wheel', onWheel, { passive: false } as any);
    window.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.removeEventListener('wheel', onWheel, { passive: false } as any);
    };
  }, [onWheel]);

  return (
    <ScrollbarContainer
      ref={ref}
      className={className}
      horizontal={horizontal}
      leftHorizontalMargin={leftHorizontalMargin}
      height={height}
      minHeight={minHeight}
    >
      {children}
    </ScrollbarContainer>
  );
};

export default DtScrollbar;
