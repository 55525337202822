import { useFormikContext } from 'formik';
import { every, isEmpty } from 'lodash';
import React from 'react';

import { CancelButton, SaveButton } from './dt-administration-details-form-control.styled';
import { dtUseAppDispatch } from '../../../../cdk/hooks/dt-store.hooks';
import { dtUsePreventReloadHook } from '../../../../cdk/hooks/dt-use-prevent-reload.hook';
import { ActionButtonsWrapper } from '../../../../components/dt-table/dt-actions-table-cell.styled';
import DtPrompt from '../../components/dt-prompt/dt-prompt';
import { dtClearAssignments, dtRevertAssignments } from '../../dt-assignments.slice';
import { DtOrganizationDetailsInitialValues } from '../pages/dt-administration-details-organization-page/dt-administration-details-organization-form/dt-administration-details-organization-form.interface';

interface DtAdministrationDetailsFormControlProps {
  isCreationFlow?: boolean;
  cancelButtonTitle?: string;
  setValidationAfterSubmit: (state: boolean) => void;
}

const DtAdministrationDetailsFormControl: React.FC<DtAdministrationDetailsFormControlProps> = (props) => {
  const { dirty, isSubmitting, initialValues, values, validateForm, submitForm, resetForm } =
    useFormikContext<DtOrganizationDetailsInitialValues>();
  const dispatch = dtUseAppDispatch();

  const isWarningPopupShown = dirty ?? false;
  const disableSubmitButton = !dirty && !props.isCreationFlow;
  const disableCancelButton = every(Object.values(values), (element) => isEmpty(element));

  dtUsePreventReloadHook(isWarningPopupShown);

  function onCancel(): void {
    if (props.isCreationFlow) {
      dispatch(dtClearAssignments());
    } else {
      dispatch(dtRevertAssignments());
    }
  }

  function handleSubmitForm(): void {
    validateForm();
    submitForm();
    props.setValidationAfterSubmit && props.setValidationAfterSubmit(true);
  }

  return (
    <>
      <DtPrompt when={isWarningPopupShown} />
      <ActionButtonsWrapper>
        {!disableSubmitButton && (
          <CancelButton
            variant='secondary'
            onClick={() => {
              resetForm({
                values: initialValues,
              });
              props.setValidationAfterSubmit && props.setValidationAfterSubmit(false);
              if (onCancel) {
                onCancel();
              }
            }}
            disabled={disableCancelButton || isSubmitting}
          >
            {props.cancelButtonTitle ?? 'Cancel'}
          </CancelButton>
        )}
        <SaveButton
          type='submit'
          variant='primary'
          onClick={handleSubmitForm}
          disabled={disableSubmitButton || isSubmitting}
        >
          Save
        </SaveButton>
      </ActionButtonsWrapper>
    </>
  );
};

export default DtAdministrationDetailsFormControl;
