import axios, { AxiosResponse } from 'axios';
import { endOfDay, format, startOfDay } from 'date-fns';
import FileSaver from 'file-saver';
import { lowerCase, upperFirst } from 'lodash';

import { DT_DISPATCH_DEFAULT_FILTERS } from './dt-dashboard.constants';
import { DT_DISPATCHES_STATUS_MAPPING, DT_DISPATCH_PRIORITY_TYPE_MAPPING } from './dt-dispatches.constants';
import { AssigneeStatusType, DtDispatchPriorityType, DtSupportedDispatchStatuses } from './dt-dispatches.enums';
import { DtDispatchItems } from './dt-dispatches.slice';
import { DtNewDispatchFormInitialValues } from './modals/dt-new-dispatch-modal/dt-new-dispatch-modal-form-interface';
import {
  dtCalculateStartAndDueDatesDifference,
  dtFormToCronModel,
} from './modals/dt-new-dispatch-modal/dt-new-dispatch-modal.utils';
import { dtCronExpressionUtils } from '../../cdk/utils/dt-cron/dt-cron-expression.utils';
import { DtCronModel } from '../../cdk/utils/dt-cron/dt-cron.interfaces';
import {
  FormAssign,
  FormDataFile,
  FormDispatchGroup,
  FormFilter,
  FormSearchSummaryDTO,
  ScheduleBasicDTO,
} from '../../repositories/__generated__/v2';
import { dtApiRepository } from '../../repositories/dt-api.repository';
import { dtToastService } from '../../services/dt-toast.service';

class DtDispatchService {
  async getDtDispatches(
    companyId: number,
    filters: DtDispatchFilters,
    status: DtSupportedDispatchStatuses
  ): Promise<DtDispatchRowItem[]> {
    try {
      const response = await dtApiRepository.DispatchApi.dispatchControllerSearchForm(
        DtDispatchService.mapDtDispatchFiltersToFormFilters(companyId, filters, status)
      );

      if (!response.data || !response.data?.length) {
        return [];
      }

      return DtDispatchService.mapFormDispatchDataToDtDispatchItems(response.data);
    } catch (error) {
      console.error(error);
      dtToastService.error(`Failed to get dispatches for ${DT_DISPATCHES_STATUS_MAPPING[status]} status`);
      return [];
    }
  }

  async getDtScheduledDispatches(
    companyId: number,
    filter: DtDispatchFilters,
    status: DtSupportedDispatchStatuses
  ): Promise<DtScheduleDispatchItem[]> {
    try {
      const scheduleResponse = await dtApiRepository.DispatchApi.dispatchControllerSearchScheduled({
        companyId,
        propertyId: filter.propertyId,
        formId: filter.formId,
        formType: filter.formType,
      });

      if (!scheduleResponse.data || !scheduleResponse.data?.length) {
        return [];
      }

      return DtDispatchService.mapScheduleDispatchDataToDtScheduleDispatchItem(scheduleResponse.data);
    } catch (error) {
      console.error(error);
      dtToastService.error(`Failed to get dispatches for ${DT_DISPATCHES_STATUS_MAPPING[status]} status`);
      return [];
    }
  }

  async getALL(
    filters: DtDispatchFilters | undefined,
    currentCompanyId: number
  ): Promise<PromiseSettledResult<(DtDispatchRowItem | DtScheduleDispatchItem)[]>[]> {
    const companyId = currentCompanyId;
    const dispatchFilters = filters || DT_DISPATCH_DEFAULT_FILTERS;

    const dtOpenDispatches = dtDispatchService.getDtDispatches(
      companyId,
      dispatchFilters,
      DtSupportedDispatchStatuses.Open.toUpperCase() as DtSupportedDispatchStatuses
    );
    const dtOverdueDispatches = dtDispatchService.getDtDispatches(
      companyId,
      dispatchFilters,
      DtSupportedDispatchStatuses.Overdue.toUpperCase() as DtSupportedDispatchStatuses
    );

    const dtCompletedDispatches = dtDispatchService.getDtDispatches(
      companyId,
      dispatchFilters,
      DtSupportedDispatchStatuses.Done.toUpperCase() as DtSupportedDispatchStatuses
    );

    const dtScheduledDispatches = dtDispatchService.getDtScheduledDispatches(
      companyId,
      dispatchFilters,
      DtSupportedDispatchStatuses.Scheduled.toUpperCase() as DtSupportedDispatchStatuses
    );

    return Promise.allSettled([dtOpenDispatches, dtOverdueDispatches, dtCompletedDispatches, dtScheduledDispatches]);
  }

  async getSingleDispatchDetails(formDispatchGroup: string): Promise<DtDispatchItemDetails | null> {
    const errorMessage = `Failed to get single dispatch details for formDispatchGroup: ${formDispatchGroup}`;
    try {
      const resp = await dtApiRepository.DispatchApi.dispatchControllerGetDispatch(formDispatchGroup);

      if (!resp.data) {
        dtToastService.error(errorMessage);
        return null;
      }

      return DtDispatchService.mapFormDispatchGroupToDtDispatchItemDetails(resp.data);
    } catch (e) {
      console.error(e);
      dtToastService.error(errorMessage);
      return null;
    }
  }

  async transformReceivedDtDispatches(data: DtDispatchFilters): Promise<DtDispatchItems> {
    const { completedDateRange, propertyId, formType, formId, currentCompanyId } = data;

    const getPromiseSettledResult = await this.getALL(
      {
        completedDateRange: {
          from: startOfDay(completedDateRange.from),
          to: endOfDay(completedDateRange.to),
        },
        currentCompanyId,
        propertyId,
        formType,
        formId,
      },
      currentCompanyId as number
    );

    const dtDispatchItems = getPromiseSettledResult.flatMap((dtDispatch) => {
      if (dtDispatch.status === 'fulfilled') {
        return dtDispatch.value;
      }
      return [];
    });

    return {
      [DtSupportedDispatchStatuses.Open]: dtDispatchItems.filter(
        (dispatch) => dispatch.status === DtSupportedDispatchStatuses.Open
      ),
      [DtSupportedDispatchStatuses.Overdue]: dtDispatchItems.filter(
        (dispatch) => dispatch.status === DtSupportedDispatchStatuses.Overdue
      ),
      [DtSupportedDispatchStatuses.Completed]: dtDispatchItems.filter(
        (dispatch) => dispatch.status === DtSupportedDispatchStatuses.Completed
      ),
      [DtSupportedDispatchStatuses.Scheduled]: dtDispatchItems.filter(
        (dispatch) => dispatch.status === DtSupportedDispatchStatuses.Scheduled
      ),
      [DtSupportedDispatchStatuses.Done]: [],
    };
  }

  async getDtDispatchReport(
    formDispatchGroup: string,
    showToastMessage?: boolean
  ): Promise<DtDispatchReportDetails | null> {
    const successMessage = 'Successfully downloaded report';
    const errorMessage = 'Failed to download report';

    try {
      const response = await dtApiRepository.DispatchApi.dispatchControllerDownloadReport(formDispatchGroup);
      if (showToastMessage) {
        response.data ? dtToastService.success(successMessage) : dtToastService.error(errorMessage);
      }
      return response.data ? DtDispatchService.mapFormDataFileToDtDispatchReportDetails(response.data) : null;
    } catch (error) {
      if (showToastMessage) {
        dtToastService.error(errorMessage);
      }
      return null;
    }
  }

  async getMultipleDispatchReports(
    formDispatchGroups: string[]
  ): Promise<PromiseSettledResult<DtDispatchReportDetails | null>[]> {
    return Promise.allSettled(
      formDispatchGroups.map(async (formDispatchGroup) => this.getDtDispatchReport(formDispatchGroup))
    );
  }

  async downloadDtDispatchReport(dtDispatchReport: DtDispatchReportDetails, multiple?: boolean): Promise<void> {
    try {
      const response = await axios.get(`data:application/pdf;base64,${dtDispatchReport.fileData}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      await FileSaver.saveAs(blob, dtDispatchReport.fileName);
    } catch (e) {
      console.error(e);
      dtToastService.error(multiple ? 'Failed to download one of the reports' : 'Failed to download report');
    }
  }

  async downloadMultipleDispatchReports(
    dtDispatchReports: PromiseSettledResult<DtDispatchReportDetails | null>[]
  ): Promise<void> {
    let failedReportsCount = 0;
    let successReportsCount = 0;
    for (const report of dtDispatchReports) {
      if (report.status === 'fulfilled' && report.value) {
        await this.downloadDtDispatchReport(report.value, true);
        successReportsCount += 1;
      } else if (report.status === 'rejected' || !report.value) {
        failedReportsCount += 1;
      }
    }

    if (successReportsCount > 0) {
      const reportsLabel = successReportsCount === 1 ? 'report' : 'reports';

      dtToastService.success(`Successfully downloaded ${successReportsCount} ${reportsLabel}`);
    }

    if (failedReportsCount > 0) {
      dtToastService.error(`Failed to download ${failedReportsCount} reports`);
    }
  }

  async deleteDtDispatch(formDispatchGroup: number | string): Promise<AxiosResponse<void>> {
    try {
      return await dtApiRepository.DispatchApi.dispatchControllerDelete(formDispatchGroup as string);
    } catch (error) {
      throw error;
    }
  }

  async createNewDispatch(currentCompanyId: number, payload: DtNewDispatchFormInitialValues): Promise<boolean> {
    const model: DtCronModel = dtFormToCronModel(payload);
    const frequencyCron = dtCronExpressionUtils.dtCronModelToExpression(model);

    const isCustomDueDateSelected = dtCalculateStartAndDueDatesDifference(
      payload.frequency.startDate,
      payload.frequency.dueDate,
      payload.frequency.customDueDate
    );

    try {
      await dtApiRepository.DispatchApi.dispatchControllerCreate({
        //TODO companyId should be used from company selector field
        companyId: currentCompanyId,
        propertyId: Number(payload.generalInfo.site),
        formId: Number(payload.generalInfo.formSubtype),
        priority: DT_DISPATCH_PRIORITY_TYPE_MAPPING[payload.details.priority],
        assignTo: payload.assignees.assignee,
        assigner: payload.assignees.assigner,
        notes: payload.details.notes ?? '',
        startDate: payload.frequency.startDate.toISOString(),
        endDate: payload.frequency.endDate ? format(payload.frequency.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'") : null,
        dueDate: isCustomDueDateSelected,
        frequency: frequencyCron ?? '',
        frequencyType: model.type,
        //TODO Currently using a mocked reminder data
        reminders: payload.frequency.reminder ?? [],
        assetList: payload.generalInfo.assets,
        emailOff: !payload.assignees.enableEmailNotificaition,
      });

      dtToastService.success('Created new dispatch');
      return true;
    } catch (error) {
      dtToastService.error('Failed to create new dispatch');
      return false;
    }
  }

  async updateScheduledDispatch(id: number, payload: DtNewDispatchFormInitialValues): Promise<void> {
    const model: DtCronModel = dtFormToCronModel(payload);
    const frequencyCron = dtCronExpressionUtils.dtCronModelToExpression(model);

    const isCustomDueDateSelected = dtCalculateStartAndDueDatesDifference(
      payload.frequency.startDate,
      payload.frequency.dueDate,
      payload.frequency.customDueDate
    );

    try {
      await dtApiRepository.DispatchApi.dispatchControllerUpdate(id, {
        priority: DT_DISPATCH_PRIORITY_TYPE_MAPPING[payload.details.priority],
        notes: payload.details.notes ?? '',
        assetList: payload.generalInfo.assets,
        assigner: payload.assignees.assigner,
        assignTo: payload.assignees.assignee,
        emailOff: !payload.assignees.enableEmailNotificaition,
        dueDate: isCustomDueDateSelected,
        frequency: frequencyCron ?? '',
        frequencyType: model.type,
        reminders: payload.frequency.reminder ?? [],
        endDate: payload.frequency.endDate ? format(payload.frequency.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'") : null,
      });
      dtToastService.success('Successfully updated dispatch');
    } catch (error) {
      dtToastService.error(`Failed to update dispatch with id: ${id}`);
      throw error;
    }
  }

  private static mapDtDispatchFiltersToFormFilters(
    companyId: number,
    filters: DtDispatchFilters,
    status: DtSupportedDispatchStatuses
  ): FormFilter {
    return {
      companyId,
      propertyId: filters.propertyId,
      formType: filters.formType,
      formId: filters.formId,
      completedDateRange: {
        from: filters.completedDateRange.from.toISOString(),
        to: filters.completedDateRange.to.toISOString(),
      },
      status,
    };
  }

  private static mapFormDispatchGroupToDtDispatchItemDetails(
    formDispatchGroup: FormDispatchGroup
  ): DtDispatchItemDetails {
    const convertDoneDispatchToCompleted =
      formDispatchGroup.status === DtSupportedDispatchStatuses.Done.toUpperCase()
        ? DtSupportedDispatchStatuses.Completed.toUpperCase()
        : formDispatchGroup.status;

    const assignerName = (formDispatchGroup.createdBy as string).replace(/,/g, ' ');

    return {
      status: upperFirst(lowerCase(convertDoneDispatchToCompleted as DtSupportedDispatchStatuses)) || '',
      dueDate: format(new Date(formDispatchGroup.dueDate as string), 'M/d/yyyy'),
      createdDate: new Date(formDispatchGroup.createdDate ?? 0),
      completedDate: new Date(formDispatchGroup.submittedDate ?? 0),
      createdBy: assignerName,
      createdByEmail: formDispatchGroup.createdByEmail || '',
      frequency: formDispatchGroup.frequency || '',
      priority: (formDispatchGroup.priority as DtDispatchPriorityType) || DtDispatchPriorityType.Medium,
      notes: formDispatchGroup.notes || '',
      assets: formDispatchGroup.assets || [],

      assigns: formDispatchGroup.assigns
        ? DtDispatchService.mapFormAssignsToDtDispatchItemDetailsAssigns(formDispatchGroup.assigns)
        : [],
    };
  }

  private static mapFormAssignsToDtDispatchItemDetailsAssigns(
    formAssigns: FormAssign[]
  ): DtDispatchItemDetailsAssign[] {
    return formAssigns.map((assign) => {
      const assigneeName = (assign.assignTo && assign.assignTo.replaceAll(/,/g, ' ')) ?? '';

      return {
        assigneeEmail: assign.assignToEmail || '',
        assigneeName: assigneeName,
        status: (assign.status as AssigneeStatusType) || AssigneeStatusType.New,
        reassigned: false,
        newAssignee: false,
      };
    });
  }

  private static mapFormDispatchDataToDtDispatchItems(dtDispatches: FormSearchSummaryDTO[]): DtDispatchRowItem[] {
    return dtDispatches.map((dtDispatch): DtDispatchRowItem => {
      const assignerName = (dtDispatch.createdBy as string).replace(/,/g, ' ');
      const assigneeName = (dtDispatch.assignTo && dtDispatch.assignTo.replaceAll(/,/g, ' ')) ?? '';

      return {
        propertyId: dtDispatch.propertyId,
        propertyName: dtDispatch.propertyName,
        propertyAddress: dtDispatch.propertyAddress,
        id: dtDispatch.formDispatchGroup,
        type: dtDispatch.formType,
        subtype: dtDispatch.formName,
        status: upperFirst(lowerCase(dtDispatch.status as DtSupportedDispatchStatuses)),
        dueDate: dtDispatch.dueDate ? format(new Date(dtDispatch.dueDate as string), 'M/d/yyyy') : undefined,
        createdDate: new Date(dtDispatch.createdDate as string),
        completedDate: new Date(dtDispatch.submittedDate as string),
        frequencyType: undefined,
        nextDispatch: undefined,
        assigner: {
          name: assignerName,
          email: dtDispatch.createdByEmail,
        },
        assignee: {
          name: assigneeName,
          email: dtDispatch.assignToEmail ?? '',
        },
        totalDeclined: dtDispatch.totalDeclined,
        totalAssigned: dtDispatch.totalAssigned,
        assets: dtDispatch.assetCount ?? 0,
        isLoading: false,
      };
    });
  }

  private static mapScheduleDispatchDataToDtScheduleDispatchItem(
    dtDispatches: ScheduleBasicDTO[]
  ): DtScheduleDispatchItem[] {
    return dtDispatches.map((dtDispatch): DtScheduleDispatchItem => {
      const assignerName = (dtDispatch.assignerName as string).replace(/,/g, ' ') ?? '';
      const assigneeName = (dtDispatch.assignTo?.fullName && dtDispatch.assignTo?.fullName.replaceAll(/,/g, ' ')) ?? '';

      return {
        propertyName: dtDispatch.propertyName,
        propertyAddress: dtDispatch.propertyAddress,
        id: dtDispatch.scheduleId ?? 0,
        companyId: dtDispatch.companyId ?? 0,
        propertyId: dtDispatch.propertyId ?? 0,
        type: dtDispatch.formType ?? '',
        subtype: dtDispatch.formSubType ?? '',
        priority: dtDispatch.priority ?? '',
        assigner: { name: assignerName, email: dtDispatch.assignerEmail ?? '' },
        totalAssigned: dtDispatch.assignToCount ?? 0,
        assets: dtDispatch.assetCount ?? 0,
        assignee: { name: assigneeName, email: dtDispatch.assignTo?.email ?? '' },
        frequency: dtDispatch.frequency ?? '',
        frequencyType: dtDispatch.frequencyType ?? '',
        formId: dtDispatch.formId ?? 0,
        nextDispatch: dtDispatch.nextDispatch ?? '',
        status: DtSupportedDispatchStatuses.Scheduled,
        isLoading: false,
        completedDate: undefined,
        createdDate: undefined,
        dueDate: undefined,
        totalDeclined: undefined,
      };
    });
  }

  private static mapFormDataFileToDtDispatchReportDetails(formDataFile: FormDataFile): DtDispatchReportDetails {
    return {
      fileType: formDataFile.fileType ?? '',
      fileName: formDataFile.fileName ?? '',
      fileData: formDataFile.fileData ?? '',
    };
  }
}

export const dtDispatchService = new DtDispatchService();
