import { dtSetFormsData, dtSetLoadingStatus, dtUpdateLoadingStatusOfTypeRow } from './dt-forms.slice';
import { DtAppThunk } from '../../../dt-store';
import { dtApiRepository } from '../../../repositories/dt-api.repository';
import { dtToastService } from '../../../services/dt-toast.service';

export const dtLoadFormsAction =
  (companyId: number, siteId?: number, formType?: string, formSubtype?: string): DtAppThunk =>
  async (dispatch) => {
    try {
      dispatch(dtSetLoadingStatus('loading'));

      const formsData =
        (await dtApiRepository.AdminFormApi.adminFormControllerGetAllForms(companyId, siteId, formType, formSubtype))
          ?.data ?? [];

      dispatch(dtSetFormsData(formsData));
    } catch (error) {
      dtToastService.error('Failed to load forms');
    }
  };

export const dtDeleteFormAction =
  (
    id: string,
    formTypeId: number,
    formSchemaId: number,
    companyId: number,
    siteId: number,
    formName: string
  ): DtAppThunk =>
  async (dispatch, getState) => {
    const root = getState();

    try {
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: true }));

      await dtApiRepository.AdminFormApi.adminFormControllerDelete(formSchemaId, companyId, siteId, formTypeId);

      const forms = root.dtForms.forms.filter((item) => item.id !== id);
      dispatch(dtSetFormsData(forms));
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: false }));

      dtToastService.success(`Successfully deleted ${formName} form`);
    } catch (e) {
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: false }));
      dtToastService.error('Failed to delete form');
    }
  };

export const dtRefreshFormAction =
  (id: string, formTypeId: number, formSchemaId: number, formName: string): DtAppThunk =>
  async (dispatch) => {
    try {
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: true }));

      await dtApiRepository.AdminFormApi.adminFormControllerRefresh(formSchemaId, formTypeId);
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: false }));

      dtToastService.success(`Successfully refreshed ${formName} form`);
    } catch (e) {
      dispatch(dtUpdateLoadingStatusOfTypeRow({ id, status: false }));
      dtToastService.error('Failed to refresh form');
    }
  };
