import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';

import { DtTimePickerProps } from './dt-time-picker.interfaces';
import { DtCustomTimePicker } from './dt-time-picker.styled';

const DtTimePicker: React.FC<DtTimePickerProps> = ({
  value,
  onChange,
  fullWidth,
  placeholder,
  error,
  disableFuture,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DtCustomTimePicker
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        onOpen={() => setIsFocused(true)}
        onClose={() => setIsFocused(false)}
        slotProps={{
          textField: {
            error: Boolean(error),
            helperText: error,
            placeholder: placeholder || '',
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
            InputProps: {
              className: isFocused ? 'focused' : '',
            },
          },
        }}
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  );
};

export default DtTimePicker;
