import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';

import { ColoredStatusWrapper, ColoredStatusLabel } from './dt-dispatch-status-badge.styled';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';

const TEN_DAYS = 10;

interface DtDispatchesStatusProps {
  dtDispatchStatus: DtSupportedDispatchStatuses;
  createdDate?: Date;
  completedDate?: Date;
  withTime?: boolean;
}

const DtDispatchStatusBadge: React.FC<DtDispatchesStatusProps> = ({
  dtDispatchStatus,
  createdDate,
  completedDate,
  withTime,
}) => {
  return (
    <ColoredStatusWrapper status={dtDispatchStatus}>
      <ColoredStatusLabel status={dtDispatchStatus}>
        {getLabel(dtDispatchStatus, createdDate, completedDate, withTime)}
      </ColoredStatusLabel>
    </ColoredStatusWrapper>
  );
};

function getLabel(
  dtDispatchStatus: DtSupportedDispatchStatuses,
  createdDate?: Date,
  completedDate?: Date,
  withTime?: boolean
): string {
  if (
    dtDispatchStatus === DtSupportedDispatchStatuses.Open ||
    dtDispatchStatus === DtSupportedDispatchStatuses.Overdue
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const diff = differenceInCalendarDays(new Date(), createdDate!);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const time = withTime ? ` at ${format(createdDate!, 'h:mm a')}` : '';
    if (diff === 0) {
      return 'Dispatched today' + time;
    }
    if (diff === 1) {
      return 'Dispatched yesterday' + time;
    }
    if (diff < TEN_DAYS) {
      return `Dispatched ${diff} days ago` + time;
    }
    if (diff >= TEN_DAYS) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return `Dispatched on ${format(createdDate!, 'M/d/yy')}` + time;
    }
  }

  if (
    dtDispatchStatus === DtSupportedDispatchStatuses.Completed ||
    dtDispatchStatus === DtSupportedDispatchStatuses.Done
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const diff = differenceInCalendarDays(new Date(), completedDate!);

    if (diff === 0) {
      return 'Completed today';
    }
    if (diff === 1) {
      return 'Completed yesterday';
    }
    if (diff < TEN_DAYS) {
      return `Completed ${diff} days ago`;
    }
    if (diff >= TEN_DAYS) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return `Completed on ${format(completedDate!, 'M/d/yy')}`;
    }
  }

  return dtDispatchStatus.toString();
}

export default DtDispatchStatusBadge;
