import { Formik, FormikHelpers } from 'formik';
import { isNull } from 'lodash';
import React, { useEffect, useState } from 'react';

import DtDeleteOrganizationButton from './components/dt-delete-organization-button/dt-delete-organization-button';
import DtAdministrationDetailsOrganizationForm from './dt-administration-details-organization-form/dt-administration-details-organization-form';
import { DtOrganizationDetailsInitialValues } from './dt-administration-details-organization-form/dt-administration-details-organization-form.interface';
import {
  DT_ORGANIZATION_DETAILS_FORM_VALIDATION,
  DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE,
} from './dt-administration-details-organization-form/dt-administration-details-organization-form.schema';
import DtOrganizationAssignments from './dt-organization-assignments';
import DtOrganizationPageFormSkeleton from './dt-organization-page-form-skeleton/dt-organization-page-form-skeleton';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { dtStore } from '../../../../../dt-store';
import { CompanyMutationDTO } from '../../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../../services/dt-toast.service';
import { dtUpdateNavigationPropertiesAndFormTypes } from '../../../../dt-navigation/dt-navigation.slice';
import { dtRefreshCurrentUserData } from '../../../../dt-user/dt-user.actions';
import { dtSelectCurrentCompanyId, dtSetCurrentCompany } from '../../../../dt-user/dt-user.slice';
import { dtClearAssignments, dtSetAssignments } from '../../../dt-assignments.slice';
import { dtLoadOrganizationsAction } from '../../../dt-organizations-tab/dt-organizations.actions';
import DtAdministrationDetailsFormControl from '../../dt-administration-details-form-control/dt-administration-details-form-control';
import DtAdministrationDetailsLayout from '../../dt-administration-details-layout/dt-administration-details-layout';
import {
  DtAdministrationDetailsContent,
  DtAdministrationDetailsContentDivider,
} from '../../dt-administration-details-layout/dt-administration-details-layout.styled';
import DtAdministrationDetailsSkeleton from '../../dt-administration-details-layout/dt-administration-details-skeleton/dt-administration-details-skeleton';
import DtAdministrationDetailsConfirmationPopup from '../../modals/dt-administration-details-confirmation-popup/dt-administration-details-confirmation-popup';

interface DtAdministrationDetailsOrganizationPageProps {
  isCreationFlow?: boolean;
}

const DtAdministrationDetailsOrganizationPage: React.FC<DtAdministrationDetailsOrganizationPageProps> = ({
  isCreationFlow,
}) => {
  const currentOrganizationId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const dispatch = dtUseAppDispatch();
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState<CompanyMutationDTO | null>(null);
  const [createdOrganizationId, setCreatedOrganizationId] = useState<number | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);

  useEffect(() => {
    if (!isCreationFlow && !isNull(currentOrganizationId)) {
      setLoading(true);
      dtApiRepository.AdminCompanyApi.adminCompanyControllerGet(Number(currentOrganizationId)).then(({ data }) => {
        setOrganization(data);
        dispatch(dtSetAssignments(data.assignments));
        setLoading(false);
      });
    } else {
      dispatch(dtClearAssignments());
      setLoading(false);
    }
  }, [currentOrganizationId]);

  async function handleOrganizationFormSubmit(
    values: DtOrganizationDetailsInitialValues,
    actions: FormikHelpers<DtOrganizationDetailsInitialValues>
  ): Promise<void> {
    const payload: CompanyMutationDTO = {
      name: values.name,
      address: values.address ?? '',
      city: values.city ?? '',
      state: values.state ?? '',
      phone: values.phone ?? '',
      email: values.email ?? '',
      assignments: dtStore.getState().dtAssignments.data,
    };
    let response;

    try {
      if (isCreationFlow) {
        response = await dtApiRepository.AdminCompanyApi.adminCompanyControllerCreate(payload);
        setCreatedOrganizationId(response.data);
        if (!isNull(currentOrganizationId)) {
          setShowConfirmationPopup(true);
        }
      } else if (organization) {
        await dtApiRepository.AdminCompanyApi.adminCompanyControllerUpdate(Number(currentOrganizationId), payload);
        dtToastService.success('Successfully updated organization');

        // load updated data
        dtStore.dispatch(dtLoadOrganizationsAction());
      }
      actions.resetForm({ values: payload });
      dispatch(dtRefreshCurrentUserData());
      if (response && isNull(currentOrganizationId)) {
        await dispatch(dtSetCurrentCompany(response.data));
        await dispatch(dtUpdateNavigationPropertiesAndFormTypes());
        await window.sessionStorage.setItem('SELECTED_COMPANY', response.data.toString());
        dtRouter.navigate(DtAppRoutes.DashboardOpen);
      }
    } catch (error) {
      dtToastService.error(`Failed to ${!isCreationFlow ? 'update' : 'create new'} organization`);
    }
  }

  const pageTitle = 'Create new organization';
  const pageSubtitle = 'Provide organization details and permissions';
  const backRoute = !isNull(currentOrganizationId) ? DtAppRoutes.OrganizationAdministration : undefined;

  return (
    <>
      {loading && (
        <Formik initialValues={{}} onSubmit={() => Promise.resolve()}>
          {isCreationFlow ? (
            <DtAdministrationDetailsLayout
              pageTitle={pageTitle}
              pageSubtitle={pageSubtitle}
              backButtonRoute={backRoute}
              isCreationFlow={isCreationFlow}
              setValidationAfterSubmit={setValidationAfterSubmit}
              cancelButtonTitle='Reset Form'
            >
              <DtAdministrationDetailsSkeleton hideParentAssignButtonSkeleton hideChildAssignButtonSkeleton>
                <DtOrganizationPageFormSkeleton />
              </DtAdministrationDetailsSkeleton>
            </DtAdministrationDetailsLayout>
          ) : (
            <DtAdministrationDetailsSkeleton hideParentAssignButtonSkeleton hideChildAssignButtonSkeleton>
              <div style={{ marginBottom: '30px', display: 'flex' }}>
                <DtAdministrationDetailsFormControl
                  setValidationAfterSubmit={setValidationAfterSubmit}
                  isCreationFlow={isCreationFlow}
                />
                <DtDeleteOrganizationButton companyId={currentOrganizationId} disabled={loading} />
              </div>
              <DtOrganizationPageFormSkeleton />
            </DtAdministrationDetailsSkeleton>
          )}
        </Formik>
      )}
      {!loading && (
        <Formik
          initialValues={DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE(organization)}
          onSubmit={handleOrganizationFormSubmit}
          validationSchema={DT_ORGANIZATION_DETAILS_FORM_VALIDATION}
          validateOnChange={validationAfterSubmit}
          validateOnBlur={validationAfterSubmit}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <>
                {isCreationFlow ? (
                  <DtAdministrationDetailsLayout
                    pageTitle={pageTitle}
                    pageSubtitle={pageSubtitle}
                    backButtonRoute={backRoute}
                    isCreationFlow={isCreationFlow}
                    setValidationAfterSubmit={setValidationAfterSubmit}
                    cancelButtonTitle='Reset Form'
                  >
                    <DtAdministrationDetailsContent>
                      <DtAdministrationDetailsOrganizationForm />
                    </DtAdministrationDetailsContent>
                    <DtAdministrationDetailsContentDivider />
                    <DtOrganizationAssignments />
                  </DtAdministrationDetailsLayout>
                ) : (
                  <>
                    <DtAdministrationDetailsContent>
                      <div style={{ marginBottom: '15px', display: 'flex' }}>
                        <DtAdministrationDetailsFormControl
                          setValidationAfterSubmit={setValidationAfterSubmit}
                          isCreationFlow={isCreationFlow}
                        />
                        <DtDeleteOrganizationButton companyId={currentOrganizationId} />
                      </div>
                      <DtAdministrationDetailsOrganizationForm />
                    </DtAdministrationDetailsContent>
                    <DtAdministrationDetailsContentDivider />
                    <DtOrganizationAssignments />
                  </>
                )}

                {showConfirmationPopup && (
                  <DtAdministrationDetailsConfirmationPopup
                    opened={showConfirmationPopup}
                    onClose={async () => {
                      // Go to details on popup close
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      if (createdOrganizationId) {
                        await dispatch(dtSetCurrentCompany(createdOrganizationId ?? null));
                        await dispatch(dtUpdateNavigationPropertiesAndFormTypes());

                        if (createdOrganizationId) {
                          window.sessionStorage.setItem('SELECTED_COMPANY', createdOrganizationId.toString());
                        }
                        dtRouter.navigate(DtAppRoutes.OrganizationAdministration, {
                          replace: true,
                        });
                      } else {
                        throw 'There is no ID of created organization';
                      }
                    }}
                    onSubmit={() => {
                      // Reset form for creation
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      formikProps.resetForm({ values: formikProps.initialValues });
                      dispatch(dtClearAssignments());
                    }}
                    title='Organization created successfully'
                    icon='check'
                    color='emerald'
                    subtitle=''
                    isShowConfirmationMessage={showConfirmationPopup}
                    submitButtonText='Create another organization'
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DtAdministrationDetailsOrganizationPage;
