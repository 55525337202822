import * as yup from 'yup';

import { DtOrganizationDetailsInitialValues } from './dt-administration-details-organization-form.interface';
import { CompanyMutationDTO } from '../../../../../../repositories/__generated__/v2';

export const DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE = (
  organization?: CompanyMutationDTO[] | CompanyMutationDTO | null
): DtOrganizationDetailsInitialValues => {
  return {
    name: (organization as CompanyMutationDTO)?.name ?? '',
    address: (organization as CompanyMutationDTO)?.address ?? '',
    email: (organization as CompanyMutationDTO)?.email ?? '',
    phone: (organization as CompanyMutationDTO)?.phone ?? '',
    city: (organization as CompanyMutationDTO)?.city ?? '',
    state: (organization as CompanyMutationDTO)?.state ?? '',
  };
};

export const DT_ORGANIZATION_DETAILS_FORM_VALIDATION: yup.ObjectSchema<DtOrganizationDetailsInitialValues> = yup
  .object()
  .shape({
    name: yup.string().label('Name').required('Required'),
    address: yup.string().label('Address').required('Required'),
    email: yup.string().email('Email should be a valid').label('Email').required('Required'),
    phone: yup
      .string()
      .label('Phone')
      .matches(/^\(\d{3}\) \d{3}\-\d{4}$/, { message: 'Must have (000) 000-0000 format', excludeEmptyString: true }),
    city: yup.string().label('City'),
    state: yup.string().label('State'),
  });
