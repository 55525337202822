import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy, flatMap } from 'lodash';

import {
  DtPropertyFormTypeItem,
  DtMenuItemDropdownType,
  DtMenuItemLink,
  DtMenuSelectOption,
  DtNavbarMenuItemType,
  DtNavigationState,
} from './dt-navigation.interfaces';
import { dtNavigationService } from './dt-navigation.service';
import { DtRootState } from '../../dt-store';
import { dtSelectProperties, dtSelectSelectedProperty } from '../dt-properties/dt-properties.slice';

export const dtUpdateNavigationPropertiesAndFormTypes = createAsyncThunk(
  'dtNavigation/dtUpdateNavigationPropertiesAndFormTypes',
  (_, { getState }): Partial<DtNavigationState> => {
    const state = getState() as DtRootState;
    const selectedProperty = dtSelectSelectedProperty(state);
    const properties = dtSelectProperties(state);

    return {
      propertyOptions: dtNavigationService.mapPropertiesToMenuSelectOptions(properties),
      navigationFormTypes: dtNavigationService.mapPropertyFormTypesToNavigationItems(selectedProperty),
    };
  }
);

export const dtUpdateNavigationFormTypes = createAsyncThunk(
  'dtNavigation/dtUpdateNavigationFormTypes',
  (_, { getState }): Partial<DtNavigationState> => {
    const selectedProperty = dtSelectSelectedProperty(getState() as DtRootState);
    const navigationFormTypes = dtNavigationService.mapPropertyFormTypesToNavigationItems(selectedProperty);

    return {
      navigationFormTypes,
    };
  }
);

const initialState: DtNavigationState = {
  status: 'loading',
  propertyOptions: [],
  navigationFormTypes: [],
  // TODO: Move to dt-inspection-details
  formSubtype: '',
};

export const dtNavigationSlice = createSlice({
  name: 'dtNavigation',
  initialState,
  reducers: {
    dtSetPropertyOptions: (state, action: PayloadAction<DtMenuSelectOption<number>[]>) => {
      state.propertyOptions = action.payload;
    },
    dtSetPropertyFormTSubtype: (state, action: PayloadAction<string>) => {
      state.formSubtype = action.payload;
    },
    dtClearNavigationEntities: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dtUpdateNavigationPropertiesAndFormTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dtUpdateNavigationPropertiesAndFormTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.propertyOptions = orderBy(action.payload.propertyOptions, (i) => i.label) ?? [];
        state.navigationFormTypes = (action.payload.navigationFormTypes ?? []).map((i) => ({
          ...i,
          children: i.hasOwnProperty('children')
            ? orderBy((i as DtMenuItemDropdownType).children, (j) => j.title)
            : undefined,
        }));
      })
      .addCase(dtUpdateNavigationPropertiesAndFormTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(dtUpdateNavigationFormTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.navigationFormTypes = (action.payload.navigationFormTypes ?? []).map((i) => ({
          ...i,
          children: i.hasOwnProperty('children')
            ? orderBy((i as DtMenuItemDropdownType).children, (j) => j.title)
            : undefined,
        }));
      });
  },
});

export const dtSelectNavigationEntities = (state: DtRootState): DtNavbarMenuItemType[] =>
  state.dtNavigation.navigationFormTypes;

export const dtSelectPropertyOptions = (state: DtRootState): DtMenuSelectOption<number>[] =>
  state.dtNavigation.propertyOptions;

export const dtSelectNavigationIsLoading = (state: DtRootState): boolean =>
  state.dtUser.status === 'loading' ||
  state.dtProperties.status === 'loading' ||
  state.dtNavigation.status === 'loading';

const dtSelectNavigationFormSubtype = (state: DtRootState): string => state.dtNavigation.formSubtype;

// TODO: Move to dt-inspection-details
export const dtSelectPropertyFormType = createSelector(
  [dtSelectNavigationEntities, dtSelectNavigationFormSubtype],
  (formTypes, navigationFormSubtype) => {
    const parentItems: DtPropertyFormTypeItem[] = (formTypes as DtMenuItemDropdownType[]).map((item) => {
      return {
        parentId: item.id,
        children: item?.children?.map((item) => item.id),
      };
    });

    return parentItems.find((item) => item?.children?.includes(navigationFormSubtype)) ?? null;
  }
);

// TODO: Move to dt-inspection-details
export const dtSelectPropertyFormSubtype = (state: DtRootState): DtMenuItemLink | null => {
  const formTypes = dtSelectNavigationEntities(state) as DtMenuItemDropdownType[];

  const formSubtypes = flatMap(formTypes, (parent) => parent.children);

  return formSubtypes.find((item) => item?.id === state.dtNavigation.formSubtype) ?? null;
};

export const { dtSetPropertyOptions, dtClearNavigationEntities, dtSetPropertyFormTSubtype } = dtNavigationSlice.actions;

export default dtNavigationSlice.reducer;
