import { styled } from '@mui/material/styles';

export const AssigneeStatusContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: keyof MainPalette }>`
  display: flex;
  align-items: center;
  color: ${({ theme, color }) => theme.palette.main[color]};
`;

export const AssigneeStatusIconContainer = styled('span')`
  margin-right: ${({ theme }) => theme.spacing(8)};
`;
