import { format } from 'date-fns';
import React from 'react';

import { CellSubtitle, CellTitle } from '../../../../../components/dt-table/table-cell.styled';
import { CellContentWrapper } from '../../../components/dt-table-cell-content-wrapper/dt-table-cell-content-wrapper.styled';

interface DtUsersNameTableCellProps {
  lastLogin: string;
  amountOfLoggedInTimes: number;
}

const DtManageUsersLastLoginTableCell: React.FC<DtUsersNameTableCellProps> = ({ lastLogin, amountOfLoggedInTimes }) => {
  const loginsText = amountOfLoggedInTimes > 1 ? 'logins' : 'login';

  return (
    <CellContentWrapper style={{ marginLeft: '24px' }}>
      <CellTitle titleBold={false}>{format(new Date(lastLogin as string), 'MM-dd-yyyy') ?? ''}</CellTitle>
      <CellSubtitle subtitleBold={false}>
        {amountOfLoggedInTimes} {loginsText}
      </CellSubtitle>
    </CellContentWrapper>
  );
};

export default DtManageUsersLastLoginTableCell;
