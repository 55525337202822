import { Grid } from '@mui/material';
import React from 'react';

import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';

const DtAdministrationDetailsOrganizationForm: React.FC = () => {
  return (
    <Grid container spacing={24} justifyContent='flex-start'>
      <FieldContainer item xs={6}>
        <InputLabel htmlFor='name'>Name</InputLabel>
        <DtFormTextInput id='name' name='name' fullWidth placeholder='Name' />
      </FieldContainer>
      <FieldContainer item xs={6}>
        <InputLabel htmlFor='address'>Address</InputLabel>
        <DtFormTextInput id='address' name='address' fullWidth placeholder='Address' />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='city'>City</InputLabel>
        <DtFormTextInput id='city' name='city' fullWidth placeholder='City' />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='state'>State</InputLabel>
        <DtFormTextInput id='state' name='state' fullWidth placeholder='State' />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='email'>Email</InputLabel>
        <DtFormTextInput id='email' name='email' fullWidth type='email' placeholder='Email' />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='phone'>Phone Number</InputLabel>
        <DtFormTextInput id='phone' fullWidth name='phone' type='tel' placeholder='(000) 000-0000' />
      </FieldContainer>
    </Grid>
  );
};

export default DtAdministrationDetailsOrganizationForm;
