import { styled } from '@mui/system';
import SimpleBar from 'simplebar-react';

export const ScrollbarContainer = styled(SimpleBar, {
  shouldForwardProp: (prop) =>
    prop !== 'horizontal' && prop !== 'leftHorizontalMargin' && prop !== 'height' && prop !== 'minHeight',
})<{
  horizontal?: boolean;
  leftHorizontalMargin?: string;
  height?: string;
  minHeight?: string;
}>`
  overflow: auto;
  ${({ horizontal }) => (horizontal ? 'width: 100%' : 'height: 100%')};
  ${({ height }) => height && `height: ${height}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};

  & .simplebar-track.simplebar-horizontal {
    ${({ horizontal, leftHorizontalMargin }) => horizontal && leftHorizontalMargin && `left: ${leftHorizontalMargin}`};
  }

  & .simplebar-scrollbar {
    &::before {
      background-color: ${({ theme }) => theme.palette.main.secondaryIcon};
    }
  }
`;
