import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DtAdministrationDetailsForms from './dt-administration-details-forms/dt-administration-details-forms';
import { DtFormsDetailsInitialValues } from './dt-administration-details-forms/dt-administration-details-forms.interface';
import {
  DT_FORM_DETAILS_FORM_VALIDATION,
  DT_FORM_DETAILS_INITIAL_FORM_VALUE,
} from './dt-administration-details-forms/dt-administration-details-forms.schema';
import DtFormsPageFormSkeleton from './dt-forms-page-form-skeleton/dt-forms-page-form-skeleton';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import { dtUseQuery } from '../../../../../cdk/hooks/dt-use-query';
import DtAssetsTable from '../../../../../components/dt-assets-table/dt-assets-table';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { FormCreateMutationResponseDto, FormMutationDto } from '../../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../../../dt-user/dt-user.actions';
import { dtSelectCurrentCompanyId } from '../../../../dt-user/dt-user.slice';
import { dtClearAssignments } from '../../../dt-assignments.slice';
import DtAdministrationDetailsLayout from '../../dt-administration-details-layout/dt-administration-details-layout';
import {
  DtAdministrationDetailsContent,
  DtAdministrationDetailsContentDivider,
} from '../../dt-administration-details-layout/dt-administration-details-layout.styled';
import DtAdministrationDetailsSkeleton from '../../dt-administration-details-layout/dt-administration-details-skeleton/dt-administration-details-skeleton';
import DtAdministrationDetailsConfirmationPopup from '../../modals/dt-administration-details-confirmation-popup/dt-administration-details-confirmation-popup';

const DtAdministrationDetailsFormsPage: React.FC = () => {
  const dispatch = dtUseAppDispatch();
  const { id } = useParams<{ id: string }>();
  const currentFormTypeId = id;
  const isCreationFlow = !currentFormTypeId;

  const query = dtUseQuery();

  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<FormMutationDto | null>(null);
  const [createdForm, setCreatedForm] = useState<FormCreateMutationResponseDto | null>(null);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  useEffect(() => {
    if (!isCreationFlow) {
      if (currentCompanyId) {
        setLoading(true);
        dtApiRepository.AdminFormApi.adminFormControllerGet(
          Number(currentFormTypeId),
          Number(query.get('formSchema')),
          currentCompanyId as number,
          Number(query.get('site'))
        ).then(({ data }) => {
          setForm(data);
          setLoading(false);
        });
      }
    } else {
      dispatch(dtClearAssignments());
      setLoading(false);
    }
  }, [currentFormTypeId]);

  async function handleTypeCreateFormSubmit(
    values: DtFormsDetailsInitialValues,
    actions: FormikHelpers<DtFormsDetailsInitialValues>
  ): Promise<void> {
    const payload: FormMutationDto = {
      formType: values.formType ?? '',
      formName: values.formName ?? '',
      pfFormId: values.pfFormId ?? '',
      reportTemplateName: values.reportTemplateName ?? '',
      formAssetList: values.formAssetList ?? [],
      siteId: values.siteId ?? 0,
    };

    try {
      if (isCreationFlow) {
        if (currentCompanyId) {
          const createdForm = (await dtApiRepository.AdminFormApi.adminFormControllerCreate(currentCompanyId, payload))
            ?.data;
          setCreatedForm(createdForm);
          setShowConfirmationPopup(true);
        }
      } else if (form) {
        if (currentCompanyId) {
          await dtApiRepository.AdminFormApi.adminFormControllerUpdate(
            Number(currentFormTypeId),
            currentCompanyId as number,
            Number(query.get('site')),
            {
              ...payload,
              formSchemaId: Number(query.get('formSchema')),
            }
          );
          dtToastService.success('Successfully updated form');
        }
      }
      actions.resetForm({ values: payload });
      dispatch(dtRefreshCurrentUserData());
    } catch (error) {
      dtToastService.error(`Failed to ${!isCreationFlow ? 'update' : 'create new'} form`);
    }
  }

  const pageTitle = !isCreationFlow ? 'Edit Form' : 'Create new form';
  const pageSubtitle = !isCreationFlow ? 'Edit form details and permissions' : 'Provide form details and permissions';
  const backRoute = DtAppRoutes.FormsAdministration;

  return (
    <>
      {loading && (
        <Formik initialValues={{}} onSubmit={() => Promise.resolve()}>
          <DtAdministrationDetailsLayout
            pageTitle={pageTitle}
            pageSubtitle={pageSubtitle}
            backButtonRoute={backRoute}
            isCreationFlow={isCreationFlow}
            setValidationAfterSubmit={setValidationAfterSubmit}
          >
            <DtAdministrationDetailsSkeleton showAssetsTableSkeleton>
              <DtFormsPageFormSkeleton />
            </DtAdministrationDetailsSkeleton>
          </DtAdministrationDetailsLayout>
        </Formik>
      )}
      {!loading && (
        <Formik
          initialValues={DT_FORM_DETAILS_INITIAL_FORM_VALUE(form)}
          onSubmit={handleTypeCreateFormSubmit}
          validationSchema={DT_FORM_DETAILS_FORM_VALIDATION}
          enableReinitialize
          validateOnBlur={validationAfterSubmit}
          validateOnChange={validationAfterSubmit}
        >
          {(formikProps) => {
            return (
              <>
                <DtAdministrationDetailsLayout
                  pageTitle={pageTitle}
                  pageSubtitle={pageSubtitle}
                  backButtonRoute={backRoute}
                  isCreationFlow={isCreationFlow}
                  setValidationAfterSubmit={setValidationAfterSubmit}
                >
                  <DtAdministrationDetailsContent>
                    <DtAdministrationDetailsForms isCreationFlow={isCreationFlow} />
                  </DtAdministrationDetailsContent>
                  <DtAdministrationDetailsContentDivider />
                  <DtAdministrationDetailsContent sx={{ flex: '1 1 0%' }}>
                    <DtAssetsTable
                      formAssetList={formikProps.values?.formAssetList ?? []}
                      disabled={formikProps.isSubmitting}
                      onChange={(assets) => {
                        formikProps.setFieldValue('formAssetList', assets);
                      }}
                    />
                  </DtAdministrationDetailsContent>
                </DtAdministrationDetailsLayout>
                {showConfirmationPopup && (
                  <DtAdministrationDetailsConfirmationPopup
                    opened={showConfirmationPopup}
                    onClose={() => {
                      // Go to details on popup close
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);

                      if (createdForm) {
                        dtRouter.navigate(
                          RoutesResolver.FormDetails(
                            createdForm.siteId.toString(),
                            createdForm.formSchemaId.toString(),
                            createdForm.formTypeId.toString()
                          ),
                          { replace: true }
                        );
                      } else {
                        throw 'There is no ID of created form';
                      }
                    }}
                    onSubmit={() => {
                      // Reset form for creation
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      formikProps.resetForm({ values: formikProps.initialValues });
                      dispatch(dtClearAssignments());
                    }}
                    title='Form created successfully'
                    icon='check'
                    color='emerald'
                    subtitle=''
                    isShowConfirmationMessage={showConfirmationPopup}
                    submitButtonText='Create another form'
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DtAdministrationDetailsFormsPage;
