import { Grid } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TabButton, TabsContainer, TabsItems } from './dt-dispatches-tabs.styled';
import DtTabButtonContent from './dt-tab-button-content/dt-tab-button-content';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import { dtGetDateRangePickerValue } from '../../../../components/dt-date-pickers/dt-date-pickers.utils';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { DtTabItem } from '../../../../components/dt-tabs/dt-tabs.interfaces';
import { DtAppRoutes } from '../../../../dt-enums';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';
import {
  dtSelectDateRange,
  dtSelectDispatchItemsCount,
  dtSelectDispatchItemsCountIsLoading,
} from '../../dt-dispatches.slice';

const DEFAULT_DATE_FORMAT = 'MM/dd/yy';

const DtDispatchesTabs: React.FC = () => {
  const navigate = useNavigate();
  const history = useLocation();
  const currentActiveTab = history.pathname;

  const completedDateRange = dtUseAppSelector(dtSelectDateRange);
  const dispatchesCount = dtUseAppSelector(dtSelectDispatchItemsCount);
  const isDispatchesCountLoading = dtUseAppSelector(dtSelectDispatchItemsCountIsLoading);

  const period = dtGetDateRangePickerValue(completedDateRange, DEFAULT_DATE_FORMAT);

  const tabs: DtTabItem[] = [
    {
      value: DtAppRoutes.DashboardOpen,
      label: (
        <DtTabButtonContent
          counter={dispatchesCount.Open}
          subtitle='Open'
          icon={<DtIcon icon='opened' size={16} color='buttonPrimary' />}
          isDataLoading={isDispatchesCountLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.DashboardOverdue,
      label: (
        <DtTabButtonContent
          counter={dispatchesCount.Overdue}
          subtitle='Overdue'
          icon={<DtIcon icon='opened-2' size={16} color='red' />}
          isDataLoading={isDispatchesCountLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.DashboardCompleted,
      label: (
        <DtTabButtonContent
          counter={dispatchesCount.Completed}
          subtitle={period}
          icon={<DtIcon icon='check' size={16} color='emerald' />}
          isDataLoading={isDispatchesCountLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.DashboardScheduled,
      label: (
        <DtTabButtonContent
          counter={dispatchesCount.Scheduled}
          subtitle='Scheduled'
          icon={<DtIcon icon='calendar' size={16} color='secondaryIcon' />}
          isDataLoading={isDispatchesCountLoading}
        />
      ),
    },
  ];

  function handleTabChange(_: SyntheticEvent, dtDispatchStatus: DtSupportedDispatchStatuses): void {
    navigate(`${dtDispatchStatus.toLowerCase()}`);
  }

  return (
    <TabsContainer container>
      <Grid item xs={12}>
        <TabsItems value={currentActiveTab} onChange={handleTabChange}>
          {tabs.map(({ value, label }) => (
            <TabButton key={value} value={value} label={label} />
          ))}
        </TabsItems>
      </Grid>
    </TabsContainer>
  );
};

export default DtDispatchesTabs;
