import { add } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import DtFormikDatePicker from '../../../../../../components/dt-date-pickers/dt-formik-date-picker/dt-formik-date-picker';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';
import { DT_DUE_DATE_MOCK_OPTIONS } from '../../dt-new-dispatch-modal.constants';
import {
  AdditionalFiledLabel,
  CustomDueDateField,
  DateLabel,
  FrequencySelectionContainer,
} from '../../steps/dt-new-dispatch-frequency/dt-new-dispatch-frequency.styled';

interface DtDispatchFrequencyDateRangesSelectorsProps {
  isEditFlow: boolean;
}

const DtDispatchFrequencyDateRangesSelectors: React.FC<DtDispatchFrequencyDateRangesSelectorsProps> = ({
  isEditFlow,
}) => {
  const { values, setFieldValue } = useFormikContext<DtNewDispatchFormInitialValues>();

  const disabledDays = {
    before: add(values.frequency.startDate, {
      days: 1,
    }),
  };

  useEffect(() => {
    if (!isEditFlow) {
      setFieldValue('frequency.startDate', new Date());
    }
  }, []);

  return (
    <>
      <FrequencySelectionContainer>
        <div>
          <DateLabel disabled={isEditFlow}>Start Date</DateLabel>
          <DtFormikDatePicker
            name='frequency.startDate'
            fullWidth
            disabled={isEditFlow}
            disabledDays={
              isEditFlow
                ? undefined
                : values.frequency.endDate
                ? {
                    after: values.frequency.endDate,
                    before: new Date(),
                  }
                : {
                    before: new Date(),
                  }
            }
          />
        </div>
        <div>
          <DateLabel>
            End Date <AdditionalFiledLabel>(optional)</AdditionalFiledLabel>
          </DateLabel>
          <DtFormikDatePicker
            name='frequency.endDate'
            fullWidth
            placeholder='Select End Date'
            disabledDays={{
              before: values.frequency.startDate,
            }}
          />
        </div>
        <div>
          <DtFormikSelect
            fullWidth
            labelPosition='top'
            label='Due Date'
            name='frequency.dueDate'
            options={DT_DUE_DATE_MOCK_OPTIONS}
            placeholder='Select Due Date'
          />
          {values.frequency.dueDate === 'Custom' && (
            <CustomDueDateField disabledDays={disabledDays} name='frequency.customDueDate' fullWidth />
          )}
        </div>
      </FrequencySelectionContainer>
    </>
  );
};

export default DtDispatchFrequencyDateRangesSelectors;
