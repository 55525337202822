import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DtAdministrationDetailsUserForm from './dt-administration-details-user-form/dt-administration-details-user-form';
import { DtUserDetailsInitialValues } from './dt-administration-details-user-form/dt-administration-details-user-form.interface';
import {
  DT_USER_DETAILS_FORM_VALIDATION,
  DT_USER_DETAILS_INITIAL_FORM_VALUE,
} from './dt-administration-details-user-form/dt-administration-details-user-form.schema';
import DtUserAssignments from './dt-user-assignments';
import DtUserPageFormSkeleton from './dt-user-page-form-skeleton/dt-user-page-form-skeleton';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { dtStore } from '../../../../../dt-store';
import { UserDetailsDto, UserMutationDto, UserRole } from '../../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../../../dt-user/dt-user.actions';
import { dtSelectCurrentCompanyId } from '../../../../dt-user/dt-user.slice';
import { dtClearAssignments, dtSetAssignments } from '../../../dt-assignments.slice';
import { dtLoadUsers } from '../../../dt-manage-users-tab/dt-manage-users.slice';
import DtAdministrationDetailsLayout from '../../dt-administration-details-layout/dt-administration-details-layout';
import {
  DtAdministrationDetailsContent,
  DtAdministrationDetailsContentDivider,
} from '../../dt-administration-details-layout/dt-administration-details-layout.styled';
import DtAdministrationDetailsSkeleton from '../../dt-administration-details-layout/dt-administration-details-skeleton/dt-administration-details-skeleton';
import DtAdministrationDetailsConfirmationPopup from '../../modals/dt-administration-details-confirmation-popup/dt-administration-details-confirmation-popup';

const DtAdministrationDetailsUserPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const currentUserEmail = id;
  const isCreationFlow = !currentUserEmail;

  const dispatch = dtUseAppDispatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserDetailsDto | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [createdUserId, setCreatedUserId] = useState<string | null>(null);
  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  useEffect(() => {
    if (!isCreationFlow) {
      setLoading(true);
      dtApiRepository.MeApi.userControllerGet(currentUserEmail).then(({ data }) => {
        setUser(data);
        dispatch(dtSetAssignments(data.assignments));
        setLoading(false);
      });
    } else {
      dispatch(dtClearAssignments());
      setLoading(false);
    }
  }, [currentUserEmail]);

  async function handleUserFormSubmit(
    values: DtUserDetailsInitialValues,
    actions: FormikHelpers<DtUserDetailsInitialValues>
  ): Promise<void> {
    if (currentCompanyId) {
      const payload: UserMutationDto = {
        firstName: values.firstName ?? '',
        lastName: values.lastName ?? '',
        email: values.email ?? '',
        phone: values.phone,
        companyId: currentCompanyId,
        mobileUsername: values.mobileUsername,
        role: values.role as UserRole,
        provideWebAppAccess: values.hasWebAccess,
        assignments: dtStore.getState().dtAssignments.data.map((assignment) => ({
          id: assignment.id,
          children: assignment.children,
          displayValue: assignment.displayValue,
          type: assignment.type,
        })),
      };

      try {
        if (isCreationFlow) {
          const response = await dtApiRepository.MeApi.userControllerCreate(payload);
          setCreatedUserId(response.data);
          setShowConfirmationPopup(true);
        } else if (user) {
          await dtApiRepository.MeApi.userControllerUpdate(user.email, payload);
          dtToastService.success('Successfully updated user');
        }
        actions.resetForm({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          values: { ...values, assignments: false } as any,
        });
        dispatch(dtRefreshCurrentUserData());
        if (currentCompanyId) {
          dispatch(dtLoadUsers({ companyId: currentCompanyId }));
        }
      } catch (error) {
        dtToastService.error(`Failed to ${isCreationFlow ? 'create new' : 'update'} user`);
      }
    }
  }

  const pageTitle = !isCreationFlow ? currentUserEmail : 'Create new user';
  const pageSubtitle = !isCreationFlow ? 'Edit user details and permissions' : 'Provide user details and permissions';
  const backRoute = DtAppRoutes.UsersAdministration;

  return (
    <>
      {loading && (
        <Formik initialValues={{}} onSubmit={() => Promise.resolve()}>
          <DtAdministrationDetailsLayout
            pageTitle={pageTitle}
            pageSubtitle={pageSubtitle}
            backButtonRoute={backRoute}
            isCreationFlow={isCreationFlow}
            setValidationAfterSubmit={setValidationAfterSubmit}
          >
            <DtAdministrationDetailsSkeleton>
              <DtUserPageFormSkeleton />
            </DtAdministrationDetailsSkeleton>
          </DtAdministrationDetailsLayout>
        </Formik>
      )}
      {!loading && (
        <Formik
          initialValues={DT_USER_DETAILS_INITIAL_FORM_VALUE(user)}
          onSubmit={handleUserFormSubmit}
          validationSchema={DT_USER_DETAILS_FORM_VALIDATION}
          validateOnChange={validationAfterSubmit}
          validateOnBlur={validationAfterSubmit}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <>
                <DtAdministrationDetailsLayout
                  pageTitle={pageTitle}
                  pageSubtitle={pageSubtitle}
                  backButtonRoute={backRoute}
                  isCreationFlow={isCreationFlow}
                  setValidationAfterSubmit={setValidationAfterSubmit}
                >
                  <DtAdministrationDetailsContent>
                    <DtAdministrationDetailsUserForm user={user} />
                  </DtAdministrationDetailsContent>
                  <DtAdministrationDetailsContentDivider />
                  <DtUserAssignments organizationId={user?.companyId} />
                </DtAdministrationDetailsLayout>
                {showConfirmationPopup && (
                  <DtAdministrationDetailsConfirmationPopup
                    opened={showConfirmationPopup}
                    onClose={() => {
                      // Go to details on popup close
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      if (createdUserId) {
                        dtRouter.navigate(RoutesResolver.UserDetails(createdUserId), { replace: true });
                      } else {
                        throw 'There is no ID of created user';
                      }
                    }}
                    onSubmit={() => {
                      // Reset form for creation
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      formikProps.resetForm({ values: formikProps.initialValues });
                      dispatch(dtClearAssignments());
                    }}
                    title='User created successfully'
                    icon='check'
                    color='emerald'
                    subtitle=''
                    isShowConfirmationMessage={showConfirmationPopup}
                    submitButtonText='Create another user'
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DtAdministrationDetailsUserPage;
