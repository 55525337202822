import React, { lazy } from 'react';

import DtResetPasswordPageSkeleton from './dt-reset-password-page-skeleton/dt-reset-password-page-skeleton';
import { dtWithSuspense } from '../../../cdk/hocs/dt-with-suspense.hoc';

const DtResetPasswordPageLazy = dtWithSuspense(
  lazy(() => import('./dt-reset-password-page')),
  <DtResetPasswordPageSkeleton />
);

export default DtResetPasswordPageLazy;
