import { IconButton } from '@mui/material';
import React, { useState, ReactNode, useRef } from 'react';
import { DateRange, DayModifiers, DayPicker, addToRange } from 'react-day-picker';

import DtPickerRangeItem from './components/dt-picker-range-item/dt-picker-range-item';
import { DT_PICKER_DAYS_RANGE } from '../../../dt-constants';
import DtIcon from '../../dt-icon/dt-icon';
import { DtDatePickerRange } from '../dt-date-pickers.enums';
import { DtDatePickersCommonProps } from '../dt-date-pickers.interfaces';
import {
  DateRangeInput,
  PopoverContainer,
  RangeList,
  InputLabel,
  PickerToolbarContainer,
  PickerApplyButton,
} from '../dt-date-pickers.styled';
import { dtGetDateRangePickerValue } from '../dt-date-pickers.utils';

export interface DtDateRangePickerProps extends DtDatePickersCommonProps {
  /**
   * Values of date picker.
   * @example { from: Date | undefined, to: Date | undefined }
   */
  value: DtDateRangeValue;
  /**
   * Date range picker change handler
   * @param value
   */
  onChange: (value: DtDateRangeValue) => void;
  month: Date;
}

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
const NUMBER_OF_MONTH = 2;

const DtDateRangePicker: React.FC<DtDateRangePickerProps> = ({
  label,
  value,
  onChange,
  dateFormat = DEFAULT_DATE_FORMAT,
  fullWidth,
  minMonth,
  maxMonth,
  disabledDays,
  month,
  disabled,
}) => {
  const pickerInputRef = useRef();
  const [opened, setOpened] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(value);
  const [currentDateRangeSelection, setCurrentDateRangeSelection] = useState(value);

  const [isDayClicked, setIsDayClicked] = useState(false);
  const [isCustomDateRangeClicked, setIsCustomDateRangeClicked] = useState(false);

  const pickerInputValue = dtGetDateRangePickerValue(value, dateFormat);

  const modifiers = { start: selectedDateRange.from, end: selectedDateRange.to };
  const selectedDays: DateRange = { from: selectedDateRange.from, to: selectedDateRange.to };

  function handleInputClick(): void {
    setOpened(true);
  }

  function handleDayClick(day: Date, { disabled }: DayModifiers): void {
    const range = addToRange(day, { from: selectedDateRange.from, to: selectedDateRange.to });

    if (!disabled && range) {
      setSelectedDateRange({ from: range.from as Date, to: range.to as Date });
    }
    setIsDayClicked(true);
  }

  function handleRangeMenuItemClick(value: DtDatePickerRange): void {
    const daysRange = DT_PICKER_DAYS_RANGE[value];
    setSelectedDateRange(daysRange);

    if (currentDateRangeSelection === daysRange) {
      setIsCustomDateRangeClicked(false);
    } else {
      setIsCustomDateRangeClicked(true);
    }
  }

  function handlePopoverClose(): void {
    setOpened(false);
    setSelectedDateRange(value);

    setIsCustomDateRangeClicked(false);
    setIsDayClicked(false);
  }

  function handleApplyClick(): void {
    onChange(selectedDateRange);
    setCurrentDateRangeSelection(selectedDateRange);
    setOpened(false);

    setIsCustomDateRangeClicked(false);
    setIsDayClicked(false);
  }

  function renderRangeItem(value: DtDatePickerRange): ReactNode {
    return (
      <DtPickerRangeItem
        key={value}
        value={value}
        highlighted={selectedDateRange === DT_PICKER_DAYS_RANGE[value]}
        onClick={() => {
          handleRangeMenuItemClick(value);
        }}
      />
    );
  }

  return (
    <>
      <DateRangeInput
        sx={label ? { '.MuiOutlinedInput-root input': { paddingLeft: '0px' } } : {}}
        disabled={disabled}
        fullWidth={fullWidth}
        value={pickerInputValue}
        onClick={handleInputClick}
        InputProps={{
          ref: pickerInputRef,
          className: opened ? 'focused' : '',
          readOnly: true,
          endAdornment: (
            <IconButton sx={{ width: '24px', height: '24px', padding: '2px' }}>
              <DtIcon icon={opened ? 'drop-up' : 'drop-down'} size='20px' />
            </IconButton>
          ),
          startAdornment: label && <InputLabel variant='bodySemibold'>{label}</InputLabel>,
        }}
      />
      <PopoverContainer
        anchorEl={pickerInputRef.current}
        open={opened}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DayPicker
          modifiersClassNames={{
            selected: 'range-day-selected',
            range_start: 'day_range_start',
            range_end: 'day_range_end',
          }}
          numberOfMonths={NUMBER_OF_MONTH}
          selected={selectedDays}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          fromMonth={minMonth}
          toMonth={maxMonth}
          disabled={disabledDays}
          showOutsideDays
          pagedNavigation
          defaultMonth={month}
          mode='range'
        />
        <PickerToolbarContainer>
          <RangeList>{Object.values(DtDatePickerRange).map(renderRangeItem)}</RangeList>
          <PickerApplyButton
            variant='primary'
            onClick={handleApplyClick}
            disabled={!isDayClicked && !isCustomDateRangeClicked}
          >
            Apply
          </PickerApplyButton>
        </PickerToolbarContainer>
      </PopoverContainer>
    </>
  );
};

export default DtDateRangePicker;
