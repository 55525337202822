import React from 'react';

import DtResetPasswordFormSkeleton from './dt-reset-password-form-skeleton';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import {
  DtAuthLayoutAppPreviewContainer,
  DtAuthLayoutContainer,
  DtAuthLayoutFormContainer,
  FormContainer,
} from '../dt-auth-layout/dt-auth-layout.styled';
import {
  CarouselTitle,
  FooterContainer,
  LogoContainer,
  Screenshot,
} from '../dt-login-page-skeleton/dt-login-page-skeleton.styled';

const DtResetPasswordPageSkeleton: React.FC = () => {
  return (
    <DtAuthLayoutContainer container>
      <DtAuthLayoutFormContainer item xs={6}>
        <FormContainer>
          <LogoContainer>
            <DtSkeleton variant='rectangular' width={136} height={40} />
          </LogoContainer>
          <DtResetPasswordFormSkeleton />
          <FooterContainer>
            <DtSkeleton variant='text' width={243} height={48} />
          </FooterContainer>
        </FormContainer>
      </DtAuthLayoutFormContainer>
      <DtAuthLayoutAppPreviewContainer item xs={6}>
        <Screenshot>
          <DtSkeleton variant='rectangular' width={640} height={540} />
        </Screenshot>
        <CarouselTitle>
          <DtSkeleton variant='text' width={260} height={22} />
        </CarouselTitle>
      </DtAuthLayoutAppPreviewContainer>
    </DtAuthLayoutContainer>
  );
};

export default DtResetPasswordPageSkeleton;
