import { Grid } from '@mui/material';
import React from 'react';

import DtSkeleton from '../../../../../../components/dt-skeleton/dt-skeleton';
import { FieldContainer } from '../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';

const DtSitePageFormSkeleton: React.FC = () => {
  return (
    <Grid container spacing={24} justifyContent='flex-start'>
      <FieldContainer item xs={6}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={6}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      {/* Should be uncomment when upload report logo feature will be done
      <FieldContainer item xs={6}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer> */}
      {/* <Grid item xs={12} sx={{ alignSelf: 'end' }}>
        <div>
          <DtSkeleton variant='text' width={120} height={20} />
          <DtSkeleton variant='text' width={300} height={15} />
        </div>
      </Grid>
      <FieldContainer item xs='auto' alignSelf='start'>
        <DtSkeleton variant='rectangular' width={200} height={100} />
      </FieldContainer>
      <FieldContainer item xs alignSelf='start'>
        <Grid container spacing={24} justifyContent='flex-start'>
          <FieldContainer item xs={6}>
            <DtSkeleton variant='rectangular' width='100%' height={40} />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <DtSkeleton variant='rectangular' width='100%' height={40} />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <DtSkeleton variant='rectangular' width='100%' height={40} />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <DtSkeleton variant='rectangular' width='100%' height={40} />
          </FieldContainer>
        </Grid>
      </FieldContainer> */}
    </Grid>
  );
};

export default DtSitePageFormSkeleton;
