import React, { useState } from 'react';

import { dtUseAppDispatch } from '../../../../../../../cdk/hooks/dt-store.hooks';
import DtDeleteConfirmationPopup from '../../../../../../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtIcon from '../../../../../../../components/dt-icon/dt-icon';
import { CenteredButton } from '../../../../../../../components/dt-table/dt-actions-table-cell.styled';
import { DtAppRoutes } from '../../../../../../../dt-enums';
import { dtRouter } from '../../../../../../../dt-routes';
import { dtSetCurrentCompany } from '../../../../../../dt-user/dt-user.slice';
import { dtDeleteOrganizationAction } from '../../../../../dt-organizations-tab/dt-organizations.actions';

interface DtOrganizationsTableCellActions {
  companyId: number | null;
  disabled?: boolean;
}

const DtDeleteOrganizationButton: React.FC<DtOrganizationsTableCellActions> = ({ companyId, disabled }) => {
  const dispatch = dtUseAppDispatch();
  const [showConfirmationMessage, updateShowConfirmationMessage] = useState(false);

  function handleOpenPopup(): void {
    updateShowConfirmationMessage(true);
  }

  async function handleDeleteOrganization(): Promise<void> {
    if (companyId) {
      await dispatch(dtDeleteOrganizationAction(companyId));
      updateShowConfirmationMessage(false);
      await dispatch(dtSetCurrentCompany(null));
      window.sessionStorage.removeItem('SELECTED_COMPANY');
      dtRouter.navigate(DtAppRoutes.OrganizationCreate);
    }
  }

  return (
    <div style={{ marginLeft: '24px' }}>
      <DtDeleteConfirmationPopup
        opened={showConfirmationMessage}
        onClose={() => {
          updateShowConfirmationMessage(false);
        }}
        onSubmit={handleDeleteOrganization}
        title='Delete current organization?'
        subtitle='You will not be able to recover it.'
        icon='trash'
        color='red'
      />
      <CenteredButton variant='transparent' onClick={handleOpenPopup} disabled={disabled}>
        <DtIcon icon='trash' size={20} color='red' />
      </CenteredButton>
    </div>
  );
};

export default DtDeleteOrganizationButton;
