import { find, isEqual } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import DtIcon from '../../dt-icon/dt-icon';
import DtSelectDropdown from '../components/dt-select-dropdown';
import { DtOptionItem, DtSelectProps } from '../dt-selects.interfaces';
import { CustomSelect, CustomSelectContainer, CustomTextField, Label, LabelInside } from '../dt-selects.styled';

const DtSelect = <T,>({
  value,
  onChange,
  options,
  disabled = false,
  label,
  labelPosition,
  className,
  placeholder,
  fullWidth,
  name,
  error,
  minWidth,
  disableClearable,
  withFlexProp,
  customOptionStyle,
  dropdownPaddingBottom,
}: DtSelectProps<T>): ReactElement => {
  const disabledInput = disabled || options.length === 0;
  const getOutsideSelectLabel = labelPosition === 'top' && label && (
    <div>
      <Label disabled={disabledInput}>{label}</Label>
    </div>
  );
  const getInnerSelectLabel = labelPosition === 'inside' && label && <LabelInside>{label}: </LabelInside>;

  const selectedValue = (find(options, { value }) ?? null) as DtOptionItem<T> | null;

  const [uniqueId] = useState((name && label && `${name}_${label}`) ?? uuidv4());

  function checkIfOptionEqualToValue(option: DtOptionItem<T>, selectedOption: DtOptionItem<T>): boolean {
    return isEqual(option, selectedOption);
  }

  function handleOptionValueChange(newValue: DtOptionItem<T> | null): void {
    onChange(newValue?.value);
  }

  return (
    <CustomSelectContainer withFlexProp={withFlexProp}>
      {getOutsideSelectLabel}
      <CustomSelect<DtOptionItem<T>, undefined, boolean>
        id={uniqueId}
        options={options}
        disabledItemsFocusable={false}
        getOptionDisabled={(option) => Boolean(option.disabled)}
        className={className}
        value={selectedValue}
        renderOption={(optionProps, option) => {
          if (option.render) {
            return (
              <li style={customOptionStyle} {...optionProps}>
                {option.render}
              </li>
            );
          }
          return <li {...optionProps}>{option.label}</li>;
        }}
        selectOnFocus
        openOnFocus
        blurOnSelect
        clearIcon={
          <span style={{ padding: '3px', display: 'flex' }}>
            <DtIcon icon='close' size={14} color='secondaryIcon' />
          </span>
        }
        disableClearable={disableClearable}
        fullWidth={fullWidth}
        isOptionEqualToValue={(option, selectedOption) =>
          checkIfOptionEqualToValue(option as DtOptionItem<T>, selectedOption as DtOptionItem<T>)
        }
        disabled={disabledInput}
        onChange={(_, newValue) => handleOptionValueChange(newValue as DtOptionItem<T>)}
        popupIcon={<DtIcon icon='drop-down' size={20} />}
        PaperComponent={({ children }) => (
          <DtSelectDropdown dropdownPaddingBottom={dropdownPaddingBottom}>{children}</DtSelectDropdown>
        )}
        renderInput={(params) => {
          return (
            <CustomTextField
              {...params}
              variant='outlined'
              name={name}
              error={Boolean(error)}
              helperText={error}
              labelPosition={labelPosition}
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              minWidth={minWidth}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: getInnerSelectLabel,
              }}
              disabled={disabledInput}
            />
          );
        }}
      />
    </CustomSelectContainer>
  );
};

export default DtSelect;
