import React, { ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DtAdministrationFilterPanel from './components/dt-administration-filter-panel/dt-administration-filter-panel';
import { dtLoadFormsAction } from './dt-forms-tab/dt-forms.actions';
import { dtSelectForms, dtSelectFormsLoading } from './dt-forms-tab/dt-forms.slice';
import { dtLoadUsers, dtSelectUsers, dtSelectUsersLoading } from './dt-manage-users-tab/dt-manage-users.slice';
import { dtSelectOrganizationsLoading } from './dt-organizations-tab/dt-organizations.slice';
import { dtLoadSites, dtSelectSites, dtSelectSitesLoading } from './dt-sites-tab/dt-sites.slice';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import DtIcon from '../../components/dt-icon/dt-icon';
import DtPageTitleAndSubtitle from '../../components/dt-page-title-and-subtitle/dt-page-title-and-subtitle';
import DtTabButtonContent from '../../components/dt-tabs/components/dt-tab-button-content/dt-tab-button-content';
import DtTabs from '../../components/dt-tabs/dt-tabs';
import { DtTabItem } from '../../components/dt-tabs/dt-tabs.interfaces';
import { DtAppRoutes } from '../../dt-enums';
import { dtRouter } from '../../dt-routes';
import { MainContent } from '../dt-dashboard/dt-dashboard.styled';
import { dtSelectCurrentCompanyId } from '../dt-user/dt-user.slice';

interface DtAdministrationLayoutProps {
  children?: ReactNode;
}

const DtAdministrationLayout: React.FC<DtAdministrationLayoutProps> = () => {
  const dispatch = dtUseAppDispatch();
  const history = useLocation();
  const currentActiveTab = history.pathname;

  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);
  const [selectedFormType, setSelectedFormType] = useState<string | null>(null);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);
  const sitesCount = dtUseAppSelector(dtSelectSites).length;
  const usersCount = dtUseAppSelector(dtSelectUsers).length;
  const formsCount = dtUseAppSelector(dtSelectForms).length;
  const isOrganizationsLoading = dtUseAppSelector(dtSelectOrganizationsLoading);
  const isSitesLoading = dtUseAppSelector(dtSelectSitesLoading);
  const isUsersLoading = dtUseAppSelector(dtSelectUsersLoading);
  const isFormsLoading = dtUseAppSelector(dtSelectFormsLoading);

  const tabs: DtTabItem[] = [
    {
      value: DtAppRoutes.OrganizationAdministration,
      label: (
        <DtTabButtonContent
          counter={1}
          subtitle='Organization'
          icon={<DtIcon icon='list' size={16} color='secondaryText' />}
          isDataLoading={isOrganizationsLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.SitesAdministration,
      label: (
        <DtTabButtonContent
          counter={sitesCount}
          subtitle='Sites'
          icon={<DtIcon icon='building' size={16} color='secondaryText' />}
          isDataLoading={isSitesLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.FormsAdministration,
      label: (
        <DtTabButtonContent
          counter={formsCount}
          subtitle='Forms'
          icon={<DtIcon icon='list' size={16} color='secondaryText' />}
          isDataLoading={isFormsLoading}
        />
      ),
    },
    {
      value: DtAppRoutes.UsersAdministration,
      label: (
        <DtTabButtonContent
          counter={usersCount}
          subtitle='Users'
          icon={<DtIcon icon='users' size={16} color='secondaryText' />}
          isDataLoading={isUsersLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    if (currentCompanyId) {
      dispatch(dtLoadFormsAction(currentCompanyId, selectedSiteId ?? undefined, selectedFormType ?? undefined));
      dispatch(
        dtLoadSites({
          companyId: currentCompanyId,
          siteId: selectedSiteId ?? undefined,
          formType: selectedFormType ?? undefined,
        })
      );
      dispatch(
        dtLoadUsers({
          companyId: currentCompanyId,
          siteId: selectedSiteId ?? undefined,
          formType: selectedFormType ?? undefined,
        })
      );
    }
  }, [currentCompanyId, selectedSiteId, selectedFormType]);

  return (
    <MainContent>
      <DtPageTitleAndSubtitle title='Administration Panel' subtitle='Manage users and their permissions' />
      <DtAdministrationFilterPanel
        selectedSiteId={selectedSiteId}
        selectedFormType={selectedFormType}
        setSelectedSiteId={setSelectedSiteId}
        setSelectedFormType={setSelectedFormType}
      />
      <DtTabs
        currentActiveTab={currentActiveTab}
        tabs={tabs}
        setCurrentActiveTab={(newActiveTab) => dtRouter.navigate(newActiveTab)}
      >
        <Outlet />
      </DtTabs>
    </MainContent>
  );
};

export default DtAdministrationLayout;
