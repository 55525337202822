import { useFormikContext } from 'formik';
import React from 'react';

import DtAssignments from './dt-assignments';
import { dtUseAppDispatch } from '../../cdk/hooks/dt-store.hooks';
import {
  dtUnassignChildFromAllParents,
  dtUnassignChildFromParent,
  dtUnassignParent,
} from '../../modules/dt-administration/dt-assignments.slice';
import { AssignmentParent } from '../../repositories/__generated__/v2';

export interface DtAssignmentBlocksProps {
  /**
   * @param {string} parentTitle - represent a header title inside parent (left side) column
   * @example 'Sites', 'Types' etc
   */
  parentTitle: string;
  /**
   * @param {string} childTitle - represent a header title inside child (right side) column
   * @example 'Users', 'Assets' etc
   */
  childTitle: string;
  /**
   * @param {DtAssignmentParentData[]} data - represents a corresponded data related to parent and child columns. Consist of particular parent data which has a corresponded children data
   * @example {
        id,
        displayValue,
        type,
        children: [
            {
                id,
                displayValue,
                type
            }
        ],
    }
   */
  data: AssignmentParent[];
  /**
   * @param {string} onParentEdit - callback function takes a specific parent list item id and will allow to make some updates on it
   * @example '1', '5' etc
   */
  onParentEdit?: (parentId: string) => void;
  /**
   * @param {string} onChildEdit - callback function takes a specific child list item id and will allow to make some updates on it
   * @example '1', '5' etc
   */
  onChildEdit?: (childId: string, parentId?: string) => void;
  /**
   * @param {void} onParentAssign - callback function will allow to open a specific, defined popup window to make assignment for parent column
   */
  onParentAssign?: () => void;
  /**
   * @param {void} onParentAssign - callback function will allow to open a specific, defined popup window to make assignment for child column
   */
  onChildAssign?: (parentId: string) => void;
  disableParentAssign?: boolean;
  disableChildAssign?: boolean;
  hideParentAssignButton?: boolean;
  hideChildAssignButton?: boolean;
  hideDeleteButton?: boolean;
  hideEditButton?: boolean;
  hideSubtitle?: boolean;
  tooltipTitle?: string;
  childColumnType?: string;
  disabled?: boolean;
}

const DtAssignmentBlocks: React.FC<DtAssignmentBlocksProps> = (props) => {
  const dispatch = dtUseAppDispatch();
  const { setFieldValue } = useFormikContext();

  function triggerAssignmentsChange(): void {
    setFieldValue('assignments', true);
  }

  function onParentItemUnassign(parentId: string): void {
    dispatch(dtUnassignParent(parentId));
    triggerAssignmentsChange();
  }

  function onChildItemUnassign(childId: string, parentId?: string): void {
    if (parentId) {
      dispatch(dtUnassignChildFromParent({ childId, parentId }));
    } else {
      dispatch(dtUnassignChildFromAllParents(childId));
    }
    triggerAssignmentsChange();
  }

  return (
    <DtAssignments
      parentTitle={props.parentTitle}
      childTitle={props.childTitle}
      data={props.data}
      disableParentAssign={props.disableParentAssign}
      disableChildAssign={props.disableChildAssign}
      hideParentAssignButton={props.hideParentAssignButton}
      hideChildAssignButton={props.hideChildAssignButton}
      hideDeleteButton={props.hideDeleteButton}
      hideEditButton={props.hideEditButton}
      hideSubtitle={props.hideSubtitle}
      tooltipTitle={props.tooltipTitle}
      childColumnType={props.childColumnType}
      disabled={props.disabled}
      onParentEdit={props.onParentEdit}
      onChildEdit={props.onChildEdit}
      onParentAssign={props.onParentAssign}
      onChildAssign={props.onChildAssign}
      onParentItemUnassign={onParentItemUnassign}
      onChildItemUnassign={onChildItemUnassign}
      triggerAssignmentsChange={triggerAssignmentsChange}
    />
  );
};

export default DtAssignmentBlocks;
