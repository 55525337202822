import { dtSetSitesData, dtUpdateLoadingStatusOfSiteRow } from './dt-sites.slice';
import { DtAppThunk } from '../../../dt-store';
import { dtApiRepository } from '../../../repositories/dt-api.repository';
import { dtToastService } from '../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../dt-user/dt-user.actions';

export const dtDeleteSiteAction =
  (id: number): DtAppThunk =>
  async (dispatch, getState) => {
    const root = getState();

    try {
      dispatch(dtUpdateLoadingStatusOfSiteRow({ id, status: true }));

      await dtApiRepository.AdminSiteApi.adminSiteControllerDelete(id);

      const sites = root.dtSites.sites.filter((item) => item.id !== id);
      dispatch(dtSetSitesData(sites));

      dtToastService.success('Successfully deleted site');
      dispatch(dtRefreshCurrentUserData());
    } catch (e) {
      dispatch(dtUpdateLoadingStatusOfSiteRow({ id, status: false }));
      dtToastService.error('Failed to delete site');
    }
  };
