import { GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dtGenerateFormsTableColumnsConfig } from './dt-generate-forms-columns-config';
import { dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtSearchInput from '../../../../../components/dt-search-input/dt-search-input';
import DtTable from '../../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../../dt-constants';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { DT_TABLE_ROW_HEIGHT } from '../../../../dt-dashboard/dt-dispatches.constants';
import DtCreateButton from '../../../components/dt-create-button/dt-create-button';
import { dtSelectForms, dtSelectFormsLoading } from '../../dt-forms.slice';

const DtFormsTable: React.FC = () => {
  const forms = dtUseAppSelector(dtSelectForms);

  const isLoading = dtUseAppSelector(dtSelectFormsLoading);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'form name',
      sort: 'asc',
    },
  ]);
  const [hoveredRowId, setHoveredRowId] = useState('');

  const dtFormTypesTableColumns = dtGenerateFormsTableColumnsConfig(isLoading, hoveredRowId);
  const showLoaderWhenNoDataYet = isLoading && !forms.length;

  const matchedForms = useMemo(() => {
    if (isEmpty(searchQuery)) {
      return forms;
    }

    return forms.filter((form) => {
      const formInfo = form.formType + form.formName + form.pfFormId;
      return formInfo.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [forms, searchQuery]);

  function handleTypeCreationRedirect(): void {
    dtRouter.navigate(DtAppRoutes.FormCreate);
  }

  function handleEditFormType(params: GridRowParams): void {
    dtRouter.navigate(
      RoutesResolver.FormDetails(
        params.row.siteId.toString(),
        params.row.formSchemaId.toString(),
        params.row.formTypeId.toString()
      )
    );
  }

  function onRowHover(rowId: string): void {
    setHoveredRowId(rowId);
  }

  const tableActionsComponent = (
    <div style={{ display: 'flex' }}>
      <DtSearchInput onChange={setSearchQuery} />
      <DtCreateButton onClick={handleTypeCreationRedirect} variant='primary' iconSize={16} iconColor='white'>
        New Form
      </DtCreateButton>
    </div>
  );

  return (
    <DtTable
      rows={matchedForms}
      columns={dtFormTypesTableColumns}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      //TODO Make form types items unique (with unique id), because siteId is not unique, hence not all items are displayed inside the table
      getRowId={() => uuidv4()}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      loading={showLoaderWhenNoDataYet}
      sortModel={sortModel}
      onSortModelChange={(newSortModel: GridSortModel) => setSortModel(newSortModel)}
      actionsComponent={tableActionsComponent}
      onRowClick={handleEditFormType}
      onRowHover={onRowHover}
    />
  );
};

export default DtFormsTable;
