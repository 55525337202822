import { CSSObject, styled } from '@mui/material/styles';

export const CellBadgeWrapper = styled('div')`
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(12)}`};
  background-color: ${({ theme }) => theme.palette.main.transparentSecondaryText};
`;

export const CellBadgeTitle = styled('span')`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;
