import { Tooltip } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React from 'react';

import DtButton from '../../../dt-button/dt-button';
import DtIcon from '../../../dt-icon/dt-icon';
import DtTooltipTriggerElement from '../../../dt-tooltip-trigger-element/dt-tooltip-trigger-element';
import { ListItemsAction, ListItemSubtitle, ListItemTitle, ListItemWrapper } from '../../dt-assignment-blocks.styled';

const TooltipContent = (items: string[]): JSX.Element => (
  <>
    {items.map((item, index) => (
      <li key={`${index}-${item}`} style={{ listStyle: 'none' }}>
        {item}
      </li>
    ))}
  </>
);

interface DtAssignmentListItemProps {
  id: string;
  title: string;
  subtitle: string;
  onEdit: (id: string, parentId?: string) => void;
  onUnassign: (id: string, parentId?: string) => void;
  disableEditing?: boolean;
  highlighted?: boolean;
  withArrow?: boolean;
  hideTrashButton: boolean;
  hideEditButton: boolean;
  selectedListItem?: string | null;
  handleListItemSelection?: (id: string) => void;
  onChildAssign?: (parentId: string) => void;
  selectedParentId?: string | null;
  tooltipContent?: string[];
  tooltipTitle?: string;
  disabled?: boolean;
}

const DtAssignmentListItem: React.FC<DtAssignmentListItemProps> = ({
  id,
  title,
  subtitle,
  highlighted,
  withArrow,
  disableEditing,
  onEdit,
  onChildAssign,
  onUnassign,
  selectedListItem,
  handleListItemSelection,
  selectedParentId,
  hideTrashButton,
  hideEditButton,
  tooltipContent,
  tooltipTitle,
  disabled,
}) => {
  const isListItemSelected = selectedListItem === id || highlighted;

  function onListItemSelection(): void | undefined {
    if (disableEditing) {
      return;
    }
    handleListItemSelection && handleListItemSelection(id);
  }

  function onListItemEdit(): void {
    onEdit(id, selectedParentId ?? '');
  }

  function onListItemUnassign(): void {
    if (disableEditing) {
      return;
    }
    onUnassign(id, selectedParentId ?? '');
  }

  return (
    <ListItemWrapper
      disableEditing={disableEditing}
      disableRipple
      selected={isListItemSelected}
      onClick={onListItemSelection}
      withArrow={withArrow}
      disabled={disabled}
    >
      <li>
        <ListItemTitle>{title}</ListItemTitle>
        <ListItemSubtitle>
          {!isNil(tooltipContent) ? (
            <>
              {tooltipContent?.length}&nbsp;{tooltipTitle}&nbsp;
              {!isEmpty(tooltipContent) && (
                <Tooltip title={TooltipContent(tooltipContent)} arrow>
                  <DtTooltipTriggerElement />
                </Tooltip>
              )}
            </>
          ) : (
            subtitle
          )}
        </ListItemSubtitle>
      </li>
      <ListItemsAction>
        {onChildAssign && (
          <DtButton variant='transparent' onClick={() => onChildAssign(id)}>
            <DtIcon icon='plus' size={18} />
          </DtButton>
        )}
        {!hideEditButton && (
          <DtButton variant='transparent' onClick={onListItemEdit}>
            <DtIcon icon='edit' size={18} />
          </DtButton>
        )}
        {!hideTrashButton && (
          <DtButton variant='transparent' onClick={onListItemUnassign}>
            <DtIcon icon='trash' size={18} />
          </DtButton>
        )}
      </ListItemsAction>
    </ListItemWrapper>
  );
};

export default DtAssignmentListItem;
