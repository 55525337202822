import { ListItemButton } from '@mui/material';
import React from 'react';

import { RangeItem } from './dt-picker-range-item.styled';
import { DtDatePickerRange } from '../../../dt-date-pickers.enums';

interface DtPickerRangeItemProps {
  value: DtDatePickerRange;
  onClick: () => void;
  highlighted: boolean;
}

const DtPickerRangeItem: React.FC<DtPickerRangeItemProps> = ({ value, onClick, highlighted }) => {
  return (
    <RangeItem>
      <ListItemButton onClick={onClick} selected={highlighted}>
        {value}
      </ListItemButton>
    </RangeItem>
  );
};

export default DtPickerRangeItem;
