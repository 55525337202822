import { styled } from '@mui/material/styles';
import { List, ListItem } from '@mui/material';

export const AssetsListContainer = styled(List)`
  display: flex;
  flex-wrap: wrap;
`;

export const AssetsListItem = styled(ListItem)`
  flex: 1 1 45%;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(16)}`};

  &:nth-of-type(odd) {
    margin-right: ${({ theme }) => theme.spacing(24)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.main.hover};
  }
`;
