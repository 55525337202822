import { isNull } from 'lodash';
import React from 'react';
import { RouteProps } from 'react-router-dom';

import DtPrivateRoute from './dt-private-route';
import { dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes } from '../../dt-enums';
import { dtRouter } from '../../dt-routes';
import {
  dtSelectCurrentCompanyId,
  dtSelectIsAuthenticated,
  dtSelectUserLoading,
  dtSelectUserRole,
} from '../../modules/dt-user/dt-user.slice';
import { UserRole } from '../../repositories/__generated__/v2';
import DtGlobalLoaderSkeleton from '../dt-global-loader-skeleton/dt-global-loader-skeleton';

const DtAuthorizedRoute: React.FC<RouteProps> = ({ ...props }) => {
  const pathname = dtRouter.state.location.pathname;

  const isUserLoading = dtUseAppSelector(dtSelectUserLoading);
  const isAuthenticated = dtUseAppSelector(dtSelectIsAuthenticated);
  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);
  const userRole = dtUseAppSelector(dtSelectUserRole);

  if (isUserLoading) {
    return <DtGlobalLoaderSkeleton />;
  }

  return (
    <DtPrivateRoute
      hasAccess={isAuthenticated}
      isUserSuperAdmin={userRole === UserRole.SuperAdmin}
      hasSelectedCompany={!isNull(currentCompanyId)}
      currentPathname={pathname}
      redirectUrl={DtAppRoutes.Login}
      {...props}
    />
  );
};

export default DtAuthorizedRoute;
