import { differenceInMinutes, formatDistanceToNowStrict } from 'date-fns';

/**
 * Relative time format.
 */
export function dtRtf(datetime: Date): string {
  return formatDistanceToNowStrict(datetime, { addSuffix: true });
}

export function dtDateDiff(d1: Date, d2: Date = new Date(), unit: 'minutes' = 'minutes'): number {
  if (unit === 'minutes') {
    return Math.abs(differenceInMinutes(d1, d2));
  }

  throw 'Unsuported unit type';
}
