import { Field, FieldProps } from 'formik';
import React from 'react';

import DtUploadFile from './dt-upload-file';

interface DtFormikUploadFile {
  name: string;
  fullWidth?: boolean;
}

const DtFormikUploadFile: React.FC<DtFormikUploadFile> = ({ name, fullWidth }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div style={{ display: 'flex', height: '40px' }}>
          <DtUploadFile
            fileName={field?.value?.name}
            onChange={(file) => form.setFieldValue(field.name, file)}
            fullWidth={fullWidth}
            error={meta.error}
          />
        </div>
      )}
    </Field>
  );
};

export default DtFormikUploadFile;
