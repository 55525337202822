import { useFormikContext } from 'formik';
import React from 'react';

import DtFormikSelect from '../../../../../../../components/dt-selects/dt-formik-select';
import DtFormTextInput from '../../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { InputLabel } from '../../../../../../dt-auth/components/dt-form-common-styles.styled';
import { DtFrequencyEditorFormRecurrenceType } from '../../../dt-frequency-editor-form.enums';
import { DtNewDispatchFormInitialValues } from '../../../dt-new-dispatch-modal-form-interface';
import {
  DT_DAY_INPUT_MIN_MAX_RESTRICTIONS,
  DT_MONTH_INPUT_MIN_MAX_RESTRICTIONS,
} from '../../../dt-new-dispatch-modal.constants';
import { FrequencyInputContainer } from '../../../steps/dt-new-dispatch-frequency/dt-new-dispatch-frequency.styled';
import { FrequencyRow } from '../dt-new-dispatch-frequency.styled';

const DtFrequencyMonthlyWithEveryRecurrence: React.FC = () => {
  const { setFieldValue } = useFormikContext<DtNewDispatchFormInitialValues>();

  return (
    <FrequencyRow columnsGridTemplate='repeat(3, 1fr)'>
      <FrequencyInputContainer>
        <DtFormikSelect
          fullWidth
          name='frequency.repeatEvery'
          label='Recur'
          labelPosition='top'
          options={[
            { label: 'Day', value: DtFrequencyEditorFormRecurrenceType.Each },
            { label: 'On the', value: DtFrequencyEditorFormRecurrenceType.Specific },
          ]}
          minWidth='auto'
          disableClearable
          onChange={(value) => {
            if (value === DtFrequencyEditorFormRecurrenceType.Specific) {
              setFieldValue('frequency.day', 1);
              setFieldValue('frequency.monthRepeats', 1);
            }
          }}
        />
      </FrequencyInputContainer>
      <FrequencyInputContainer>
        <InputLabel>&nbsp;</InputLabel>
        <DtFormTextInput
          type='number'
          name='frequency.day'
          InputProps={{ inputProps: DT_DAY_INPUT_MIN_MAX_RESTRICTIONS }}
        />
      </FrequencyInputContainer>
      <FrequencyInputContainer>
        <InputLabel>Every month(s)</InputLabel>
        <DtFormTextInput
          type='number'
          name='frequency.month'
          InputProps={{ inputProps: DT_MONTH_INPUT_MIN_MAX_RESTRICTIONS }}
        />
      </FrequencyInputContainer>
    </FrequencyRow>
  );
};

export default DtFrequencyMonthlyWithEveryRecurrence;
