import React, { ReactElement } from 'react';

import { DialogActionsContainer, DialogTitle, DialogSubtitle, DialogContainer, DialogIcon } from './dt-popup.styled';
import { DtSupportedIcon } from '../dt-icon/gen/dt-supported-icons';

export interface DtPopupProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  icon?: DtSupportedIcon;
  color?: keyof MainPalette;
  body?: ReactElement;
  children?: false | ReactElement | undefined;
}

const DtPopup: React.FC<DtPopupProps> = (props) => {
  return (
    <DialogContainer onClose={props.onClose} open={props.opened}>
      {props.icon && <DialogIcon icon={props.icon} color={props.color} size={32} />}
      <DialogTitle>{props.title}</DialogTitle>
      {props.subtitle && <DialogSubtitle>{props.subtitle}</DialogSubtitle>}
      {props.body && props.body}
      <DialogActionsContainer>{props.children}</DialogActionsContainer>
    </DialogContainer>
  );
};

export default DtPopup;
