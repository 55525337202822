import React from 'react';

import { DispatchFrequencyLabel } from './dt-dispatch-frequency-label.styled';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';

interface DtDispatchFrequencyLabelProps {
  status: DtSupportedDispatchStatuses;
  frequency: string;
  date: string;
}

const DtDispatchFrequencyLabel: React.FC<DtDispatchFrequencyLabelProps> = ({ status, frequency, date }) => {
  const isStatusScheduled = status === DtSupportedDispatchStatuses.Scheduled;

  function getStatusSubtitleLabel(status: DtSupportedDispatchStatuses): string {
    switch (status) {
      case DtSupportedDispatchStatuses.Completed:
        return 'submitted';
      case DtSupportedDispatchStatuses.Scheduled:
        return 'Frequency:';
      default:
        return 'due on';
    }
  }

  return (
    <>
      {getStatusSubtitleLabel(status)}&nbsp;
      {isStatusScheduled ? <DispatchFrequencyLabel>{frequency}</DispatchFrequencyLabel> : date}
    </>
  );
};

export default DtDispatchFrequencyLabel;
