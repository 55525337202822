import { CSSObject, styled, Theme } from '@mui/material/styles';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';

const coloredStatusWrapper = (status: DtSupportedDispatchStatuses, theme: Theme) => {
  switch (status) {
    case DtSupportedDispatchStatuses.Open:
      return theme.palette.main.transparentButtonPrimary;
    case DtSupportedDispatchStatuses.Overdue:
      return theme.palette.main.transparentRed;
    case DtSupportedDispatchStatuses.Completed:
      return theme.palette.main.transparentEmerald;
    case DtSupportedDispatchStatuses.Scheduled:
      return theme.palette.main.transparentSecondaryText;
  }
};

const coloredStatusLabel = (status: DtSupportedDispatchStatuses, theme: Theme) => {
  switch (status) {
    case DtSupportedDispatchStatuses.Open:
      return theme.palette.main.buttonPrimary;
    case DtSupportedDispatchStatuses.Overdue:
      return theme.palette.main.red;
    case DtSupportedDispatchStatuses.Completed:
      return theme.palette.main.emerald;
    case DtSupportedDispatchStatuses.Scheduled:
      return theme.palette.main.secondaryText;
  }
};

export const StatusContainer = styled('div')`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(16)}`};
  border-radius: 3px;
  width: fit-content;
`;

export const ColoredStatusWrapper = styled(StatusContainer, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: DtSupportedDispatchStatuses }>`
  background-color: ${({ theme, status }) => coloredStatusWrapper(status, theme)};
`;

export const ColoredStatusLabel = styled('span', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: DtSupportedDispatchStatuses }>`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject}
  color: ${({ theme, status }) => coloredStatusLabel(status, theme)};
`;
