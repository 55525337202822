/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FormSchemaDto } from '../entities';
/**
 * AdminFormSchemaApi - axios parameter creator
 * @export
 */
export const AdminFormSchemaApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerCreate: async (
      formSchemaDto: FormSchemaDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formSchemaDto' is not null or undefined
      assertParamExists('adminFormSchemaControllerCreate', 'formSchemaDto', formSchemaDto);
      const localVarPath = `/admin/form-schema`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formSchemaDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormSchemaControllerDelete', 'id', id);
      const localVarPath = `/admin/form-schema/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/form-schema`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerGetReportTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/form-schema/report-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerRefresh: async (
      formId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('adminFormSchemaControllerRefresh', 'formId', formId);
      const localVarPath = `/admin/form-schema/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (formId !== undefined) {
        localVarQueryParameter['formId'] = formId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerUpdate: async (
      formSchemaDto: FormSchemaDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formSchemaDto' is not null or undefined
      assertParamExists('adminFormSchemaControllerUpdate', 'formSchemaDto', formSchemaDto);
      const localVarPath = `/admin/form-schema`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formSchemaDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminFormSchemaApi - functional programming interface
 * @export
 */
export const AdminFormSchemaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminFormSchemaApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerCreate(
      formSchemaDto: FormSchemaDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerCreate(formSchemaDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerGetAll(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormSchemaDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerGetAll(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerGetReportTemplates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerGetReportTemplates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerRefresh(
      formId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerRefresh(formId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormSchemaControllerUpdate(
      formSchemaDto: FormSchemaDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormSchemaControllerUpdate(formSchemaDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminFormSchemaApi - factory interface
 * @export
 */
export const AdminFormSchemaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminFormSchemaApiFp(configuration);
  return {
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerCreate(formSchemaDto: FormSchemaDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminFormSchemaControllerCreate(formSchemaDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.adminFormSchemaControllerDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerGetAll(options?: any): AxiosPromise<Array<FormSchemaDto>> {
      return localVarFp.adminFormSchemaControllerGetAll(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerGetReportTemplates(options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .adminFormSchemaControllerGetReportTemplates(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerRefresh(formId: number, options?: any): AxiosPromise<void> {
      return localVarFp.adminFormSchemaControllerRefresh(formId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FormSchemaDto} formSchemaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormSchemaControllerUpdate(formSchemaDto: FormSchemaDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminFormSchemaControllerUpdate(formSchemaDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminFormSchemaApi - interface
 * @export
 * @interface AdminFormSchemaApi
 */
export interface AdminFormSchemaApiInterface {
  /**
   *
   * @param {FormSchemaDto} formSchemaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerCreate(formSchemaDto: FormSchemaDto, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<FormSchemaDto>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerGetReportTemplates(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

  /**
   *
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerRefresh(formId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {FormSchemaDto} formSchemaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApiInterface
   */
  adminFormSchemaControllerUpdate(formSchemaDto: FormSchemaDto, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * AdminFormSchemaApi - object-oriented interface
 * @export
 * @class AdminFormSchemaApi
 * @extends {BaseAPI}
 */
export class AdminFormSchemaApi extends BaseAPI implements AdminFormSchemaApiInterface {
  /**
   *
   * @param {FormSchemaDto} formSchemaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerCreate(formSchemaDto: FormSchemaDto, options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerCreate(formSchemaDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerDelete(id: number, options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerGetAll(options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerGetAll(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerGetReportTemplates(options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerGetReportTemplates(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerRefresh(formId: number, options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerRefresh(formId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FormSchemaDto} formSchemaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormSchemaApi
   */
  public adminFormSchemaControllerUpdate(formSchemaDto: FormSchemaDto, options?: AxiosRequestConfig) {
    return AdminFormSchemaApiFp(this.configuration)
      .adminFormSchemaControllerUpdate(formSchemaDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
