import React from 'react';

import { CellSubtitle, CellTitle } from '../../../../../components/dt-table/table-cell.styled';
import { CellContentWrapper } from '../../../../dt-dashboard/components/dt-dispatches-table/dt-dispatch-assigner-table-cell/dt-dispatch-assigner-table-cell.styled';

interface DtUsersNameTableCellProps {
  fullName: string;
  userEmail: string;
}

const DtManageUsersNameTableCell: React.FC<DtUsersNameTableCellProps> = ({ fullName, userEmail }) => {
  return (
    <CellContentWrapper>
      <CellTitle titleBold={true}>{fullName}</CellTitle>
      <CellSubtitle subtitleBold={false}>{userEmail}</CellSubtitle>
    </CellContentWrapper>
  );
};

export default DtManageUsersNameTableCell;
