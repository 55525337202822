export enum DtAppRoutes {
  Login = '/auth/login',
  ResetPassword = '/auth/reset-password',
  Actions = '/actions',

  DashboardOpen = '/dashboard/open',
  DashboardOverdue = '/dashboard/overdue',
  DashboardCompleted = '/dashboard/completed',
  DashboardScheduled = '/dashboard/scheduled',

  InspectionDetails = '/inspection/:id',

  OrganizationAdministration = '/administration/organization',
  OrganizationCreate = '/administration/organizations/create',
  OrganizationDetails = '/administration/organizations/:id',

  SitesAdministration = '/administration/sites',
  SiteCreate = '/administration/sites/create',
  SiteDetails = '/administration/sites/:id',

  TypesAdministration = '/administration/types',
  FormsAdministration = '/administration/forms',
  FormCreate = '/administration/forms/create',
  FormDetails = '/administration/forms/:id',

  UsersAdministration = '/administration/users',
  UserCreate = '/administration/users/create',
  UserDetails = '/administration/users/:id',
  Assets = '/assets',
  AssetsDetails = '/assets/:id',
  Profile = '/profile',
  NotFound = '*',
}

export enum DtStatus {
  WaitingToLoad = 'waiting-to-load',
  Loading = 'loading',
  Idle = 'idle',
  Failed = 'failed',
}

export class RoutesResolver {
  static UserDetails(id?: string): string {
    return DtAppRoutes.UserDetails.replace(':id', id ?? '');
  }

  static OrganizationDetails(id?: string): string {
    return DtAppRoutes.OrganizationDetails.replace(':id', id ?? '');
  }

  static SiteDetails(id?: string): string {
    return DtAppRoutes.SiteDetails.replace(':id', id ?? '');
  }

  static FormDetails(siteId: string, formSchemaId: string, id?: string): string {
    const path = DtAppRoutes.FormDetails.replace(':id', id ?? '');

    return `${path}?site=${siteId}&formSchema=${formSchemaId}`;
  }

  static InspectionDetails(id?: string): string {
    return DtAppRoutes.InspectionDetails.replace(':id', id ?? '');
  }

  static AssetsDetails(id: string, siteId: string, formTypeId: string, formSubtypeId: string): string {
    const path = DtAppRoutes.AssetsDetails.replace(':id', id ?? '');

    return `${path}?site=${siteId}&formType=${formTypeId}&formSubtype=${formSubtypeId}`;
  }
}
