import { GridRowSelectionModel } from '@mui/x-data-grid';
import { isEmpty, isObject } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import DtAssetsTable, { DtAssetRowItem } from './components/dt-assets-table/dt-assets-table';
import DtAssetsDetailsModal from './modals/dt-assets-details-modal/dt-assets-details-modal';
import { dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { dtUseQuery } from '../../cdk/hooks/dt-use-query';
import DtCopyrightFooter from '../../components/dt-copyright-footer/dt-copyright-footer';
import DtDeleteConfirmationPopup from '../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtSearchInput from '../../components/dt-search-input/dt-search-input';
import DtSelectionSummaryBar from '../../components/dt-selection-summary-bar/dt-selection-summary-bar';
import { DtAppRoutes } from '../../dt-enums';
import { FormMutationDto } from '../../repositories/__generated__/v2';
import { dtApiRepository } from '../../repositories/dt-api.repository';
import { dtToastService } from '../../services/dt-toast.service';
import DtCreateButton from '../dt-administration/components/dt-create-button/dt-create-button';
import DtAdministrationDetailsHeader from '../dt-administration/dt-administration-details/dt-administration-details-header/dt-administration-details-header';
import { DtAdministrationDetailsHeaderWrapper } from '../dt-administration/dt-administration-details/dt-administration-details-layout/dt-administration-details-layout.styled';
import { MainContent } from '../dt-dashboard/dt-dashboard.styled';
import { dtSelectCurrentCompanyId } from '../dt-user/dt-user.slice';

const DtAssetsDetails: React.FC = () => {
  const query = dtUseQuery();

  const [form, setForm] = useState<FormMutationDto | null>(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [assets, setAssets] = useState<DtAssetRowItem[]>([]);
  const [assetsToDelete, setAssetsToDelete] = useState<string[] | Array<{ id: string }>>([]);
  const [openedAssetRow, setOpenedAssetRow] = useState<DtAssetRowItem | null>(null);
  const [isShowConfirmationMessage, setIsShowConfirmationMessage] = useState<boolean>(false);
  const [selectedAssets, setSelectedAssets] = useState<GridRowSelectionModel>([]);
  const [isAssetsLoading, setIsAssetsLoading] = useState(false);
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const selectionSummaryBarOpened = selectedAssets.length > 0 && !isShowConfirmationMessage;
  const isSelectedAllAssets = assets?.length === selectedAssets.length;

  const currentSiteId = Number(query.get('site'));
  const currentFormTypeId = Number(query.get('formType'));
  const currentFormSubtypeId = Number(query.get('formSubtype'));

  const matchedAssets = useMemo(() => {
    if (isEmpty(searchQuery)) {
      return assets;
    }

    return assets.filter((asset) => {
      const assetInfo = asset.assetName + asset.assetFloor + asset.assetLocation;
      return assetInfo.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [assets, searchQuery]);

  useEffect(() => {
    if (currentCompanyId) {
      setIsFormLoading(true);
      dtApiRepository.AdminFormApi.adminFormControllerGet(
        currentFormTypeId,
        currentFormSubtypeId,
        currentCompanyId as number,
        currentSiteId
      ).then(({ data }) => {
        setForm(data);
        setIsFormLoading(false);
      });
    }
  }, [currentFormTypeId, currentFormSubtypeId, currentSiteId, currentCompanyId]);

  useEffect(() => {
    getAllAssets();
  }, [currentFormTypeId, currentFormSubtypeId, currentSiteId, currentCompanyId]);

  function getAllAssets(): void {
    if (currentCompanyId) {
      setIsAssetsLoading(true);
      dtApiRepository.AssetApi.assetControllerGetAllAssets(
        currentFormTypeId,
        currentFormSubtypeId,
        currentCompanyId as number,
        currentSiteId
      ).then(({ data }) => {
        const normalizedData = data.map((asset) => ({ ...asset, id: uuidv4(), isLoading: false }));
        setAssets(normalizedData);
        setIsAssetsLoading(false);
      });
    }
  }

  function onTableRowClick(row: DtAssetRowItem): void {
    setShowModal(true);
    setOpenedAssetRow(row);
  }

  function onCreateAsset(): void {
    setShowModal(true);
  }

  function onTableRowSelectionChange(selectionModel: GridRowSelectionModel): void {
    setSelectedAssets(selectionModel);
  }

  function onOpenDeleteConfirmationPopup(id: string): void {
    setIsShowConfirmationMessage(true);
    setAssetsToDelete([{ id }]);
  }

  function handleClosePopupWindow(): void {
    setIsShowConfirmationMessage(false);
  }

  async function handleSubmitRowDeletion(): Promise<void> {
    const ids =
      assetsToDelete.length === 1 && isObject(assetsToDelete[0])
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [(assetsToDelete as any)[0].id.toString()]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (assetsToDelete as any[]).map((i) => i.toString());

    handleClosePopupWindow();
    setSelectedAssets(selectedAssets.filter((i) => !ids.includes(i.toString())));

    const newAssets = assets
      ?.filter((asset) => !ids.includes(asset.id))
      .map((asset) => ({
        assetKey: asset.assetName,
        floorKey: asset.assetFloor,
        locationKey: asset.assetLocation,
      }));

    const payload: FormMutationDto = {
      formType: form?.formType ?? '',
      formName: form?.formName ?? '',
      pfFormId: form?.pfFormId ?? '',
      reportTemplateName: form?.reportTemplateName ?? '',
      formAssetList: newAssets,
      siteId: currentSiteId,
    };

    for (const id of ids) {
      const assetRow = assets.find((asset) => asset.id.toString() === id.toString());

      if (assetRow) {
        assetRow.isLoading = true;
      }
    }

    try {
      if (currentCompanyId) {
        await dtApiRepository.AdminFormApi.adminFormControllerUpdate(
          Number(currentFormTypeId),
          currentCompanyId as number,
          currentSiteId,
          {
            ...payload,
            formSchemaId: currentFormSubtypeId,
          }
        );
        dtToastService.success(`Successfully deleted asset`);
        setAssets((assets) => assets.filter((asset) => !ids.includes(asset.id)));
      }
    } catch (error) {
      dtToastService.error(`Failed to delete form`);
      for (const id of ids) {
        const assetRow = assets.find((asset) => asset.id.toString() === id.toString());

        if (assetRow) {
          assetRow.isLoading = false;
        }
      }
    }
  }

  async function handleMultipleDelete(): Promise<void> {
    setIsShowConfirmationMessage(true);
    setAssetsToDelete(selectedAssets.map((i) => i.toString()));
  }

  // TODO: Replace with real download function when FJ api will be ready
  async function handleDownloadReport(): Promise<void> {
    setIsReportDownloading(true);
    setIsReportDownloading(false);
  }

  function handleSelectAllTableRows(): void {
    const newIds = assets?.map((item) => item.id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelectedAssets(newIds as any);
  }

  function handleDeselectAllTableRows(): void {
    setSelectedAssets([]);
  }

  function handleDetailsModalClose(): void {
    setShowModal(false);
    setOpenedAssetRow(null);
  }

  const tableActionsComponent = (
    <div style={{ display: 'flex' }}>
      <DtSearchInput onChange={setSearchQuery} />
      <DtCreateButton onClick={onCreateAsset} variant='primary' iconSize={16} iconColor='white'>
        New Asset
      </DtCreateButton>
    </div>
  );

  return (
    <MainContent>
      <DtAdministrationDetailsHeaderWrapper>
        <DtAdministrationDetailsHeader
          pageTitle='Assets'
          pageSubtitle='Manage assets'
          backButtonRoute={DtAppRoutes.Assets}
        />
      </DtAdministrationDetailsHeaderWrapper>
      <DtAssetsTable
        rows={matchedAssets}
        isLoading={isReportDownloading || isAssetsLoading || isFormLoading}
        actionsComponent={tableActionsComponent}
        onRowClick={onTableRowClick}
        openDeleteConfirmation={onOpenDeleteConfirmationPopup}
        onRowSelectionChange={onTableRowSelectionChange}
      />
      <DtSelectionSummaryBar
        open={selectionSummaryBarOpened}
        selectedCount={selectedAssets.length}
        totalCount={assets?.length as number}
        onSelectAll={handleSelectAllTableRows}
        onDeselectAll={handleDeselectAllTableRows}
        isSelectedAllItems={isSelectedAllAssets}
        actions={[
          {
            icon: 'download',
            onClick: handleDownloadReport,
          },
          {
            icon: 'trash',
            onClick: handleMultipleDelete,
          },
        ]}
        isLoading={isReportDownloading || isAssetsLoading || isFormLoading}
      />
      <DtDeleteConfirmationPopup
        opened={isShowConfirmationMessage}
        onClose={handleClosePopupWindow}
        onSubmit={handleSubmitRowDeletion}
        title={assetsToDelete.length === 1 ? 'Delete the asset?' : 'Delete selected assets?'}
        subtitle={
          assetsToDelete.length === 1 ? 'You will not be able to recover it.' : 'You will not be able to recover them.'
        }
        icon='trash'
        color='red'
      />
      {showModal && (
        <DtAssetsDetailsModal
          open={showModal}
          assetRowItem={openedAssetRow}
          form={form}
          assetList={assets}
          onSubmit={getAllAssets}
          onCancel={handleDetailsModalClose}
        />
      )}
      <DtCopyrightFooter />
    </MainContent>
  );
};

export default DtAssetsDetails;
