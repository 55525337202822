import { InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DT_DEBOUNCE_DURATION, DtSearchInputProps } from './dt-search-input.interface';
import { CustomSearchInput } from './dt-search-input.styled';
import DtIcon from '../dt-icon/dt-icon';

const DtSearchInput: React.FC<DtSearchInputProps> = ({
  onChange,
  inputStyles,
  size,
  autoFocus,
  fullWidth,
  disabled,
  icon,
  iconSize,
  iconColor,
  placeholder,
  id,
}) => {
  const placeholderText = placeholder ? placeholder : 'Search';
  const searchInputSize = size ? size : 'small';

  const [searchText, setSearchText] = useState('');
  const [uniqueId] = useState(id ?? uuidv4());

  const debouncedSearch = useCallback(
    debounce((searchText) => {
      onChange(searchText);
    }, DT_DEBOUNCE_DURATION),
    []
  );

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      debouncedSearch.cancel();
      onChange(searchText);
    }
  }

  const iconItem = icon && (
    <InputAdornment position='start'>
      <DtIcon icon='search' size={iconSize} color={iconColor} />
    </InputAdornment>
  );

  return (
    <>
      <CustomSearchInput
        id={uniqueId}
        type='search'
        size={searchInputSize}
        placeholder={placeholderText}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        disabled={disabled}
        height={inputStyles?.height}
        padding={inputStyles?.padding}
        position={inputStyles?.position}
        width={inputStyles?.width}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        InputProps={{ startAdornment: iconItem }}
      />
    </>
  );
};

export default DtSearchInput;
