import { toast } from 'react-toastify';

/**
 * Service for managing application`s toast massages
 */
class DtToastService {
  isDisabled = false;

  success(message: string): void {
    if (!this.isDisabled) {
      toast.success(message);
    }
  }

  error(message: string): void {
    if (!this.isDisabled) {
      toast.error(message);
    }
  }

  info(message: string): void {
    if (!this.isDisabled) {
      toast.info(message);
    }
  }

  disableAlerts(): void {
    this.isDisabled = true;
  }
}

export const dtToastService = new DtToastService();
