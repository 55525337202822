import { CSSObject, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const DtEditView = styled('span')`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  & > span {
    flex: 1 0 auto;
  }

  & > button {
    opacity: 0%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover > button {
    opacity: 100%;
  }
`;

export const DtCreateNewView = styled('span')`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.main.primary};
`;

export const DtInspectionDetailsHeaderWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(32)};
  display: flex;
  justify-content: space-between;
`;

export const DtLastSubmitLabel = styled(Typography)`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;

export const DtInspectionDetailsContent = styled('div')`
  background-color: ${({ theme }) => theme.palette.main.white};
  border-radius: 3px;
  ${({ theme }) => theme.mixins.accordionBoxShadowMixin};
  width: 100%;
`;
