import { CSSObject, styled, Theme } from '@mui/material/styles';
import { DialogContainer } from '../dt-dispatch-details-modal/dt-dispatch-details-modal.styled';

export const errorFieldMarginMixin = (theme: Theme, hasError: boolean, errorMargin?: number): CSSObject => {
  let marginBottom: string | number = 0;
  if (hasError) {
    marginBottom = errorMargin ? theme.spacing(errorMargin) : theme.spacing(18);
  }
  return { marginBottom };
};

export const DialogWrapper = styled(DialogContainer)`
  & .MuiPaper-root {
    height: 700px;
    min-width: 640px;
    overflow-y: auto;
  }
`;

export const DialogHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(24)} ${theme.spacing(32)}`};
`;

export const DialogHeaderTitle = styled('h3')`
  color: ${({ theme }) => theme.palette.main.black};
  ${({ theme }) => theme.typography.h3 as CSSObject};
  margin: 0;
`;

export const DialogHeaderSubtitle = styled('p')`
  color: ${({ theme }) => theme.palette.main.secondaryText};
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  margin: 0;
`;

export const DialogStep = styled('div', { shouldForwardProp: (prop) => prop !== 'isActive' })<{
  isActive?: boolean;
}>`
  height: 100%;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: ${({ isActive }) => (isActive ? 'column' : 'none')};
`;
