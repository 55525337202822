import { isNil } from 'lodash';
import isUndefined from 'lodash/isUndefined';

import { DT_CRON_HOUR_INDEXES, DT_CRON_MONTH_BY_INDEX, DT_CRON_MONTH_INDEXES } from './dt-cron.interfaces';

export class DtCronItemModel {
  strictValue: number | undefined;
  repeatEvery: number | undefined;

  constructor(value?: number, repeat?: number) {
    this.strictValue = value;
    this.repeatEvery = repeat;
  }

  toExpression(defaultValue?: string): string {
    if (!this.repeatEvery && isUndefined(this.strictValue)) {
      // return forever if empty
      return '*';
    } else if (this.repeatEvery) {
      if (this.repeatEvery === 1) {
        // return forver if is shoud repeat every 1 day/week/month
        return '*';
      }
      return `*/${this.repeatEvery}`;
    }
    return defaultValue || `${this.strictValue}`;
  }

  static fromExpression(expr: string): DtCronItemModel {
    return new DtCronItemModel(Number(expr));
  }
}

export class DtCronItemTimeModel extends DtCronItemModel {
  constructor(value?: number) {
    super(value);
  }

  toExpression(): string {
    if (!this.strictValue) {
      return '0';
    }

    return super.toExpression();
  }
}

export class DtCronItemWithSpecialChars extends DtCronItemModel {
  notSpecific: boolean | undefined;
  selection: number[] | undefined;

  constructor(value?: number, repeat?: number, notSpecific?: boolean, selection?: number[]) {
    super(value, repeat);
    this.notSpecific = notSpecific;
    this.selection = selection;
  }

  toExpression(defaultValue?: string): string {
    if (!this.repeatEvery && !this.selection && isUndefined(this.strictValue)) {
      if (this.notSpecific) {
        return '?';
      }
      return '*';
    } else if (this.selection) {
      return this.selection.join();
    }

    return super.toExpression(defaultValue);
  }

  static fromExpression(expr: string, isFrequencyTypeWeekly?: boolean): DtCronItemWithSpecialChars {
    const notSpecified = expr === '?';
    let value;
    let selection;

    if (isFrequencyTypeWeekly) {
      value =
        expr === '*' || expr === '?' || expr.includes(',') || expr.includes('/')
          ? undefined
          : DT_CRON_HOUR_INDEXES[expr];
      selection = expr.includes(',') ? expr.split(',').map((item) => DT_CRON_HOUR_INDEXES[item]) : undefined;
    } else {
      value = expr === '*' || expr === '?' || expr.includes(',') || expr.includes('/') ? undefined : Number(expr);
      selection = expr.includes(',') ? expr.split(',').map((item) => Number(item)) : undefined;
    }
    const repeats = expr.includes('/') ? Number(expr.split('/')[1]) : undefined;

    return new DtCronItemWithSpecialChars(value, repeats, notSpecified, selection);
  }
}

export class DtCronItemWithWeekSpecialChars extends DtCronItemWithSpecialChars {
  range: number[] | undefined;

  constructor(value?: number, repeat?: number, notSpecific?: boolean, selection?: number[], range?: number[]) {
    super(value, repeat, notSpecific);
    this.range = range;
    this.selection = selection;
  }

  toExpression(): string {
    if (this.range) {
      return `${DT_CRON_MONTH_BY_INDEX[Number(this.range[0])]}-${DT_CRON_MONTH_BY_INDEX[Number(this.range[1])]}`;
    } else if (this.repeatEvery) {
      return `${DT_CRON_MONTH_BY_INDEX[Number(this.strictValue)]}#${this.repeatEvery}`;
    } else if (this.selection) {
      return this.selection.map((idx) => DT_CRON_MONTH_BY_INDEX[idx]).join();
    }

    return super.toExpression(DT_CRON_MONTH_BY_INDEX[Number(this.strictValue)]);
  }

  static fromExpression(expr: string, isFrequencyTypeWeekly?: boolean): DtCronItemWithWeekSpecialChars {
    const indexOfRepeatExpr = isFrequencyTypeWeekly ? 0 : 1;
    const indexOfDayExpr = isFrequencyTypeWeekly ? 1 : 0;
    const repeats = expr.includes('#') ? Number(expr.split('#')[indexOfRepeatExpr]) : undefined;
    const notSpecified = expr === '?';
    let selection = expr.includes(',') ? expr.split(',').map((el) => DT_CRON_MONTH_INDEXES[el]) : undefined;

    if (isFrequencyTypeWeekly && !isNil(selection)) {
      selection = expr
        .split('#')
        [indexOfDayExpr].split(',')
        .map((el) => DT_CRON_MONTH_INDEXES[el]);
    }

    let range = undefined;
    if (expr.includes('-')) {
      const monthNames = expr.split('-');
      range = [DT_CRON_MONTH_INDEXES[monthNames[0]], DT_CRON_MONTH_INDEXES[monthNames[1]]];
    }

    let value = undefined;
    if (repeats) {
      const indexOfDayExpr = isFrequencyTypeWeekly ? 1 : 0;
      const split = expr.split('#');
      const test = Object.keys(DT_CRON_MONTH_BY_INDEX).find(
        (key) => DT_CRON_MONTH_BY_INDEX[Number(key)] === split[indexOfDayExpr]
      );

      if (test) {
        value = Number(test);
      }
    } else if (Object.values(DT_CRON_MONTH_BY_INDEX).includes(expr)) {
      const test = Object.keys(DT_CRON_MONTH_BY_INDEX).find((key) => DT_CRON_MONTH_BY_INDEX[Number(key)] === expr);
      value = Number(test);
    }

    return new DtCronItemWithWeekSpecialChars(value, repeats, notSpecified, selection, range);
  }
}
