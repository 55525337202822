import _, { isNull, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { dtUseAppSelector } from '../../../cdk/hooks/dt-store.hooks';
import DtButton from '../../../components/dt-button/dt-button';
import DtIcon from '../../../components/dt-icon/dt-icon';
import DtSelect from '../../../components/dt-selects/dt-select/dt-select';
import { DtOptionItem } from '../../../components/dt-selects/dt-selects.interfaces';
import { STORAGE_KEYS } from '../../../dt-constants';
import { dtApiRepository } from '../../../repositories/dt-api.repository';
import { dtPersistentStorageService } from '../../../services/dt-persistent-storage.service';
import { dtSelectPropertyFormType } from '../../dt-navigation/dt-navigation.slice';
import { dtSelectSelectedPropertyId } from '../../dt-properties/dt-properties.slice';
import { DtCreateNewView, DtEditView } from '../dt-inspection-details.styled';
import DtViewSchemaConfigModal from '../modals/dt-view-schema-config-modal/dt-view-schema-config-modal';

interface InspectionLastSelectedSchema {
  [propertyId: number]: {
    [formSubtypeId: number]: string | undefined;
  };
}

interface Props {
  formSubtypeId: number;
  formSchemaName?: string | null;
  onFormSchemaChange: (name?: string) => void;
}

const DtViewSelector: React.FC<Props> = (props) => {
  const propertyFormType = dtUseAppSelector(dtSelectPropertyFormType)?.parentId;
  const propertyId: number | null = dtUseAppSelector(dtSelectSelectedPropertyId);

  const [viewConfigInPopup, openPopupForView] = useState<string>();
  const [formSchemaConfigNames, setFormSchemaConfigNames] = useState<DtOptionItem<string | undefined | null>[]>([]);

  useEffect(() => {
    setFormSchemaConfigNames([]);
    refreshViewConfigs();
  }, [propertyId, props.formSubtypeId]);

  function getLatestSchemaFromStorage(): string | undefined {
    const previouslySelectedConfigObject =
      dtPersistentStorageService.getObject<InspectionLastSelectedSchema>(
        STORAGE_KEYS.INSPECTION_LAST_SELECTED_SCHEMA
      ) ?? {};
    const lastSelectedSchema = _.get(previouslySelectedConfigObject, `${propertyId}.${props.formSubtypeId}`, undefined);
    return lastSelectedSchema;
  }

  function updateLatestSchemaInStorage(schemaName: string | undefined): void {
    const previouslySelectedConfigObject =
      dtPersistentStorageService.getObject<InspectionLastSelectedSchema>(
        STORAGE_KEYS.INSPECTION_LAST_SELECTED_SCHEMA
      ) ?? {};

    _.update(previouslySelectedConfigObject, `${propertyId}`, (value) => (value ? value : {}));
    _.set(previouslySelectedConfigObject, `${propertyId}.${props.formSubtypeId}`, schemaName);

    dtPersistentStorageService.setObject(STORAGE_KEYS.INSPECTION_LAST_SELECTED_SCHEMA, previouslySelectedConfigObject);
  }

  function refreshViewConfigs(): void {
    if (!propertyId) {
      return;
    }
    dtApiRepository.DispatchApi.dispatchControllerGetInspectionSchemaConfigNames(propertyId, props.formSubtypeId).then(
      (i) => {
        const latestSchema = getLatestSchemaFromStorage();
        props.onFormSchemaChange(i.data.includes(latestSchema ?? '') ? latestSchema : undefined);

        return setFormSchemaConfigNames([
          {
            label: 'Default',
            value: undefined,
          },
          ...(i.data ?? []).map((i) => ({
            label: i,
            render: (
              <DtEditView>
                <span
                  style={{
                    flex: 'auto',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  title={i}
                >
                  {i}
                </span>
                <DtButton
                  variant='transparent'
                  padding={4}
                  onClick={() => {
                    openPopupForView(i);
                  }}
                >
                  <DtIcon icon='edit' size={16} />
                </DtButton>
              </DtEditView>
            ),
            value: i,
          })),
          {
            label: 'Create New View',
            render: (
              <DtCreateNewView>
                <DtIcon icon='plus' size={16} color='primary' /> Create New View
              </DtCreateNewView>
            ),
            value: null,
          },
        ]);
      }
    );
  }

  return (
    <>
      <DtSelect
        minWidth='248px'
        label='View'
        labelPosition='inside'
        value={props.formSchemaName}
        disableClearable
        onChange={(value) => {
          if (isNull(value)) {
            // Create new view
            openPopupForView('');
            return;
          }
          updateLatestSchemaInStorage(value);
          props.onFormSchemaChange(value);
        }}
        options={formSchemaConfigNames}
      />

      {/* <DtButton
            variant='flat'
            onClick={() => {
              console.log(params);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              params?.inputProps?.onFocus?.({} as any);
            }}
            padding={6}
            type='button'>
            <DtIcon size={24} icon='tools' />
          </DtButton> */}
      <DtViewSchemaConfigModal
        formType={propertyFormType ?? ''}
        open={!isUndefined(viewConfigInPopup)}
        propertyId={propertyId ?? -1}
        formSubtypeId={props.formSubtypeId}
        formSchemaName={viewConfigInPopup}
        onCancel={() => {
          openPopupForView(undefined);
        }}
        onSubmit={() => {
          refreshViewConfigs();
          openPopupForView(undefined);
        }}
      />
    </>
  );
};

export default DtViewSelector;
