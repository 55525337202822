import React from 'react';

import { InputLabel } from '../../modules/dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../modules/dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import { ListItemTitle } from '../dt-assignment-blocks/dt-assignment-blocks.styled';
import DtScrollbar from '../dt-scrollbar/dt-scrollbar';
import DtSkeleton from '../dt-skeleton/dt-skeleton';

const DtAssetsTableSkeleton: React.FC = () => {
  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <div
          style={{
            display: 'grid',
            flexDirection: 'row',
            gridTemplateColumns: '3fr 1fr 3fr 40px',
            gap: '10px',
            marginRight: '15px',
          }}
        >
          <FieldContainer>
            <InputLabel>Asset</InputLabel>
          </FieldContainer>

          <FieldContainer>
            <InputLabel>Floor</InputLabel>
          </FieldContainer>

          <FieldContainer>
            <InputLabel>Location</InputLabel>
          </FieldContainer>
        </div>
        <DtScrollbar height='calc(100vh - 595px)' minHeight='260px'>
          <ListItemTitle sx={{ paddingTop: '0px', marginRight: '15px' }} />
        </DtScrollbar>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <DtSkeleton variant='rectangular' width={115} height={40} />
      </div>
    </>
  );
};

export default DtAssetsTableSkeleton;
