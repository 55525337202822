import { TextFieldProps } from '@mui/material';
import React from 'react';

import { TextInputCustom } from './dt-text-input.styled';

/**
 * Common text input component.
 * IMPORTANT: This component comes without form validation. To include validation - use component DtFormTextInput.
 * @example <DtTextInput placeholder='Type Here' value='' onChange={() => {}} />
 */
const DtTextInput: React.FC<TextFieldProps> = (props) => {
  return <TextInputCustom {...props} />;
};

export default DtTextInput;
