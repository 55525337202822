import { Autocomplete, AutocompleteProps, Chip, Paper, TextField } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import { Component } from 'react';
import DtScrollbar from '../dt-scrollbar/dt-scrollbar';

const TRANSPARENT_BORDER = '1.5px solid transparent';

export const CustomSelectContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'withFlexProp' })<{
  withFlexProp?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: ${({ withFlexProp }) => withFlexProp && '1 1 auto'};
`;

export const CustomSelect: new <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>() => Component<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & { hasSomeValues?: boolean }> = styled(
  Autocomplete,
  {
    shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'hasSomeValues',
  }
)<{
  fullWidth?: boolean;
  hasSomeValues?: boolean;
}>`
  &.MuiAutocomplete-root {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

    & .MuiAutocomplete-endAdornment {
      top: 6px;
    }

    & .MuiOutlinedInput-root {
      padding: ${({ theme, hasSomeValues }) =>
        hasSomeValues
          ? `${theme.spacing(1)} ${theme.spacing(16)} ${theme.spacing(1)} ${theme.spacing(13)}`
          : `${theme.spacing(10)} ${theme.spacing(16)}`};
      cursor: pointer;
      color: ${({ theme }) => theme.palette.main.black};
      display: flex;
      align-content: center;

      & fieldset {
        border: ${TRANSPARENT_BORDER};
      }

      &:hover {
        fieldset {
          border: ${TRANSPARENT_BORDER};
        }
      }

      &.Mui-focused {
        fieldset {
          border: ${({ theme }) => `1.5px solid ${theme.palette.main.primary}`};
        }
      }

      &.Mui-disabled {
        cursor: not-allowed;

        fieldset {
          border: ${TRANSPARENT_BORDER};
        }
      }

      .MuiAutocomplete-input {
        padding: 0;
        padding-right: 14px;
        cursor: pointer;
        ${({ theme, hasSomeValues }) =>
          hasSomeValues
            ? `width: 0px;
          flex: none;
          height: 0;`
            : ``}

        &:disabled {
          cursor: not-allowed;
        }
      }

      &.Mui-error {
        background-color: #fef3f4;

        fieldset {
          border: ${({ theme }) => `1.5px solid ${theme.palette.main.red}`};
        }
      }
    }
  }
` as any;

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'labelPosition' && prop !== 'minWidth',
})<{
  labelPosition?: string;
  minWidth?: string;
}>`
  position: relative;
  min-width: ${({ labelPosition, minWidth }) => (minWidth ? minWidth : labelPosition === 'top' ? '200px' : '255px')};
  caret-color: transparent;
  ${({ theme }) => theme.mixins.textInputCommonStylesMixin(theme)};

  & .MuiFormHelperText-root {
    ${({ theme }) => theme.typography.caption as CSSObject};
    position: absolute;
    top: calc(100% + 1px);

    &.Mui-error {
      margin: 0;
      color: ${({ theme }) => theme.palette.main.red};
    }
  }
`;

export const Label = styled('label', { shouldForwardProp: (prop) => prop !== 'disabled' })<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  opacity: ${({ disabled }) => (disabled ? '40%' : '100%')};
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
`;

export const LabelInside = styled(Label)`
  margin-right: ${({ theme }) => theme.spacing(5)};
  margin-bottom: 0;
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
`;

export const Dropdown = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'paddingBottom',
})<{ paddingBottom?: number }>`
  background-color: ${({ theme }) => theme.palette.main.white};
  margin-top: ${({ theme }) => theme.spacing(8)};
  ${({ theme }) => theme.mixins.boxShadowMixin};
  ${({ theme }) => theme.typography.body as CSSObject};
  padding-bottom: ${({ theme, paddingBottom }) => paddingBottom && theme.spacing(paddingBottom)};

  & .MuiAutocomplete-listbox {
    max-height: none;

    & .MuiAutocomplete-option {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: ${({ theme }) => theme.palette.main.white};

      &[aria-selected='true'] {
        background-color: ${({ theme }) => theme.palette.main.white};
      }
    }
  }
`;

export const DropdownScrollbar = styled(DtScrollbar)`
  max-height: 184px;
`;

export const CustomChip = styled(Chip)`
  ${({ theme }) => theme.typography.body as CSSObject};
  height: 32px;

  & .MuiChip-deleteIcon {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.main.secondaryIcon};
  }
`;
