import React, { useState } from 'react';

import { dtUseAppDispatch } from '../../../../../cdk/hooks/dt-store.hooks';
import DtDeleteConfirmationPopup from '../../../../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtIcon from '../../../../../components/dt-icon/dt-icon';
import {
  ActionButtonsWrapper,
  CenteredButton,
  IconPlaceholder,
} from '../../../../../components/dt-table/dt-actions-table-cell.styled';
import { dtDeleteUserAction } from '../../dt-manage-users.action';

interface DtManageUsersActionsTableCellProps {
  dtUserEmail: string;
  isCurrentUser: boolean;
}

const DtManageUsersActionsTableCell: React.FC<DtManageUsersActionsTableCellProps> = ({
  dtUserEmail,
  isCurrentUser,
}) => {
  const dispatch = dtUseAppDispatch();
  const [showConfirmationMessage, updateShowConfirmationMessage] = useState(false);

  function handleOpenPopup(): void {
    updateShowConfirmationMessage(true);
  }

  function handleDeleteUser(): void {
    dispatch(dtDeleteUserAction(dtUserEmail));
    updateShowConfirmationMessage(false);
  }

  return (
    <ActionButtonsWrapper>
      <DtDeleteConfirmationPopup
        opened={showConfirmationMessage}
        onClose={() => {
          updateShowConfirmationMessage(false);
        }}
        onSubmit={handleDeleteUser}
        title='Delete selected user?'
        subtitle='You will not be able to recover it.'
        icon='trash'
        color='red'
      />
      <IconPlaceholder style={{ marginRight: '28px' }} />
      {!isCurrentUser ? (
        <CenteredButton variant='transparent' onClick={handleOpenPopup}>
          <DtIcon icon='trash' size={20} color='secondaryIcon' />
        </CenteredButton>
      ) : null}
    </ActionButtonsWrapper>
  );
};

export default DtManageUsersActionsTableCell;
