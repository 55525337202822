import { GridRowSelectionModel } from '@mui/x-data-grid';
import { isEmpty, isObject } from 'lodash';
import React, { useState } from 'react';

import DtDispatchesTable from './components/dt-dispatches-table/dt-dispatches-table';
import { dtDispatchService } from './dt-dispatch.service';
import { DtSupportedDispatchStatuses } from './dt-dispatches.enums';
import {
  DtDeleteDispatchResponse,
  dtDeleteDispatch,
  dtSelectCompletedDispatchIsLoading,
  dtSelectCompletedDispatches,
  dtSelectIsShowConfirmationMessage,
  dtSelectOpenDispatchIsLoading,
  dtSelectOpenDispatches,
  dtSelectOverdueDispatchIsLoading,
  dtSelectOverdueDispatches,
  dtSelectScheduledDispatchIsLoading,
  dtSelectScheduledDispatches,
  dtSetDeleteConfirmationPopupState,
  dtSetLoadingStatusToRow,
} from './dt-dispatches.slice';
import DtDispatchDetailsModal from './modals/dt-dispatch-details-modal/dt-dispatch-details-modal';
import DtNewDispatchModal from './modals/dt-new-dispatch-modal/dt-new-dispatch-modal';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import DtDeleteConfirmationPopup from '../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtSelectionSummaryBar from '../../components/dt-selection-summary-bar/dt-selection-summary-bar';
import { TabPanelContent } from '../../components/dt-table/dt-table.styled';
import { dtToastService } from '../../services/dt-toast.service';
import { dtSelectUserRole } from '../dt-user/dt-user.slice';

interface DtDashboardProps {
  status: DtSupportedDispatchStatuses;
}

const DtDashboard: React.FC<DtDashboardProps> = ({ status }) => {
  const dispatch = dtUseAppDispatch();

  const dispatches = getDispatches(status);
  const isDispatchesLoading = getIsDispatchesLoading(status);
  const isShowConfirmationMessage = dtUseAppSelector(dtSelectIsShowConfirmationMessage);
  const userRole = dtUseAppSelector(dtSelectUserRole);

  const [dispatchesToDelete, setDispatchesToDelete] = useState<string[] | Array<{ id: string; formType: string }>>([]);
  const [openedDispatchRow, setOpenedDispatchRow] = useState<DtDispatchRowItem | null>(null);
  const [openedScheduledDispatchRow, setOpenedScheduledDispatchRow] = useState<DtScheduleDispatchItem | null>(null);
  const [selectedDispatches, setSelectedDispatches] = useState<GridRowSelectionModel>([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectionSummaryBarOpened = selectedDispatches.length > 0 && !isShowConfirmationMessage;

  const isSelectedAllDispatches = dispatches?.length === selectedDispatches.length;

  function getDispatches(
    status: DtSupportedDispatchStatuses
  ): (DtDispatchRowItem | DtScheduleDispatchItem)[] | undefined {
    switch (status) {
      case DtSupportedDispatchStatuses.Open:
        return dtUseAppSelector(dtSelectOpenDispatches);
      case DtSupportedDispatchStatuses.Overdue:
        return dtUseAppSelector(dtSelectOverdueDispatches);
      case DtSupportedDispatchStatuses.Completed:
        return dtUseAppSelector(dtSelectCompletedDispatches);
      case DtSupportedDispatchStatuses.Scheduled:
        return dtUseAppSelector(dtSelectScheduledDispatches);
    }
  }

  function getIsDispatchesLoading(status: DtSupportedDispatchStatuses): boolean | undefined {
    switch (status) {
      case DtSupportedDispatchStatuses.Open:
        return dtUseAppSelector(dtSelectOpenDispatchIsLoading);
      case DtSupportedDispatchStatuses.Overdue:
        return dtUseAppSelector(dtSelectOverdueDispatchIsLoading);
      case DtSupportedDispatchStatuses.Completed:
        return dtUseAppSelector(dtSelectCompletedDispatchIsLoading);
      case DtSupportedDispatchStatuses.Scheduled:
        return dtUseAppSelector(dtSelectScheduledDispatchIsLoading);
    }
  }

  function handleTableRowClick(row: DtDispatchRowItem): void {
    setOpenedDispatchRow(row);
  }

  function handleTableScheduleRowClick(row: DtScheduleDispatchItem): void {
    setOpenedScheduledDispatchRow(row);
  }

  function openDeleteConfirmationPopup(id: string, formType: string): void {
    dispatch(dtSetDeleteConfirmationPopupState(true));
    setDispatchesToDelete([{ id, formType }]);
  }

  function handleTableRowSelectionChange(selectionModel: GridRowSelectionModel): void {
    setSelectedDispatches(selectionModel);
  }

  function handleSelectAllTableRows(): void {
    const newIds = dispatches?.map((item) => item.id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelectedDispatches(newIds as any);
  }

  function handleDeselectAllTableRows(): void {
    setSelectedDispatches([]);
  }

  async function handleDownloadReport(): Promise<void> {
    setIsLoading(true);
    const reports = await dtDispatchService.getMultipleDispatchReports(selectedDispatches as string[]);
    await dtDispatchService.downloadMultipleDispatchReports(reports);
    setIsLoading(false);
  }

  async function handleMultipleDelete(): Promise<void> {
    dispatch(dtSetDeleteConfirmationPopupState(true));
    setDispatchesToDelete(selectedDispatches.map((i) => i.toString()));
  }

  function handleDetailsModalClose(): void {
    setOpenedDispatchRow(null);
    setOpenedScheduledDispatchRow(null);
  }

  function handleClosePopupWindow(): void {
    dispatch(dtSetDeleteConfirmationPopupState(false));
  }

  async function handleSubmitRowDeletion(): Promise<void> {
    try {
      const ids: string[] =
        dispatchesToDelete.length === 1 && isObject(dispatchesToDelete[0])
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [(dispatchesToDelete as any)[0].id.toString()]
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (dispatchesToDelete as any[]).map((i) => i.toString());

      dispatch(dtSetLoadingStatusToRow({ ids, dispatchStatus: status }));
      setSelectedDispatches(selectedDispatches.filter((i) => !ids.includes(i.toString())));
      handleClosePopupWindow();
      await dispatch(
        dtDeleteDispatch({
          ids,
          dispatchStatus: status,
        })
      ).then((data) => {
        if (!isEmpty((data.payload as DtDeleteDispatchResponse).passed)) {
          dtToastService.success(
            dispatchesToDelete.length === 1 && isObject(dispatchesToDelete[0])
              ? `Successfully deleted dispatch`
              : `Successfully deleted several dispatches`
          );
        }
        if (!isEmpty((data.payload as DtDeleteDispatchResponse).failed)) {
          dtToastService.error(
            dispatchesToDelete.length === 1 && isObject(dispatchesToDelete[0])
              ? `Sorry, you can't delete dispatch`
              : `Sorry, some dispatches failed to delete`
          );
        }
      });
    } catch (e) {
      console.error(e);
      dtToastService.error(
        dispatchesToDelete.length === 1 && isObject(dispatchesToDelete[0])
          ? `Sorry, you can't delete dispatch`
          : `Sorry, some dispatches failed to delete`
      );
    }
  }

  return (
    <>
      <TabPanelContent>
        <DtDispatchesTable
          dispatchStatus={status}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          rows={dispatches as any}
          onRowClick={handleTableRowClick}
          onScheduledRowClick={handleTableScheduleRowClick}
          isLoading={isDispatchesLoading as boolean}
          selectionModel={selectedDispatches}
          openDeleteConfirmation={openDeleteConfirmationPopup}
          onRowSelectionChange={handleTableRowSelectionChange}
        />
      </TabPanelContent>
      <DtSelectionSummaryBar
        open={selectionSummaryBarOpened}
        selectedCount={selectedDispatches.length}
        totalCount={dispatches?.length as number}
        onSelectAll={handleSelectAllTableRows}
        onDeselectAll={handleDeselectAllTableRows}
        isSelectedAllItems={isSelectedAllDispatches}
        actions={
          status === DtSupportedDispatchStatuses.Completed
            ? [
                {
                  icon: 'download',
                  onClick: handleDownloadReport,
                },
                {
                  icon: 'trash',
                  onClick: handleMultipleDelete,
                },
              ]
            : [
                {
                  icon: 'trash',
                  onClick: handleMultipleDelete,
                },
              ]
        }
        isLoading={isLoading}
      />

      {openedDispatchRow && (
        <DtDispatchDetailsModal
          open={Boolean(openedDispatchRow)}
          dispatchRowItem={openedDispatchRow}
          onCancel={handleDetailsModalClose}
          userRole={userRole}
        />
      )}
      {openedScheduledDispatchRow && (
        <DtNewDispatchModal
          opened={Boolean(openedScheduledDispatchRow)}
          onClose={handleDetailsModalClose}
          dispatchRowItem={openedScheduledDispatchRow}
          title='Edit Dispatch'
          subtitle='Configure, assign, and schedule a task to be dispatched'
        />
      )}

      <DtDeleteConfirmationPopup
        opened={isShowConfirmationMessage}
        onClose={handleClosePopupWindow}
        onSubmit={handleSubmitRowDeletion}
        title={dispatchesToDelete.length === 1 ? 'Delete the dispatch?' : 'Delete selected dispatches?'}
        subtitle={
          dispatchesToDelete.length === 1
            ? 'You will not be able to recover it.'
            : 'You will not be able to recover them.'
        }
        icon='trash'
        color='red'
      />
    </>
  );
};

export default DtDashboard;
