import { countBy, flatMap, fromPairs, lowerCase, orderBy, uniqBy, upperFirst } from 'lodash';
import React, { useState } from 'react';

import DtAssignmentColumn from './components/dt-assignment-column/dt-assignment-column';
import { DtAssignmentListItemData } from './dt-assignment-blocks.interfaces';
import { AssignmentsWrapper } from './dt-assignment-blocks.styled';
import { AssignmentParent } from '../../repositories/__generated__/v2';

export interface DtAssignmentsProps {
  parentTitle: string;
  childTitle: string;
  data: AssignmentParent[];
  disableParentAssign?: boolean;
  disableChildAssign?: boolean;
  hideParentAssignButton?: boolean;
  hideChildAssignButton?: boolean;
  hideDeleteButton?: boolean;
  hideEditButton?: boolean;
  hideSubtitle?: boolean;
  tooltipTitle?: string;
  childColumnType?: string;
  disabled?: boolean;
  onParentItemUnassign: (parentId: string) => void;
  onChildItemUnassign: (id: string, parentId?: string | undefined) => void;
  triggerAssignmentsChange: () => void;
  onParentEdit?: (parentId: string) => void;
  onChildEdit?: (childId: string, parentId?: string) => void;
  onParentAssign?: () => void;
  onChildAssign?: (parentId: string) => void;
}

const DtAssignments: React.FC<DtAssignmentsProps> = (props) => {
  const [selectedParentId, setSelectedParentId] = useState<string>(orderBy(props.data, 'displayValue')[0]?.id ?? '');

  const allChildren = flatMap(props.data, (parent) => parent.children);
  const childrenPerParent = fromPairs(
    props.data.map((parent) => [parent.id, parent.children.map((child) => child.id)])
  );

  const uniqueChildrenToDisplay = uniqBy(allChildren, 'id');

  const childColumnCounter = uniqueChildrenToDisplay.length;
  const childrenUsedInParent = countBy(allChildren, 'id');

  const childColumnType = props.childColumnType ?? upperFirst(lowerCase(allChildren.find((child) => child)?.type));
  const parentColumnType = upperFirst(lowerCase(props.data.find((parent) => parent)?.type));

  const parentColumnListItems: DtAssignmentListItemData[] = orderBy(
    props.data.map((item) => {
      const childType = item.children.length === 1 ? childColumnType : !childColumnType ? '' : `${childColumnType}s`;

      return {
        id: item.id,
        title: item.displayValue,
        subtitle: `${item.children.length} ${childType} assigned`,
      };
    }),
    'title'
  );

  const childrenAssignments: DtAssignmentListItemData[] = orderBy(
    uniqueChildrenToDisplay.map((item) => {
      const parentType = childrenUsedInParent[item.id] === 1 ? parentColumnType : `${parentColumnType}s`;

      return {
        id: item.id,
        title: item.displayValue,
        subtitle: props.hideSubtitle ? '' : `${childrenUsedInParent[item.id]} ${parentType} assigned`,
        tooltipContent: item.subtitleItems,
        tooltipTitle: props.tooltipTitle || props.childTitle,
        chosen: childrenPerParent[selectedParentId]?.includes(item.id),
      };
    }),
    'title'
  );

  const childrenColumnListItems: DtAssignmentListItemData[] = selectedParentId
    ? childrenAssignments.filter((item) => childrenPerParent[selectedParentId]?.includes(item.id))
    : childrenAssignments;
  return (
    <AssignmentsWrapper>
      <DtAssignmentColumn
        title={props.parentTitle}
        counter={props.data.length}
        onAssign={props.onParentAssign ? () => props.onParentAssign?.() : undefined}
        listItems={parentColumnListItems}
        onEdit={(id) => {
          props.triggerAssignmentsChange();
          if (props.onParentEdit) {
            props.onParentEdit(id);
          }
        }}
        onUnassign={(parentId) => {
          if (parentId === selectedParentId) {
            setSelectedParentId('');
          }
          props.onParentItemUnassign(parentId);
        }}
        selectedListItem={selectedParentId}
        handleListItemSelection={(parentId) => {
          setSelectedParentId(parentId);
        }}
        withArrow
        disableAssignButton={props.disableParentAssign}
        hideAssignButton={props.hideParentAssignButton}
        hideDeleteButton={props.hideDeleteButton}
        hideEditButton={props.hideEditButton}
        disabled={props.disabled}
      />
      <DtAssignmentColumn
        title={props.childTitle}
        counter={childColumnCounter}
        // disableEditing={!props.onExistingChildAssign}
        disableAssignButton={selectedParentId === '' || props.disableChildAssign}
        hideAssignButton={props.hideChildAssignButton}
        hideDeleteButton={props.hideDeleteButton}
        hideEditButton={props.hideEditButton}
        listItems={childrenColumnListItems}
        onEdit={(childId, parentId) => {
          props.triggerAssignmentsChange();
          if (props.onChildEdit) {
            props.onChildEdit(childId, parentId);
          }
        }}
        onUnassign={props.onChildItemUnassign}
        onAssign={props.onChildAssign ? () => props.onChildAssign?.(selectedParentId) : undefined}
        // handleListItemSelection={(childId: string) => {
        // setSelectedParentId('');
        // setSelectedChildId(childId !== selectedChildId ? childId : '');
        // if (selectedParentId) {
        //   if (childrenPerParent[selectedParentId].includes(childId)) {
        //     handleChildItemUnassign(childId, selectedParentId);
        //   } else {
        //     const item = _.find(uniqueChildrenToDisplay, { id: childId });
        //     if (item && props.onExistingChildAssign) {
        //       props.onExistingChildAssign(selectedParentId, item);
        //       triggerAssignmentsChange();
        //     }
        //   }
        // }
        // }}
        selectedItem={selectedParentId}
        disabled={props.disabled}
      />
    </AssignmentsWrapper>
  );
};

export default DtAssignments;
