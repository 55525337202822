import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, Typography } from '@mui/material';
import { DtDetailsSummaryRowAlign } from '../../../../components/dt-details-summary-row/dt-details-summary-row';
import DtScrollbar from '../../../../components/dt-scrollbar/dt-scrollbar';

export const ReportUploadContainer = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: stretch;
  height: 40px;

  > * {
    flex: none;
    width: 200px;
  }

  > div:last-child > div {
    width: auto;
  }

  > div:last-child > div > div {
    min-width: auto;
  }
`;

export const DialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    width: 640px;
    height: 640px;
    border-radius: 4px;
    ${({ theme }) => theme.mixins.boxShadowMixin};

    &.MuiDialog-paper {
      max-height: inherit;
    }
  }
`;

export const DialogTitleContainer = styled('header')`
  border-bottom: solid 1.5px #ebf0f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(24)} ${theme.spacing(32)}`};
`;

export const ModalTitleMain = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
`;

export const ModalScrollbar = styled(DtScrollbar)`
  max-height: 120px;

  ul {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const DialogContentContainer = styled('main')`
  flex: 1 1 auto;
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing(32)};
`;

export const TitleSecondary = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;

export const ContentRowContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignItems',
})<{ alignItems?: DtDetailsSummaryRowAlign }>`
  display: grid;
  grid-template-columns: 105px auto;
  column-gap: ${({ theme }) => theme.spacing(16)};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(16)};
  }
`;

export const ContentRowName = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};

  &::after {
    content: ':';
  }
`;

export const SummaryRowContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `0 ${theme.spacing(32)} ${theme.spacing(20)}`};
`;
