import { styled } from '@mui/material/styles';

export const UploadButtonContainer = styled('label', {
  shouldForwardProp: (prop) => prop !== 'hasFile' && prop !== 'hasError' && prop !== 'fullWidth',
})<{ hasFile?: boolean; hasError?: boolean; fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  padding: 4px 16px;
  border-radius: 4px;
  border: ${({ theme, hasFile, hasError }) =>
    hasError
      ? `solid 1.5px ${theme.palette.main.red}`
      : hasFile
      ? 'solid 1px transparent'
      : `dashed 1px ${theme.palette.main.secondaryIcon}`};
  background-color: ${({ theme, hasError }) => (hasError ? '#fef3f4' : theme.palette.main.background)};
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    background-color: ${({ theme, hasError }) => (hasError ? '#fef3f4' : theme.palette.main.hover)};
  }

  .error {
    position: absolute;
    top: calc(100% + 2px);
    margin: 0;
    padding: 0;
    width: 100%;
    color: ${({ theme }) => theme.palette.main.red};
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: normal;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
  }
`;
