import { CSSObject, styled } from '@mui/material/styles';
import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import DtButton from '../../../../../../components/dt-button/dt-button';
import { Typography } from '@mui/material';

export const ProvideAccessButton = styled(DtButton)`
  width: 100%;
`;

export const ProvideAccessText = styled(Typography)`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UsernameTextInput = styled(DtFormTextInput)`
  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiButton-root {
    opacity: 0%;
  }

  &:hover .MuiButton-root {
    opacity: 100%;
  }

  & .MuiButton-root:hover {
    background-color: rgba(0, 0, 0, 4%);
  }
`;
