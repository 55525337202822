import { Box } from '@mui/material';
import { addMonths, startOfMonth } from 'date-fns';
import { chain, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FilterPanelContainer } from './dt-dashboard-filter-panel.styled';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import DtDashboardFilters from '../../../../components/dt-dashboard-filters/dt-dashboard-filters';
import DtDateRangePicker from '../../../../components/dt-date-pickers/dt-date-range-picker/dt-date-range-picker';
import { PropertyFilter } from '../../../../repositories/__generated__/v2';
import { dtSelectProperties, dtSelectSelectedProperty } from '../../../dt-properties/dt-properties.slice';
import { dtSelectCurrentCompanyId } from '../../../dt-user/dt-user.slice';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';
import {
  dtLoadDtDispatchesCount,
  dtSelectDateRange,
  dtSelectDispatchesAndAssetsFormSubtypeFilter,
  dtSelectDispatchesAndAssetsFormTypeFilter,
  dtSelectDispatchesAndAssetsPropertyIdFilter,
  dtSetCompletedDateRange,
  dtSetPropertyFilters,
} from '../../dt-dispatches.slice';

interface DtDashboardFilterPanelProps {
  isLoading: boolean;
}

const DtDashboardFilterPanel: React.FC<DtDashboardFilterPanelProps> = ({ isLoading }) => {
  const dispatch = dtUseAppDispatch();
  const history = useLocation();
  const activeDispatchStatus = history.pathname;

  const propertiesOptions = dtUseAppSelector(dtSelectProperties, isEqual);
  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);
  const completedDateRange = dtUseAppSelector(dtSelectDateRange);
  const currentProperty = dtUseAppSelector(dtSelectSelectedProperty);
  const propertyIdFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsPropertyIdFilter);
  const formTypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormTypeFilter);
  const formSubtypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormSubtypeFilter);

  const isCompletedDispatchStatus = activeDispatchStatus.includes(DtSupportedDispatchStatuses.Completed.toLowerCase());

  useEffect(() => {
    dispatch(
      dtLoadDtDispatchesCount({
        companyId: currentCompanyId,
        formId: formSubtypeFilter?.id,
        formType: formTypeFilter ?? undefined,
        propertyId: propertyIdFilter ?? undefined,
        dataRangeRequired: {
          from: completedDateRange.from.toISOString(),
          to: completedDateRange.to.toISOString(),
        },
      })
    );
  }, [completedDateRange, formTypeFilter, formSubtypeFilter, currentProperty, currentCompanyId, propertyIdFilter]);

  function handleCompletedDateRangeChange(value: DtDateRangeValue): void {
    dispatch(dtSetCompletedDateRange(value));
  }

  useEffect(() => {
    const properties = chain(propertiesOptions)
      .flatMapDeep((property) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formTypesArray: any[] = property.formTypes.length === 0 ? [null] : property.formTypes;
        return formTypesArray.map((formType) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const formSubTypesArray: any[] =
            formType?.formItems?.length ?? 0 > 1 ? formType?.formItems ?? [null] : [null];
          return formSubTypesArray.map((item) => {
            return {
              propertyId: property.id,
              formType: formType?.type,
              formSubtype: item?.id,
              formSubtypeName: item?.name,
            };
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any;
      })
      .filter(
        propertyIdFilter || formTypeFilter || formSubtypeFilter
          ? {
              ...(propertyIdFilter ? { propertyId: propertyIdFilter } : undefined),
              ...(formTypeFilter ? { formType: formTypeFilter } : undefined),
              ...(formSubtypeFilter ? { formSubtypeName: formSubtypeFilter.name } : undefined),
            }
          : undefined
      )
      .groupBy('propertyId')
      .map(
        (value, key) =>
          ({
            propertyId: Number(key),
            formTypes: chain(value)
              .groupBy('formType')
              .map((subtypes, formType) => ({
                formType: formType === 'undefined' ? null : formType,
                formId: subtypes.map((s) => s.formSubtype ?? null),
              }))
              .value(),
          }) as PropertyFilter
      )
      .value();

    dispatch(dtSetPropertyFilters(properties));
  }, [completedDateRange, formTypeFilter, formSubtypeFilter, propertiesOptions, propertyIdFilter]);

  const disabledDays = { after: new Date() };
  const previousMonth = startOfMonth(addMonths(new Date(), -1));
  const thisMonth = startOfMonth(new Date());

  return (
    <FilterPanelContainer>
      <DtDashboardFilters isLoading={isLoading} />
      <Box marginLeft='auto'>
        {isCompletedDispatchStatus && (
          <DtDateRangePicker
            disabled={isLoading}
            label='Range:'
            value={completedDateRange}
            onChange={handleCompletedDateRangeChange}
            disabledDays={disabledDays}
            month={previousMonth}
            maxMonth={thisMonth}
          />
        )}
      </Box>
    </FilterPanelContainer>
  );
};

export default DtDashboardFilterPanel;
