import React, { lazy } from 'react';

import DtNavigationSkeleton from './dt-navigation-skeleton/dt-navigation-skeleton';
import { dtWithSuspense } from '../../../cdk/hocs/dt-with-suspense.hoc';

const DtNavigationLazy = dtWithSuspense(
  lazy(() => import('../dt-navigation')),
  <DtNavigationSkeleton />
);

export default DtNavigationLazy;
