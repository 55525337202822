import React from 'react';

import DtIcon from '../../../../../components/dt-icon/dt-icon';
import { CellTitle, CellSubtitle } from '../../../../../components/dt-table/table-cell.styled';

interface DtDispatchActionsTableCellProps {
  totalAssigned: number;
  totalDeclined: number;
  assigneeName: string;
  assigneeEmail: string;
}

const DtDispatchAssigneeTableCell: React.FC<DtDispatchActionsTableCellProps> = ({
  totalAssigned,
  totalDeclined,
  assigneeName,
  assigneeEmail,
}) => {
  const showEmailWhenSingleAssigneeAndNoDecline = totalAssigned <= 1 && totalDeclined < 1;
  const showEmailWhenSingleAssignee = totalAssigned <= 1;
  const showIconWhenMultipleDeclines = totalDeclined >= 1;
  const showAssigneeNameWhenOneAssignee = totalAssigned <= 1;

  return (
    <div style={{ width: '100%' }}>
      {showAssigneeNameWhenOneAssignee ? (
        <CellTitle titleBold={false}>{assigneeName}</CellTitle>
      ) : (
        <CellTitle titleBold={false}>{totalAssigned} Assignees</CellTitle>
      )}
      {showIconWhenMultipleDeclines && (
        <div>
          <DtIcon icon='close' size={16} color='red' />
          <CellSubtitle subtitleBold={false}>&nbsp;{totalDeclined} declined</CellSubtitle>
        </div>
      )}
      <div>
        {(showEmailWhenSingleAssignee || showEmailWhenSingleAssigneeAndNoDecline) && (
          <CellSubtitle subtitleBold={false}>{assigneeEmail}</CellSubtitle>
        )}
      </div>
    </div>
  );
};

export default DtDispatchAssigneeTableCell;
