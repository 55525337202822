import { styled } from '@mui/material/styles';
import { List, OutlinedInput, Popover, Typography } from '@mui/material';
import DtButton from '../dt-button/dt-button';
import DtTextInput from '../text-input/dt-text-input/dt-text-input';
import { CSSObject } from '@emotion/react';

const TRANSPARENT_BORDER = '1.5px solid transparent';

export const DateRangeInput = styled(DtTextInput, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  & input {
    color: ${({ theme }) => theme.palette.main.black};
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    cursor: pointer;
    padding-right: 6px;
  }

  & .MuiOutlinedInput-root fieldset,
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border: ${TRANSPARENT_BORDER};
  }

  & .MuiOutlinedInput-root.Mui-focused {
    background-color: ${({ theme }) => theme.palette.main.control};
  }

  & .MuiOutlinedInput-root:hover {
    background-color: #e6edf5;

    fieldset {
      border: ${TRANSPARENT_BORDER};
    }
  }

  & .MuiOutlinedInput-root.focused {
    background-color: #fff;

    fieldset {
      border: ${({ theme }) => `1.5px solid ${theme.palette.main.primary}`};
    }
  }

  & .Mui-disabled {
    opacity: 40%;
  }

  & .MuiInputBase-root.Mui-error {
    background-color: #fef3f4;

    fieldset {
      border: ${({ theme }) => `1.5px solid ${theme.palette.main.red}`};
    }
  }

  & .MuiFormHelperText-root {
    ${({ theme }) => theme.typography.caption as CSSObject};
    position: absolute;
    top: calc(100% + 1px);

    &.Mui-error {
      margin: 0;
      color: ${({ theme }) => theme.palette.main.red};
    }
  }
`;

export const InputLabel = styled(Typography)`
  flex: 1 0 auto;
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-right: ${({ theme }) => theme.spacing(8)};
`;

export const PopoverContainer = styled(Popover)`
  & .MuiPaper-root {
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;

export const RangeList = styled(List)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(10)};
`;

export const PickerToolbarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  border-left: ${({ theme }) => `1px solid ${theme.palette.main.control}`};
`;

export const PickerApplyButton = styled(DtButton)`
  margin: ${({ theme }) => theme.spacing(20)};
  width: 80%;
`;
