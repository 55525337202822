import { Grid } from '@mui/material';
import React from 'react';

import { TabsContainer } from './components/dt-dispatches-tabs/dt-dispatches-tabs.styled';
import { MainContent } from './dt-dashboard.styled';
import { FooterContainer } from '../../components/dt-copyright-footer/dt-copyright-footer.styled';
import DtSkeleton from '../../components/dt-skeleton/dt-skeleton';

const DtDashboardSkeleton: React.FC = () => {
  return (
    <MainContent>
      <DtSkeleton variant='rectangular' width={145} height={16} style={{ marginBottom: '6px' }} />
      <DtSkeleton variant='rectangular' width={251} height={8} style={{ marginBottom: '22px' }} />
      <div
        style={{
          marginBottom: '32px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: '16px',
        }}
      >
        <DtSkeleton variant='rectangular' width={262} height={40} />
        <DtSkeleton variant='rectangular' width={262} height={40} />
        <DtSkeleton variant='rectangular' width={262} height={40} />
      </div>
      <TabsContainer>
        <Grid item xs={12}>
          <DtSkeleton variant='rectangular' height={800} />
        </Grid>
      </TabsContainer>
      <FooterContainer container>
        <DtSkeleton variant='text' width={215} />
        <DtSkeleton variant='text' width={112} />
      </FooterContainer>
    </MainContent>
  );
};

export default DtDashboardSkeleton;
