import { Field, FieldProps } from 'formik';
import React from 'react';

import DtCreatableSelect from './dt-creatable-select/dt-creatable-select';
import { DtAvailableOptionItems, DtSelectProps } from './dt-selects.interfaces';

const DtFormikCreatableSelect: React.FC<Partial<DtSelectProps<DtAvailableOptionItems>>> = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <DtCreatableSelect
          value={field.value}
          onChange={(value?: DtAvailableOptionItems) => {
            form.setFieldError(field.name, undefined);
            form.setFieldValue(field.name, value);
          }}
          withFlexProp={props.withFlexProp}
          disabled={props.disabled}
          label={props.label}
          labelPosition={props.labelPosition}
          className={props.className}
          fullWidth={props.fullWidth}
          minWidth={props.minWidth}
          placeholder={props.placeholder}
          name={field.name}
          disableClearable={props.disableClearable}
          options={props.options ?? []}
          error={meta.error}
        />
      )}
    </Field>
  );
};

export default DtFormikCreatableSelect;
