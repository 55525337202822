import React from 'react';
import { unstable_useBlocker } from 'react-router-dom';

import DtPopup from '../../../../components/dt-popup/dt-popup';
import { DialogButton } from '../../../../components/dt-popup/dt-popup.styled';

interface DtPromptProps {
  when: boolean;
}

const DtPrompt: React.FC<DtPromptProps> = ({ when }) => {
  const blocker = unstable_useBlocker(when);

  function handleClose(): void {
    if (blocker.reset) {
      blocker.reset();
    }
  }

  function handleConfirm(): void {
    if (blocker.proceed) {
      blocker.proceed();
    }
  }

  return (
    <DtPopup
      icon='info-2'
      color='yellow'
      title='Are you sure you want to leave this page?'
      subtitle='Your changes will be lost'
      opened={blocker.state === 'blocked'}
      onClose={handleClose}
    >
      <>
        <DialogButton variant='secondary' onClick={handleClose}>
          Cancel
        </DialogButton>
        <DialogButton variant='primary' onClick={handleConfirm}>
          Proceed
        </DialogButton>
      </>
    </DtPopup>
  );
};

export default DtPrompt;
