import { Box, Typography } from '@mui/material';
import { lowerCase, upperFirst } from 'lodash';
import React from 'react';

import { ToggleButtonsContainer, ToggleItemsCountChip } from './dt-dispatch-details-modal-tabs.styled';
import DtMultipleSelect from '../../../../../../components/dt-selects/dt-multiple-select/dt-multiple-select';
import { DtOptionItem } from '../../../../../../components/dt-selects/dt-selects.interfaces';
import DtToggleButton, { DtTabsItem } from '../../../../../../components/dt-toggle-button/dt-toggle-button';
import { UserRole } from '../../../../../../repositories/__generated__/v2';
import DtDispatchAssignees from '../../../../components/dt-dispatch-assignees/dt-dispatch-assignees';
import { AssigneeStatusType, DtDispatchDetailsTab, DtSupportedDispatchStatuses } from '../../../../dt-dispatches.enums';

interface DtDispatchDetailsTabsProps {
  value: DtDispatchDetailsTab;
  onChange: (newValue: DtDispatchDetailsTab) => void;
  amountOfAssignees: number;
  amountOfAssets: number;
  dispatchStatus: DtSupportedDispatchStatuses;
}

export const DtDispatchDetailsTabs: React.FC<DtDispatchDetailsTabsProps> = ({
  value,
  onChange,
  amountOfAssignees,
  amountOfAssets,
  dispatchStatus,
}) => {
  const isAssetsArrayEmpty = amountOfAssets === 0;

  const baseDispatchToggleItems = [
    {
      value: DtDispatchDetailsTab.General,
      label: (
        <Box display='flex' alignItems='center'>
          <Typography variant='bodySemibold'>General</Typography>
        </Box>
      ),
    },

    {
      value: DtDispatchDetailsTab.Assignees,
      label: (
        <Box display='flex' alignItems='center'>
          <Typography variant='bodySemibold'>Assignees</Typography>
          <ToggleItemsCountChip label={amountOfAssignees} />
        </Box>
      ),
    },
  ];

  let toggleButtonItems: DtTabsItem<DtDispatchDetailsTab>[] = baseDispatchToggleItems;

  if (!isAssetsArrayEmpty) {
    toggleButtonItems = [
      ...toggleButtonItems,
      {
        value: DtDispatchDetailsTab.Assets,
        label: (
          <Box display='flex' alignItems='center'>
            <Typography variant='bodySemibold'>Assets</Typography>
            <ToggleItemsCountChip label={amountOfAssets} />
          </Box>
        ),
      },
    ];
  }

  if (dispatchStatus === DtSupportedDispatchStatuses.Open || dispatchStatus === DtSupportedDispatchStatuses.Overdue) {
    toggleButtonItems = [
      ...toggleButtonItems,
      {
        value: DtDispatchDetailsTab.Upload_Report,
        label: (
          <Box display='flex' alignItems='center'>
            <Typography variant='bodySemibold'>Upload</Typography>
          </Box>
        ),
      },
    ];
  }

  return (
    <>
      <ToggleButtonsContainer>
        <DtToggleButton value={value} tabsItems={toggleButtonItems} minWidth='130px' onChange={onChange} />
      </ToggleButtonsContainer>
    </>
  );
};

interface DtDispatchDetailsAssignessProps {
  dispatchStatus: DtSupportedDispatchStatuses;
  userRole: UserRole;
  options: DtOptionItem<string>[];
  value: DtDispatchItemDetailsAssign[];
  onChange: (value: DtDispatchItemDetailsAssign[]) => void;
}

export const DtDispatchDetailsAssigness: React.FC<DtDispatchDetailsAssignessProps> = ({
  dispatchStatus,
  userRole,
  options,
  value,
  onChange,
}) => {
  let isAssigneeSelectFieldShown =
    userRole !== upperFirst(lowerCase(UserRole.User)) && dispatchStatus !== DtSupportedDispatchStatuses.Completed;
  // TODO: temporary hide asignee selection, revert when FJ API will be ready
  isAssigneeSelectFieldShown = false;

  function handleAsigneesChange(selectedEmails: string[]): void {
    const leftAssignees = value.filter((i) => selectedEmails.includes(i.assigneeEmail));
    const newUsersEmails = selectedEmails.filter((i) => !leftAssignees.find((l) => l.assigneeEmail === i));
    const newAssignees: DtDispatchItemDetailsAssign[] = options
      .filter((i) => newUsersEmails.includes(i.value))
      .map((option) => ({
        assigneeName: option.label,
        assigneeEmail: option.value,
        status: AssigneeStatusType.New,
        newAssignee: true,
        reassigned: false,
      }));

    onChange([...leftAssignees, ...newAssignees]);
  }

  return (
    <>
      {isAssigneeSelectFieldShown && (
        <div style={{ marginBottom: '10px' }}>
          <DtMultipleSelect
            value={value.map((i) => i.assigneeEmail) ?? []}
            onChange={handleAsigneesChange}
            name='assignees'
            options={options}
            placeholder='Select Assignee'
            fullWidth
            hideChips
          />
        </div>
      )}
      <DtDispatchAssignees
        assignees={value}
        status={dispatchStatus}
        onDelete={(userEmail) => onChange(value.filter((i) => i.assigneeEmail !== userEmail))}
        onReassign={(userEmail) =>
          onChange(value.map((i) => ({ ...i, reassigned: i.assigneeEmail === userEmail ? true : i.reassigned })))
        }
      />
    </>
  );
};
