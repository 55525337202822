import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { differenceWith, every, isEqual, isNil, negate, some, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  DtDispatchDetailsAssigness,
  DtDispatchDetailsTabs,
} from './components/dt-dispatch-details-modal-tabs/dt-dispatch-details-modal-tabs';
import DtDispatchUploadReportForm from './components/dt-dispatch-upload-report-form/dt-dispatch-upload-report-form';
import {
  DT_DISPATCH_UPLOAD_REPORT_INITIAL_FORM_VALUES,
  DT_DISPATCH_UPLOAD_REPORT_FORM_VALIDATION,
} from './components/dt-dispatch-upload-report-form/dt-dispatch-upload-report-form.schema';
import DtDispatchDetailsModalSkeleton from './dt-dispatch-details-modal-skeleton';
import {
  DialogActionsContainer,
  DialogContainer,
  DialogContentContainer,
  DialogTitleContainer,
  ModalTitleMain,
  SummaryRowContainer,
  TitleSecondary,
} from './dt-dispatch-details-modal.styled';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import { dtUseFetchData } from '../../../../cdk/hooks/dt-use-fetch-data.hook';
import { FrequencyType } from '../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import { dtToBase64 } from '../../../../cdk/utils/dt-file.utils';
import DtButton from '../../../../components/dt-button/dt-button';
import DtDispatchDetailsSummaryRow, {
  DtSummaryRowItem,
} from '../../../../components/dt-details-summary-row/dt-details-summary-row';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { DtOptionItem } from '../../../../components/dt-selects/dt-selects.interfaces';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { UserRole } from '../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../services/dt-toast.service';
import { dtSelectGetAvailableFormUsers } from '../../../dt-properties/dt-properties.slice';
import DtDispatchAssets from '../../components/dt-dispatch-assets/dt-dispatch-assets';
import DtDispatchFrequencyLabel from '../../components/dt-dispatch-frequency-label/dt-dispatch-frequency-label';
import DtDispatchPriority from '../../components/dt-dispatch-priority/dt-dispatch-priority';
import DtDispatchStatusBadge from '../../components/dt-dispatch-status-badge/dt-dispatch-status-badge';
import DtModalActions from '../../components/dt-modal-actions/dt-modal-actions';
import { dtDispatchService } from '../../dt-dispatch.service';
import { DtDispatchDetailsTab, DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';
import {
  dtRefreshCompletedDtDispatches,
  dtRefreshDtDispatchesCount,
  dtRefreshOpenDtDispatches,
  dtRefreshOverdueDtDispatches,
  dtRefreshScheduledDtDispatches,
} from '../../dt-dispatches.slice';

interface DtDispatchDetailsModalProps {
  open: boolean;
  dispatchRowItem: DtDispatchRowItem;
  onCancel: (shouldRefresh?: boolean) => void;
  userRole: UserRole;
}

const DtDispatchDetailsModal: React.FC<DtDispatchDetailsModalProps> = ({
  open,
  dispatchRowItem,
  onCancel,
  userRole,
}) => {
  const dispatch = dtUseAppDispatch();
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(DtDispatchDetailsTab.General);
  const [assignees, setAssignees] = useState<DtDispatchItemDetailsAssign[]>([]);
  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);

  const availableUsersUnderCurrentFormType: DtFormUser[] = dtUseAppSelector(
    dtSelectGetAvailableFormUsers(dispatchRowItem.propertyId, dispatchRowItem.type)
  );

  const { data, isLoading } = dtUseFetchData<DtDispatchItemDetails>([dispatchRowItem.id], () =>
    dtDispatchService.getSingleDispatchDetails(dispatchRowItem.id)
  );

  useEffect(() => {
    if (data) {
      setAssignees(data.assigns);
    }
  }, [data]);

  const assigneeOptions: DtOptionItem<string>[] = sortBy(
    availableUsersUnderCurrentFormType.map((user) => ({
      value: user.email,
      label: user.fullName,
    })),
    'label'
  );

  async function handleDetailsModalSubmit(
    reportToUpload: File | null,
    reportAuthor?: string,
    reportUploadDate?: Date,
    reportUploadTime?: Date
  ): Promise<void> {
    const userEmailsToRemove = differenceWith(data?.assigns ?? [], assignees, isEqual).map((i) => i.assigneeEmail);
    const userEmailsToAdd = differenceWith(assignees, data?.assigns ?? [], isEqual).map((i) => i.assigneeEmail);
    const userEmailsToReassign = assignees.filter((i) => i.reassigned).map((i) => i.assigneeEmail);
    const completedDate = `${reportUploadDate && format(reportUploadDate, 'yyyy-MM-dd')}T${
      reportUploadTime && format(reportUploadTime as Date, 'HH:mm:ss')
    }`;

    try {
      setLoading(true);
      const fileData = await dtToBase64(reportToUpload);
      await dtApiRepository.DispatchApi.dispatchControllerUpdateAssignee(dispatchRowItem.id, {
        userEmailsToRemove,
        userEmailsToAdd,
        userEmailsToReassign,
        report:
          reportAuthor && reportToUpload && fileData && completedDate
            ? {
                author: reportAuthor,
                type: reportToUpload?.type,
                name: reportToUpload?.name,
                data: fileData,
                date: completedDate,
              }
            : undefined,
      });
      dispatch(dtRefreshDtDispatchesCount());
      dispatch(dtRefreshOpenDtDispatches());
      dispatch(dtRefreshOverdueDtDispatches());
      dispatch(dtRefreshCompletedDtDispatches());
      dispatch(dtRefreshScheduledDtDispatches());
      onCancel();
      dtToastService.success('Successfully updated dispatch');
    } catch (error) {
      dtToastService.error('Failed to change assignee');
    }
    setLoading(false);
  }

  function handleBackdropClick(): void {
    const changedAssigness = differenceWith(data?.assigns ?? [], assignees, isEqual);

    if (changedAssigness.length > 0) {
      dtToastService.info("Popup can't be closed because you made some changes, Save or Cancel them");
    } else {
      onCancel();
    }
  }

  if (!data || isLoading) {
    return <DtDispatchDetailsModalSkeleton />;
  }

  const summaryItemsConfig: DtSummaryRowItem[] = [
    {
      label: 'Status',
      children: (
        <SummaryRowContainer>
          <Box marginRight='12px'>
            <DtDispatchStatusBadge
              dtDispatchStatus={data.status}
              createdDate={data.createdDate}
              completedDate={data.completedDate}
              withTime
            />
          </Box>
          <TitleSecondary variant='body'>
            {data.status !== DtSupportedDispatchStatuses.Completed && (
              <DtDispatchFrequencyLabel status={data.status} frequency={data.frequency} date={data.dueDate} />
            )}

            {data.status === DtSupportedDispatchStatuses.Completed
              ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                `submitted at ${format(data.completedDate!, 'h:mm a')}`
              : ''}
          </TitleSecondary>
        </SummaryRowContainer>
      ),
    },
    {
      label: 'Priority',
      children: <DtDispatchPriority priority={data.priority} />,
    },
    {
      label: 'Assigned by',
      children: (
        <Box display='flex'>
          <Typography variant='body'>{data.createdBy}</Typography>
          <TitleSecondary variant='body' sx={{ marginLeft: 8 }}>
            {data.createdByEmail.length ? `(${data.createdByEmail})` : null}
          </TitleSecondary>
        </Box>
      ),
    },
    {
      label: 'Frequency',
      children: (
        <SummaryRowContainer>
          <DtIcon icon='calendar' size={16} color='black' />
          <Typography variant='body' color='black' sx={{ marginLeft: 8 }}>
            {data.frequency === FrequencyType.OnceFuture ? FrequencyType.None : data.frequency}
          </Typography>
        </SummaryRowContainer>
      ),
    },
    {
      label: 'Notes',
      alignItems: 'baseline',
      children: <Typography variant='body'>{data.notes || '-'}</Typography>,
    },
  ];

  return (
    <DialogContainer open={open} onClose={handleBackdropClick} transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogTitleContainer>
        <section>
          <ModalTitleMain variant='h3'>{dispatchRowItem.type}</ModalTitleMain>
          <TitleSecondary variant='bodySmall'>{dispatchRowItem.subtype}</TitleSecondary>
        </section>
        <section>
          <DtButton variant='transparent' onClick={onCancel}>
            <DtIcon icon='close' size={20} />
          </DtButton>
        </section>
      </DialogTitleContainer>
      <Formik
        initialValues={DT_DISPATCH_UPLOAD_REPORT_INITIAL_FORM_VALUES}
        validationSchema={DT_DISPATCH_UPLOAD_REPORT_FORM_VALIDATION}
        onSubmit={(values) => {
          const { reportToUpload, reportAuthor, reportUploadDate, reportUploadTime } = values;
          handleDetailsModalSubmit(reportToUpload, reportAuthor, reportUploadDate, reportUploadTime);
        }}
        validateOnBlur={validationAfterSubmit}
        validateOnChange={validationAfterSubmit}
        enableReinitialize
      >
        {({ values, isValid, handleSubmit }) => (
          <>
            <DialogContentContainer>
              <DtDispatchDetailsTabs
                value={currentTab}
                onChange={setCurrentTab}
                amountOfAssignees={assignees.length}
                amountOfAssets={data.assets.length}
                dispatchStatus={data?.status}
              />

              {currentTab === DtDispatchDetailsTab.General && (
                <div>
                  {summaryItemsConfig.map(({ label, alignItems, children }) => (
                    <DtDispatchDetailsSummaryRow key={label} label={label} alignItems={alignItems}>
                      {children}
                    </DtDispatchDetailsSummaryRow>
                  ))}
                </div>
              )}

              {currentTab === DtDispatchDetailsTab.Assignees && (
                <DtDispatchDetailsAssigness
                  dispatchStatus={data.status}
                  userRole={userRole}
                  options={assigneeOptions}
                  onChange={setAssignees}
                  value={assignees}
                />
              )}

              {currentTab === DtDispatchDetailsTab.Assets && <DtDispatchAssets assets={data.assets} />}

              {currentTab === DtDispatchDetailsTab.Upload_Report && (
                <DtDispatchUploadReportForm userRole={userRole} assignees={assignees} />
              )}
            </DialogContentContainer>
            <DialogActionsContainer>
              {loading ? (
                <>
                  <Box marginRight={24}>
                    <DtSkeleton variant='rectangular' height={40} width={128} />
                  </Box>
                  <DtSkeleton variant='rectangular' height={40} width={128} />
                </>
              ) : (
                <DtModalActions
                  onClose={onCancel}
                  onSubmit={() => {
                    if (currentTab === DtDispatchDetailsTab.Upload_Report) {
                      setValidationAfterSubmit(true);
                      return handleSubmit();
                    }
                    const { reportToUpload, reportAuthor, reportUploadDate, reportUploadTime } = values;

                    if (every(values, negate(isNil))) {
                      setValidationAfterSubmit(true);

                      return handleSubmit();
                    }

                    if (some(values, negate(isNil))) {
                      dtToastService.error('Upload report is not completed');
                      setValidationAfterSubmit(true);

                      return handleSubmit();
                    }

                    handleDetailsModalSubmit(reportToUpload, reportAuthor, reportUploadDate, reportUploadTime);
                  }}
                  dispatchCompleted={data.status === DtSupportedDispatchStatuses.Completed}
                  disableSaveButton={
                    !isValid ||
                    (Boolean(values.reportAuthor) && !some(assignees, { assigneeEmail: values.reportAuthor }))
                  }
                />
              )}
            </DialogActionsContainer>
          </>
        )}
      </Formik>
    </DialogContainer>
  );
};

export default DtDispatchDetailsModal;
