import { Step, StepLabel, Stepper } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

const STEP_CONNECTOR_POSITION = '25px';

export const CustomStepper = styled(Stepper)`
  padding: ${({ theme }) => `${theme.spacing(24)} ${theme.spacing(32)}`};
  background-color: ${({ theme }) => theme.palette.main.hover};
`;

export const CustomStep = styled(Step, { shouldForwardProp: (prop) => prop !== 'completed' })<{
  completed: boolean;
}>`
  ${({ completed }) => (completed ? 'cursor: pointer;' : 'pointer-events: none;')}

  & .MuiStepConnector-root {
    left: calc(-50% + ${STEP_CONNECTOR_POSITION});
    right: calc(50% + ${STEP_CONNECTOR_POSITION});
    border-top-style: dashed;
    border-color: ${({ theme }) => theme.palette.main.secondaryIcon};
    border-top-width: 2px;
    opacity: 80%;

    & .MuiStepConnector-line {
      border-top-width: 0;
    }

    &.Mui-active,
    &.Mui-completed {
      border-top-style: solid;
      border-color: ${({ theme }) => theme.palette.main.emerald};
    }
  }
`;

export const CustomStepLabel = styled(StepLabel)`
  & .MuiSvgIcon-root {
    color: transparent;
    border: ${({ theme }) => `2px solid ${theme.palette.main.secondaryText}`};
    border-radius: 50%;
    opacity: 80%;

    &.Mui-active {
      border: none;
      color: ${({ theme }) => theme.palette.main.primary};
      opacity: 100%;

      text {
        border: none;
        fill: ${({ theme }) => theme.palette.main.white};
      }
    }

    &.Mui-completed {
      border: none;
      color: ${({ theme }) => theme.palette.main.emerald};
    }
  }

  & .MuiStepIcon-text {
    fill: ${({ theme }) => theme.palette.main.secondaryText};
    ${({ theme }) => theme.typography.bodySmallSemibold as CSSObject};
  }

  & .MuiStepLabel-label {
    color: ${({ theme }) => theme.palette.main.secondaryText};
    ${({ theme }) => theme.typography.body as CSSObject};

    &.MuiStepLabel-alternativeLabel {
      margin-top: ${({ theme }) => theme.spacing(8)};
    }

    &.Mui-active,
    &.Mui-completed {
      color: ${({ theme }) => theme.palette.main.black};
    }

    &.Mui-active {
      ${({ theme }) => theme.typography.bodySemibold as CSSObject};
    }

    &.Mui-completed {
      ${({ theme }) => theme.typography.body as CSSObject}
    }
  }
`;
