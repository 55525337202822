import { Grid, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabsContainer = styled(Grid)`
  flex: 1 1 auto;
`;

export const TabsItems = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const TabButton = styled(Tab)`
  flex: 1 0 auto;
  width: auto;
  color: ${({ theme }) => theme.palette.main.black};
  position: relative;

  @media (min-width: 1200px) {
    padding: ${({ theme }) => `${theme.spacing(17)} ${theme.spacing(57)}`};
    min-width: 255px;
  }

  &:last-child {
    position: relative;
    overflow: inherit;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.main.black};
    background-color: ${({ theme }) => theme.palette.main.white};
    ${({ theme }) => theme.mixins.boxShadowMixin};
  }
`;
