import React from 'react';

import { DtStepperProps } from './dt-stepper-interface';
import { CustomStep, CustomStepLabel, CustomStepper } from './dt-stepper.styled';

const DtStepper: React.FC<DtStepperProps> = (props) => {
  function isStepCompleted(index: number): boolean {
    return props.activeStep > index || props.isCompletedStep;
  }

  return (
    <CustomStepper alternativeLabel activeStep={props.activeStep}>
      {props.steps.map((step, index) => (
        <CustomStep key={step.id} completed={isStepCompleted(index)} onClick={() => props.onClick(step.id)}>
          <CustomStepLabel>{step.label}</CustomStepLabel>
        </CustomStep>
      ))}
    </CustomStepper>
  );
};

export default DtStepper;
