/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AssignmentParent } from './assignment-parent';
import { UserRole } from './user-role';

/**
 *
 * @export
 * @interface UserDetailsDto
 */
export interface UserDetailsDto {
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof UserDetailsDto
   */
  companyId?: number;
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  companyName?: string;
  /**
   *
   * @type {UserRole}
   * @memberof UserDetailsDto
   */
  role?: UserRole;
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsDto
   */
  phone?: string;
  /**
   * If empty - then that user has no Mobile App username, and UI flag should be disabled
   * @type {string}
   * @memberof UserDetailsDto
   */
  mobileAppUsername?: string;
  /**
   * Can be used to identify if we need to display \'Resend invite\' button on UI.   Next documentation is copied from AWS docs, and may be changed in future without our acknowledgement.            UNCONFIRMED - User has been created but not confirmed.      CONFIRMED - User has been confirmed.      ARCHIVED - User is no longer active.      COMPROMISED - User is disabled due to a potential security threat.      UNKNOWN - User status isn\'t known.      RESET_REQUIRED - User is confirmed, but the user must request a code and reset their password before they can sign in.      FORCE_CHANGE_PASSWORD - The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else.
   * @type {string}
   * @memberof UserDetailsDto
   */
  status?: UserDetailsDtoStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserDetailsDto
   */
  hasMobileAppAccess: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserDetailsDto
   */
  hasWebAppAccess: boolean;
  /**
   *
   * @type {number}
   * @memberof UserDetailsDto
   */
  amountOfLoggedInTimes: number;
  /**
   * Date is provided in UTC format, example: \"2022-02-15T13:20:19.000Z\"
   * @type {string}
   * @memberof UserDetailsDto
   */
  lastLogin?: string;
  /**
   *
   * @type {Array<AssignmentParent>}
   * @memberof UserDetailsDto
   */
  assignments: Array<AssignmentParent>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserDetailsDtoStatusEnum {
  Unconfirmed = 'UNCONFIRMED',
  Confirmed = 'CONFIRMED',
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Unknown = 'UNKNOWN',
  ResetRequired = 'RESET_REQUIRED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
}
