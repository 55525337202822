import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';

import { dtFormAsset } from './dt-generate-forms-columns-config.interfaces';
import DtSkeleton from '../../../../../components/dt-skeleton/dt-skeleton';
import { CellTitle } from '../../../../../components/dt-table/table-cell.styled';
import DtTooltipTriggerElement from '../../../../../components/dt-tooltip-trigger-element/dt-tooltip-trigger-element';
import DtTableCellActionsSkeleton from '../../../components/dt-table-cell-actions-skeleton/dt-table-cell-actions-skeleton';
import { CellBadgeTitle, CellBadgeWrapper } from '../../../components/dt-table-cell-badge/dt-table-cell-badge.styled';
import { DT_ACTIONS_COLUMN_WIDTH } from '../../../dt-administration.constants';
import { DtFormsRowItem } from '../../dt-forms.slice';
import DtCopyButton from '../dt-copy-button/dt-copy-button';
import DtFormTypesTableCellActions from '../dt-forms-table-cell-actions/dt-forms-table-cell-actions';

const TooltipContent = (formAssetList: dtFormAsset[]): JSX.Element => (
  <>
    {formAssetList.map((formAsset: dtFormAsset, index: number) => (
      <li key={`${formAsset.assetKey}_${index}`} style={{ listStyle: 'none' }}>
        {formAsset.assetKey}
      </li>
    ))}
  </>
);

export function dtGenerateFormsTableColumnsConfig(isLoading: boolean, hoveredRowId: string): GridColDef[] {
  return [
    {
      field: 'form name',
      headerName: 'Form Name',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formName,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={true}>{params.row.formName}</CellTitle>
        );
      },
    },
    {
      field: 'form type',
      headerName: 'Form Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formType,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.formType}</CellTitle>
        );
      },
    },
    {
      field: 'site',
      headerName: 'Site',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.site,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.site}</CellTitle>
        );
      },
    },
    {
      field: 'amount of assets',
      headerName: 'Amount of Assets',
      flex: 0.75,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formAssetList,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>): React.ReactNode => {
        const assetsLabel = params.row.formAssetList.length === 1 ? 'asset' : 'assets';

        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' height={24} width={78} />
        ) : (
          <CellBadgeWrapper>
            <CellBadgeTitle>
              {params.row.formAssetList.length}&nbsp;{assetsLabel}&nbsp;
              {params.row.formAssetList.length > 0 && (
                <Tooltip title={TooltipContent(params.row.formAssetList)} arrow>
                  <DtTooltipTriggerElement />
                </Tooltip>
              )}
            </CellBadgeTitle>
          </CellBadgeWrapper>
        );
      },
    },
    {
      field: 'pronto form id',
      headerName: 'Pronto Form ID',
      flex: 0.5,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.pfFormId,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>): React.ReactNode => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' height={24} width={78} />
        ) : (
          <>
            <CellBadgeWrapper>
              <CellBadgeTitle>{params.row.pfFormId}</CellBadgeTitle>
            </CellBadgeWrapper>
            {hoveredRowId === params.row.id && <DtCopyButton textToCopy={params.row.pfFormId} />}
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: DT_ACTIONS_COLUMN_WIDTH,
      sortable: false,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellActionsSkeleton buttonsNumber={2} />
        ) : (
          <DtFormTypesTableCellActions
            id={params.row.id}
            formTypeId={params.row.formTypeId}
            formSchemaId={params.row.formSchemaId}
            siteId={params.row.siteId}
            formName={params.row.formName}
          />
        );
      },
    },
  ];
}
