import { DtSelectOption } from './dt-administration-details-forms.interface';

export const dtMapReportTemplatesNamesToOptions = (reportTemplates: string[]): DtSelectOption[] => {
  return (
    reportTemplates.map((template) => {
      return {
        value: template,
        label: template,
      };
    }) ?? []
  );
};
