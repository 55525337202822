import React from 'react';

import { FooterContainer } from './../../../../components/dt-copyright-footer/dt-copyright-footer.styled';
import DtLoginFormSkeleton from './dt-login-form-skeleton';
import { Screenshot, CarouselTitle, LogoContainer } from './dt-login-page-skeleton.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import {
  DtAuthLayoutAppPreviewContainer,
  DtAuthLayoutContainer,
  DtAuthLayoutFormContainer,
  FormContainer,
} from '../dt-auth-layout/dt-auth-layout.styled';

const DtLoginPageSkeleton: React.FC = () => {
  return (
    <DtAuthLayoutContainer container>
      <DtAuthLayoutFormContainer item xs={6}>
        <FormContainer>
          <LogoContainer>
            <DtSkeleton variant='rectangular' width={136} height={40} />
          </LogoContainer>
          <DtLoginFormSkeleton />
          <FooterContainer>
            <DtSkeleton variant='text' width={243} height={48} />
          </FooterContainer>
        </FormContainer>
      </DtAuthLayoutFormContainer>
      <DtAuthLayoutAppPreviewContainer item xs={6}>
        <Screenshot>
          <DtSkeleton variant='rectangular' width={640} height={540} />
        </Screenshot>
        <CarouselTitle>
          <DtSkeleton variant='text' width={260} height={22} />
        </CarouselTitle>
      </DtAuthLayoutAppPreviewContainer>
    </DtAuthLayoutContainer>
  );
};

export default DtLoginPageSkeleton;
