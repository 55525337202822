import { differenceInCalendarDays } from 'date-fns';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  AdditionalFiledLabel,
  DateLabel,
  FrequencyReminderSelectionContainer,
} from './dt-new-dispatch-frequency.styled';
import { FrequencyType } from '../../../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtDispatchFrequencyDateRangesSelectors from '../../components/dt-dispatch-frequency-date-ranges-selectors/dt-dispatch-frequency-date-ranges-selectors';
import DtNewDispatchFrequencyDaily from '../../components/dt-new-dispatch-frequency-tabs/dt-new-dispatch-frequency-daily';
import DtNewDispatchFrequencyMonthly from '../../components/dt-new-dispatch-frequency-tabs/dt-new-dispatch-frequency-monthly';
import DtNewDispatchFrequencyNone from '../../components/dt-new-dispatch-frequency-tabs/dt-new-dispatch-frequency-none';
import DtNewDispatchFrequencyWeekly from '../../components/dt-new-dispatch-frequency-tabs/dt-new-dispatch-frequency-weekly';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';
import { DT_REMINDER_OPTIONS } from '../../dt-new-dispatch-modal.constants';

interface DtNewDispatchFrequencyProps {
  isEditFlow: boolean;
}

const DtNewDispatchFrequency: React.FC<DtNewDispatchFrequencyProps> = ({ isEditFlow }) => {
  const { values } = useFormikContext<DtNewDispatchFormInitialValues>();

  const isFrequencyWeeklyOrMonthlyTab =
    values.frequency.frequencyType === FrequencyType.Weekly || values.frequency.frequencyType === FrequencyType.Monthly;

  return (
    <>
      <div>
        {values.frequency.frequencyType === FrequencyType.Daily && <DtNewDispatchFrequencyDaily />}
        {values.frequency.frequencyType === FrequencyType.Weekly && <DtNewDispatchFrequencyWeekly />}
        {values.frequency.frequencyType === FrequencyType.Monthly && <DtNewDispatchFrequencyMonthly />}
      </div>

      {values.frequency.frequencyType === FrequencyType.None ? (
        <DtNewDispatchFrequencyNone isEditFlow={isEditFlow} />
      ) : (
        <DtDispatchFrequencyDateRangesSelectors isEditFlow={isEditFlow} />
      )}

      {isFrequencyWeeklyOrMonthlyTab && (
        <FrequencyReminderSelectionContainer>
          <div>
            <DateLabel>
              Reminder <AdditionalFiledLabel>(optional)</AdditionalFiledLabel>
            </DateLabel>
            <DtFormikMultipleSelect
              fullWidth
              labelPosition='top'
              name='frequency.reminder'
              options={
                values.frequency.customDueDate
                  ? DT_REMINDER_OPTIONS.filter(({ value }) => {
                      const reminderDays = parseInt(value, 10);

                      const diffDays = differenceInCalendarDays(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        values.frequency.customDueDate!,
                        values.frequency.startDate
                      );

                      return diffDays > reminderDays;
                    })
                  : DT_REMINDER_OPTIONS
              }
              placeholder='Select Reminder'
            />
          </div>
        </FrequencyReminderSelectionContainer>
      )}
    </>
  );
};

export default DtNewDispatchFrequency;
