import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DtRootState } from '../../../dt-store';
import { CompanyListDto } from '../../../repositories/__generated__/v2';

export interface DtOrganizationsState {
  status: DtStatus;
  organizations: DtOrganizationsRowItem[];
}

export interface DtOrganizationsRowItem extends CompanyListDto {
  isLoading?: boolean;
}

const initialState: DtOrganizationsState = {
  status: 'waiting-to-load',
  organizations: [],
};

const dtOrganizationsSlice = createSlice({
  name: 'dtOrganizations',
  initialState,
  reducers: {
    dtSetOrganizationsData: (state, action: PayloadAction<DtOrganizationsRowItem[]>) => {
      state.organizations = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
    dtUpdateLoadingStatusOfOrganizationRow: (state, action: PayloadAction<{ id: number; status: boolean }>) => {
      const dtOrganizationRow = _.find(state.organizations, { id: action.payload.id });

      if (dtOrganizationRow) {
        dtOrganizationRow.isLoading = action.payload.status;
      }
    },
    dtClearOrganizations: () => initialState,
  },
});

export const dtSelectOrganizations = (state: DtRootState): DtOrganizationsRowItem[] =>
  state.dtOrganizations.organizations;

export const dtSelectOrganizationsStatus = (state: DtRootState): DtStatus => state.dtOrganizations.status;
export const dtSelectOrganizationsLoading = (state: DtRootState): boolean => state.dtOrganizations.status === 'loading';

export const {
  dtSetOrganizationsData,
  dtSetLoadingStatus,
  dtClearOrganizations,
  dtUpdateLoadingStatusOfOrganizationRow,
} = dtOrganizationsSlice.actions;

export default dtOrganizationsSlice.reducer;
