import { Pagination, TablePagination } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

export const PaginationWrapper = styled('section')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomTablePagination = styled(TablePagination)`
  border-bottom: none;
  padding: 0;

  & .MuiTablePagination-selectLabel {
    ${({ theme }) => theme.typography.bodySemibold as CSSObject};
    display: flex;
    align-items: center;
    height: 39px;
    background-color: ${({ theme }) => theme.palette.main.control};
    margin: 0;
    padding-left: ${({ theme }) => theme.spacing(16)};
    color: ${({ theme }) => theme.palette.main.secondaryText};
    border-top-left-radius: ${({ theme }) => theme.spacing(3)};
    border-bottom-left-radius: ${({ theme }) => theme.spacing(3)};
  }

  & .MuiTablePagination-displayedRows {
    ${({ theme }) => theme.typography.bodySmall as CSSObject};
    margin: 0;
    color: ${({ theme }) => theme.palette.main.secondaryText};
    letter-spacing: 0;
  }

  & .MuiTablePagination-input {
    margin-left: 0;
    margin-right: ${({ theme }) => theme.spacing(24)};
    color: ${({ theme }) => theme.palette.main.black};
    ${({ theme }) => theme.typography.body as CSSObject};

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50px;
      padding-left: ${({ theme }) => theme.spacing(5)};
      height: ${({ theme }) => theme.spacing(30)};
      background-color: ${({ theme }) => theme.palette.main.control};
      border-top-right-radius: ${({ theme }) => theme.spacing(3)};
      border-bottom-right-radius: ${({ theme }) => theme.spacing(3)};
    }

    svg {
      margin-right: ${({ theme }) => theme.spacing(16)};

      &.MuiSelect-icon {
        color: ${({ theme }) => theme.palette.main.secondaryText};
      }
    }
  }

  &.MuiSelect-select-MuiInputBase-input {
    padding-right: ${({ theme }) => theme.spacing(40)};
  }

  & .MuiTablePagination-actions {
    display: none;
  }
`;

export const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-root {
    padding: ${({ theme }) => `${theme.spacing(0)} ${theme.spacing(16)}`};
    color: ${({ theme }) => theme.palette.main.secondaryText};
  }

  button {
    border-radius: 0;
    margin: 0;
    ${({ theme }) => theme.typography.bodySemibold as CSSObject};
    color: ${({ theme }) => theme.palette.main.secondaryText};

    &:hover {
      background-color: transparent;
    }

    &.Mui-selected {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.main.black};

      &:hover {
        background-color: transparent;
      }
    }
  }
`;
