import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import DtButton from '../dt-button/dt-button';

export const DtSnackbarContentContainer = styled('div')`
  width: 724px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.main.white};
  padding: ${({ theme }) => `${theme.spacing(20)} ${theme.spacing(24)}`};
  ${({ theme }) => theme.mixins.boxShadowMixin};
`;

export const SelectionSummaryTextContainer = styled('span')`
  margin-right: ${({ theme }) => theme.spacing(40)};
`;

export const SelectionSummaryLabel = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const SelectAllButton = styled(DtButton)`
  color: ${({ theme }) => theme.palette.main.primary};
`;

export const ActionsContainer = styled('div')`
  margin-left: auto;
  display: flex;
`;
