import { format } from 'date-fns';
import React from 'react';

import { CellSubtitle } from '../../../../../components/dt-table/table-cell.styled';
import { DtSupportedDispatchStatuses } from '../../../dt-dispatches.enums';
import DtDispatchStatusBadge from '../../dt-dispatch-status-badge/dt-dispatch-status-badge';

interface DtDispatchActionsTableCellProps {
  dueDate?: string;
  createdDate?: Date;
  completedDate?: Date;
  scheduledFrequency?: string;
  dtDispatchStatus: DtSupportedDispatchStatuses;
}

const DtDispatchStatusTableCell: React.FC<DtDispatchActionsTableCellProps> = ({
  dueDate,
  createdDate,
  completedDate,
  scheduledFrequency,
  dtDispatchStatus,
}) => {
  const isStatusScheduled = dtDispatchStatus === DtSupportedDispatchStatuses.Scheduled;
  const isStatusCompleted = dtDispatchStatus === DtSupportedDispatchStatuses.Completed;

  return (
    <div>
      <DtDispatchStatusBadge
        dtDispatchStatus={dtDispatchStatus}
        createdDate={createdDate}
        completedDate={completedDate}
      />
      <CellSubtitle subtitleBold={false}>
        {getStatusSubtitleLabel(dtDispatchStatus)}&nbsp;
        {isStatusScheduled ? (
          <CellSubtitle subtitleBold={true} style={{ display: 'inline' }}>
            {scheduledFrequency}
          </CellSubtitle>
        ) : isStatusCompleted ? (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          format(completedDate!, 'h:mm a')
        ) : (
          dueDate
        )}
      </CellSubtitle>
    </div>
  );
};

function getStatusSubtitleLabel(status: DtSupportedDispatchStatuses): string {
  switch (status) {
    case DtSupportedDispatchStatuses.Completed:
      return 'submitted at';
    case DtSupportedDispatchStatuses.Scheduled:
      return 'Frequency:';
    default:
      return 'due on';
  }
}

export default DtDispatchStatusTableCell;
