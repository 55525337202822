import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DtManageUsersRowItem, DtManageUsersState } from './dt-manage-users.interface';
import { DtRootState } from '../../../dt-store';
import { dtApiRepository } from '../../../repositories/dt-api.repository';

const initialState: DtManageUsersState = {
  status: 'waiting-to-load',
  users: [],
};

export const dtLoadUsers = createAsyncThunk(
  'dtManageUsers/dtLoadUsers',
  async (
    data: { companyId: number; siteId?: number; formType?: string },
    { getState }
  ): Promise<DtManageUsersRowItem[]> => {
    if (data) {
      const root = getState();

      const usersData = (
        await dtApiRepository.MeApi.userControllerGetAllCompanyUsers(data.companyId, data.siteId, data.formType)
      )?.data;

      if (usersData) {
        const users = usersData.map((item) =>
          item.email === (root as DtRootState).dtUser.data?.userEmail ? { ...item, isCurrentUser: true } : item
        );
        return users;
      }
    }
    return initialState.users;
  }
);

export const dtManageUsersSlice = createSlice({
  name: 'dtManageUsers',
  initialState,
  reducers: {
    dtSetUsersData: (state, action: PayloadAction<DtManageUsersRowItem[] | []>) => {
      state.users = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
    dtClearUsers: () => initialState,
    dtUpdateLoadingStatusOfUserRow: (state, action: PayloadAction<{ email: string; status: boolean }>) => {
      const dtUserRow = _.find(state.users, { email: action.payload.email });

      if (dtUserRow) {
        dtUserRow.isLoading = action.payload.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dtLoadUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dtLoadUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(dtLoadUsers.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = 'failed';
        }
      });
  },
});

export const dtSelectUsers = (state: DtRootState): DtManageUsersRowItem[] => state.dtManageUsers.users ?? [];
export const dtSelectUsersStatus = (state: DtRootState): DtStatus => state.dtManageUsers.status;
export const dtSelectUsersLoading = (state: DtRootState): boolean =>
  state.dtManageUsers.status === 'loading' || state.dtManageUsers.status === 'waiting-to-load';

export const { dtUpdateLoadingStatusOfUserRow, dtSetUsersData, dtClearUsers, dtSetLoadingStatus } =
  dtManageUsersSlice.actions;

export default dtManageUsersSlice.reducer;
