import { TextFieldProps } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { FormTextInputCustom } from './dt-form-text-input.styled';

/**
 * Common form text input component.
 * IMPORTANT: This component should be wrapped inside Formik.
 * @example
 * <Formik initialValues={{ test: '' }} validationSchema={validation} onSubmit={() => {}>
 *  {() => (
 *    <Form>
 *      <DtFormTextInput name='test' placeholder='Type Here' />
 *    </Form>
 *  )}
 * </Formik>
 */
const DtFormTextInput: React.FC<TextFieldProps> = ({ name, ...props }) => {
  const { setFieldValue, setFieldError } = useFormikContext();

  return (
    <Field
      id={name}
      name={name}
      component={FormTextInputCustom}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldError(e.target.name, undefined);
        setFieldValue(e.target.name, e.target.value);
      }}
      {...props}
    />
  );
};

export default DtFormTextInput;
