import { Box, LinearProgress, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';

import { DtInspectionDetailsContent } from './dt-inspection-details.styled';
import DtButton from '../../components/dt-button/dt-button';
import DtQuestionsTable from '../../components/dt-questions-table/dt-questions-table';
import DtQuestionsTableSkeleton from '../../components/dt-questions-table/dt-questions-table.skeleton';
import DtTable from '../../components/dt-table/dt-table';
import { DispatchInspectionCombinedDataDto } from '../../repositories/__generated__/v2';

const HUNDRED = 100;

interface DtInspectionDetailsProps {
  combinedData: DispatchInspectionCombinedDataDto | undefined;
  isLoading: boolean;
  offset: number;
  size: number;
  totalItems: number;
  onLoadMore: () => void;
}

const DtInspectionDetails: React.FC<React.PropsWithChildren<DtInspectionDetailsProps>> = (props) => {
  const itemsCouldBeOnTheCurrentPage = props.isLoading ? props.offset : props.offset + props.size;
  const hasPagesToLoad = itemsCouldBeOnTheCurrentPage < props.totalItems;

  const currentlyVisibleItems =
    props.totalItems <= itemsCouldBeOnTheCurrentPage ? props.totalItems : itemsCouldBeOnTheCurrentPage;
  const isFirstPage = props.offset === 0;
  const isFirstPageLoading = props.isLoading && isFirstPage;

  return (
    <>
      <DtInspectionDetailsContent>
        {isFirstPageLoading && <DtQuestionsTableSkeleton />}
        {!isFirstPageLoading && (isEmpty(props.combinedData?.answers) || isEmpty(props.combinedData?.questions)) && (
          <DtTable
            removeShadow
            rowHeight={0}
            columns={[]}
            rows={[]}
            tableHeight='calc(100vh - 255px)'
            tableMaxHeight='100%'
            dataGridHeight='100%'
          />
        )}
        {!isFirstPageLoading &&
          props.combinedData &&
          !isEmpty(props.combinedData.answers) &&
          !isEmpty(props.combinedData.questions) && <DtQuestionsTable combinedData={props.combinedData} />}
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          margin={12}
          marginBottom={16}
          gap={12}
        >
          <Typography variant='body' color='main.secondaryText'>
            {isFirstPageLoading
              ? `Loading Submissions`
              : `Showing ${currentlyVisibleItems} of ${props.totalItems} Submissions`}
          </Typography>
          <LinearProgress
            variant='buffer'
            value={(currentlyVisibleItems / props.totalItems) * HUNDRED}
            style={{ width: '200px', borderRadius: '4px', opacity: 0.6 }}
            valueBuffer={props.isLoading ? (currentlyVisibleItems / props.totalItems) * HUNDRED : HUNDRED}
          />
          <DtButton
            disabled={props.isLoading}
            variant='secondary'
            onClick={props.onLoadMore}
            padding={6}
            type='button'
            css={{ padding: '8px', visibility: hasPagesToLoad ? 'visible' : 'hidden' }}
          >
            Load More
          </DtButton>
        </Box>
      </DtInspectionDetailsContent>
    </>
  );
};
export default DtInspectionDetails;
