import { useFormikContext } from 'formik';
import React from 'react';

import { DialogActionsWrapper, DialogButton } from './dt-new-dispatch-modal-actions.styled';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';

interface DtNewDispatchModalActionsProps {
  onPreviousStep?: () => void;
  onNextStep?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const DtNewDispatchModalActions: React.FC<DtNewDispatchModalActionsProps> = ({
  onCancel,
  onPreviousStep,
  onNextStep,
  onSubmit,
}) => {
  const { isSubmitting } = useFormikContext<DtNewDispatchFormInitialValues>();

  return (
    <DialogActionsWrapper>
      {onPreviousStep && (
        <DialogButton variant='secondary' onClick={onPreviousStep} disabled={isSubmitting}>
          Back
        </DialogButton>
      )}
      {onCancel && (
        <DialogButton variant='secondary' onClick={onCancel}>
          Cancel
        </DialogButton>
      )}
      {onNextStep && (
        <DialogButton variant='primary' onClick={onNextStep}>
          Next
        </DialogButton>
      )}
      {onSubmit && (
        <DialogButton type='submit' variant='primary' disabled={isSubmitting} onClick={onSubmit}>
          Submit
        </DialogButton>
      )}
    </DialogActionsWrapper>
  );
};

export default DtNewDispatchModalActions;
