import { chain, isEqual, sortBy } from 'lodash';
import React, { useEffect } from 'react';

import { DtFilterPanelContainer, DtFiltersWrapper } from './dt-administration-filter-panel.styled';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import DtSelect from '../../../../components/dt-selects/dt-select/dt-select';
import { DtRootState } from '../../../../dt-store';
import { dtSelectFormTypesForFilter, dtSelectProperties } from '../../../dt-properties/dt-properties.slice';
import { dtSelectFormsLoading } from '../../dt-forms-tab/dt-forms.slice';
import { dtSelectUsersLoading } from '../../dt-manage-users-tab/dt-manage-users.slice';
import { dtSelectSitesLoading } from '../../dt-sites-tab/dt-sites.slice';

interface DtAdministrationFilterPanelProps {
  selectedSiteId: number | null;
  selectedFormType: string | null;
  setSelectedSiteId: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedFormType: React.Dispatch<React.SetStateAction<string | null>>;
}

const DtAdministrationFilterPanel: React.FC<DtAdministrationFilterPanelProps> = ({
  selectedSiteId,
  selectedFormType,
  setSelectedSiteId,
  setSelectedFormType,
}) => {
  const isSitesLoading = dtUseAppSelector(dtSelectSitesLoading);
  const isFormsLoading = dtUseAppSelector(dtSelectFormsLoading);
  const isUsersLoading = dtUseAppSelector(dtSelectUsersLoading);
  const isLoading = isSitesLoading || isFormsLoading || isUsersLoading;

  const propertiesOptions = dtUseAppSelector(dtSelectProperties, isEqual);

  const getFormTypesBySelectedProperties = dtUseAppSelector((state: DtRootState) =>
    dtSelectFormTypesForFilter(state, selectedSiteId)
  );

  useEffect(() => {
    setSelectedSiteId(null);
    setSelectedFormType(null);
  }, [propertiesOptions]);

  const sortedAvailableProperties = sortBy(
    propertiesOptions.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    'label'
  );

  const sortedAvailableFormTypes = chain(getFormTypesBySelectedProperties)
    .map((formType) => ({
      value: formType.id,
      label: formType.type,
    }))
    .uniqBy('value')
    .sortBy('label')
    .value();

  function handleSiteChange(selectedOption: number | null | undefined): void {
    setSelectedSiteId(selectedOption ?? null);
    resetOrKeepFormType(selectedOption);
  }

  function handleFormTypeChange(selectedOption: string | null | undefined): void {
    setSelectedFormType(selectedOption ?? null);
  }

  function resetOrKeepFormType(newProperty: number | null | undefined): void {
    if (!newProperty) {
      return;
    }
    if (selectedFormType) {
      const formTypes = propertiesOptions.find((i) => i.id === newProperty)?.formTypes ?? [];
      const selectedOptionHasSelectedFormType = formTypes.some((i) => i.id === selectedFormType);
      if (!selectedOptionHasSelectedFormType) {
        setSelectedFormType(null);
      }
      // otherwise - keep selected value
    }
  }

  return (
    <DtFilterPanelContainer>
      <DtFiltersWrapper>
        {sortedAvailableProperties.length > 1 && (
          <DtSelect<number | null>
            disabled={isLoading}
            value={selectedSiteId}
            label='Property'
            labelPosition='inside'
            options={sortedAvailableProperties}
            onChange={handleSiteChange}
            placeholder='Any'
            fullWidth
          />
        )}
        <DtSelect<string | null>
          disabled={isLoading}
          value={selectedFormType}
          label='Type'
          labelPosition='inside'
          options={sortedAvailableFormTypes}
          onChange={handleFormTypeChange}
          placeholder='Any'
          fullWidth
        />
      </DtFiltersWrapper>
    </DtFilterPanelContainer>
  );
};

export default DtAdministrationFilterPanel;
