import {
  dtSetLoadingStatus,
  dtSetOrganizationsData,
  dtUpdateLoadingStatusOfOrganizationRow,
} from './dt-organizations.slice';
import { DtAppThunk } from '../../../dt-store';
import { dtApiRepository } from '../../../repositories/dt-api.repository';
import { dtToastService } from '../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../dt-user/dt-user.actions';
import { dtSetUserCompanyData } from '../../dt-user/dt-user.slice';

export const dtLoadOrganizationsAction = (): DtAppThunk => async (dispatch) => {
  try {
    dispatch(dtSetLoadingStatus('loading'));

    const organizationsData =
      (await dtApiRepository.AdminCompanyApi.adminCompanyControllerGetAllCompanies())?.data ?? [];

    dispatch(dtSetOrganizationsData(organizationsData));
  } catch (error) {
    dtToastService.error('Failed to load organizations');
  }
};

export const dtLoadOrganizationsActionIfNeeded = (): DtAppThunk => async (dispatch, getState) => {
  const root = getState();

  if (root.dtOrganizations.status === 'waiting-to-load') {
    dispatch(dtLoadOrganizationsAction());
  }
};

export const dtDeleteOrganizationAction =
  (id: number): DtAppThunk =>
  async (dispatch, getState) => {
    const root = getState();

    try {
      dispatch(dtUpdateLoadingStatusOfOrganizationRow({ id, status: true }));

      await dtApiRepository.AdminCompanyApi.adminCompanyControllerDelete(id);

      const organizations = root.dtUser.companyData?.filter((item) => item.id !== id) ?? null;
      dispatch(dtSetUserCompanyData(organizations));

      dtToastService.success('Successfully deleted organization');
      dispatch(dtRefreshCurrentUserData());
    } catch (e) {
      dispatch(dtUpdateLoadingStatusOfOrganizationRow({ id, status: false }));
      dtToastService.error('Failed to delete organization');
    }
  };
