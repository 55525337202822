import React from 'react';

import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { ActionSkeletonWrapper } from '../../../../components/dt-table/dt-actions-table-cell.styled';

interface DtTableCellActionsSkeletonProps {
  buttonsNumber?: number;
}

const DtTableCellActionsSkeleton: React.FC<DtTableCellActionsSkeletonProps> = ({ buttonsNumber = 1 }) => {
  return (
    <ActionSkeletonWrapper gap={20}>
      {[...Array(buttonsNumber)].map((_, i) => (
        <DtSkeleton key={i} variant='rectangular' width={20} height={20} />
      ))}
    </ActionSkeletonWrapper>
  );
};

export default DtTableCellActionsSkeleton;
