import { Typography } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import DtButton from '../../../../../components/dt-button/dt-button';
import DtIcon from '../../../../../components/dt-icon/dt-icon';

export const NavbarButton = styled(DtButton, {
  shouldForwardProp: (prop) => prop !== 'extended' && prop !== 'isCompanySelectFieldShown',
})<{
  isCompanySelectFieldShown?: boolean;
}>`
  width: 100%;
  margin-top: ${({ theme, isCompanySelectFieldShown }) =>
    isCompanySelectFieldShown ? `${theme.spacing(24)}` : `${theme.spacing(0)}`};
  margin-bottom: ${({ theme }) => theme.spacing(24)};
  overflow: hidden;
`;

export const UpperContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'extended' && prop !== 'isCompanySelectFieldShown',
})<{
  extended: boolean;
  isCompanySelectFieldShown?: boolean;
}>`
  display: flex;
  margin-bottom: ${({ isCompanySelectFieldShown, theme }) =>
    isCompanySelectFieldShown ? `${theme.spacing(19)}` : `${theme.spacing(40)}`};
  overflow: ${({ extended }) => (extended ? 'visible' : 'hidden')};

  a {
    display: flex;
    text-decoration: none;
    align-items: center;
    overflow: hidden;
  }
`;

export const Logo = styled('img')`
  height: 32px;
  width: auto;
`;

export const LogoText = styled('img', { shouldForwardProp: (prop) => prop !== 'extended' })<{ extended: boolean }>`
  width: ${({ extended }) => (extended ? '60px' : '0')};
  height: 15px;
  margin-left: ${({ theme }) => theme.spacing(8)};
  object-fit: cover;
  transition:
    width 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-left 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const AddDispatchIcon = styled(DtIcon, { shouldForwardProp: (prop) => prop !== 'extended' })<{
  extended: boolean;
}>`
  color: ${({ theme }) => theme.palette.main.white};
  margin-right: ${({ extended }) => (extended ? '8px' : 0)};
  transition: margin-right 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const AddDispatchText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'extended' })<{
  extended: boolean;
}>`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
  max-width: ${({ extended }) => (extended ? '100px' : '0')};
  white-space: nowrap;
  overflow: ${({ extended }) => (extended ? 'visible' : 'hidden')};
  transition:
    max-width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    overflow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const DtCreateNewOrganization = styled('span')`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.main.primary};
`;
