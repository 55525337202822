import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import { UploadButtonContainer } from './dt-upload-button.styled';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';

export interface DtUploadFileProps {
  fileName?: string;
  onChange: (file: File | null) => void;
  error?: string;
  fullWidth?: boolean;
}

const DtUploadFile: React.FC<DtUploadFileProps> = ({ onChange, fileName, error, fullWidth }) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [internalError, setInternalError] = useState<string>();
  const hasFile = fileRef?.current?.value || fileName;

  function onFileChange(event?: React.ChangeEvent<HTMLInputElement>): void {
    const file = event?.target?.files?.item(0) ?? null;

    // Handle flow when file size is too big
    // eslint-disable-next-line no-magic-numbers
    const maximumSize = 5 * 1024 * 1024; // In MegaBytes
    if ((file?.size ?? 0) >= maximumSize) {
      if (Boolean(fileRef?.current?.value)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fileRef!.current!.value = '';
      }
      onChange(null);
      setInternalError('Max file size is 5Mb');
      return;
    }
    setInternalError(undefined);

    if (!file && Boolean(fileRef?.current?.value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fileRef!.current!.value = '';
    }

    onChange(file);
  }

  return (
    <UploadButtonContainer hasFile={Boolean(hasFile)} hasError={Boolean(internalError || error)} fullWidth={fullWidth}>
      <input
        type='file'
        accept='application/pdf,application/msword,application/vnd.ms-excel'
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      {hasFile ? (
        <>
          <Typography
            variant='body'
            marginRight={16}
            style={{
              flex: 'auto',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {fileName}
          </Typography>
          <DtButton variant='transparent' onClick={() => onFileChange(undefined)} padding={4}>
            <DtIcon icon='trash' size={14} />
          </DtButton>
        </>
      ) : (
        <Typography variant='bodyBold' color={(theme) => theme.palette.main.primary}>
          Browse
        </Typography>
      )}
      <p className='error'>{internalError || error}</p>
    </UploadButtonContainer>
  );
};

export default DtUploadFile;
