import { Box } from '@mui/material';
import React from 'react';

import {
  DialogActionsContainer,
  DialogContainer,
  DialogContentContainer,
  DialogTitleContainer,
} from './dt-dispatch-details-modal.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';

const DtDispatchDetailsModalSkeleton: React.FC = () => {
  const dialogContentSummaryRows = Array.from({ length: 5 }, (_, index) => (
    <Box key={`summary-row-item-${index}`} marginBottom={22}>
      <DtSkeleton variant='text' height={24} width='100%' />
    </Box>
  ));

  return (
    <DialogContainer open={true}>
      <DialogTitleContainer>
        <section>
          <DtSkeleton variant='text' height={22} width={400} />
          <DtSkeleton variant='text' height={18} width={400} />
        </section>
        <section>
          <DtSkeleton variant='rectangular' height={40} width={40} />
        </section>
      </DialogTitleContainer>

      <DialogContentContainer>
        <Box marginBottom={32} marginTop={26} display='flex' justifyContent='center'>
          <DtSkeleton variant='rectangular' height={40} width={312} />
        </Box>
        {dialogContentSummaryRows}
      </DialogContentContainer>

      <DialogActionsContainer>
        <Box marginRight={24}>
          <DtSkeleton variant='rectangular' height={40} width={128} />
        </Box>
        <DtSkeleton variant='rectangular' height={40} width={128} />
      </DialogActionsContainer>
    </DialogContainer>
  );
};

export default DtDispatchDetailsModalSkeleton;
