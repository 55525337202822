import { styled, CSSObject } from '@mui/material/styles';

export const CellTitle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'titleBold',
})<{ titleBold: boolean }>`
  ${({ theme, titleBold }) =>
    titleBold ? (theme.typography.bodySemibold as CSSObject) : (theme.typography.body as CSSObject)}
  color: ${({ theme }) => theme.palette.main.black};
`;

export const CellSubtitle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'subtitleBold',
})<{ subtitleBold: boolean }>`
  ${({ theme, subtitleBold }) =>
    subtitleBold ? (theme.typography.bodySmallSemibold as CSSObject) : (theme.typography.bodySmall as CSSObject)}
  color: ${({ theme }) => theme.palette.main.secondaryText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;
