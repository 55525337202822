import { Grid } from '@mui/material';
import React, { ReactNode, SyntheticEvent } from 'react';

import { DtTabItem } from './dt-tabs.interfaces';
import { TabButton, TabsContainer, TabsItems } from './dt-tabs.styled';

export interface DtTabsProps {
  children?: ReactNode;
  currentActiveTab: string;
  tabs: DtTabItem[];
  disabled?: boolean;
  setCurrentActiveTab: (newActiveTab: string) => void;
}

const DtTabs: React.FC<DtTabsProps> = ({ children, currentActiveTab, tabs, disabled, setCurrentActiveTab }) => {
  function handleTabValue(_: SyntheticEvent, newActiveTab: string): void {
    setCurrentActiveTab(newActiveTab);
  }

  return (
    <TabsContainer container>
      <Grid item xs={12}>
        <TabsItems value={currentActiveTab} onChange={handleTabValue}>
          {tabs.map(({ value, label }) => (
            <TabButton disabled={disabled} key={value} value={value} label={label} />
          ))}
        </TabsItems>
        {children}
      </Grid>
    </TabsContainer>
  );
};

export default DtTabs;
