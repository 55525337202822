import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes } from '../../dt-enums';
import { dtIsUserHaveAccessToAssets } from '../../modules/dt-navigation/components/dt-navbar/dt-menu/utils/dt-menu.utils';
import { dtSelectProperties } from '../../modules/dt-properties/dt-properties.slice';
import { dtSelectUser } from '../../modules/dt-user/dt-user.slice';

const DtAssetsRoute: React.FC = () => {
  const currentUserEmail = dtUseAppSelector(dtSelectUser)?.userEmail;
  const availableProperties = dtUseAppSelector(dtSelectProperties);
  const isAssetsPageAvailable = dtIsUserHaveAccessToAssets(availableProperties, currentUserEmail);

  if (!isAssetsPageAvailable) {
    return <Navigate to={DtAppRoutes.DashboardOpen} />;
  }

  return <Outlet />;
};

export default DtAssetsRoute;
