import { isEmpty } from 'lodash';

import { dtApiRepository } from '../../repositories/dt-api.repository';
import { dtAppLifecycleService } from '../../services/dt-app-lifecycle.service';
import { dtToastService } from '../../services/dt-toast.service';
import { dtPropertiesService } from '../dt-properties/dt-properties.service';

interface MeData {
  currentUser: DtUser | null;
  companyData: DtCompanyData[];
  defaultCompany: DtCompanyData | null;
}

/**
 * Service for performing side-effects with API-repositories entities
 */

class DtUserService {
  /**
   * Method to get authenticated DtUser data
   */
  async getAuthenticatedUserData(reconnect?: boolean): Promise<MeData> {
    try {
      const resp = await dtApiRepository.GeneralApi.generalControllerGetMe(reconnect ?? false);

      if (!resp.data) {
        throw new Error('Failed to get User and properties');
      }

      const companyData = dtPropertiesService.mapFormCompanyDataToDtCompanyData(resp.data.companyData) ?? [];

      const getCompanyIdFromStorage = window.sessionStorage.getItem('SELECTED_COMPANY');
      const companyByStoredId = companyData.find((company) => company.id === Number(getCompanyIdFromStorage)) ?? null;

      const defaultCompany = getCompanyIdFromStorage
        ? companyByStoredId
        : companyData.find((data) => !isEmpty(data.propertyList)) ?? null;

      const currentUser = {
        id: resp.data.userId,
        companyId: defaultCompany?.id as number,
        pfName: '',
        userEmail: resp.data.email,
        firstName: resp.data.firstName,
        lastName: resp.data.lastName,
        phone: '',
        password: '',
        userRole: resp.data.role,
        isActive: true,
      };

      return { companyData, currentUser, defaultCompany };
    } catch (e) {
      dtToastService.error('Failed to load user.');
      await dtAppLifecycleService.logout();
      throw e;
    }
  }
}

export const dtUserService = new DtUserService();
