import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';

import { FieldContainer } from './dt-new-dispatch-general-information.styled';
import { dtUseAppSelector } from '../../../../../../cdk/hooks/dt-store.hooks';
import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { DtRootState } from '../../../../../../dt-store';
import {
  dtSelectAvailableAssetsByFormSubtype,
  dtSelectAvailableFormSubtypesBySelectedType,
  dtSelectAvailableFormTypesBySelectedProperty,
  dtSelectProperties,
} from '../../../../../dt-properties/dt-properties.slice';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';

interface DtNewDispatchGeneralInformationProps {
  isEditFlow: boolean;
}

const DtNewDispatchGeneralInformation: React.FC<DtNewDispatchGeneralInformationProps> = ({ isEditFlow }) => {
  const { setFieldValue, values, resetForm } = useFormikContext<DtNewDispatchFormInitialValues>();

  const getAllProperties = dtUseAppSelector(dtSelectProperties);
  const getAvailableFormTypesBySelectedProperty = dtUseAppSelector((state: DtRootState) =>
    dtSelectAvailableFormTypesBySelectedProperty(state, values.generalInfo.site)
  );
  const getAvailableFormSubtypesBySelectedType = dtUseAppSelector((state: DtRootState) =>
    dtSelectAvailableFormSubtypesBySelectedType(state, values.generalInfo.formType, values.generalInfo.site)
  );
  const getAvailableAssetsBySelectedSubtype = dtUseAppSelector((state: DtRootState) =>
    dtSelectAvailableAssetsByFormSubtype(
      state,
      values.generalInfo.formSubtype,
      values.generalInfo.site,
      values.generalInfo.formType
    )
  );

  const sortedAvailableProperties = sortBy(
    getAllProperties.map((property) => ({
      value: property.id ?? 0,
      label: property.name,
    })),
    'label'
  );

  const sortedAvailableFormTypes = sortBy(
    getAvailableFormTypesBySelectedProperty.map((formType) => ({
      value: formType.id,
      label: formType.type,
    })),
    'label'
  );

  const sortedAvailableFormSubtypes = sortBy(
    getAvailableFormSubtypesBySelectedType.map((subtype) => ({
      value: subtype.id,
      label: subtype.name,
    })),
    'label'
  );

  const sortedAvailableFormAssets = sortBy(
    getAvailableAssetsBySelectedSubtype.map((asset) => ({
      value: asset.rowId.toString(),
      label: asset.assetName,
    })),
    'label'
  );

  /**
   * This is a workaround to set the value of the hasAssets field
   */
  const hasAssets = Boolean(sortedAvailableFormAssets.length > 0 && values.generalInfo.formSubtype);
  useEffect(() => {
    setFieldValue('generalInfo.hasAssets', hasAssets);
  }, [hasAssets]);

  function handleSiteValueChange(value: number | undefined): void {
    // setFieldValue('site', value ?? '');
    resetForm({
      values: {
        ...values,
        generalInfo: {
          ...values.generalInfo,
          site: value ?? undefined,
          formType: '',
          formSubtype: undefined,
          assets: [],
        },
      },
    });
  }

  function handleFormTypeValueChange(value: string | null | undefined): void {
    // setFieldValue('formType', value ?? '');
    resetForm({
      values: {
        ...values,
        generalInfo: { ...values.generalInfo, formType: value ?? '', formSubtype: undefined, assets: [] },
      },
    });
  }

  function handleFormSubtypeValueChange(value: number | undefined): void {
    // setFieldValue('formSubtype', value ?? '');
    resetForm({
      values: { ...values, generalInfo: { ...values.generalInfo, formSubtype: value ?? undefined, assets: [] } },
    });
  }

  return (
    <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
      <Grid item xs={12}>
        <DtFormikSelect<number>
          onChange={handleSiteValueChange}
          label='Site'
          name='generalInfo.site'
          labelPosition='top'
          options={sortedAvailableProperties}
          placeholder='Any'
          fullWidth={true}
          disabled={isEditFlow}
        />
      </Grid>

      <FieldContainer item xs={6}>
        <DtFormikSelect<string | null>
          onChange={handleFormTypeValueChange}
          label='Task Type'
          name='generalInfo.formType'
          labelPosition='top'
          options={sortedAvailableFormTypes}
          placeholder='Any'
          fullWidth={true}
          disabled={!values.generalInfo.site || isEditFlow}
        />
      </FieldContainer>

      <FieldContainer item xs={6}>
        <DtFormikSelect<number>
          onChange={handleFormSubtypeValueChange}
          label='Task Subtype'
          name='generalInfo.formSubtype'
          labelPosition='top'
          options={sortedAvailableFormSubtypes}
          placeholder='Any'
          fullWidth={true}
          disabled={!values.generalInfo.formType || isEditFlow}
        />
      </FieldContainer>

      {hasAssets && (
        <Grid item xs={12}>
          <DtFormikMultipleSelect
            label='Assets'
            name='generalInfo.assets'
            labelPosition='top'
            options={sortedAvailableFormAssets}
            placeholder='Select Assets'
            fullWidth={true}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DtNewDispatchGeneralInformation;
