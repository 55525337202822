import React from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

import { dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes } from '../../dt-enums';
import { dtRouter } from '../../dt-routes';
import { dtSelectUserRole } from '../../modules/dt-user/dt-user.slice';
import { UserRole } from '../../repositories/__generated__/v2';

const DtAdministrationRoute: React.FC<RouteProps> = () => {
  const pathname = dtRouter.state.location.pathname;

  const userRole = dtUseAppSelector(dtSelectUserRole);

  if (userRole !== UserRole.SuperAdmin && pathname.includes('administration')) {
    return <Navigate to={DtAppRoutes.DashboardOpen} />;
  }

  return <Outlet />;
};

export default DtAdministrationRoute;
