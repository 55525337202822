import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';

import DtSkeleton from '../../../../../components/dt-skeleton/dt-skeleton';
import { CellSubtitle, CellTitle } from '../../../../../components/dt-table/table-cell.styled';
import DtTableCellActionsSkeleton from '../../../components/dt-table-cell-actions-skeleton/dt-table-cell-actions-skeleton';
import { CellBadgeTitle, CellBadgeWrapper } from '../../../components/dt-table-cell-badge/dt-table-cell-badge.styled';
import { CellContentWrapper } from '../../../components/dt-table-cell-content-wrapper/dt-table-cell-content-wrapper.styled';
import DtTableCellSkeleton from '../../../components/dt-table-cell-skeleton/dt-table-cell-skeleton';
import { DT_ACTIONS_COLUMN_WIDTH } from '../../../dt-administration.constants';
import DtSitesTableCellActions from '../dt-sites-table-cell-actions/dt-sites-table-cell-actions';

export function dtGenerateSitesTableColumnsConfig(isLoading: boolean): GridColDef[] {
  return [
    {
      field: 'site name',
      headerName: 'Site Name',
      flex: 1.2,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.name || params.row.state || params.row.city,
      renderCell: (params: GridRenderCellParams) => {
        const subtitle = `${params.row.city}, ${params.row.state}`;

        return isLoading || params.row.isLoading ? (
          <DtTableCellSkeleton titleHeight={20} titleWidth={200} subtitleHeight={16} subtitleWidth={200} />
        ) : (
          <CellContentWrapper>
            <CellTitle titleBold={true}>{params.row.name}</CellTitle>
            <CellSubtitle subtitleBold={false}>{subtitle}</CellSubtitle>
          </CellContentWrapper>
        );
      },
    },
    {
      field: 'site users',
      headerName: 'Users',
      flex: 0.75,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.amountOfUsers,
      renderCell: (params: GridRenderCellParams) => {
        const usersLabel = params.row.amountOfUsers === 1 ? 'user' : 'users';

        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' height={24} width={78} />
        ) : (
          <CellBadgeWrapper>
            <CellBadgeTitle>
              {params.row.amountOfUsers}&nbsp;{usersLabel}
            </CellBadgeTitle>
          </CellBadgeWrapper>
        );
      },
    },
    {
      field: 'site types',
      headerName: 'Types',
      flex: 0.75,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.amountOfTypes,
      renderCell: (params: GridRenderCellParams) => {
        const typesLabel = params.row.amountOfTypes === 1 ? 'type' : 'types';

        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' height={24} width={78} />
        ) : (
          <CellBadgeWrapper>
            <CellBadgeTitle>
              {params.row.amountOfTypes}&nbsp;{typesLabel}
            </CellBadgeTitle>
          </CellBadgeWrapper>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: DT_ACTIONS_COLUMN_WIDTH,
      sortable: false,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return isLoading || params.row.isLoading ? (
          <DtTableCellActionsSkeleton />
        ) : (
          <DtSitesTableCellActions siteId={params.row.id} />
        );
      },
    },
  ];
}
