/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DataRangeRequired } from '../entities';
// @ts-ignore
import { DispatchAssigneeMutationDto } from '../entities';
// @ts-ignore
import { DispatchCreationDto } from '../entities';
// @ts-ignore
import { DispatchDetailsDto } from '../entities';
// @ts-ignore
import { DispatchInspectionDetailsDto } from '../entities';
// @ts-ignore
import { DispatchMutationDto } from '../entities';
// @ts-ignore
import { DispatchesCountDto } from '../entities';
// @ts-ignore
import { FormDataFile } from '../entities';
// @ts-ignore
import { FormDispatchGroup } from '../entities';
// @ts-ignore
import { FormFilter } from '../entities';
// @ts-ignore
import { FormSchemaViewDto } from '../entities';
// @ts-ignore
import { FormSearchSummaryDTO } from '../entities';
// @ts-ignore
import { ScheduleBasicDTO } from '../entities';
// @ts-ignore
import { ScheduledFilter } from '../entities';
/**
 * DispatchApi - axios parameter creator
 * @export
 */
export const DispatchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DispatchCreationDto} dispatchCreationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerCreate: async (
      dispatchCreationDto: DispatchCreationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dispatchCreationDto' is not null or undefined
      assertParamExists('dispatchControllerCreate', 'dispatchCreationDto', dispatchCreationDto);
      const localVarPath = `/dispatch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dispatchCreationDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FormSchemaViewDto} formSchemaViewDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerCreateInspectionSchemaConfig: async (
      formSchemaViewDto: FormSchemaViewDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formSchemaViewDto' is not null or undefined
      assertParamExists('dispatchControllerCreateInspectionSchemaConfig', 'formSchemaViewDto', formSchemaViewDto);
      const localVarPath = `/dispatch/inspection/schema-config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formSchemaViewDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDelete: async (
      formDispatchGroup: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formDispatchGroup' is not null or undefined
      assertParamExists('dispatchControllerDelete', 'formDispatchGroup', formDispatchGroup);
      const localVarPath = `/dispatch/{formDispatchGroup}`.replace(
        `{${'formDispatchGroup'}}`,
        encodeURIComponent(String(formDispatchGroup))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteCompleted: async (
      formDispatchGroup: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formDispatchGroup' is not null or undefined
      assertParamExists('dispatchControllerDeleteCompleted', 'formDispatchGroup', formDispatchGroup);
      const localVarPath = `/dispatch/completed/{formDispatchGroup}`.replace(
        `{${'formDispatchGroup'}}`,
        encodeURIComponent(String(formDispatchGroup))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteInspectionSchemaConfig: async (
      propertyId: number,
      formId: number,
      viewName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('dispatchControllerDeleteInspectionSchemaConfig', 'propertyId', propertyId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('dispatchControllerDeleteInspectionSchemaConfig', 'formId', formId);
      // verify required parameter 'viewName' is not null or undefined
      assertParamExists('dispatchControllerDeleteInspectionSchemaConfig', 'viewName', viewName);
      const localVarPath = `/dispatch/property/{propertyId}/inspection/{formId}/schema-config/{viewName}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'formId'}}`, encodeURIComponent(String(formId)))
        .replace(`{${'viewName'}}`, encodeURIComponent(String(viewName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteScheduled: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dispatchControllerDeleteScheduled', 'id', id);
      const localVarPath = `/dispatch/scheduled/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDownloadReport: async (
      formDispatchGroup: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formDispatchGroup' is not null or undefined
      assertParamExists('dispatchControllerDownloadReport', 'formDispatchGroup', formDispatchGroup);
      const localVarPath = `/dispatch/download-report/{formDispatchGroup}`.replace(
        `{${'formDispatchGroup'}}`,
        encodeURIComponent(String(formDispatchGroup))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {DataRangeRequired} dataRangeRequired
     * @param {number} [propertyId]
     * @param {number} [formId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetDashboardDispatchesCount: async (
      companyId: number,
      dataRangeRequired: DataRangeRequired,
      propertyId?: number,
      formId?: number,
      formType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('dispatchControllerGetDashboardDispatchesCount', 'companyId', companyId);
      // verify required parameter 'dataRangeRequired' is not null or undefined
      assertParamExists('dispatchControllerGetDashboardDispatchesCount', 'dataRangeRequired', dataRangeRequired);
      const localVarPath = `/dispatch/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (formId !== undefined) {
        localVarQueryParameter['formId'] = formId;
      }

      if (formType !== undefined) {
        localVarQueryParameter['formType'] = formType;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataRangeRequired, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetDispatch: async (
      formDispatchGroup: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formDispatchGroup' is not null or undefined
      assertParamExists('dispatchControllerGetDispatch', 'formDispatchGroup', formDispatchGroup);
      const localVarPath = `/dispatch/{formDispatchGroup}`.replace(
        `{${'formDispatchGroup'}}`,
        encodeURIComponent(String(formDispatchGroup))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {number} size
     * @param {number} offset
     * @param {string} viewName
     * @param {string} assetName
     * @param {DataRangeRequired} dataRangeRequired
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspection: async (
      propertyId: number,
      formId: number,
      size: number,
      offset: number,
      viewName: string,
      assetName: string,
      dataRangeRequired: DataRangeRequired,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'propertyId', propertyId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'formId', formId);
      // verify required parameter 'size' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'size', size);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'offset', offset);
      // verify required parameter 'viewName' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'viewName', viewName);
      // verify required parameter 'assetName' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'assetName', assetName);
      // verify required parameter 'dataRangeRequired' is not null or undefined
      assertParamExists('dispatchControllerGetInspection', 'dataRangeRequired', dataRangeRequired);
      const localVarPath = `/dispatch/property/{propertyId}/inspection/{formId}/content`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'formId'}}`, encodeURIComponent(String(formId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (viewName !== undefined) {
        localVarQueryParameter['viewName'] = viewName;
      }

      if (assetName !== undefined) {
        localVarQueryParameter['assetName'] = assetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataRangeRequired, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspectionSchemaConfig: async (
      propertyId: number,
      formId: number,
      viewName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('dispatchControllerGetInspectionSchemaConfig', 'propertyId', propertyId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('dispatchControllerGetInspectionSchemaConfig', 'formId', formId);
      // verify required parameter 'viewName' is not null or undefined
      assertParamExists('dispatchControllerGetInspectionSchemaConfig', 'viewName', viewName);
      const localVarPath = `/dispatch/property/{propertyId}/inspection/{formId}/schema-config`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'formId'}}`, encodeURIComponent(String(formId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (viewName !== undefined) {
        localVarQueryParameter['viewName'] = viewName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspectionSchemaConfigNames: async (
      propertyId: number,
      formId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('dispatchControllerGetInspectionSchemaConfigNames', 'propertyId', propertyId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists('dispatchControllerGetInspectionSchemaConfigNames', 'formId', formId);
      const localVarPath = `/dispatch/property/{propertyId}/inspection/{formId}/schema-config-names`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'formId'}}`, encodeURIComponent(String(formId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetScheduled: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dispatchControllerGetScheduled', 'id', id);
      const localVarPath = `/dispatch/scheduled/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FormFilter} formFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerSearchForm: async (
      formFilter: FormFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formFilter' is not null or undefined
      assertParamExists('dispatchControllerSearchForm', 'formFilter', formFilter);
      const localVarPath = `/dispatch/search-form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formFilter, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ScheduledFilter} scheduledFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerSearchScheduled: async (
      scheduledFilter: ScheduledFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'scheduledFilter' is not null or undefined
      assertParamExists('dispatchControllerSearchScheduled', 'scheduledFilter', scheduledFilter);
      const localVarPath = `/dispatch/search-scheduled`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(scheduledFilter, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {DispatchMutationDto} dispatchMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerUpdate: async (
      id: number,
      dispatchMutationDto: DispatchMutationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dispatchControllerUpdate', 'id', id);
      // verify required parameter 'dispatchMutationDto' is not null or undefined
      assertParamExists('dispatchControllerUpdate', 'dispatchMutationDto', dispatchMutationDto);
      const localVarPath = `/dispatch/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dispatchMutationDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {DispatchAssigneeMutationDto} dispatchAssigneeMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerUpdateAssignee: async (
      id: string,
      dispatchAssigneeMutationDto: DispatchAssigneeMutationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dispatchControllerUpdateAssignee', 'id', id);
      // verify required parameter 'dispatchAssigneeMutationDto' is not null or undefined
      assertParamExists('dispatchControllerUpdateAssignee', 'dispatchAssigneeMutationDto', dispatchAssigneeMutationDto);
      const localVarPath = `/dispatch/{id}/assignee`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dispatchAssigneeMutationDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DispatchApi - functional programming interface
 * @export
 */
export const DispatchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DispatchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {DispatchCreationDto} dispatchCreationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerCreate(
      dispatchCreationDto: DispatchCreationDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerCreate(dispatchCreationDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FormSchemaViewDto} formSchemaViewDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerCreateInspectionSchemaConfig(
      formSchemaViewDto: FormSchemaViewDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerCreateInspectionSchemaConfig(
        formSchemaViewDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerDelete(
      formDispatchGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerDelete(formDispatchGroup, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerDeleteCompleted(
      formDispatchGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerDeleteCompleted(
        formDispatchGroup,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerDeleteInspectionSchemaConfig(
      propertyId: number,
      formId: number,
      viewName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerDeleteInspectionSchemaConfig(
        propertyId,
        formId,
        viewName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerDeleteScheduled(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerDeleteScheduled(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerDownloadReport(
      formDispatchGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDataFile>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerDownloadReport(
        formDispatchGroup,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} companyId
     * @param {DataRangeRequired} dataRangeRequired
     * @param {number} [propertyId]
     * @param {number} [formId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetDashboardDispatchesCount(
      companyId: number,
      dataRangeRequired: DataRangeRequired,
      propertyId?: number,
      formId?: number,
      formType?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatchesCountDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetDashboardDispatchesCount(
        companyId,
        dataRangeRequired,
        propertyId,
        formId,
        formType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetDispatch(
      formDispatchGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDispatchGroup>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetDispatch(
        formDispatchGroup,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {number} size
     * @param {number} offset
     * @param {string} viewName
     * @param {string} assetName
     * @param {DataRangeRequired} dataRangeRequired
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetInspection(
      propertyId: number,
      formId: number,
      size: number,
      offset: number,
      viewName: string,
      assetName: string,
      dataRangeRequired: DataRangeRequired,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatchInspectionDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetInspection(
        propertyId,
        formId,
        size,
        offset,
        viewName,
        assetName,
        dataRangeRequired,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetInspectionSchemaConfig(
      propertyId: number,
      formId: number,
      viewName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormSchemaViewDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetInspectionSchemaConfig(
        propertyId,
        formId,
        viewName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetInspectionSchemaConfigNames(
      propertyId: number,
      formId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetInspectionSchemaConfigNames(
        propertyId,
        formId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerGetScheduled(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatchDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerGetScheduled(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FormFilter} formFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerSearchForm(
      formFilter: FormFilter,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormSearchSummaryDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerSearchForm(formFilter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ScheduledFilter} scheduledFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerSearchScheduled(
      scheduledFilter: ScheduledFilter,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleBasicDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerSearchScheduled(
        scheduledFilter,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {DispatchMutationDto} dispatchMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerUpdate(
      id: number,
      dispatchMutationDto: DispatchMutationDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerUpdate(
        id,
        dispatchMutationDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {DispatchAssigneeMutationDto} dispatchAssigneeMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dispatchControllerUpdateAssignee(
      id: string,
      dispatchAssigneeMutationDto: DispatchAssigneeMutationDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchControllerUpdateAssignee(
        id,
        dispatchAssigneeMutationDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DispatchApi - factory interface
 * @export
 */
export const DispatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DispatchApiFp(configuration);
  return {
    /**
     *
     * @param {DispatchCreationDto} dispatchCreationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerCreate(dispatchCreationDto: DispatchCreationDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerCreate(dispatchCreationDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FormSchemaViewDto} formSchemaViewDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerCreateInspectionSchemaConfig(
      formSchemaViewDto: FormSchemaViewDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerCreateInspectionSchemaConfig(formSchemaViewDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDelete(formDispatchGroup: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerDelete(formDispatchGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteCompleted(formDispatchGroup: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerDeleteCompleted(formDispatchGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteInspectionSchemaConfig(
      propertyId: number,
      formId: number,
      viewName: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerDeleteInspectionSchemaConfig(propertyId, formId, viewName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDeleteScheduled(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.dispatchControllerDeleteScheduled(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerDownloadReport(formDispatchGroup: string, options?: any): AxiosPromise<FormDataFile> {
      return localVarFp
        .dispatchControllerDownloadReport(formDispatchGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {DataRangeRequired} dataRangeRequired
     * @param {number} [propertyId]
     * @param {number} [formId]
     * @param {string} [formType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetDashboardDispatchesCount(
      companyId: number,
      dataRangeRequired: DataRangeRequired,
      propertyId?: number,
      formId?: number,
      formType?: string,
      options?: any
    ): AxiosPromise<DispatchesCountDto> {
      return localVarFp
        .dispatchControllerGetDashboardDispatchesCount(
          companyId,
          dataRangeRequired,
          propertyId,
          formId,
          formType,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formDispatchGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetDispatch(formDispatchGroup: string, options?: any): AxiosPromise<FormDispatchGroup> {
      return localVarFp
        .dispatchControllerGetDispatch(formDispatchGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {number} size
     * @param {number} offset
     * @param {string} viewName
     * @param {string} assetName
     * @param {DataRangeRequired} dataRangeRequired
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspection(
      propertyId: number,
      formId: number,
      size: number,
      offset: number,
      viewName: string,
      assetName: string,
      dataRangeRequired: DataRangeRequired,
      options?: any
    ): AxiosPromise<DispatchInspectionDetailsDto> {
      return localVarFp
        .dispatchControllerGetInspection(
          propertyId,
          formId,
          size,
          offset,
          viewName,
          assetName,
          dataRangeRequired,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {string} viewName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspectionSchemaConfig(
      propertyId: number,
      formId: number,
      viewName: string,
      options?: any
    ): AxiosPromise<FormSchemaViewDto> {
      return localVarFp
        .dispatchControllerGetInspectionSchemaConfig(propertyId, formId, viewName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} propertyId
     * @param {number} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetInspectionSchemaConfigNames(
      propertyId: number,
      formId: number,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .dispatchControllerGetInspectionSchemaConfigNames(propertyId, formId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerGetScheduled(id: number, options?: any): AxiosPromise<DispatchDetailsDto> {
      return localVarFp.dispatchControllerGetScheduled(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FormFilter} formFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerSearchForm(formFilter: FormFilter, options?: any): AxiosPromise<Array<FormSearchSummaryDTO>> {
      return localVarFp.dispatchControllerSearchForm(formFilter, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ScheduledFilter} scheduledFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerSearchScheduled(
      scheduledFilter: ScheduledFilter,
      options?: any
    ): AxiosPromise<Array<ScheduleBasicDTO>> {
      return localVarFp
        .dispatchControllerSearchScheduled(scheduledFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {DispatchMutationDto} dispatchMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerUpdate(id: number, dispatchMutationDto: DispatchMutationDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerUpdate(id, dispatchMutationDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {DispatchAssigneeMutationDto} dispatchAssigneeMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dispatchControllerUpdateAssignee(
      id: string,
      dispatchAssigneeMutationDto: DispatchAssigneeMutationDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .dispatchControllerUpdateAssignee(id, dispatchAssigneeMutationDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DispatchApi - interface
 * @export
 * @interface DispatchApi
 */
export interface DispatchApiInterface {
  /**
   *
   * @param {DispatchCreationDto} dispatchCreationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerCreate(dispatchCreationDto: DispatchCreationDto, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {FormSchemaViewDto} formSchemaViewDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerCreateInspectionSchemaConfig(
    formSchemaViewDto: FormSchemaViewDto,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerDelete(formDispatchGroup: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerDeleteCompleted(formDispatchGroup: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {string} viewName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerDeleteInspectionSchemaConfig(
    propertyId: number,
    formId: number,
    viewName: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerDeleteScheduled(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerDownloadReport(formDispatchGroup: string, options?: AxiosRequestConfig): AxiosPromise<FormDataFile>;

  /**
   *
   * @param {number} companyId
   * @param {DataRangeRequired} dataRangeRequired
   * @param {number} [propertyId]
   * @param {number} [formId]
   * @param {string} [formType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetDashboardDispatchesCount(
    companyId: number,
    dataRangeRequired: DataRangeRequired,
    propertyId?: number,
    formId?: number,
    formType?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DispatchesCountDto>;

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetDispatch(
    formDispatchGroup: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<FormDispatchGroup>;

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {number} size
   * @param {number} offset
   * @param {string} viewName
   * @param {string} assetName
   * @param {DataRangeRequired} dataRangeRequired
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetInspection(
    propertyId: number,
    formId: number,
    size: number,
    offset: number,
    viewName: string,
    assetName: string,
    dataRangeRequired: DataRangeRequired,
    options?: AxiosRequestConfig
  ): AxiosPromise<DispatchInspectionDetailsDto>;

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {string} viewName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetInspectionSchemaConfig(
    propertyId: number,
    formId: number,
    viewName: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<FormSchemaViewDto>;

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetInspectionSchemaConfigNames(
    propertyId: number,
    formId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<string>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerGetScheduled(id: number, options?: AxiosRequestConfig): AxiosPromise<DispatchDetailsDto>;

  /**
   *
   * @param {FormFilter} formFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerSearchForm(
    formFilter: FormFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<FormSearchSummaryDTO>>;

  /**
   *
   * @param {ScheduledFilter} scheduledFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerSearchScheduled(
    scheduledFilter: ScheduledFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ScheduleBasicDTO>>;

  /**
   *
   * @param {number} id
   * @param {DispatchMutationDto} dispatchMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerUpdate(
    id: number,
    dispatchMutationDto: DispatchMutationDto,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {string} id
   * @param {DispatchAssigneeMutationDto} dispatchAssigneeMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApiInterface
   */
  dispatchControllerUpdateAssignee(
    id: string,
    dispatchAssigneeMutationDto: DispatchAssigneeMutationDto,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * DispatchApi - object-oriented interface
 * @export
 * @class DispatchApi
 * @extends {BaseAPI}
 */
export class DispatchApi extends BaseAPI implements DispatchApiInterface {
  /**
   *
   * @param {DispatchCreationDto} dispatchCreationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerCreate(dispatchCreationDto: DispatchCreationDto, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerCreate(dispatchCreationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FormSchemaViewDto} formSchemaViewDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerCreateInspectionSchemaConfig(
    formSchemaViewDto: FormSchemaViewDto,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerCreateInspectionSchemaConfig(formSchemaViewDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerDelete(formDispatchGroup: string, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerDelete(formDispatchGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerDeleteCompleted(formDispatchGroup: string, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerDeleteCompleted(formDispatchGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {string} viewName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerDeleteInspectionSchemaConfig(
    propertyId: number,
    formId: number,
    viewName: string,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerDeleteInspectionSchemaConfig(propertyId, formId, viewName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerDeleteScheduled(id: number, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerDeleteScheduled(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerDownloadReport(formDispatchGroup: string, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerDownloadReport(formDispatchGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {DataRangeRequired} dataRangeRequired
   * @param {number} [propertyId]
   * @param {number} [formId]
   * @param {string} [formType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetDashboardDispatchesCount(
    companyId: number,
    dataRangeRequired: DataRangeRequired,
    propertyId?: number,
    formId?: number,
    formType?: string,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetDashboardDispatchesCount(
        companyId,
        dataRangeRequired,
        propertyId,
        formId,
        formType,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formDispatchGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetDispatch(formDispatchGroup: string, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetDispatch(formDispatchGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {number} size
   * @param {number} offset
   * @param {string} viewName
   * @param {string} assetName
   * @param {DataRangeRequired} dataRangeRequired
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetInspection(
    propertyId: number,
    formId: number,
    size: number,
    offset: number,
    viewName: string,
    assetName: string,
    dataRangeRequired: DataRangeRequired,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetInspection(
        propertyId,
        formId,
        size,
        offset,
        viewName,
        assetName,
        dataRangeRequired,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {string} viewName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetInspectionSchemaConfig(
    propertyId: number,
    formId: number,
    viewName: string,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetInspectionSchemaConfig(propertyId, formId, viewName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} propertyId
   * @param {number} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetInspectionSchemaConfigNames(
    propertyId: number,
    formId: number,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetInspectionSchemaConfigNames(propertyId, formId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerGetScheduled(id: number, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerGetScheduled(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FormFilter} formFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerSearchForm(formFilter: FormFilter, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerSearchForm(formFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScheduledFilter} scheduledFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerSearchScheduled(scheduledFilter: ScheduledFilter, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerSearchScheduled(scheduledFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {DispatchMutationDto} dispatchMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerUpdate(id: number, dispatchMutationDto: DispatchMutationDto, options?: AxiosRequestConfig) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerUpdate(id, dispatchMutationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {DispatchAssigneeMutationDto} dispatchAssigneeMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchApi
   */
  public dispatchControllerUpdateAssignee(
    id: string,
    dispatchAssigneeMutationDto: DispatchAssigneeMutationDto,
    options?: AxiosRequestConfig
  ) {
    return DispatchApiFp(this.configuration)
      .dispatchControllerUpdateAssignee(id, dispatchAssigneeMutationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
