import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';

import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { CellTitle } from '../../../../components/dt-table/table-cell.styled';
import DtTooltipTriggerElement from '../../../../components/dt-tooltip-trigger-element/dt-tooltip-trigger-element';
import {
  CellBadgeTitle,
  CellBadgeWrapper,
} from '../../../dt-administration/components/dt-table-cell-badge/dt-table-cell-badge.styled';
import { dtFormAsset } from '../../../dt-administration/dt-forms-tab/components/dt-forms-table/dt-generate-forms-columns-config.interfaces';
import { DtFormsRowItem } from '../../../dt-administration/dt-forms-tab/dt-forms.slice';

const TooltipContent = (formAssetList: dtFormAsset[]): JSX.Element => (
  <>
    {formAssetList.map((formAsset: dtFormAsset, index: number) => (
      <li key={`${formAsset.assetKey}_${index}`} style={{ listStyle: 'none' }}>
        {formAsset.assetKey}
      </li>
    ))}
  </>
);

export function dtGenerateFormAssetsTableColumnsConfig(isLoading: boolean): GridColDef[] {
  return [
    {
      field: 'property',
      headerName: 'Property',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.site,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.site}</CellTitle>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formType,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={false}>{params.row.formType}</CellTitle>
        );
      },
    },
    {
      field: 'subtype',
      headerName: 'Subtype',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formName,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' width={200} height={24} />
        ) : (
          <CellTitle titleBold={true}>{params.row.formName}</CellTitle>
        );
      },
    },
    {
      field: 'amount of assets',
      headerName: 'Amount of Assets',
      flex: 0.75,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.formAssetList,
      renderCell: (params: GridRenderCellParams<DtFormsRowItem>): React.ReactNode => {
        const assetsLabel = params.row.formAssetList.length === 1 ? 'asset' : 'assets';

        return isLoading || params.row.isLoading ? (
          <DtSkeleton variant='rectangular' height={24} width={78} />
        ) : (
          <CellBadgeWrapper>
            <CellBadgeTitle>
              {params.row.formAssetList.length}&nbsp;{assetsLabel}&nbsp;
              {params.row.formAssetList.length > 0 && (
                <Tooltip title={TooltipContent(params.row.formAssetList)} arrow>
                  <DtTooltipTriggerElement />
                </Tooltip>
              )}
            </CellBadgeTitle>
          </CellBadgeWrapper>
        );
      },
    },
  ];
}
