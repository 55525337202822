import {
  DtCronItemModel,
  DtCronItemTimeModel,
  DtCronItemWithSpecialChars,
  DtCronItemWithWeekSpecialChars,
} from './dt-cron.models';

export const DT_CRON_MONTH_BY_INDEX: Record<number, string> = {
  1: 'SUN',
  2: 'MON',
  3: 'TUE',
  4: 'WED',
  5: 'THU',
  6: 'FRI',
  7: 'SAT',
};

export const DT_CRON_MONTH_INDEXES: Record<string, number> = {
  SUN: 1,
  MON: 2,
  TUE: 3,
  WED: 4,
  THU: 5,
  FRI: 6,
  SAT: 7,
};

export const DT_CRON_HOUR_BY_INDEX: Record<number, string> = {
  0: '00:00',
  1: '01:00',
  2: '02:00',
  3: '03:00',
  4: '04:00',
  5: '05:00',
  6: '06:00',
  7: '07:00',
  8: '08:00',
  9: '09:00',
  10: '10:00',
  11: '11:00',
  12: '12:00',
  13: '13:00',
  14: '14:00',
  15: '15:00',
  16: '16:00',
  17: '17:00',
  18: '18:00',
  19: '19:00',
  20: '20:00',
  21: '21:00',
  22: '22:00',
  23: '23:00',
};

export const DT_CRON_HOUR_INDEXES: Record<string, number> = {
  '00:00': 0,
  '01:00': 1,
  '02:00': 2,
  '03:00': 3,
  '04:00': 4,
  '05:00': 5,
  '06:00': 6,
  '07:00': 7,
  '08:00': 8,
  '09:00': 9,
  '10:00': 10,
  '11:00': 11,
  '13:00': 13,
  '14:00': 14,
  '15:00': 15,
  '16:00': 16,
  '17:00': 17,
  '18:00': 18,
  '19:00': 19,
  '20:00': 20,
  '21:00': 21,
  '22:00': 22,
  '23:00': 23,
};

export enum FrequencyType {
  None = 'None',
  OnceFuture = 'OnceFuture',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface DtCronModel {
  type: FrequencyType;
  minute: DtCronItemTimeModel;
  hour: DtCronItemWithSpecialChars;
  day: DtCronItemWithSpecialChars;
  month: DtCronItemModel;
  week: DtCronItemWithWeekSpecialChars;
  year?: DtCronItemWithSpecialChars;
}
