import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addMonths, format, startOfMonth } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DtViewSelector from './components/dt-view-selector';
import DtInspectionDetails from './dt-inspection-details';
import { dtLoadFormSubtypeDataAction } from './dt-inspection-details.actions';
import {
  dtSelectFilters,
  dtSelectFormSubtypeData,
  dtSelectLastUpdatedDatetime,
  dtSelectFormSubtypeDataLoading,
  dtUpdateFiltersData,
} from './dt-inspection-details.slice';
import { DtInspectionDetailsHeaderWrapper, DtLastSubmitLabel } from './dt-inspection-details.styled';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import DtDateRangePicker from '../../components/dt-date-pickers/dt-date-range-picker/dt-date-range-picker';
import DtLastUpdated from '../../components/dt-last-updated/dt-last-updated';
import DtPageTitleAndSubtitle from '../../components/dt-page-title-and-subtitle/dt-page-title-and-subtitle';
import DtSelect from '../../components/dt-selects/dt-select/dt-select';
import { DtOptionItem } from '../../components/dt-selects/dt-selects.interfaces';
import DtSkeleton from '../../components/dt-skeleton/dt-skeleton';
import { ActionButtonsWrapper } from '../../components/dt-table/dt-actions-table-cell.styled';
import { RoutesResolver } from '../../dt-enums';
import { dtRouter } from '../../dt-routes';
import { DtRootState } from '../../dt-store';
import { dtApiRepository } from '../../repositories/dt-api.repository';
import { dtExportInspectionDataToExcel } from '../../services/dt-excel-export.service';
import { dtToastService } from '../../services/dt-toast.service';
import { SaveButton } from '../dt-administration/dt-administration-details/dt-administration-details-form-control/dt-administration-details-form-control.styled';
import { MainContent } from '../dt-dashboard/dt-dashboard.styled';
import {
  dtSelectPropertyFormSubtype,
  dtSelectPropertyFormType,
  dtSetPropertyFormTSubtype,
} from '../dt-navigation/dt-navigation.slice';
import {
  dtSelectAvailableAssetsByFormSubtype,
  dtSelectAvailableFormSubtypesBySelectedType,
  dtSelectSelectedPropertyId,
} from '../dt-properties/dt-properties.slice';

const DtInspectionDetailsWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const formSubtypeId = parseInt(id as string, 10);

  const dispatch = dtUseAppDispatch();

  const propertyId = dtUseAppSelector(dtSelectSelectedPropertyId);
  const propertyFormType = dtUseAppSelector(dtSelectPropertyFormType);
  const propertyFormSubtype = dtUseAppSelector(dtSelectPropertyFormSubtype);
  const availableFormSubtypes = dtUseAppSelector((state: DtRootState) =>
    dtSelectAvailableFormSubtypesBySelectedType(state, undefined, propertyId ?? undefined)
  );
  const filters = dtUseAppSelector(dtSelectFilters);
  const lastUpdatedDatetime = dtUseAppSelector(dtSelectLastUpdatedDatetime);
  const formSubtypeData = dtUseAppSelector(dtSelectFormSubtypeData);
  const isLoading = dtUseAppSelector(dtSelectFormSubtypeDataLoading);

  const [loadingExport, setLoadingExport] = useState(false);

  const availableAssets: DtOptionItem<string>[] = _.chain(
    dtUseAppSelector((state: DtRootState) =>
      dtSelectAvailableAssetsByFormSubtype(
        state,
        filters.formSubtypeId,
        filters.propertyId ?? -1,
        propertyFormType?.parentId
      )
    )
  )
    .map((asset) => ({
      value: asset.assetName,
      label: asset.assetName,
    }))
    .orderBy('label')
    .value();

  const disabledDays = { after: new Date() };
  const previousMonth = startOfMonth(addMonths(new Date(), -1));
  const thisMonth = startOfMonth(new Date());

  const shouldSwitchToDefaultPage = isLoading
    ? false
    : availableFormSubtypes.length === 0 || !availableFormSubtypes.some((i) => i.id === formSubtypeId);

  // If current form subtype is not available for selected property, switch to default page
  useEffect(() => {
    if (shouldSwitchToDefaultPage && availableFormSubtypes.length > 0) {
      dtRouter.navigate(RoutesResolver.InspectionDetails(availableFormSubtypes[0].id.toString()));
    }
  }, [shouldSwitchToDefaultPage]);

  useEffect(() => {
    dispatch(
      dtUpdateFiltersData({ propertyId: propertyId, formSubtypeId: formSubtypeId, dateRange: filters.dateRange })
    );

    dispatch(dtSetPropertyFormTSubtype(id as string));
  }, [propertyId, id, formSubtypeId]);

  useEffect(() => {
    dispatch(dtLoadFormSubtypeDataAction(filters));
  }, [
    filters.schemaName,
    filters.dateRange,
    filters.size,
    filters.offset,
    filters.assetName,
    filters.propertyId,
    filters.formSubtypeId,
  ]);

  // Format lastSubmittedDate if presented, if not - return undefined
  // If date is null - return last value
  const [lastSubmittedDate, setLastSubmissionDate] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (formSubtypeData?.lastSubmissionDate === undefined) {
      setLastSubmissionDate(undefined);
      return;
    }

    if (formSubtypeData.lastSubmissionDate === null) {
      setLastSubmissionDate(lastSubmittedDate);
      return;
    }

    setLastSubmissionDate(format(new Date(formSubtypeData.lastSubmissionDate), 'MM/dd/yyyy p'));
  }, [formSubtypeData?.lastSubmissionDate]);

  const totalItems = formSubtypeData?.paggination?.total ?? 0;

  const onFormSchemaChange = useCallback(
    (value: string | undefined): void => {
      if (value === filters.schemaName) {
        dispatch(dtUpdateFiltersData({ offset: 0 }));
      } else {
        dispatch(dtUpdateFiltersData({ schemaName: value, offset: 0 }));
      }
    },
    [filters]
  );

  async function onExport(): Promise<void> {
    if (!formSubtypeData || !filters.propertyId || !filters.formSubtypeId) {
      dtToastService.error(`Failed to export inspection data, filters are not set`);
      return;
    }

    dtToastService.info(`Exporting data for ${formSubtypeData.paggination.total} submissions`);
    setLoadingExport(true);
    await dtApiRepository.DispatchApi.dispatchControllerGetInspection(
      filters.propertyId,
      filters.formSubtypeId,
      formSubtypeData.paggination.total,
      0,
      filters.schemaName ?? '',
      filters.assetName ?? '',
      {
        from: filters.dateRange.from.toJSON(),
        to: filters.dateRange.to.toJSON(),
      }
    )
      .then(({ data }) => {
        dtExportInspectionDataToExcel(data);
        setLoadingExport(false);
      })
      .catch(() => {
        setLoadingExport(false);
        dtToastService.error(`Failed to export inspection data for current filters`);
      });
  }

  return (
    <MainContent>
      <DtInspectionDetailsHeaderWrapper>
        <DtPageTitleAndSubtitle
          mb={0}
          title={propertyFormSubtype?.title ?? ''}
          subtitle={
            <Box display='flex' alignItems='center'>
              <DtLastSubmitLabel>
                Last submission date:{' '}
                {isLoading ? (
                  <DtSkeleton variant='text' width={120} style={{ display: 'inline-block' }} />
                ) : (
                  <Typography variant='body' color='black'>
                    {lastSubmittedDate ?? 'None'}
                  </Typography>
                )}
              </DtLastSubmitLabel>
              <DtLastUpdated
                lastUpdatedDatetime={lastUpdatedDatetime}
                onRefresh={() => {
                  if (filters.offset !== 0) {
                    dispatch(dtUpdateFiltersData({ offset: 0 }));
                  } else {
                    dispatch(dtLoadFormSubtypeDataAction(filters));
                  }
                }}
                buttonOnly
              />
            </Box>
          }
        />
        <ActionButtonsWrapper>
          {availableAssets.length > 0 && (
            <DtSelect
              minWidth='248px'
              label='Asset'
              labelPosition='inside'
              value={filters.assetName}
              onChange={(value) => {
                dispatch(dtUpdateFiltersData({ assetName: value, offset: 0 }));
              }}
              placeholder='All'
              options={availableAssets}
            />
          )}
          {filters.formSubtypeId && (
            <DtViewSelector
              formSubtypeId={filters.formSubtypeId}
              formSchemaName={filters.schemaName}
              onFormSchemaChange={onFormSchemaChange}
            />
          )}
          <DtDateRangePicker
            label='Range:'
            value={filters.dateRange}
            onChange={(value) => {
              dispatch(dtUpdateFiltersData({ dateRange: value, offset: 0 }));
            }}
            disabledDays={disabledDays}
            month={previousMonth}
            maxMonth={thisMonth}
          />
          <SaveButton
            type='button'
            variant='primary'
            onClick={onExport}
            disabled={
              isLoading ||
              !formSubtypeData ||
              !filters.propertyId ||
              !filters.formSubtypeId ||
              totalItems === 0 ||
              loadingExport
            }
          >
            Export
          </SaveButton>
        </ActionButtonsWrapper>
      </DtInspectionDetailsHeaderWrapper>
      <DtInspectionDetails
        combinedData={formSubtypeData?.combinedData}
        isLoading={isLoading}
        totalItems={totalItems}
        offset={filters.offset}
        size={filters.size}
        onLoadMore={() => {
          dispatch(dtUpdateFiltersData({ offset: filters.offset + filters.size }));
        }}
      />
    </MainContent>
  );
};

export default DtInspectionDetailsWrapper;
