/* eslint-disable no-magic-numbers */
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import DtButton from '../../../../../components/dt-button/dt-button';

const DialogActionButton = styled(DtButton)`
  min-width: 128px;
  ${({ theme }) => theme.mixins.commonItemsSpacingMixin(theme, 24)};
`;

interface Props {
  onClose: () => void;
  onSubmit?: () => void;
  onDelete?: () => void;
}

export const DtModalActions: React.FC<Props> = ({ onClose, onSubmit, onDelete }) => {
  return (
    <>
      {onDelete && (
        <DialogActionButton onClick={onDelete} variant='transparent'>
          <Typography variant='bodySemibold' color='error'>
            Delete
          </Typography>
        </DialogActionButton>
      )}
      <DialogActionButton onClick={onClose} variant='secondary'>
        Cancel
      </DialogActionButton>
      <DialogActionButton onClick={() => onSubmit && onSubmit()} variant='primary' disabled={!Boolean(onSubmit)}>
        Save
      </DialogActionButton>
    </>
  );
};
