import React, { ReactNode } from 'react';

import DtCopyrightFooter from './../../../../components/dt-copyright-footer/dt-copyright-footer';
import { MainContent } from './../../../dt-dashboard/dt-dashboard.styled';
import { DtAdministrationDetailsHeaderWrapper } from './dt-administration-details-layout.styled';
import { DtAppRoutes } from '../../../../dt-enums';
import DtAdministrationDetailsFormControl from '../dt-administration-details-form-control/dt-administration-details-form-control';
import DtAdministrationDetailsHeader from '../dt-administration-details-header/dt-administration-details-header';

interface DtAdministrationDetailsLayoutProps {
  pageTitle: string;
  pageSubtitle: string;
  backButtonRoute?: DtAppRoutes;
  children: ReactNode;
  setValidationAfterSubmit: (state: boolean) => void;
  isCreationFlow: boolean;
  cancelButtonTitle?: string;
}

const DtAdministrationDetailsLayout: React.FC<DtAdministrationDetailsLayoutProps> = (props) => {
  return (
    <MainContent>
      <DtAdministrationDetailsHeaderWrapper>
        <DtAdministrationDetailsHeader
          backButtonRoute={props.backButtonRoute}
          pageTitle={props.pageTitle}
          pageSubtitle={props.pageSubtitle}
        />
        <DtAdministrationDetailsFormControl
          setValidationAfterSubmit={props.setValidationAfterSubmit}
          isCreationFlow={props.isCreationFlow}
          cancelButtonTitle={props.cancelButtonTitle}
        />
      </DtAdministrationDetailsHeaderWrapper>
      {props.children}
      <DtCopyrightFooter />
    </MainContent>
  );
};

export default DtAdministrationDetailsLayout;
