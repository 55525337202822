import { Snackbar, SnackbarOrigin, Typography } from '@mui/material';
import React, { forwardRef } from 'react';

import {
  SelectAllButton,
  DtSnackbarContentContainer,
  SelectionSummaryLabel,
  SelectionSummaryTextContainer,
  ActionsContainer,
} from './dt-selection-summary-bar.styled';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';
import { DtSupportedIcon } from '../dt-icon/gen/dt-supported-icons';
import DtSkeleton from '../dt-skeleton/dt-skeleton';

interface DtSelectionSummaryBarActions {
  icon: DtSupportedIcon;
  onClick: () => void;
}

export interface DtSelectionSummaryBarContentProps {
  selectedCount: number;
  totalCount: number;
  onSelectAll: () => void;
  actions: DtSelectionSummaryBarActions[];
  onDeselectAll: () => void;
  isSelectedAllItems: boolean;
  isLoading: boolean;
}

export interface DtSelectionSummaryBarProps extends DtSelectionSummaryBarContentProps {
  open: boolean;
  anchorOrigin?: SnackbarOrigin;
}

const DEFAULT_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const SelectionSummaryBarContent = forwardRef<HTMLDivElement, DtSelectionSummaryBarContentProps>(
  ({ onSelectAll, actions, selectedCount, totalCount, isSelectedAllItems, onDeselectAll, isLoading }, ref) => (
    <DtSnackbarContentContainer ref={ref}>
      <SelectionSummaryTextContainer>
        <SelectionSummaryLabel variant='body'>Selected:</SelectionSummaryLabel>
        <Typography variant='bodySemibold'>
          {selectedCount} of {totalCount} dispatches
        </Typography>
      </SelectionSummaryTextContainer>
      <SelectAllButton
        variant='transparent'
        onClick={isSelectedAllItems ? onDeselectAll : onSelectAll}
        disabled={selectedCount === 0}
      >
        {isSelectedAllItems ? 'Deselect all' : 'Select all'}
      </SelectAllButton>
      <ActionsContainer>
        {actions.map(({ icon, onClick }) => (
          <div key={`action-${icon}`} style={{ marginRight: '5px' }}>
            {isLoading ? (
              <DtSkeleton variant='rectangular' width={40} height={40} />
            ) : (
              <DtButton variant='transparent' onClick={onClick}>
                <DtIcon icon={icon} size={20} />
              </DtButton>
            )}
          </div>
        ))}
      </ActionsContainer>
    </DtSnackbarContentContainer>
  )
);

const DtSelectionSummaryBar: React.FC<DtSelectionSummaryBarProps> = ({
  open,
  anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
  ...barContentProps
}) => {
  return (
    <Snackbar open={open} anchorOrigin={anchorOrigin}>
      <SelectionSummaryBarContent {...barContentProps} />
    </Snackbar>
  );
};

export default DtSelectionSummaryBar;
