import { styled } from '@mui/material/styles';

export const LogoContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(88)};
`;

export const LoginTitleContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(15)};
`;

export const LoginInputContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const FooterContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(64)};
`;

export const Screenshot = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(48)};
`;

export const CarouselTitle = styled('span')`
  align-self: center;
`;
