import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { difference } from 'lodash';

import { dtDispatchService } from './dt-dispatch.service';
import { DtSupportedDispatchStatuses } from './dt-dispatches.enums';
import { DtNewDispatchFormInitialValues } from './modals/dt-new-dispatch-modal/dt-new-dispatch-modal-form-interface';
import { DtDatePickerRange } from '../../components/dt-date-pickers/dt-date-pickers.enums';
import { DT_PICKER_DAYS_RANGE } from '../../dt-constants';
import { DtRootState } from '../../dt-store';
import { DataRangeRequired, PropertyFilter } from '../../repositories/__generated__/v2';
import { dtApiRepository } from '../../repositories/dt-api.repository';

export type DtDispatchItems = Record<DtSupportedDispatchStatuses, (DtDispatchRowItem | DtScheduleDispatchItem)[]>;
export type DtDispatchItemsStatuses = Record<DtSupportedDispatchStatuses, DtStatus>;
export type DtDispatchItemsCount = Record<
  Exclude<DtSupportedDispatchStatuses, DtSupportedDispatchStatuses.Done>,
  number
>;
export type DtDispatchLastUpdatedDatetime = Record<
  Exclude<DtSupportedDispatchStatuses, DtSupportedDispatchStatuses.Done>,
  Date
>;

export interface DtDispatchesState {
  status: DtDispatchItemsStatuses;
  entities: DtDispatchItems;
  count: DtDispatchItemsCount;
  countStatus: DtStatus;
  lastCountRequestParams?: DtDispatchesCountParams;
  lastListRequestParams?: DtDispatchFilters;
  isShowConfirmationMessage: boolean;
  dateRange: DtDateRangeValue;
  lastUpdatedDatetime: DtDispatchLastUpdatedDatetime;
  propertyFilters?: PropertyFilter[];
  dispatchesAndAssetsFilters: {
    propertyId: number | undefined;
    formType: string | undefined;
    formSubtype: { id: number; name: string } | undefined;
  };
}

const initialState: DtDispatchesState = {
  status: {
    [DtSupportedDispatchStatuses.Open]: 'loading',
    [DtSupportedDispatchStatuses.Overdue]: 'loading',
    [DtSupportedDispatchStatuses.Completed]: 'loading',
    [DtSupportedDispatchStatuses.Scheduled]: 'loading',
    [DtSupportedDispatchStatuses.Done]: 'loading',
  },
  entities: {
    [DtSupportedDispatchStatuses.Open]: [],
    [DtSupportedDispatchStatuses.Overdue]: [],
    [DtSupportedDispatchStatuses.Completed]: [],
    [DtSupportedDispatchStatuses.Scheduled]: [],
    [DtSupportedDispatchStatuses.Done]: [],
  },
  count: {
    [DtSupportedDispatchStatuses.Open]: 0,
    [DtSupportedDispatchStatuses.Overdue]: 0,
    [DtSupportedDispatchStatuses.Completed]: 0,
    [DtSupportedDispatchStatuses.Scheduled]: 0,
  },
  countStatus: 'loading',
  isShowConfirmationMessage: false,
  dateRange: DT_PICKER_DAYS_RANGE[DtDatePickerRange.LastThirtyDays],
  lastUpdatedDatetime: {
    [DtSupportedDispatchStatuses.Open]: new Date(),
    [DtSupportedDispatchStatuses.Overdue]: new Date(),
    [DtSupportedDispatchStatuses.Completed]: new Date(),
    [DtSupportedDispatchStatuses.Scheduled]: new Date(),
  },
  dispatchesAndAssetsFilters: {
    propertyId: undefined,
    formType: undefined,
    formSubtype: undefined,
  },
};

export const dtLoadOpenDtDispatches = createAsyncThunk(
  'dtDispatches/dtLoadOpenDtDispatches',
  async (
    data: DtDispatchFilters | undefined
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Open.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Open,
      requestParams: data,
    };
  }
);

export const dtLoadOverdueDtDispatches = createAsyncThunk(
  'dtDispatches/dtLoadOverdueDtDispatches',
  async (
    data: DtDispatchFilters | undefined
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Overdue.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Overdue,
      requestParams: data,
    };
  }
);

export const dtLoadCompletedDtDispatches = createAsyncThunk(
  'dtDispatches/dtLoadCompletedDtDispatches',
  async (
    data: DtDispatchFilters | undefined
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Done.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Completed,
      requestParams: data,
    };
  }
);

export const dtLoadScheduledDtDispatches = createAsyncThunk(
  'dtDispatches/dtLoadScheduledDtDispatches',
  async (
    data: DtDispatchFilters | undefined
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    if (data) {
      return {
        data: await dtDispatchService.getDtScheduledDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Scheduled.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Scheduled,
      requestParams: data,
    };
  }
);

export const dtRefreshOpenDtDispatches = createAsyncThunk(
  'dtDispatches/dtRefreshOpenDtDispatches',
  async (
    _: undefined,
    api
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    const data = (api.getState() as DtRootState).dtDispatches.lastListRequestParams;

    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Open.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Open,
      requestParams: data,
    };
  }
);

export const dtRefreshOverdueDtDispatches = createAsyncThunk(
  'dtDispatches/dtRefreshOverdueDtDispatches',
  async (
    _: undefined,
    api
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    const data = (api.getState() as DtRootState).dtDispatches.lastListRequestParams;

    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Overdue.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Overdue,
      requestParams: data,
    };
  }
);

export const dtRefreshCompletedDtDispatches = createAsyncThunk(
  'dtDispatches/dtRefreshCompletedDtDispatches',
  async (
    _: undefined,
    api
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    const data = (api.getState() as DtRootState).dtDispatches.lastListRequestParams;

    if (data) {
      return {
        data: await dtDispatchService.getDtDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Done.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Completed,
      requestParams: data,
    };
  }
);

export const dtRefreshScheduledDtDispatches = createAsyncThunk(
  'dtDispatches/dtRefreshScheduledDtDispatches',
  async (
    _: undefined,
    api
  ): Promise<{ data: (DtDispatchRowItem | DtScheduleDispatchItem)[]; requestParams?: DtDispatchFilters }> => {
    const data = (api.getState() as DtRootState).dtDispatches.lastListRequestParams;

    if (data) {
      return {
        data: await dtDispatchService.getDtScheduledDispatches(
          data.currentCompanyId as number,
          data,
          DtSupportedDispatchStatuses.Scheduled.toUpperCase() as DtSupportedDispatchStatuses
        ),
        requestParams: data,
      };
    }
    return {
      data: initialState.entities.Scheduled,
      requestParams: data,
    };
  }
);

interface DtDispatchesCountParams {
  companyId: number | null;
  propertyId?: number;
  formId?: number;
  formType?: string;
  dataRangeRequired: DataRangeRequired;
}

export const dtLoadDtDispatchesCount = createAsyncThunk(
  'dtDispatches/dtLoadDtDispatchesCount',
  async (
    data: DtDispatchesCountParams | undefined
  ): Promise<{ data: DtDispatchItemsCount; requestParams?: DtDispatchesCountParams }> => {
    if (data) {
      const response = await dtApiRepository.DispatchApi.dispatchControllerGetDashboardDispatchesCount(
        data.companyId as number,
        data.dataRangeRequired,
        data.propertyId as number,
        data.formId,
        data.formType
      );

      return { data: response.data, requestParams: data };
    }
    return { data: initialState.count, requestParams: data };
  }
);

export const dtRefreshDtDispatchesCount = createAsyncThunk(
  'dtDispatches/dtRefreshDtDispatchesCount',
  async (_: undefined, api): Promise<{ data: DtDispatchItemsCount; requestParams?: DtDispatchesCountParams }> => {
    const data = (api.getState() as DtRootState).dtDispatches.lastCountRequestParams;

    if (data) {
      const response = await dtApiRepository.DispatchApi.dispatchControllerGetDashboardDispatchesCount(
        data.companyId as number,
        data.dataRangeRequired,
        data.propertyId as number,
        data.formId,
        data.formType
      );

      return {
        data: response.data,
        requestParams: data,
      };
    }

    return {
      data: initialState.count,
      requestParams: data,
    };
  }
);

export interface DtDeleteDispatchResponse {
  passed: string[];
  failed: string[];
  dispatchStatus: DtSupportedDispatchStatuses;
}

export const dtDeleteDispatch = createAsyncThunk(
  'dtDispatches/deleteDispatch',
  async ({
    ids,
    dispatchStatus,
  }: {
    ids: string[];
    dispatchStatus: DtSupportedDispatchStatuses;
  }): Promise<DtDeleteDispatchResponse> => {
    const deleteDispatch = (id: string, dispatchStatus: DtSupportedDispatchStatuses): Promise<string> => {
      switch (dispatchStatus) {
        case DtSupportedDispatchStatuses.Scheduled:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return dtApiRepository.DispatchApi.dispatchControllerDeleteScheduled(id as any).then(() => id.toString());
        case DtSupportedDispatchStatuses.Completed:
          return dtApiRepository.DispatchApi.dispatchControllerDeleteCompleted(id).then(() => id.toString());
        default:
          return dtDispatchService.deleteDtDispatch(id).then(() => id.toString());
      }
    };

    const result: PromiseSettledResult<string | number>[] = await Promise.allSettled(
      ids.map((id) => deleteDispatch(id, dispatchStatus))
    );

    const passed = result
      .filter((v) => v.status === 'fulfilled')
      .map((v) => (v as PromiseFulfilledResult<string>).value);

    return {
      passed,
      failed: difference(ids, passed),
      dispatchStatus,
    };
  }
);

export const dtCreateNewDispatch = createAsyncThunk(
  'dtDispatches/createNewDispatch',
  async (payload: DtNewDispatchFormInitialValues, { getState }): Promise<boolean> => {
    const { dtUser } = getState() as DtRootState;

    if (dtUser.currentCompanyId) {
      return dtDispatchService.createNewDispatch(dtUser.currentCompanyId, payload);
    }

    return false;
  }
);

export const dtUpdateScheduledDispatch = createAsyncThunk(
  'dtDispatches/dtUpdateScheduledDispatch',
  async ({ payload, id }: { payload: DtNewDispatchFormInitialValues; id: number }): Promise<void> => {
    return await dtDispatchService.updateScheduledDispatch(id, payload);
  }
);

export const dtDispatchesSlice = createSlice({
  name: 'dtDispatches',
  initialState,
  reducers: {
    dtSetPropertyFilters: (state, action: PayloadAction<PropertyFilter[]>) => {
      state.propertyFilters = action.payload;
    },
    dtClearDispatches: () => initialState,
    dtSetLoadingStatusToRow: (
      state,
      action: PayloadAction<{ ids: (string | number)[]; dispatchStatus: DtSupportedDispatchStatuses }>
    ) => {
      for (const id of action.payload.ids) {
        const dtDispatchRow = state.entities[action.payload.dispatchStatus].find(
          (item) => item.id.toString() === id.toString()
        );

        if (dtDispatchRow) {
          dtDispatchRow.isLoading = true;
        }
      }
    },
    dtSetDeleteConfirmationPopupState: (state, action: PayloadAction<boolean>) => {
      state.isShowConfirmationMessage = action.payload;
    },
    dtSetCompletedDateRange: (state, action: PayloadAction<DtDateRangeValue>) => {
      state.dateRange = action.payload;
    },
    dtSetDispatchesAndAssetsPropertyIdFilter: (state, action: PayloadAction<number | undefined>) => {
      state.dispatchesAndAssetsFilters.propertyId = action.payload;
    },
    dtSetDispatchesAndAssetsFormTypeFilter: (state, action: PayloadAction<string | undefined>) => {
      state.dispatchesAndAssetsFilters.formType = action.payload;
    },
    dtSetDispatchesAndAssetsFormSubtypeFilter: (
      state,
      action: PayloadAction<{ id: number; name: string } | undefined>
    ) => {
      state.dispatchesAndAssetsFilters.formSubtype = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dtLoadOpenDtDispatches.pending, (state) => {
        state.status.Open = 'loading';
      })
      .addCase(dtLoadOpenDtDispatches.fulfilled, (state, action) => {
        state.status.Open = 'idle';
        state.entities.Open = action.payload.data;
        state.lastListRequestParams = action.payload.requestParams;
        state.lastUpdatedDatetime.Open = new Date();
      })
      .addCase(dtLoadOpenDtDispatches.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.Open = 'failed';
        }
      })

      .addCase(dtLoadOverdueDtDispatches.pending, (state) => {
        state.status.Overdue = 'loading';
      })
      .addCase(dtLoadOverdueDtDispatches.fulfilled, (state, action) => {
        state.status.Overdue = 'idle';
        state.entities.Overdue = action.payload.data;
        state.lastListRequestParams = action.payload.requestParams;
        state.lastUpdatedDatetime.Overdue = new Date();
      })
      .addCase(dtLoadOverdueDtDispatches.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.Overdue = 'failed';
        }
      })

      .addCase(dtLoadCompletedDtDispatches.pending, (state) => {
        state.status.Completed = 'loading';
      })
      .addCase(dtLoadCompletedDtDispatches.fulfilled, (state, action) => {
        state.status.Completed = 'idle';
        state.entities.Completed = action.payload.data;
        state.lastListRequestParams = action.payload.requestParams;
        state.lastUpdatedDatetime.Completed = new Date();
      })
      .addCase(dtLoadCompletedDtDispatches.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.Completed = 'failed';
        }
      })

      .addCase(dtLoadScheduledDtDispatches.pending, (state) => {
        state.status.Scheduled = 'loading';
      })
      .addCase(dtLoadScheduledDtDispatches.fulfilled, (state, action) => {
        state.status.Scheduled = 'idle';
        state.entities.Scheduled = action.payload.data;
        state.lastListRequestParams = action.payload.requestParams;
        state.lastUpdatedDatetime.Scheduled = new Date();
      })
      .addCase(dtLoadScheduledDtDispatches.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.Scheduled = 'failed';
        }
      })

      .addCase(dtLoadDtDispatchesCount.pending, (state) => {
        state.countStatus = 'loading';
      })
      .addCase(dtLoadDtDispatchesCount.fulfilled, (state, action) => {
        state.countStatus = 'idle';
        state.count = action.payload.data;
        state.lastCountRequestParams = action.payload.requestParams;
      })
      .addCase(dtLoadDtDispatchesCount.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.countStatus = 'failed';
        }
      })

      .addCase(dtRefreshDtDispatchesCount.pending, (state) => {
        state.countStatus = 'loading';
      })
      .addCase(dtRefreshDtDispatchesCount.fulfilled, (state, action) => {
        state.countStatus = 'idle';
        state.count = action.payload.data;
      })
      .addCase(dtRefreshDtDispatchesCount.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.countStatus = 'failed';
        }
      })

      .addCase(dtRefreshOpenDtDispatches.pending, (state) => {
        state.status.Open = 'loading';
      })
      .addCase(dtRefreshOpenDtDispatches.fulfilled, (state, action) => {
        state.status.Open = 'idle';
        state.entities.Open = action.payload.data;
        state.lastUpdatedDatetime.Open = new Date();
      })
      .addCase(dtRefreshOpenDtDispatches.rejected, (state) => {
        state.status.Open = 'failed';
      })

      .addCase(dtRefreshOverdueDtDispatches.pending, (state) => {
        state.status.Overdue = 'loading';
      })
      .addCase(dtRefreshOverdueDtDispatches.fulfilled, (state, action) => {
        state.status.Overdue = 'idle';
        state.entities.Overdue = action.payload.data;
        state.lastUpdatedDatetime.Overdue = new Date();
      })
      .addCase(dtRefreshOverdueDtDispatches.rejected, (state) => {
        state.status.Overdue = 'failed';
      })

      .addCase(dtRefreshCompletedDtDispatches.pending, (state) => {
        state.status.Completed = 'loading';
      })
      .addCase(dtRefreshCompletedDtDispatches.fulfilled, (state, action) => {
        state.status.Completed = 'idle';
        state.entities.Completed = action.payload.data;
        state.lastUpdatedDatetime.Completed = new Date();
      })
      .addCase(dtRefreshCompletedDtDispatches.rejected, (state) => {
        state.status.Completed = 'failed';
      })

      .addCase(dtRefreshScheduledDtDispatches.pending, (state) => {
        state.status.Scheduled = 'loading';
      })
      .addCase(dtRefreshScheduledDtDispatches.fulfilled, (state, action) => {
        state.status.Scheduled = 'idle';
        state.entities.Scheduled = action.payload.data;
        state.lastUpdatedDatetime.Scheduled = new Date();
      })
      .addCase(dtRefreshScheduledDtDispatches.rejected, (state) => {
        state.status.Scheduled = 'failed';
      })

      .addCase(dtDeleteDispatch.fulfilled, (state, action) => {
        state.status[action.payload.dispatchStatus] = 'idle';
        state.isShowConfirmationMessage = false;
        state.count[
          action.payload.dispatchStatus as Exclude<DtSupportedDispatchStatuses, DtSupportedDispatchStatuses.Done>
        ] -= action.payload.passed.length;

        state.entities[action.payload.dispatchStatus] = state.entities[action.payload.dispatchStatus].filter(
          (dtDispatch) => !action.payload.passed.includes(dtDispatch.id.toString())
        );

        for (const id of action.payload.failed) {
          const dtDispatchRow = state.entities[action.payload.dispatchStatus].find(
            (item) => item.id.toString() === id.toString()
          );

          if (dtDispatchRow) {
            dtDispatchRow.isLoading = false;
          }
        }
      });
  },
});

export const {
  dtSetPropertyFilters,
  dtClearDispatches,
  dtSetLoadingStatusToRow,
  dtSetDeleteConfirmationPopupState,
  dtSetCompletedDateRange,
  dtSetDispatchesAndAssetsPropertyIdFilter,
  dtSetDispatchesAndAssetsFormTypeFilter,
  dtSetDispatchesAndAssetsFormSubtypeFilter,
} = dtDispatchesSlice.actions;

export const dtSelectOpenDispatches = (state: DtRootState): (DtDispatchRowItem | DtScheduleDispatchItem)[] =>
  state.dtDispatches.entities.Open;
export const dtSelectOverdueDispatches = (state: DtRootState): (DtDispatchRowItem | DtScheduleDispatchItem)[] =>
  state.dtDispatches.entities.Overdue;
export const dtSelectCompletedDispatches = (state: DtRootState): (DtDispatchRowItem | DtScheduleDispatchItem)[] =>
  state.dtDispatches.entities.Completed;
export const dtSelectScheduledDispatches = (state: DtRootState): (DtDispatchRowItem | DtScheduleDispatchItem)[] =>
  state.dtDispatches.entities.Scheduled;

export const dtSelectOpenDispatchIsLoading = (state: DtRootState): boolean =>
  state.dtDispatches.status.Open === 'loading';
export const dtSelectOverdueDispatchIsLoading = (state: DtRootState): boolean =>
  state.dtDispatches.status.Overdue === 'loading';
export const dtSelectCompletedDispatchIsLoading = (state: DtRootState): boolean =>
  state.dtDispatches.status.Completed === 'loading';
export const dtSelectScheduledDispatchIsLoading = (state: DtRootState): boolean =>
  state.dtDispatches.status.Scheduled === 'loading';

export const dtSelectIsShowConfirmationMessage = (state: DtRootState): boolean =>
  state.dtDispatches.isShowConfirmationMessage;

export const dtSelectDateRange = (state: DtRootState): DtDateRangeValue => state.dtDispatches.dateRange;

export const dtSelectOpenLastUpdatedDatetime = (state: DtRootState): Date =>
  state.dtDispatches.lastUpdatedDatetime.Open;
export const dtSelectOverdueLastUpdatedDatetime = (state: DtRootState): Date =>
  state.dtDispatches.lastUpdatedDatetime.Overdue;
export const dtSelectCompletedLastUpdatedDatetime = (state: DtRootState): Date =>
  state.dtDispatches.lastUpdatedDatetime.Completed;
export const dtSelectScheduledLastUpdatedDatetime = (state: DtRootState): Date =>
  state.dtDispatches.lastUpdatedDatetime.Scheduled;

export const dtSelectDispatchItemsCount = (state: DtRootState): DtDispatchItemsCount => state.dtDispatches.count;
export const dtSelectDispatchItemsCountIsLoading = (state: DtRootState): boolean =>
  state.dtDispatches.countStatus === 'loading';

// TODO: tbd if needed...
// export const dtSelectPropertyFilters = (state: DtRootState): PropertyFilter[] | undefined =>
//   state.dtDispatches.propertyFilters;

export const dtSelectDispatchesAndAssetsFilter = (
  state: DtRootState
): {
  propertyId: number | undefined;
  formType: string | undefined;
  formSubtype: { id: number; name: string } | undefined;
} => state.dtDispatches.dispatchesAndAssetsFilters;
export const dtSelectDispatchesAndAssetsPropertyIdFilter = (state: DtRootState): number | undefined =>
  state.dtDispatches.dispatchesAndAssetsFilters.propertyId;
export const dtSelectDispatchesAndAssetsFormTypeFilter = (state: DtRootState): string | undefined =>
  state.dtDispatches.dispatchesAndAssetsFilters.formType;
export const dtSelectDispatchesAndAssetsFormSubtypeFilter = (
  state: DtRootState
): { id: number; name: string } | undefined => state.dtDispatches.dispatchesAndAssetsFilters.formSubtype;

export default dtDispatchesSlice.reducer;
