import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const PriorityContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const PriorityLabel = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(8)};
`;
