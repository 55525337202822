import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { LayoutContainer } from './dt-layout-with-navigation.styled';
import DtNavigationLazy from '../../modules/dt-navigation/pages/dt-navigation-lazy';

export interface DtLayoutWithNavigationProps {
  children?: ReactNode;
}

const DtLayoutWithNavigation: React.FC<DtLayoutWithNavigationProps> = () => {
  return (
    <LayoutContainer>
      <DtNavigationLazy />
      <Outlet />
    </LayoutContainer>
  );
};

export default DtLayoutWithNavigation;
