import { IconButton, List, Typography } from '@mui/material';
import React from 'react';

import {
  AssetsTableAddButtonWrapper,
  AssetsTableHeaderWrapper,
  AssetsTableRow,
  AssetsTableWrapper,
} from './dt-assets-table.styled';
import { InputLabel } from '../../modules/dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../modules/dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import { FormSchemaAssetDto } from '../../repositories/__generated__/v2';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';
import DtScrollbar from '../dt-scrollbar/dt-scrollbar';
import DtTextInput from '../text-input/dt-text-input/dt-text-input';

export interface DtAssetsTableProps {
  formAssetList: FormSchemaAssetDto[];
  readonly?: boolean;
  disabled?: boolean;
  onChange: (formAssetList: FormSchemaAssetDto[]) => void;
}

const DtAssetsTable: React.FC<DtAssetsTableProps> = (props) => {
  const items = props.formAssetList;

  const handleAddItem = (): void => {
    const newItem: FormSchemaAssetDto = {
      assetKey: '',
      floorKey: '',
      locationKey: '',
    };
    props.onChange([...items, newItem]);
  };

  const handleDeleteItem = (index: number): void => {
    const newItems = items.filter((item, i) => index !== i);
    props.onChange(newItems);
  };

  const handleUpdateItem = (index: number, field: keyof FormSchemaAssetDto, value: string): void => {
    const newItems = items.map((item, i) => (i === index ? { ...item, [field]: value } : item));
    props.onChange(newItems);
  };

  return (
    <>
      <AssetsTableWrapper>
        <AssetsTableHeaderWrapper>
          <FieldContainer>
            <InputLabel>Asset</InputLabel>
          </FieldContainer>

          <FieldContainer>
            <InputLabel>Floor</InputLabel>
          </FieldContainer>

          <FieldContainer>
            <InputLabel>Location</InputLabel>
          </FieldContainer>
        </AssetsTableHeaderWrapper>
        <DtScrollbar height='calc(100vh - 595px)' minHeight='260px'>
          <List sx={{ paddingTop: '0px', marginRight: '15px' }}>
            {items.map((item, index) => (
              <AssetsTableRow key={index}>
                {props.readonly ? (
                  <>
                    <Typography variant='body' color='black'>
                      {item.assetKey}
                    </Typography>
                    <Typography variant='body' color='black'>
                      {item.floorKey}
                    </Typography>
                    <Typography variant='body' color='black'>
                      {item.locationKey}
                    </Typography>
                  </>
                ) : (
                  <>
                    <FieldContainer>
                      <DtTextInput
                        id='assetKey'
                        fullWidth
                        InputProps={{
                          style: {
                            fontSize: '12px',
                          },
                        }}
                        disabled={props.disabled}
                        placeholder='Asset'
                        value={item.assetKey}
                        onChange={(e) => handleUpdateItem(index, 'assetKey', e.target.value)}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <DtTextInput
                        id='floorKey'
                        fullWidth
                        InputProps={{
                          style: {
                            fontSize: '12px',
                          },
                        }}
                        disabled={props.disabled}
                        placeholder='Floor'
                        value={item.floorKey}
                        onChange={(e) => handleUpdateItem(index, 'floorKey', e.target.value)}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <DtTextInput
                        id='locationKey'
                        fullWidth
                        InputProps={{
                          style: {
                            fontSize: '12px',
                          },
                        }}
                        disabled={props.disabled}
                        placeholder='Location'
                        value={item.locationKey}
                        onChange={(e) => handleUpdateItem(index, 'locationKey', e.target.value)}
                      />
                    </FieldContainer>
                    <IconButton
                      disabled={props.disabled}
                      sx={{ ':disabled': { opacity: '40%' } }}
                      onClick={() => handleDeleteItem(index)}
                    >
                      <DtIcon icon='close' size={16} />
                    </IconButton>
                  </>
                )}
              </AssetsTableRow>
            ))}
          </List>
        </DtScrollbar>
      </AssetsTableWrapper>
      {!props.readonly && (
        <AssetsTableAddButtonWrapper>
          <DtButton variant='secondary' onClick={handleAddItem} disabled={props.disabled}>
            Add Asset
          </DtButton>
        </AssetsTableAddButtonWrapper>
      )}
    </>
  );
};

export default DtAssetsTable;
