import { Grid } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import DtCheckbox from './../../../../../../components/dt-checkbox/dt-checkbox';

export const AssigneeFieldWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AssigneeFullName = styled('p')`
  ${({ theme }) => theme.typography.body as CSSObject};
  margin: 0;
`;

export const AssigneeEmail = styled('p')`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin: 0;
`;

export const CustomCheckboxWrapper = styled(AssigneeFieldWrapper)`
  justify-content: center;
`;

export const CustomCheckbox = styled(DtCheckbox)`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  margin-left: ${({ theme }) => theme.spacing(8)};
  margin-right: 0;
  border-radius: 3px;
`;
