import * as yup from 'yup';

import { DtSiteDetailsInitialValues } from './dt-create-update-site-form.interface';
import { SiteMutationDTO } from '../../../../../../../repositories/__generated__/v2';

export const DT_SITE_DETAILS_INITIAL_FORM_VALUES = (
  site?: SiteMutationDTO[] | SiteMutationDTO | null
): DtSiteDetailsInitialValues => {
  return {
    name: (site as SiteMutationDTO)?.name ?? '',
    address: (site as SiteMutationDTO)?.address ?? '',
    city: (site as SiteMutationDTO)?.city ?? '',
    email: (site as SiteMutationDTO)?.email ?? undefined,
    phone: (site as SiteMutationDTO)?.phone ?? '',
    state: (site as SiteMutationDTO)?.state ?? '',
    reportConfig: {
      reportLogo: (site as SiteMutationDTO)?.reportLogo ?? '',
      // reportName: (site as SiteMutationDTO)?.reportName ?? '',
      // reportAddress: (site as SiteMutationDTO)?.reportAddress ?? '',
      // reportPhone: (site as SiteMutationDTO)?.reportPhone ?? '',
      // reportFax: (site as SiteMutationDTO)?.reportFax ?? '',
    },
    reportHeader: (site as SiteMutationDTO)?.reportHeader ?? '',
    reportFooter: (site as SiteMutationDTO)?.reportFooter ?? '',
  };
};

export const DT_SITE_DETAILS_FORM_VALIDATION: yup.ObjectSchema<DtSiteDetailsInitialValues> = yup.object().shape({
  name: yup.string().label('First Name').required('Required'),
  address: yup.string().label('Address').required('Required'),
  city: yup.string().label('City').required('Required'),
  email: yup.string().label('Email').email('Email should be a valid'),
  phone: yup
    .string()
    .label('Phone')
    .matches(/^\(\d{3}\) \d{3}\-\d{4}$/, { message: 'Must have (000) 000-0000 format', excludeEmptyString: true }),
  state: yup.string().label('State').required('Required'),
  reportConfig: yup.object().shape({
    reportLogo: yup.string().label('Reporter Logo'),
    // reportName: yup.string().label('Reporter Name'),
    // reportAddress: yup.string().label('Reporter Address'),
    // reportPhone: yup.string().label('Reporter Phone'),
    // reportFax: yup.string().label('Reporter Fax'),
  }),
  reportHeader: yup.string().label('Reporter Header'),
  reportFooter: yup.string().label('Reporter Footer'),
});
