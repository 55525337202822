import { format, setHours, setMinutes } from 'date-fns';

import { DtOptionItem } from '../../../../components/dt-selects/dt-selects.interfaces';
import { DT_WEEKDAYS } from '../../../../dt-constants';

/**
 * Util to get frequency editor time-select options
 */
const numberOfTimeOptions = 24;
export const dtTimeSelectOptions: DtOptionItem<number>[] = Array.from(Array(numberOfTimeOptions).keys()).map((item) => {
  const date = setMinutes(setHours(new Date(), item), 0);
  return {
    label: format(date, 'p'),
    value: item,
  };
});

/**
 * Util to get frequency editor day-select options
 */
const numberOfDayOptions = 30;
export const dtDaySelectOptions: DtOptionItem<number>[] = Array.from(
  { length: numberOfDayOptions },
  (_, i) => i + 1
).map((item) => ({
  label: item.toString(),
  value: item,
}));

/**
 * Util to get frequency editor days-wo-week-select options
 */

export const dtDaysOfWeekSelectOptions: DtOptionItem<number>[] = Object.entries(DT_WEEKDAYS).map(([key, value]) => ({
  label: key,
  value,
}));
