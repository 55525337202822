import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { DT_MIXINS_OPTIONS } from './dt-mixins';
import { DT_PALETTE_OPTIONS } from './dt-palette';
import { DT_TYPOGRAPHY_OPTIONS } from './dt-typography';

export const DT_THEME = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          overflowX: 'hidden',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, &  .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: DT_PALETTE_OPTIONS.main.primary,
            color: DT_PALETTE_OPTIONS.main.white,
            padding: '10px 24px',
            ...DT_MIXINS_OPTIONS.primaryButtonBoxShadowMixin,
            ['&:hover']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.buttonPrimaryHover,
            },
            ['&:active']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.buttonPrimaryPressed,
              boxShadow: 'none',
            },
            ['&:disabled']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.buttonPrimary,
              color: DT_PALETTE_OPTIONS.main.white,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: DT_PALETTE_OPTIONS.main.primary,
            padding: '10px 24px',
            ['&:hover']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.secondaryButtonHover,
            },
            ['&:active']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.secondaryButtonPressed,
            },
            ['&:disabled']: {
              color: DT_PALETTE_OPTIONS.main.primary,
            },
          },
        },
        {
          props: { variant: 'flat' },
          style: {
            backgroundColor: DT_PALETTE_OPTIONS.main.control,
            padding: '10px',
            ['&:hover']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.flatButtonHover,
            },
            ['&:active']: {
              ...DT_MIXINS_OPTIONS.flatButtonShadowMixin,
            },
            ['&:disabled']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.control,
            },
          },
        },
        {
          props: { variant: 'transparent' },
          style: {
            backgroundColor: 'transparent',
            padding: '10px',
            ['&:hover']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.flatButtonHover,
            },
            ['&:active']: {
              ...DT_MIXINS_OPTIONS.flatButtonShadowMixin,
            },
            ['&:disabled']: {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'raised' },
          style: {
            backgroundColor: DT_PALETTE_OPTIONS.main.white,
            padding: '5px',
            ...DT_MIXINS_OPTIONS.boxShadowMixin,
            ['&:hover']: {
              backgroundColor: DT_PALETTE_OPTIONS.main.white,
              path: {
                fill: DT_PALETTE_OPTIONS.main.buttonPrimary,
              },
            },
            ['&:active']: {
              boxShadow: 'none',
            },
          },
        },
      ],
    },
  },
  mixins: DT_MIXINS_OPTIONS,
  typography: DT_TYPOGRAPHY_OPTIONS,
  palette: DT_PALETTE_OPTIONS,
  /**
   * Spacing ration. Ratio: 1 = 1px
   * @example theme.spacing(16) = 16px
   */
  spacing: 1,
});
