import React from 'react';

import DtDashboardSkeleton from '../../modules/dt-dashboard/dt-dashboard-skeleton';
import DtNavigationSkeleton from '../../modules/dt-navigation/pages/dt-navigation-skeleton/dt-navigation-skeleton';
import { LayoutContainer } from '../dt-layout-with-navigation/dt-layout-with-navigation.styled';

const DtGlobalLoaderSkeleton: React.FC = () => {
  return (
    <LayoutContainer>
      <DtNavigationSkeleton />
      <DtDashboardSkeleton />
    </LayoutContainer>
  );
};

export default DtGlobalLoaderSkeleton;
