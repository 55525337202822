import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import DtDashboardFilterPanel from './components/dt-dashboard-filter-panel/dt-dashboard-filter-panel';
import DtDispatchesTabs from './components/dt-dispatches-tabs/dt-dispatches-tabs';
import { MainContent } from './dt-dashboard.styled';
import { DtSupportedDispatchStatuses } from './dt-dispatches.enums';
import {
  dtLoadCompletedDtDispatches,
  dtLoadOpenDtDispatches,
  dtLoadOverdueDtDispatches,
  dtLoadScheduledDtDispatches,
  dtRefreshCompletedDtDispatches,
  dtRefreshOpenDtDispatches,
  dtRefreshOverdueDtDispatches,
  dtRefreshScheduledDtDispatches,
  dtSelectCompletedDispatchIsLoading,
  dtSelectCompletedLastUpdatedDatetime,
  dtSelectDateRange,
  dtSelectDispatchesAndAssetsFilter,
  dtSelectOpenDispatchIsLoading,
  dtSelectOpenLastUpdatedDatetime,
  dtSelectOverdueDispatchIsLoading,
  dtSelectOverdueLastUpdatedDatetime,
  dtSelectScheduledDispatchIsLoading,
  dtSelectScheduledLastUpdatedDatetime,
} from './dt-dispatches.slice';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import DtCopyrightFooter from '../../components/dt-copyright-footer/dt-copyright-footer';
import DtLastUpdated from '../../components/dt-last-updated/dt-last-updated';
import DtPageTitleAndSubtitle from '../../components/dt-page-title-and-subtitle/dt-page-title-and-subtitle';
import { dtSelectCurrentCompanyId, dtSelectUser } from '../dt-user/dt-user.slice';

const ALLOWED_PATH = ['open', 'overdue', 'completed', 'scheduled'];

// It is needed because layout fetching logic is hardly based on opened tab
const DtDashboardLayout: React.FC = () => {
  const history = useLocation();
  const path = history.pathname.split('/').slice(-1)[0];
  if (!ALLOWED_PATH.includes(path)) {
    return <Navigate to='./open' />;
  }
  const activeDispatchStatus = capitalize(path) as DtSupportedDispatchStatuses;

  return <DtDashboardLayoutBase activeDispatchStatus={activeDispatchStatus} />;
};

const DtDashboardLayoutBase: React.FC<{ activeDispatchStatus: DtSupportedDispatchStatuses }> = ({
  activeDispatchStatus,
}) => {
  const dispatch = dtUseAppDispatch();

  const filters = dtUseAppSelector(dtSelectDispatchesAndAssetsFilter);
  const companyId = dtUseAppSelector(dtSelectCurrentCompanyId);
  const completedDateRange = dtUseAppSelector(dtSelectDateRange);
  const user = dtUseAppSelector(dtSelectUser);

  const currentCompanyId = companyId ? companyId : user?.companyId;

  useEffect(() => {
    if (currentCompanyId && filters) {
      dispatch(
        dtLoadOpenDtDispatches({
          currentCompanyId,
          propertyId: filters.propertyId,
          formType: filters.formType,
          formId: filters.formSubtype?.id,
          completedDateRange,
        })
      );
      dispatch(
        dtLoadOverdueDtDispatches({
          currentCompanyId,
          propertyId: filters.propertyId,
          formType: filters.formType,
          formId: filters.formSubtype?.id,
          completedDateRange,
        })
      );
      dispatch(
        dtLoadCompletedDtDispatches({
          currentCompanyId,
          propertyId: filters.propertyId,
          formType: filters.formType,
          formId: filters.formSubtype?.id,
          completedDateRange,
        })
      );
      dispatch(
        dtLoadScheduledDtDispatches({
          currentCompanyId,
          propertyId: filters.propertyId,
          formType: filters.formType,
          formId: filters.formSubtype?.id,
          completedDateRange,
        })
      );
    }
  }, [currentCompanyId, filters, completedDateRange]);

  function onRefresh(status: DtSupportedDispatchStatuses): void {
    switch (status) {
      case DtSupportedDispatchStatuses.Open:
        dispatch(dtRefreshOpenDtDispatches());
        break;
      case DtSupportedDispatchStatuses.Overdue:
        dispatch(dtRefreshOverdueDtDispatches());
        break;
      case DtSupportedDispatchStatuses.Completed:
        dispatch(dtRefreshCompletedDtDispatches());
        break;
      case DtSupportedDispatchStatuses.Scheduled:
        dispatch(dtRefreshScheduledDtDispatches());
        break;
    }
  }

  function getIsDispatchesLoading(status: DtSupportedDispatchStatuses): boolean | undefined {
    switch (status) {
      case DtSupportedDispatchStatuses.Open:
        return dtUseAppSelector(dtSelectOpenDispatchIsLoading);
      case DtSupportedDispatchStatuses.Overdue:
        return dtUseAppSelector(dtSelectOverdueDispatchIsLoading);
      case DtSupportedDispatchStatuses.Completed:
        return dtUseAppSelector(dtSelectCompletedDispatchIsLoading);
      case DtSupportedDispatchStatuses.Scheduled:
        return dtUseAppSelector(dtSelectScheduledDispatchIsLoading);
    }
  }

  function getLastUpdatedDatetime(status: DtSupportedDispatchStatuses): Date | undefined {
    switch (status) {
      case DtSupportedDispatchStatuses.Open:
        return dtUseAppSelector(dtSelectOpenLastUpdatedDatetime);
      case DtSupportedDispatchStatuses.Overdue:
        return dtUseAppSelector(dtSelectOverdueLastUpdatedDatetime);
      case DtSupportedDispatchStatuses.Completed:
        return dtUseAppSelector(dtSelectCompletedLastUpdatedDatetime);
      case DtSupportedDispatchStatuses.Scheduled:
        return dtUseAppSelector(dtSelectScheduledLastUpdatedDatetime);
    }
  }

  return (
    <MainContent>
      <DtPageTitleAndSubtitle
        title='Dashboard'
        subtitle={
          <DtLastUpdated
            lastUpdatedDatetime={getLastUpdatedDatetime(activeDispatchStatus) as Date}
            onRefresh={() => onRefresh(activeDispatchStatus)}
          />
        }
      />
      <DtDashboardFilterPanel isLoading={getIsDispatchesLoading(activeDispatchStatus) as boolean} />
      <DtDispatchesTabs />
      <Outlet />
      <DtCopyrightFooter />
    </MainContent>
  );
};

export default DtDashboardLayout;
