import React from 'react';

import { CustomSwitcher } from './dt-switcher.styled';

export interface DtSwitcherProps {
  /**
   * @param {boolean} Defines the state of a switcher
   * @default false
   * @example checked={true}
   */
  checked: boolean;
  /**
   * onChange callback fired when the state of the switcher is changed.
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * @param {boolean} Defines if the switcher is disabled or not
   * @default false
   * @example disabled={true}
   */
  disabled?: boolean;
  name?: string;
}

const DtSwitcher: React.FC<DtSwitcherProps> = ({ checked, onChange, disabled = false, name }) => {
  return <CustomSwitcher checked={checked} disableRipple disabled={disabled} onChange={onChange} name={name} />;
};

export default DtSwitcher;
