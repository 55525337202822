import React from 'react';

import { CellContentWrapper } from './dt-dispatch-assigner-table-cell.styled';
import { CellTitle, CellSubtitle } from '../../../../../components/dt-table/table-cell.styled';

interface DtDispatchActionsTableCellProps {
  assignerName: string;
  assignerEmail: string;
}

const DtDispatchAssignerTableCell: React.FC<DtDispatchActionsTableCellProps> = ({ assignerEmail, assignerName }) => {
  return (
    <CellContentWrapper style={{ width: '100%' }}>
      <CellTitle titleBold={false}>{assignerName}</CellTitle>
      <CellSubtitle subtitleBold={false}>{assignerEmail}</CellSubtitle>
    </CellContentWrapper>
  );
};

export default DtDispatchAssignerTableCell;
