import { styled } from '@mui/material/styles';
import { Dialog, DialogActions } from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    width: 640px;
    border-radius: 4px;
    ${({ theme }) => theme.mixins.boxShadowMixin};

    &.MuiDialog-paper {
      max-height: inherit;
    }
  }
`;

export const DialogContentContainer = styled('main')`
  flex: 1 1 auto;
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing(32)};
`;
