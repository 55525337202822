import React, { ReactNode } from 'react';

import { Dropdown, DropdownScrollbar } from '../dt-selects.styled';

export interface DtSelectDropdownProps {
  children?: ReactNode;
  dropdownPaddingBottom?: number;
}

const DtSelectDropdown: React.FC<DtSelectDropdownProps> = ({ children, dropdownPaddingBottom }) => (
  <Dropdown paddingBottom={dropdownPaddingBottom}>
    <DropdownScrollbar>{children}</DropdownScrollbar>
  </Dropdown>
);

export default DtSelectDropdown;
