import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DtRootState } from '../../dt-store';
import { AssignmentItem, AssignmentParent } from '../../repositories/__generated__/v2';

export interface DtAssignmentsState {
  data: AssignmentParent[];
  originalData: AssignmentParent[];
}

const initialState: DtAssignmentsState = {
  data: [],
  originalData: [],
};

const dtAssignmentsSlice = createSlice({
  name: 'dtAssignments',
  initialState,
  reducers: {
    dtSetAssignments: (state, action: PayloadAction<AssignmentParent[]>) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    dtAssignParents: (state, action: PayloadAction<AssignmentParent[]>) => {
      state.data.push(...action.payload);
    },
    dtReplaceChildren: (state, action: PayloadAction<{ parentId: string; children: AssignmentItem[] }>) => {
      const { parentId, children } = action.payload;
      const entity = _.find(state.data, { id: parentId });
      if (entity) {
        entity.children = children;
      }
    },
    dtAddChildren: (state, action: PayloadAction<{ parentId: string; children: AssignmentItem[] }>) => {
      const { parentId, children } = action.payload;
      const entity = _.find(state.data, { id: parentId });

      if (entity) {
        entity.children = [...entity.children, ...children];
      }
    },
    dtUnassignParent: (state, action: PayloadAction<string>) => {
      _.remove(state.data, { id: action.payload });
    },
    dtUnassignChildFromParent: (state, action: PayloadAction<{ parentId: string; childId: string }>) => {
      const { parentId, childId } = action.payload;
      const parent = _.find(state.data, { id: parentId });
      _.remove(parent?.children ?? [], { id: childId });
    },
    dtUnassignChildFromAllParents: (state, action: PayloadAction<string>) => {
      const childId = action.payload;
      for (const parent of state.data) {
        _.remove(parent.children ?? [], { id: childId });
      }
    },
    dtClearAssignments: () => initialState,
    dtRevertAssignments: (state) => {
      state.data = state.originalData;
    },
  },
});

export const dtSelectAssignments = (state: DtRootState): AssignmentParent[] => state.dtAssignments.data;

export const {
  dtSetAssignments,
  dtClearAssignments,
  dtReplaceChildren,
  dtAddChildren,
  dtAssignParents,
  dtUnassignChildFromAllParents,
  dtUnassignChildFromParent,
  dtUnassignParent,
  dtRevertAssignments,
} = dtAssignmentsSlice.actions;

export default dtAssignmentsSlice.reducer;
