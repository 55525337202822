import React, { lazy } from 'react';

import DtNotFoundSkeleton from './dt-not-found-skeleton';
import { dtWithSuspense } from '../../../cdk/hocs/dt-with-suspense.hoc';

const DtNotFoundLazy = dtWithSuspense(
  lazy(() => import('../dt-not-found')),
  <DtNotFoundSkeleton />
);

export default DtNotFoundLazy;
