import { isEmpty } from 'lodash';

import { CompanyData } from '../../repositories/__generated__/v2';

class DtPropertiesService {
  //TODO: Implement data mapping on server side
  mapFormCompanyDataToDtCompanyData(companyData: CompanyData[]): DtCompanyData[] {
    return companyData.map(
      (company): DtCompanyData => ({
        id: company.companyId ?? 0,
        name: company.companyName,
        propertyList:
          company.propertyList?.map((property) => ({
            id: property.propertyId,
            name: property.propertyName,
            region: property.propertyRegion,
            address: property.propertyAddress,
            formTypes:
              property.formTypeList?.map((propType) => ({
                id: propType.formType,
                type: propType.formType,
                formItems:
                  propType.formList?.map((subType) => ({
                    id: subType.formId,
                    name: subType.formName,
                    companyPropertyFormId: subType.companyPropertyFormId,
                    assetList: subType.assetList?.map((asset) => ({
                      rowId: asset.rowId ?? 0,
                      schemaId: asset.formSchemaId,
                      assetName: asset.assetName,
                      floor: asset.floor,
                      location: asset.location,
                    })),
                  })) ?? [],
                formUsers: propType.userList.map((user) => ({
                  id: user.userId,
                  email: user.userEmail,
                  fullName: user.userFullName.replaceAll(/(\s?,\s?)/g, ' '),
                  hasAccessToMobileApp: !isEmpty(user.userName),
                  companyPropertyUserId: user.companyPropertyUserId,
                })),
              })) ?? [],
          })) ?? [],
      })
    );
  }
}

export const dtPropertiesService = new DtPropertiesService();
