/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FormTypeDto } from '../entities';
/**
 * AdminFormTypesApi - axios parameter creator
 * @export
 */
export const AdminFormTypesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerCreate: async (
      formTypeDto: FormTypeDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formTypeDto' is not null or undefined
      assertParamExists('adminFormTypeControllerCreate', 'formTypeDto', formTypeDto);
      const localVarPath = `/admin/form-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formTypeDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormTypeControllerDelete', 'id', id);
      const localVarPath = `/admin/form-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/form-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerUpdate: async (
      formTypeDto: FormTypeDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formTypeDto' is not null or undefined
      assertParamExists('adminFormTypeControllerUpdate', 'formTypeDto', formTypeDto);
      const localVarPath = `/admin/form-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formTypeDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminFormTypesApi - functional programming interface
 * @export
 */
export const AdminFormTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminFormTypesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormTypeControllerCreate(
      formTypeDto: FormTypeDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormTypeControllerCreate(formTypeDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormTypeControllerDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormTypeControllerDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormTypeControllerGetAll(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormTypeDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormTypeControllerGetAll(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormTypeControllerUpdate(
      formTypeDto: FormTypeDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormTypeControllerUpdate(formTypeDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminFormTypesApi - factory interface
 * @export
 */
export const AdminFormTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminFormTypesApiFp(configuration);
  return {
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerCreate(formTypeDto: FormTypeDto, options?: any): AxiosPromise<void> {
      return localVarFp.adminFormTypeControllerCreate(formTypeDto, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.adminFormTypeControllerDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerGetAll(options?: any): AxiosPromise<Array<FormTypeDto>> {
      return localVarFp.adminFormTypeControllerGetAll(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FormTypeDto} formTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormTypeControllerUpdate(formTypeDto: FormTypeDto, options?: any): AxiosPromise<void> {
      return localVarFp.adminFormTypeControllerUpdate(formTypeDto, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminFormTypesApi - interface
 * @export
 * @interface AdminFormTypesApi
 */
export interface AdminFormTypesApiInterface {
  /**
   *
   * @param {FormTypeDto} formTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApiInterface
   */
  adminFormTypeControllerCreate(formTypeDto: FormTypeDto, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApiInterface
   */
  adminFormTypeControllerDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApiInterface
   */
  adminFormTypeControllerGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<FormTypeDto>>;

  /**
   *
   * @param {FormTypeDto} formTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApiInterface
   */
  adminFormTypeControllerUpdate(formTypeDto: FormTypeDto, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * AdminFormTypesApi - object-oriented interface
 * @export
 * @class AdminFormTypesApi
 * @extends {BaseAPI}
 */
export class AdminFormTypesApi extends BaseAPI implements AdminFormTypesApiInterface {
  /**
   *
   * @param {FormTypeDto} formTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApi
   */
  public adminFormTypeControllerCreate(formTypeDto: FormTypeDto, options?: AxiosRequestConfig) {
    return AdminFormTypesApiFp(this.configuration)
      .adminFormTypeControllerCreate(formTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApi
   */
  public adminFormTypeControllerDelete(id: number, options?: AxiosRequestConfig) {
    return AdminFormTypesApiFp(this.configuration)
      .adminFormTypeControllerDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApi
   */
  public adminFormTypeControllerGetAll(options?: AxiosRequestConfig) {
    return AdminFormTypesApiFp(this.configuration)
      .adminFormTypeControllerGetAll(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FormTypeDto} formTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormTypesApi
   */
  public adminFormTypeControllerUpdate(formTypeDto: FormTypeDto, options?: AxiosRequestConfig) {
    return AdminFormTypesApiFp(this.configuration)
      .adminFormTypeControllerUpdate(formTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
