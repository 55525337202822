import { lowerCase, upperFirst } from 'lodash';
import React from 'react';

import { ColoredStatusWrapper, ColoredStatusLabel, IconsDivider } from './dt-user-role-badge.styled';
import DtIcon from '../../../../../components/dt-icon/dt-icon';
import { UserRole } from '../../../../../repositories/__generated__/v2';

interface DtUserRoleBadgeProps {
  userRole: UserRole;
  hasMobileAppAccess: boolean;
  hasWebAppAccess: boolean;
}

const DtUserRoleBadge: React.FC<DtUserRoleBadgeProps> = ({ userRole, hasMobileAppAccess, hasWebAppAccess }) => {
  return (
    <ColoredStatusWrapper userRole={userRole}>
      <DtIcon icon={hasWebAppAccess ? 'web' : 'minus'} size={14} color='secondaryIcon' />
      <IconsDivider> / </IconsDivider>
      <DtIcon icon={hasMobileAppAccess ? 'mobile' : 'minus'} size={14} color='secondaryIcon' />
      <ColoredStatusLabel userRole={userRole}>{upperFirst(lowerCase(userRole))}</ColoredStatusLabel>
    </ColoredStatusWrapper>
  );
};

export default DtUserRoleBadge;
