import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

export const ToggleButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacing(0)} 0 ${theme.spacing(24)}`};
`;

export const ToggleItemsCountChip = styled(Chip)`
  border-radius: 3px;
  height: 20px;
  width: 25px;
  margin-left: ${({ theme }) => theme.spacing(8)};

  & .MuiChip-label {
    padding: 0;
  }
`;
