import React, { LegacyRef, forwardRef } from 'react';

import DtIcon from '../dt-icon/dt-icon';

const DtTooltipTriggerElement = forwardRef(function DtTooltip(props, ref: LegacyRef<HTMLDivElement> | undefined) {
  return (
    <span {...props} ref={ref}>
      <DtIcon icon='Info' size={18} color='secondaryIcon' />
    </span>
  );
});

export default DtTooltipTriggerElement;
