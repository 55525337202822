import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import DtCopyrightFooter from '../../../../components/dt-copyright-footer/dt-copyright-footer';

export const DtAuthLayoutContainer = styled(Grid)`
  height: 100vh;
`;

export const DtAuthLayoutFormContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DtAuthLayoutAppPreviewContainer = styled(DtAuthLayoutFormContainer)`
  display: flex;
  align-items: flex-end;
  background-image: ${({ theme }) =>
    `linear-gradient(141deg, ${theme.palette.main.emerald}, ${theme.palette.main.primary} 100%)`};
  overflow: hidden;
  padding-left: 80px;
`;

export const Logo = styled('img')`
  height: 40px;
  width: auto;
  margin-bottom: ${({ theme }) => theme.spacing(64)};
`;

export const FormContainer = styled('section', { shouldForwardProp: (prop) => prop !== 'isResetPasswordForm' })<{
  isResetPasswordForm?: boolean;
}>`
  width: ${({ isResetPasswordForm }) => (isResetPasswordForm ? '410px' : '320px')};
`;

export const Footer = styled(DtCopyrightFooter)`
  padding-top: ${({ theme }) => theme.spacing(64)};
  align-items: flex-start;
`;
