import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DtRootState } from '../../../dt-store';
import { SiteListDto } from '../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../repositories/dt-api.repository';

export interface DtSitesState {
  status: DtStatus;
  sites: DtSiteRowItem[];
}

export interface DtSiteRowItem extends SiteListDto {
  isLoading?: boolean;
}

const initialState: DtSitesState = {
  status: 'waiting-to-load',
  sites: [],
};

export const dtLoadSites = createAsyncThunk(
  'dtSites/dtLoadSites',
  async (data: { companyId: number; siteId?: number; formType?: string }): Promise<SiteListDto[]> => {
    if (data) {
      const sitesData = (
        await dtApiRepository.AdminSiteApi.adminSiteControllerGetAllCompanySites(
          data.companyId,
          data.siteId,
          data.formType
        )
      )?.data;

      if (sitesData) {
        return sitesData;
      }
    }
    return initialState.sites;
  }
);

const dtSitesSlice = createSlice({
  name: 'dtSites',
  initialState,
  reducers: {
    dtSetSitesData: (state, action: PayloadAction<DtSiteRowItem[]>) => {
      state.sites = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
    dtUpdateLoadingStatusOfSiteRow: (state, action: PayloadAction<{ id: number; status: boolean }>) => {
      const dtSiteRow = _.find(state.sites, { id: action.payload.id });

      if (dtSiteRow) {
        dtSiteRow.isLoading = action.payload.status;
      }
    },
    dtClearSites: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dtLoadSites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dtLoadSites.fulfilled, (state, action) => {
        state.status = 'idle';
        state.sites = action.payload;
      })
      .addCase(dtLoadSites.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = 'failed';
        }
      });
  },
});

export const dtSelectSites = (state: DtRootState): DtSiteRowItem[] => state.dtSites.sites;

export const dtSelectSite =
  (siteId: number) =>
  (state: DtRootState): DtSiteRowItem | undefined =>
    state.dtSites.sites.find((site) => site.id === siteId);

export const dtSelectSitesStatus = (state: DtRootState): DtStatus => state.dtSites.status;

export const dtSelectSitesLoading = (state: DtRootState): boolean =>
  state.dtSites.status === 'loading' || state.dtSites.status === 'waiting-to-load';

export const { dtSetSitesData, dtSetLoadingStatus, dtClearSites, dtUpdateLoadingStatusOfSiteRow } =
  dtSitesSlice.actions;

export default dtSitesSlice.reducer;
