import React from 'react';
import { RouteProps } from 'react-router-dom';

import DtPrivateRoute from './dt-private-route';
import { dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes } from '../../dt-enums';
import { dtSelectIsAuthenticated, dtSelectUserLoading } from '../../modules/dt-user/dt-user.slice';
import DtGlobalLoaderSkeleton from '../dt-global-loader-skeleton/dt-global-loader-skeleton';

const DtUnauthorizedRoute: React.FC<RouteProps> = ({ ...props }) => {
  const isUserLoading = dtUseAppSelector(dtSelectUserLoading);
  const isUnauthorized = !dtUseAppSelector(dtSelectIsAuthenticated);

  if (isUserLoading) {
    return <DtGlobalLoaderSkeleton />;
  }
  return <DtPrivateRoute hasAccess={isUnauthorized} redirectUrl={DtAppRoutes.DashboardOpen} {...props} />;
};

export default DtUnauthorizedRoute;
