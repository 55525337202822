import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import React from 'react';

import { CustomCheckbox } from './dt-checkbox.styled';

export interface DtCheckboxProps extends CheckboxProps {
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
}

const DtCheckbox: React.FC<DtCheckboxProps> = ({ checked, disabled, onChange, size, className }) => (
  <CustomCheckbox
    checked={checked}
    disableRipple
    disabled={disabled}
    onChange={onChange}
    className={className}
    size={size}
  />
);

export default DtCheckbox;
