import { Grid } from '@mui/material';
import React from 'react';

import DtSkeleton from '../../../../../../components/dt-skeleton/dt-skeleton';
import { FieldContainer } from '../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';

const DtFormsPageFormSkeleton: React.FC = () => {
  return (
    <Grid container spacing={24} justifyContent='flex-start'>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtSkeleton variant='rectangular' width='100%' height={40} />
      </FieldContainer>
    </Grid>
  );
};

export default DtFormsPageFormSkeleton;
