import React from 'react';

import DtSkeleton from '../../../dt-skeleton/dt-skeleton';
import {
  AssignmentContent,
  AssignmentsWrapper,
  AssignmentHeader,
  AssignmentHeaderTitle,
  FallBackMessageWrapper,
} from '../../dt-assignment-blocks.styled';

interface DtAssignmentSkeletonProps {
  hideParentAssignButtonSkeleton?: boolean;
  hideChildAssignButtonSkeleton?: boolean;
}

const DtAssignmentSkeleton: React.FC<DtAssignmentSkeletonProps> = ({
  hideParentAssignButtonSkeleton,
  hideChildAssignButtonSkeleton,
}) => {
  return (
    <AssignmentsWrapper style={{ width: '100%' }}>
      <AssignmentContent>
        <AssignmentHeader>
          <AssignmentHeaderTitle>
            <DtSkeleton variant='rectangular' width={43} height={20} />
          </AssignmentHeaderTitle>
          <DtSkeleton variant='rectangular' width={20} height={20} />
          {!hideParentAssignButtonSkeleton && (
            <div style={{ marginLeft: 'auto' }}>
              <DtSkeleton variant='rectangular' width={55} height={18} />
            </div>
          )}
        </AssignmentHeader>
        <FallBackMessageWrapper>
          <DtSkeleton variant='rectangular' width={270} height={18} style={{ marginBottom: '8px' }} />
          <DtSkeleton variant='rectangular' width={282} height={80} style={{ marginBottom: '12px' }} />
          <DtSkeleton variant='rectangular' width={70} height={18} />
        </FallBackMessageWrapper>
      </AssignmentContent>
      <AssignmentContent>
        <AssignmentHeader>
          <AssignmentHeaderTitle>
            <DtSkeleton variant='rectangular' width={43} height={20} />
          </AssignmentHeaderTitle>
          <DtSkeleton variant='rectangular' width={20} height={20} />
          {!hideChildAssignButtonSkeleton && (
            <div style={{ marginLeft: 'auto' }}>
              <DtSkeleton variant='rectangular' width={55} height={18} />
            </div>
          )}
        </AssignmentHeader>
        <FallBackMessageWrapper>
          <DtSkeleton variant='rectangular' width={270} height={18} style={{ marginBottom: '8px' }} />
          <DtSkeleton variant='rectangular' width={282} height={80} style={{ marginBottom: '12px' }} />
          <DtSkeleton variant='rectangular' width={70} height={18} />
        </FallBackMessageWrapper>
      </AssignmentContent>
    </AssignmentsWrapper>
  );
};

export default DtAssignmentSkeleton;
