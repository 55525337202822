import { Typography } from '@mui/material';
import React from 'react';

import { AssetsListContainer, AssetsListItem } from './dt-dispatch-assets.styled';

interface DtDispatchAssetsProps {
  assets: string[];
}

const DtDispatchAssets: React.FC<DtDispatchAssetsProps> = ({ assets }) => {
  // TODO: uncomment when it is needed
  // function handleAssetDeleteClick(): void {
  //   dtToastService.info(DT_FEATURE_NOT_IMPLEMENTED_MESSAGE);
  // }

  return (
    <AssetsListContainer>
      {assets.map((asset, index) => (
        <AssetsListItem key={`asset-${index}`}>
          <Typography variant='body'>{asset}</Typography>
          {/* <DtButton variant='transparent' onClick={handleAssetDeleteClick}>
            <DtIcon icon='trash' size={20} />
          </DtButton> */}
        </AssetsListItem>
      ))}
    </AssetsListContainer>
  );
};

export default DtDispatchAssets;
