import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DtCreateUpdateSiteForm from './components/dt-create-update-site-form/dt-create-update-site-form';
import { DtSiteDetailsInitialValues } from './components/dt-create-update-site-form/dt-create-update-site-form.interface';
import {
  DT_SITE_DETAILS_FORM_VALIDATION,
  DT_SITE_DETAILS_INITIAL_FORM_VALUES,
} from './components/dt-create-update-site-form/dt-create-update-site-form.schema';
import DtSiteAssignments from './dt-site-assignments';
import DtSitePageFormSkeleton from './dt-site-page-form-skeleton/dt-site-page-form-skeleton';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { dtStore } from '../../../../../dt-store';
import { SiteMutationDTO } from '../../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../../../dt-user/dt-user.actions';
import { dtSelectCurrentCompanyId } from '../../../../dt-user/dt-user.slice';
import { dtClearAssignments, dtSetAssignments } from '../../../dt-assignments.slice';
import { dtLoadSites } from '../../../dt-sites-tab/dt-sites.slice';
import DtAdministrationDetailsLayout from '../../dt-administration-details-layout/dt-administration-details-layout';
import {
  DtAdministrationDetailsContent,
  DtAdministrationDetailsContentDivider,
} from '../../dt-administration-details-layout/dt-administration-details-layout.styled';
import DtAdministrationDetailsSkeleton from '../../dt-administration-details-layout/dt-administration-details-skeleton/dt-administration-details-skeleton';
import DtAdministrationDetailsConfirmationPopup from '../../modals/dt-administration-details-confirmation-popup/dt-administration-details-confirmation-popup';

const DtAdministrationDetailsSitePage: React.FC = () => {
  const dispatch = dtUseAppDispatch();
  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const currentSiteId = id;
  const isCreationFlow = !currentSiteId;

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState<SiteMutationDTO | null>(null);
  const [createdSiteId, setCreatedSiteId] = useState<number | null>(null);
  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const pageTitle = !isCreationFlow ? 'Edit Site' : 'Create new site';
  const pageSubtitle = !isCreationFlow ? 'Edit site details and permissions' : 'Provide site details and permissions';

  const backRoute = DtAppRoutes.SitesAdministration;

  useEffect(() => {
    if (currentCompanyId) {
      const loadSitesDispatch = dispatch(dtLoadSites({ companyId: currentCompanyId }));

      return () => {
        loadSitesDispatch.abort();
      };
    }
  }, []);

  useEffect(() => {
    if (!isCreationFlow) {
      setLoading(true);
      dtApiRepository.AdminSiteApi.adminSiteControllerGet(Number(currentSiteId)).then(({ data }) => {
        setSite(data);
        dispatch(dtSetAssignments(data.assignments));
        setLoading(false);
      });
    } else {
      dispatch(dtClearAssignments());
      setLoading(false);
    }
  }, [currentSiteId]);

  async function handleSiteCreateFormSubmit(
    values: DtSiteDetailsInitialValues,
    actions: FormikHelpers<DtSiteDetailsInitialValues>
  ): Promise<void> {
    const initialReportConfig = {
      reportLogo: values.reportConfig.reportLogo ?? (site as SiteMutationDTO)?.reportLogo,
      reportName: values.reportConfig.reportName ?? '',
      reportAddress: values.reportConfig.reportAddress ?? '',
      reportPhone: values.reportConfig.reportPhone ?? '',
      reportFax: values.reportConfig.reportFax ?? '',
    };

    const initialValuesWithoutReportConfig = {
      name: values.name,
      address: values.address,
      city: values.city,
      email: values.email,
      phone: values.phone ?? '',
      state: values.state,
      organizationId: currentCompanyId ?? 0,
      reportHeader: values.reportHeader ?? (site as SiteMutationDTO)?.reportHeader,
      reportFooter: values.reportFooter ?? (site as SiteMutationDTO)?.reportFooter,
      assignments: dtStore.getState().dtAssignments.data,
    };

    const payload: SiteMutationDTO = {
      ...initialValuesWithoutReportConfig,
      ...initialReportConfig,
    };

    try {
      if (isCreationFlow) {
        const response = await dtApiRepository.AdminSiteApi.adminSiteControllerCreate(payload);
        setCreatedSiteId(response.data);
        setShowConfirmationPopup(true);
        actions.resetForm({ values: { ...initialValuesWithoutReportConfig, reportConfig: initialReportConfig } });
      } else if (site) {
        await dtApiRepository.AdminSiteApi.adminSiteControllerUpdate(Number(currentSiteId), payload);
      }
      await dispatch(dtRefreshCurrentUserData());

      if (currentCompanyId) {
        await dispatch(dtLoadSites({ companyId: currentCompanyId }));
      }

      if (site) {
        actions.resetForm({ values: { ...initialValuesWithoutReportConfig, reportConfig: initialReportConfig } });
        dtToastService.success('Successfully updated site');
      }
    } catch (error) {
      dtToastService.error(`Failed to ${!isCreationFlow ? 'update' : 'create new'} site`);
    }
  }

  return (
    <>
      {loading && (
        <Formik initialValues={{}} onSubmit={() => Promise.resolve()}>
          <DtAdministrationDetailsLayout
            pageTitle={pageTitle}
            pageSubtitle={pageSubtitle}
            backButtonRoute={backRoute}
            isCreationFlow={isCreationFlow}
            setValidationAfterSubmit={setValidationAfterSubmit}
          >
            <DtAdministrationDetailsSkeleton hideParentAssignButtonSkeleton>
              <DtSitePageFormSkeleton />
            </DtAdministrationDetailsSkeleton>
          </DtAdministrationDetailsLayout>
        </Formik>
      )}
      {!loading && (
        <Formik
          initialValues={DT_SITE_DETAILS_INITIAL_FORM_VALUES(site)}
          onSubmit={handleSiteCreateFormSubmit}
          validationSchema={DT_SITE_DETAILS_FORM_VALIDATION}
          validateOnChange={validationAfterSubmit}
          validateOnBlur={validationAfterSubmit}
          enableReinitialize
        >
          {(formikProps) => {
            return (
              <>
                <DtAdministrationDetailsLayout
                  pageTitle={pageTitle}
                  pageSubtitle={pageSubtitle}
                  backButtonRoute={backRoute}
                  isCreationFlow={isCreationFlow}
                  setValidationAfterSubmit={setValidationAfterSubmit}
                >
                  <DtAdministrationDetailsContent>
                    <DtCreateUpdateSiteForm isCreationFlow={isCreationFlow} />
                  </DtAdministrationDetailsContent>
                  <DtAdministrationDetailsContentDivider />
                  <DtSiteAssignments organizationId={currentCompanyId} siteId={Number(currentSiteId)} />
                </DtAdministrationDetailsLayout>
                {showConfirmationPopup && (
                  <DtAdministrationDetailsConfirmationPopup
                    opened={showConfirmationPopup}
                    onClose={() => {
                      // Go to details on popup close
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      if (createdSiteId) {
                        dtRouter.navigate(RoutesResolver.SiteDetails(createdSiteId.toString()), { replace: true });
                      } else {
                        throw 'There is no ID of created site';
                      }
                    }}
                    onSubmit={() => {
                      // Reset form for creation
                      setShowConfirmationPopup(false);
                      setValidationAfterSubmit(false);
                      formikProps.resetForm({ values: formikProps.initialValues });
                      dispatch(dtClearAssignments());
                    }}
                    title='Site created successfully'
                    icon='check'
                    color='emerald'
                    subtitle=''
                    isShowConfirmationMessage={showConfirmationPopup}
                    submitButtonText='Create another site'
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DtAdministrationDetailsSitePage;
