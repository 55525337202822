import { CSSObject } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const TRANSPARENT_BORDER = '1.5px solid transparent';

export const DtCustomTimePicker = styled(TimePicker, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>`
  ${({ theme }) => theme.mixins.textInputCommonStylesMixin(theme)};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  & input {
    color: ${({ theme }) => theme.palette.main.black};
  }

  & .MuiOutlinedInput-root fieldset,
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border: ${TRANSPARENT_BORDER};
  }

  & .MuiOutlinedInput-root:hover {
    background-color: #e6edf5;

    fieldset {
      border: ${TRANSPARENT_BORDER};
    }
  }

  & .MuiOutlinedInput-root.focused {
    background-color: #fff;

    fieldset {
      border: ${({ theme }) => `1.5px solid ${theme.palette.main.primary}`};
    }
  }

  & .Mui-disabled {
    opacity: 40%;
  }

  & .MuiInputBase-root.Mui-error {
    background-color: #fef3f4;

    fieldset {
      border: ${({ theme }) => `1.5px solid ${theme.palette.main.red}`};
    }
  }

  & .MuiFormHelperText-root {
    ${({ theme }) => theme.typography.caption as CSSObject};
    position: absolute;
    top: calc(100% + 1px);

    &.Mui-error {
      margin: 0;
      color: ${({ theme }) => theme.palette.main.red};
    }
  }
`;
