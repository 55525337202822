import * as yup from 'yup';

import { DtUserDetailsInitialValues } from './dt-administration-details-user-form.interface';
import { UserDetailsDto } from '../../../../../../repositories/__generated__/v2';

export const DT_USER_DETAILS_INITIAL_FORM_VALUE = (
  user?: UserDetailsDto | null | undefined
): DtUserDetailsInitialValues => {
  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    mobileUsername: user?.mobileAppUsername ?? '',
    role: user?.role ?? '',
    hasMobileAccess: user?.hasMobileAppAccess ?? false,
    hasWebAccess: user?.hasWebAppAccess ?? false,
  };
};

export const DT_USER_DETAILS_FORM_VALIDATION: yup.ObjectSchema<DtUserDetailsInitialValues> = yup.object().shape({
  firstName: yup.string().label('First Name').required('Required'),
  lastName: yup.string().label('Last Name').required('Required'),
  email: yup.string().label('Email').required('Required').email('Email should be a valid'),
  phone: yup
    .string()
    .label('Phone')
    .matches(/^\(\d{3}\) \d{3}\-\d{4}$/, { message: 'Must have (000) 000-0000 format', excludeEmptyString: true }),
  mobileUsername: yup
    .string()
    .label('Username')
    .when('hasMobileAccess', {
      is: true,
      then: () => yup.string().required('Required'),
    }),
  hasMobileAccess: yup.boolean(),
  hasWebAccess: yup.boolean(),
  role: yup
    .string()
    .label('User Type')
    .when('hasWebAccess', {
      is: true,
      then: () => yup.string().required('Required'),
    }),
});
