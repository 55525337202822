import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';

import { InputLabel } from './../../../../../dt-auth/components/dt-form-common-styles.styled';
import { AssigneeFieldWrapper, CustomCheckbox, CustomCheckboxWrapper } from './dt-new-dispatch-assignees.styled';
import { dtUseAppSelector } from '../../../../../../cdk/hooks/dt-store.hooks';
import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { UserRole } from '../../../../../../repositories/__generated__/v2';
import { dtSelectGetAvailableFormUsers } from '../../../../../dt-properties/dt-properties.slice';
import { dtSelectUser } from '../../../../../dt-user/dt-user.slice';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';

interface DtNewDispatchAssigneesProps {
  siteId?: number;
  formType?: string;
  isEditFlow: boolean;
}

const DtNewDispatchAssignees: React.FC<DtNewDispatchAssigneesProps> = ({ siteId, formType, isEditFlow }) => {
  const { setFieldValue, values } = useFormikContext<DtNewDispatchFormInitialValues>();

  const currentUser = dtUseAppSelector(dtSelectUser);
  const getAvailableUsersBySelectedProperty = dtUseAppSelector(dtSelectGetAvailableFormUsers(siteId, formType));

  const isSuperAdmin = currentUser?.userRole === UserRole.SuperAdmin;

  useEffect(() => {
    if (!isSuperAdmin && !isEditFlow) {
      setFieldValue('assignees.assigner', currentUser?.userEmail);
    }
  }, []);

  const currentUserEmail = isSuperAdmin ? currentUser?.userEmail : undefined;
  let addSuperAdmin = true;

  const availableUsers = sortBy(
    getAvailableUsersBySelectedProperty
      .map((user) => {
        if (user.email === currentUserEmail) {
          addSuperAdmin = false;
        }
        return {
          value: user.email,
          label: user.fullName,
          email: user.email,
          hasAccessToMobileApp: user.hasAccessToMobileApp,
        };
      })
      .concat(
        addSuperAdmin && isSuperAdmin && currentUser
          ? [
              {
                value: currentUser.userEmail,
                label: `${currentUser.firstName} ${currentUser.lastName}`,
                email: currentUser.userEmail,
                hasAccessToMobileApp: true,
              },
            ]
          : []
      ),
    'label'
  );

  const sortedAvailableAssigneesByPropertyId = availableUsers.filter((user) => user.hasAccessToMobileApp);
  const sortedAvailableAssignersByPropertyId = availableUsers;

  function handleCheckboxStateChange(): void {
    setFieldValue('assignees.enableEmailNotificaition', !values.assignees.enableEmailNotificaition);
  }

  return (
    <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
      <Grid item xs={12}>
        <DtFormikSelect<string>
          label='Assigned By'
          name='assignees.assigner'
          labelPosition='top'
          options={sortedAvailableAssignersByPropertyId}
          placeholder='Select Assigner'
          fullWidth={true}
          disabled={!isSuperAdmin}
        />
      </Grid>

      <Grid item xs={12}>
        <AssigneeFieldWrapper>
          <InputLabel>Assigned To</InputLabel>
          <CustomCheckboxWrapper>
            <InputLabel>Email notifications</InputLabel>
            <CustomCheckbox
              size='small'
              checked={Boolean(values.assignees.enableEmailNotificaition)}
              name='assignees.enableEmailNotificaition'
              onChange={handleCheckboxStateChange}
            />
          </CustomCheckboxWrapper>
        </AssigneeFieldWrapper>
        <DtFormikMultipleSelect
          name='assignees.assignee'
          labelPosition='top'
          options={sortedAvailableAssigneesByPropertyId}
          placeholder='Select Assignee'
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

export default DtNewDispatchAssignees;
