import React, { ReactElement, ReactNode } from 'react';

import { CustomToggleButton, ToggleButtonsContainer } from './dt-toggle-button.styled';

export interface DtTabsItem<T> {
  /**
   * Value of each tab
   */
  value: T;
  /**
   * Label component displayed on tab
   */
  label: ReactNode;
  /**
   * Disable tab item
   * @default undefined - tab enabled
   */
  disabled?: boolean;
}

export interface DtTabsProps<T> {
  /**
   * Initial tab value
   */
  value: T;
  /**
   * Tabs panel items
   */
  tabsItems: DtTabsItem<T>[];
  /**
   * Centering tabs inside container. If true - centered, false - align to left corner.
   * @default false
   */
  centered?: boolean;
  /**
   * Tab change custom handler
   * @param newValue
   */
  onChange: (newValue: T) => void;
  /**
   * Changes the width of tabs container to make it full width
   * @param boolean
   */
  fullWidth?: boolean;
  /**
   * Changes the alignment of tabs container
   * @param boolean
   */
  alignTabs?: boolean;
  stretchTabs?: boolean;
  minWidth?: string;
}

/**
 * Common tabs component
 */
function DtToggleButton<T>({
  value,
  tabsItems,
  onChange,
  centered = false,
  fullWidth,
  alignTabs,
  stretchTabs,
  minWidth,
}: DtTabsProps<T>): ReactElement {
  return (
    <>
      <ToggleButtonsContainer
        value={value}
        onChange={(e, newValue) => onChange(newValue)}
        centered={centered}
        fullWidth={fullWidth}
        alignTabs={alignTabs}
        stretchTabs={stretchTabs}
      >
        {tabsItems.map(({ value, label, disabled }) => (
          <CustomToggleButton key={String(value)} label={label} value={value} disabled={disabled} minWidth={minWidth} />
        ))}
      </ToggleButtonsContainer>
    </>
  );
}

export default DtToggleButton;
