// TODO: rewrite to setTimeout @see https://javascript.info/settimeout-setinterval

/**
 * Simple wrapper around single timer to make it controllable
 */
export class DtManagedInterval {
  private intervalId?: number;

  get isRunning(): boolean {
    return Boolean(this.intervalId);
  }

  constructor(
    /**
     * Duration is provided in milliseconds
     */
    private intervalDuration: number,
    private callback: VoidFunction
  ) {}

  start(triggerOnStart?: boolean): void {
    if (this.isRunning) {
      throw `Interval for each ${this.intervalDuration} ms is already running, no need to start it`;
    }

    // TODO: find how to fix typings
    //
    // Need to make type conversion, because TS defines that setInterval
    // returns `NodeJS.Timeout` which is not right because React is running
    // in Browser and not Node env
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.intervalId = setInterval(this.callback, this.intervalDuration) as any as number;

    if (triggerOnStart) {
      this.callback();
    }
  }

  stop(): void {
    if (!this.isRunning) {
      // eslint-disable-next-line no-console
      console.info(`Interval for each ${this.intervalDuration} ms is already stopped, no need to stop it`);
    }

    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  restart(): void {
    this.stop();
    this.start();
  }
}
