import { CSSObject, styled } from '@mui/material/styles';
import DtFormikDatePicker from '../../../../../../components/dt-date-pickers/dt-formik-date-picker/dt-formik-date-picker';

export const FrequencyInputContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const WeekFrequencySelectionContainer = styled('div')`
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-gap: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const FrequencySelectionContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: ${({ theme }) => theme.spacing(24)};
`;

export const FrequencyReminderSelectionContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(24)};
`;

export const DateLabel = styled('span', { shouldForwardProp: (prop) => prop !== 'disabled' })<{
  disabled?: boolean;
}>`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
  opacity: ${({ disabled }) => (disabled ? '40%' : '100%')};
`;

export const AdditionalFiledLabel = styled('span')`
  opacity: 60%;
`;

export const CustomDueDateField = styled(DtFormikDatePicker)`
  margin-top: ${({ theme }) => theme.spacing(20)};
`;
