import React from 'react';

import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import { dtDaysOfWeekSelectOptions, dtTimeSelectOptions } from '../../dt-frequency-editor-form.utils';
import { WeekFrequencySelectionContainer } from '../../steps/dt-new-dispatch-frequency/dt-new-dispatch-frequency.styled';

const DtNewDispatchFrequencyWeekly: React.FC = () => {
  return (
    <>
      <WeekFrequencySelectionContainer>
        <div>
          <InputLabel>Every week(s)</InputLabel>
          <DtFormTextInput type='number' name='frequency.weekRepeats' InputProps={{ inputProps: { min: 1 } }} />
        </div>

        <DtFormikMultipleSelect
          fullWidth
          name='frequency.weekday'
          label='Weekday'
          labelPosition='top'
          options={dtDaysOfWeekSelectOptions}
          placeholder='Select weekday'
          minWidth='180px'
          tagsToPreview={3}
        />
      </WeekFrequencySelectionContainer>

      <DtFormikMultipleSelect
        fullWidth
        name='frequency.time'
        label='At time'
        labelPosition='top'
        options={dtTimeSelectOptions}
        placeholder='Select time'
        minWidth='180px'
        tagsToPreview={3}
      />
    </>
  );
};

export default DtNewDispatchFrequencyWeekly;
