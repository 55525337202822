import React, { useState } from 'react';

import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtDeleteConfirmationPopup from '../../../../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtIcon from '../../../../../components/dt-icon/dt-icon';
import {
  ActionButtonsWrapper,
  CenteredButton,
  IconPlaceholder,
} from '../../../../../components/dt-table/dt-actions-table-cell.styled';
import { dtSelectCurrentCompanyId } from '../../../../dt-user/dt-user.slice';
import { dtDeleteFormAction, dtRefreshFormAction } from '../../dt-forms.actions';

interface DtFormsTableCellActionsProps {
  id: string;
  formTypeId: number;
  formSchemaId: number;
  siteId: number;
  formName: string;
}

const DtFormsTableCellActions: React.FC<DtFormsTableCellActionsProps> = ({
  id,
  formTypeId,
  formSchemaId,
  siteId,
  formName,
}) => {
  const dispatch = dtUseAppDispatch();

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const [showConfirmationMessage, updateShowConfirmationMessage] = useState(false);

  function handleOpenPopup(): void {
    updateShowConfirmationMessage(true);
  }

  async function handleDeleteForm(): Promise<void> {
    if (currentCompanyId) {
      await dispatch(dtDeleteFormAction(id, formTypeId, formSchemaId, currentCompanyId, siteId, formName));
      updateShowConfirmationMessage(false);
    }
  }

  async function handleRefreshSiteForm(): Promise<void> {
    await dispatch(dtRefreshFormAction(id, formTypeId, formSchemaId, formName));
  }

  return (
    <ActionButtonsWrapper gap={5}>
      <DtDeleteConfirmationPopup
        opened={showConfirmationMessage}
        onClose={() => {
          updateShowConfirmationMessage(false);
        }}
        onSubmit={handleDeleteForm}
        title='Delete selected form?'
        subtitle='You will not be able to recover it.'
        icon='trash'
        color='red'
      />
      <IconPlaceholder style={{ marginRight: '28px' }} />
      <CenteredButton variant='transparent' onClick={handleRefreshSiteForm}>
        <DtIcon icon='refresh' size={20} color='secondaryIcon' />
      </CenteredButton>
      <CenteredButton variant='transparent' onClick={handleOpenPopup}>
        <DtIcon icon='trash' size={20} color='secondaryIcon' />
      </CenteredButton>
    </ActionButtonsWrapper>
  );
};

export default DtFormsTableCellActions;
