import { Grid } from '@mui/material';
import React from 'react';

import { DialogActionsWrapper } from './components/dt-new-dispatch-modal-actions/dt-new-dispatch-modal-actions.styled';
import { FieldContainer } from './steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';

const DtNewDispatchModalSkeleton: React.FC = () => {
  return (
    <>
      <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
        <Grid item xs={12}>
          <DtSkeleton variant='rectangular' width='100%' height={40} />
        </Grid>

        <FieldContainer item xs={6}>
          <DtSkeleton variant='rectangular' width='100%' height={40} />
        </FieldContainer>

        <FieldContainer item xs={6}>
          <DtSkeleton variant='rectangular' width='100%' height={40} />
        </FieldContainer>

        <Grid item xs={12}>
          <DtSkeleton variant='rectangular' width='100%' height={40} />
        </Grid>

        <Grid item xs={12}>
          <DtSkeleton variant='rectangular' width='100%' height={40} />
        </Grid>
      </Grid>
      <DialogActionsWrapper>
        <div style={{ marginRight: '24px' }}>
          <DtSkeleton variant='rectangular' width='128px' height={40} />
        </div>
        <DtSkeleton variant='rectangular' width='128px' height={40} />
      </DialogActionsWrapper>
    </>
  );
};

export default DtNewDispatchModalSkeleton;
