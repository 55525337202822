import React from 'react';

import {
  FooterContent,
  MenuContainer,
  MenuItemContainer,
  MenuItemIconContainer,
  MenuPropertiesSelectContainer,
  NewDispatchButtonContainer,
} from './dt-navigation-skeleton.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { UpperContent } from '../../components/dt-navbar/dt-navbar-header/dt-navbar-header.styled';
import { DrawerContainer, Footer, NavbarSection } from '../../components/dt-navbar/dt-navbar.styled';

const DtNavigationSkeleton: React.FC = () => {
  return (
    <DrawerContainer open variant='permanent'>
      <NavbarSection>
        <UpperContent extended>
          <DtSkeleton variant='rectangular' width={108} height={32} />
        </UpperContent>

        <NewDispatchButtonContainer>
          <DtSkeleton variant='rectangular' height={40} />
        </NewDispatchButtonContainer>
      </NavbarSection>

      <NavbarSection>
        <MenuContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
          <MenuPropertiesSelectContainer>
            <DtSkeleton variant='rectangular' height={63} width='100%' />
          </MenuPropertiesSelectContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
          <MenuItemContainer>
            <MenuItemIconContainer>
              <DtSkeleton variant='rectangular' width={20} height={20} />
            </MenuItemIconContainer>
            <DtSkeleton variant='rectangular' width={195} height={20} />
          </MenuItemContainer>
        </MenuContainer>
      </NavbarSection>

      <Footer>
        <FooterContent>
          <DtSkeleton variant='rectangular' height={85} />
        </FooterContent>
      </Footer>
    </DrawerContainer>
  );
};

export default DtNavigationSkeleton;
