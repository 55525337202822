import { add, isToday } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { FrequencyNoneFormContainer, NoteMessageContainer, NoteText } from './dt-new-dispatch-frequency.styled';
import DtFormikDatePicker from '../../../../../../components/dt-date-pickers/dt-formik-date-picker/dt-formik-date-picker';
import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { dtTimeSelectOptions } from '../../dt-frequency-editor-form.utils';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';
import { DT_DUE_DATE_MOCK_OPTIONS } from '../../dt-new-dispatch-modal.constants';
import { CustomDueDateField, DateLabel } from '../../steps/dt-new-dispatch-frequency/dt-new-dispatch-frequency.styled';

interface DtNewDispatchFrequencyNoneProps {
  isEditFlow: boolean;
}

const DtNewDispatchFrequencyNone: React.FC<DtNewDispatchFrequencyNoneProps> = ({ isEditFlow }) => {
  const { setFieldValue, values } = useFormikContext<DtNewDispatchFormInitialValues>();

  const isNowSelected = values.frequency.time?.length === 1 && values.frequency.time[0] === -1;
  const isHoursSelected = (values.frequency.time?.length ?? 0) >= 1 && values.frequency.time?.[0] !== -1;

  const dispatchDateIsToday = isToday(values.frequency.startDate);
  const nowHour = new Date().getHours();

  const timeSelectOptions = useMemo(() => {
    const result = [];

    if (!isEditFlow) {
      result.push({
        label: 'Now',
        value: -1,
        disabled: isHoursSelected || !dispatchDateIsToday,
      });
    }

    result.push(
      ...dtTimeSelectOptions
        .filter((v) => {
          return dispatchDateIsToday ? v.value > nowHour : true;
        })
        .map((v) => ({ ...v, disabled: isNowSelected }))
    );

    return result;
  }, [isHoursSelected, dispatchDateIsToday, nowHour, isNowSelected, isEditFlow]);

  // Cleanup frequency time if start date is not today and time is selected as now
  useEffect(() => {
    if (isNowSelected && !dispatchDateIsToday) {
      setFieldValue('frequency.time', []);
    }
    if (dispatchDateIsToday) {
      setFieldValue('frequency.time', values.frequency.time?.filter((v) => v > nowHour) ?? []);
    }
  }, [dispatchDateIsToday]);

  useEffect(() => {
    if (!isEditFlow) {
      setFieldValue('frequency.startDate', new Date());
    }
  }, []);

  const disabledDays = {
    before: add(values.frequency.startDate, {
      days: 1,
    }),
  };

  function handleStartDateChange(value: Date): void {
    setFieldValue(
      'frequency.customDueDate',
      add(value, {
        days: 1,
      })
    );
  }

  return (
    <>
      <FrequencyNoneFormContainer>
        <div>
          <DateLabel disabled={isEditFlow}>Dispatch Date</DateLabel>
          <DtFormikDatePicker
            onChange={handleStartDateChange}
            name='frequency.startDate'
            fullWidth
            disabled={isEditFlow}
            disabledDays={{
              before: new Date(),
            }}
          />
        </div>
        <div>
          <DtFormikSelect
            fullWidth
            labelPosition='top'
            label='Due Date'
            name='frequency.dueDate'
            options={DT_DUE_DATE_MOCK_OPTIONS}
            placeholder='Select Due Date'
          />
          {values.frequency.dueDate === 'Custom' && (
            <CustomDueDateField disabledDays={disabledDays} name='frequency.customDueDate' fullWidth />
          )}
        </div>
        <div style={{ gridColumnStart: '1', gridColumnEnd: '3' }}>
          <DtFormikMultipleSelect
            fullWidth
            name='frequency.time'
            label='At time'
            labelPosition='top'
            options={timeSelectOptions}
            placeholder='Select time'
            minWidth='220px'
            disableSelectAll
          />
        </div>
      </FrequencyNoneFormContainer>
      <NoteMessageContainer>
        <NoteText variant='bodySemibold' style={{ marginBottom: '8px' }}>
          Please note
        </NoteText>
        <NoteText variant='body' style={{ marginBottom: '8px' }}>
          Dispatch frequency settings define the rule for the repeatable dispatches.
        </NoteText>
        <NoteText variant='body'>
          If the “None” option is selected the dispatch will be created only once and will not be re-created after the
          completion.
        </NoteText>
      </NoteMessageContainer>
    </>
  );
};

export default DtNewDispatchFrequencyNone;
