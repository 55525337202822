import React from 'react';

import DtDashboardFilters from '../../../../components/dt-dashboard-filters/dt-dashboard-filters';
import { FilterPanelContainer } from '../../../../components/dt-dashboard-filters/dt-dashboard-filters.styled';

interface DtAssetsFilterPanelProps {
  isLoading: boolean;
}

const DtAssetsFilterPanel: React.FC<DtAssetsFilterPanelProps> = ({ isLoading }) => {
  return (
    <FilterPanelContainer>
      <DtDashboardFilters isLoading={isLoading} />
    </FilterPanelContainer>
  );
};

export default DtAssetsFilterPanel;
