import React from 'react';

import { CellSubtitle, CellTitle } from '../../../../../components/dt-table/table-cell.styled';

interface DtDispatchTypeTableCellProps {
  assetsCount: number;
  dispatchType: string;
  dispatchSubtype: string;
}

const DtDispatchTypeTableCell: React.FC<DtDispatchTypeTableCellProps> = ({
  assetsCount,
  dispatchType,
  dispatchSubtype,
}) => {
  const assetsLabel = assetsCount === 1 ? 'Asset' : 'Assets';
  const subtypeSuffix = assetsCount >= 1 ? ':' : '';

  return (
    <div>
      <CellTitle titleBold={true}>{dispatchType}</CellTitle>
      <div>
        <CellSubtitle subtitleBold={false}>
          {dispatchSubtype}
          {subtypeSuffix}&nbsp;<b>{assetsCount >= 1 ? `${assetsCount} ${assetsLabel}` : ''}</b>
        </CellSubtitle>
      </div>
    </div>
  );
};

export default DtDispatchTypeTableCell;
