import { Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

import { DtDateItem, DtTimeItem } from './dt-questions-table.styled';
import { AnswerDto, AnswerType } from '../../repositories/__generated__/v2';
import DtIcon from '../dt-icon/dt-icon';

const trueStrings = [
  'true',
  'yes',
  '✔',
  '✓',
  '1',
  'on',
  'enabled',
  'allow',
  'positive',
  'affirmative',
  'allow',
  'permit',
  'accept',
  'approve',
];
const falseStrings = [
  'false',
  'no',
  '✘',
  '0',
  'off',
  'disabled',
  'deny',
  'negative',
  'decline',
  'deny',
  'reject',
  'disapprove',
];

/**
 * Checks if the value is a supported boolean string
 */
function isSupportedBooleanString(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  return trueStrings.includes(value?.toLowerCase()) || falseStrings.includes(value?.toLowerCase());
}

/**
 * Parses a boolean string to a boolean
 */
function parseBooleanString(value: string): boolean {
  if (trueStrings.includes(value.toLowerCase())) {
    return true;
  } else if (falseStrings.includes(value.toLowerCase())) {
    return false;
  }
  throw new Error('Invalid boolean string');
}

export const DtCellContentWithAnswer: React.FC<{ answer: AnswerDto }> = ({ answer }) => {
  // Render comment if there is a comment
  if (answer.comment) {
    return (
      <Typography variant='body' color='black'>
        {answer.comment}
      </Typography>
    );
  }

  // Render boolean answer as boolean, or string answer as boolean if it is a supported boolean string
  const shouldRenderAsBoolean =
    answer.answerType === AnswerType.Boolean ||
    (answer.answerType === AnswerType.String && isSupportedBooleanString(answer.answerContent));
  if (shouldRenderAsBoolean) {
    const truthy =
      answer.answerType === AnswerType.Boolean
        ? Boolean(answer.answerContent)
        : parseBooleanString(answer.answerContent as unknown as string);
    return (
      <>{truthy ? <DtIcon icon='check' size={20} color='emerald' /> : <DtIcon icon='close' size={20} color='red' />}</>
    );
  }

  // Render timestamp answer as formatted date and time
  if (answer.answerType === AnswerType.Timestamp && typeof answer.answerContent === 'string') {
    if (!answer.answerContent) {
      return <>Invalid date</>;
    }

    const date = format(new Date(answer.answerContent), 'MM/dd/yy');
    const time = format(new Date(answer.answerContent), 'p');

    return (
      <div style={{ flex: 'none' }}>
        <DtDateItem>{date}</DtDateItem>
        <DtTimeItem>{time}</DtTimeItem>
      </div>
    );
  }

  if (!answer.answerType && !answer.answerContent) {
    return (
      <Typography variant='body' color='black'>
        -
      </Typography>
    );
  }

  return (
    <Typography variant='body' color='black'>
      <>{answer.answerContent}</>
    </Typography>
  );
};
