import { styled, CSSObject } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const FooterContainer = styled(Grid)`
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.main.secondaryText};
  padding-top: ${({ theme }) => theme.spacing(56)};
  padding-bottom: ${({ theme }) => theme.spacing(23)};
  opacity: 80%;
  margin-top: auto;
`;

export const CompanyName = styled('span')`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const Copyright = styled('span')`
  ${({ theme }) => theme.typography.body as CSSObject};
`;
