import * as yup from 'yup';

import { DtCreateUpdateAssetsValues } from './dt-create-update-assets-form.interface';

export const DT_CREATE_UPDATE_ASSETS_FORM_VALIDATION: yup.ObjectSchema<DtCreateUpdateAssetsValues> = yup
  .object()
  .shape({
    assetKey: yup.string().label('Asset Name').required('Required'),
    floorKey: yup.string().label('Asset Floor').required('Required'),
    locationKey: yup.string().label('Asset Location').required('Required'),
  });
