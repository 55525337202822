import { Auth, Amplify } from 'aws-amplify';

/**
 * Service for authentication and jwt management
 */
class DtAuthService {
  init(): void {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_AUTH_REGION,
        userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_USER_POOL_CLIENT_ID,
      },
      oauth: {
        scope: ['email', 'openid', 'phone', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'token',
      },
    });
  }

  signIn(username: string, password: string): Promise<DtAWSCognitoUser> {
    return Auth.signIn(username, password);
  }

  /**
   * Reset authentication data for user
   * */
  signOut(): Promise<void> {
    return Auth.signOut();
  }

  completeNewPassword(user: DtAWSCognitoUser, newPassword: string): Promise<void> {
    return Auth.completeNewPassword(user, newPassword);
  }

  forgotPassword(username: string): Promise<void> {
    return Auth.forgotPassword(username);
  }

  forgetPasswordOnSubmit(code: string, username: string, newPassword: string): Promise<string> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  getUser(): Promise<DtAWSCognitoUser> {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  async getAccessToken(): Promise<string | undefined> {
    const userSession = await Auth.currentSession();

    return userSession.isValid() ? userSession.getAccessToken().getJwtToken() : undefined;
  }
}

export const dtAuthService = new DtAuthService();
