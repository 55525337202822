import React from 'react';

import { ConfirmDialogButton } from './dt-administration-details-confirmation-popup.styled';
import DtPopup, { DtPopupProps } from '../../../../../components/dt-popup/dt-popup';

export interface DtAdministrationDetailsConfirmationPopupProps extends DtPopupProps {
  isShowConfirmationMessage?: boolean;
  submitButtonText: string;
  onSubmit: () => void;
}

const DtAdministrationDetailsConfirmationPopup: React.FC<DtAdministrationDetailsConfirmationPopupProps> = (props) => {
  return (
    <DtPopup
      icon={props.icon}
      color={props.color}
      onClose={props.onClose}
      opened={props.opened}
      title={props.title}
      subtitle={props.subtitle}
    >
      {props.isShowConfirmationMessage && (
        <>
          <ConfirmDialogButton variant='secondary' onClick={props.onClose}>
            Go to details
          </ConfirmDialogButton>
          <ConfirmDialogButton variant='primary' onClick={props.onSubmit}>
            {props.submitButtonText}
          </ConfirmDialogButton>
        </>
      )}
    </DtPopup>
  );
};

export default DtAdministrationDetailsConfirmationPopup;
