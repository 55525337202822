import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DtInspectionDetailsFilters } from './dt-inspection-details.types';
import { DtDatePickerRange } from '../../components/dt-date-pickers/dt-date-pickers.enums';
import { DT_PICKER_DAYS_RANGE } from '../../dt-constants';
import { DtStatus } from '../../dt-enums';
import { DtRootState } from '../../dt-store';
import { DispatchInspectionDetailsDto } from '../../repositories/__generated__/v2';

export interface DtInspectionDetailsState {
  status: DtStatus;
  formSubtypeData: DispatchInspectionDetailsDto | undefined;
  filters: DtInspectionDetailsFilters;
  lastUpdatedDatetime: Date;
}

const initialState: DtInspectionDetailsState = {
  status: DtStatus.WaitingToLoad,
  formSubtypeData: undefined,
  filters: {
    propertyId: undefined,
    formSubtypeId: undefined,
    size: 10,
    offset: 0,
    assetName: null,
    schemaName: null,
    dateRange: DT_PICKER_DAYS_RANGE[DtDatePickerRange.LastThirtyDays],
  },
  lastUpdatedDatetime: new Date(),
};

const dtInspectionDetailsSlice = createSlice({
  name: 'dtInspectionDetails',
  initialState,
  reducers: {
    dtSetFormSubtypeData: (state, action: PayloadAction<DispatchInspectionDetailsDto>) => {
      state.formSubtypeData = action.payload;
      state.status = action.payload ? DtStatus.Idle : DtStatus.Failed;
    },
    dtSetFiltersData: (state, action: PayloadAction<DtInspectionDetailsFilters>) => {
      state.filters = action.payload;
    },
    dtUpdateFiltersData: (state, action: PayloadAction<Partial<DtInspectionDetailsFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    dtSetLastUpdatedDatetime: (state, action: PayloadAction<Date>) => {
      state.lastUpdatedDatetime = action.payload;
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
  },
});

export const dtSelectFormSubtypeData = (state: DtRootState): DispatchInspectionDetailsDto | undefined =>
  state.dtInspectionDetails.formSubtypeData;

export const dtSelectFilters = (state: DtRootState): DtInspectionDetailsFilters => state.dtInspectionDetails.filters;

export const dtSelectLastUpdatedDatetime = (state: DtRootState): Date => state.dtInspectionDetails.lastUpdatedDatetime;

export const dtSelectFormSubtypeDataLoading = (state: DtRootState): boolean =>
  state.dtInspectionDetails.status === DtStatus.Loading || state.dtInspectionDetails.status === DtStatus.WaitingToLoad;

export const {
  dtSetFormSubtypeData,
  dtSetFiltersData,
  dtUpdateFiltersData,
  dtSetLastUpdatedDatetime,
  dtSetLoadingStatus,
} = dtInspectionDetailsSlice.actions;

export default dtInspectionDetailsSlice.reducer;
