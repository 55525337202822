import React from 'react';

import DtPopup, { DtPopupProps } from '../dt-popup/dt-popup';
import { DialogButton } from '../dt-popup/dt-popup.styled';

export interface DtDeleteConfirmationPopupProps extends DtPopupProps {
  onSubmit: () => void;
}

const DtDeleteConfirmationPopup: React.FC<DtDeleteConfirmationPopupProps> = (props) => {
  return (
    <DtPopup
      icon={props.icon}
      color={props.color}
      onClose={props.onClose}
      opened={props.opened}
      title={props.title}
      subtitle={props.subtitle}
    >
      <>
        <DialogButton variant='secondary' onClick={props.onClose}>
          Cancel
        </DialogButton>
        <DialogButton variant='primary' onClick={props.onSubmit}>
          Delete
        </DialogButton>
      </>
    </DtPopup>
  );
};

export default DtDeleteConfirmationPopup;
