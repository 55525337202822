export function dtToBase64(file: File | null): Promise<string | null> {
  if (!file) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() ?? null);
    reader.onerror = (error) => reject(error);
  });
}
