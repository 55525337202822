import * as yup from 'yup';

import { DtFormsDetailsInitialValues } from './dt-administration-details-forms.interface';
import { FormMutationDto } from '../../../../../../repositories/__generated__/v2';

export const DT_FORM_DETAILS_INITIAL_FORM_VALUE = (form: FormMutationDto | null): DtFormsDetailsInitialValues => {
  return {
    formType: form?.formType ?? '',
    formName: form?.formName ?? '',
    pfFormId: form?.pfFormId ?? '',
    reportTemplateName: form?.reportTemplateName ?? '',
    formAssetList: form?.formAssetList ?? [],
    siteId: form?.siteId ?? undefined,
  };
};

export const DT_FORM_DETAILS_FORM_VALIDATION: yup.ObjectSchema<DtFormsDetailsInitialValues> = yup.object().shape({
  formType: yup.string().label('Form Type').required('Required'),
  formName: yup.string().label('Form Name').required('Required'),
  pfFormId: yup.string().label('Pronto Form ID').required('Required'),
  reportTemplateName: yup.string().label('Report Template Name').required('Required'),
  formAssetList: yup.array().of(
    yup
      .object()
      .shape({
        assetKey: yup.string().required(),
        floorKey: yup.string().required(),
        locationKey: yup.string().required(),
      })
      .required()
  ),
  siteId: yup.number().label('Site').required('Required'),
});
