import { GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';

import { dtGenerateFormAssetsTableColumnsConfig } from './dt-generate-form-assets-table-columns-config';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import DtSearchInput from '../../../../components/dt-search-input/dt-search-input';
import DtTable from '../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../dt-constants';
import { RoutesResolver } from '../../../../dt-enums';
import { dtRouter } from '../../../../dt-routes';
import { dtSelectForms, dtSelectFormsLoading } from '../../../dt-administration/dt-forms-tab/dt-forms.slice';
import { DT_TABLE_ROW_HEIGHT } from '../../../dt-dashboard/dt-dispatches.constants';

const DtFormAssetsTable: React.FC = () => {
  const forms = dtUseAppSelector(dtSelectForms);
  const isLoading = dtUseAppSelector(dtSelectFormsLoading);

  const dtFormTypesTableColumns = dtGenerateFormAssetsTableColumnsConfig(isLoading);
  const showLoaderWhenNoDataYet = isLoading && !forms.length;

  const [searchQuery, setSearchQuery] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'form name',
      sort: 'asc',
    },
  ]);

  const formsWithAssets = useMemo(() => {
    return forms.filter((form) => {
      return !isEmpty(form.formAssetList);
    });
  }, [forms]);

  const matchedForms = useMemo(() => {
    if (isEmpty(searchQuery)) {
      return formsWithAssets;
    }

    return formsWithAssets.filter((form) => {
      const formInfo = form.formType + form.formName + form.pfFormId;
      return formInfo.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [formsWithAssets, searchQuery]);

  function handleEditFormType(params: GridRowParams): void {
    dtRouter.navigate(
      RoutesResolver.AssetsDetails(
        params.row.id.toString(),
        params.row.siteId.toString(),
        params.row.formTypeId.toString(),
        params.row.formSchemaId.toString()
      )
    );
  }

  const tableActionsComponent = <DtSearchInput onChange={setSearchQuery} />;

  return (
    <DtTable
      rows={matchedForms}
      columns={dtFormTypesTableColumns}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      loading={showLoaderWhenNoDataYet}
      sortModel={sortModel}
      onSortModelChange={(newSortModel: GridSortModel) => setSortModel(newSortModel)}
      actionsComponent={tableActionsComponent}
      onRowClick={handleEditFormType}
    />
  );
};

export default DtFormAssetsTable;
