import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { DtSiteDetailsInitialValues } from './dt-create-update-site-form.interface';
import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../../../cdk/hooks/dt-store.hooks';
// import DtUploadImage from '../../../../../../../components/dt-upload-image/dt-upload-image';
import DtFormTextInput from '../../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { InputLabel } from '../../../../../../dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import { dtLoadOrganizationsAction } from '../../../../../dt-organizations-tab/dt-organizations.actions';
import { dtSelectOrganizations } from '../../../../../dt-organizations-tab/dt-organizations.slice';

interface DtCreateUpdateSiteFormProps {
  isCreationFlow: boolean;
}

const DtCreateUpdateSiteForm: React.FC<DtCreateUpdateSiteFormProps> = ({ isCreationFlow }) => {
  const dispatch = dtUseAppDispatch();

  const organizations = dtUseAppSelector(dtSelectOrganizations);
  const { values, setFieldValue } = useFormikContext<DtSiteDetailsInitialValues>();

  useEffect(() => {
    if (!organizations.length) {
      dispatch(dtLoadOrganizationsAction());
    }
  }, []);

  // function onLogoChange(data: File | string | null): void {
  //   if (data) {
  //     if (typeof data === 'string') {
  //       setFieldValue('reportConfig.reportLogo', data);
  //     } else {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(data as Blob);

  //       reader.onloadend = () => {
  //         const base64ImageString = reader.result;

  //         setFieldValue('reportConfig.reportLogo', base64ImageString);
  //       };
  //     }
  //   } else {
  //     setFieldValue('reportConfig.reportLogo', '');
  //   }
  // }

  function handleBlurField(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    isFieldEmpty: boolean
  ): void {
    if (isCreationFlow && isFieldEmpty) {
      setFieldValue(fieldName, e.target.value);
    }
  }

  return (
    <Grid container spacing={24} justifyContent='flex-start'>
      <FieldContainer item xs={6}>
        <InputLabel htmlFor='name'>Name</InputLabel>
        <DtFormTextInput
          id='name'
          name='name'
          placeholder='Name'
          fullWidth
          onBlur={(e) => handleBlurField(e, 'reportConfig.reportName', !Boolean(values.reportConfig.reportName))}
        />
      </FieldContainer>
      <FieldContainer item xs={6}>
        <InputLabel htmlFor='address'>Address</InputLabel>
        <DtFormTextInput
          id='address'
          name='address'
          placeholder='Address'
          fullWidth
          onBlur={(e) => handleBlurField(e, 'reportConfig.reportAddress', !Boolean(values.reportConfig.reportAddress))}
        />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='city'>City</InputLabel>
        <DtFormTextInput id='city' name='city' placeholder='City' fullWidth />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='state'>State</InputLabel>
        <DtFormTextInput id='state' name='state' placeholder='State' fullWidth />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='email'>Email</InputLabel>
        <DtFormTextInput id='email' type='email' name='email' placeholder='Email' fullWidth />
      </FieldContainer>
      <FieldContainer item xs={3}>
        <InputLabel htmlFor='phone'>Phone Number</InputLabel>
        <DtFormTextInput
          id='phone'
          type='tel'
          name='phone'
          placeholder='(000) 000-0000'
          fullWidth
          onBlur={(e) => handleBlurField(e, 'reportConfig.reportPhone', !Boolean(values.reportConfig.reportPhone))}
        />
      </FieldContainer>

      {/* Should be uncomment when upload report logo feature will be done
      <Grid item xs={12} sx={{ alignSelf: 'end' }}>
        <div>
          <Typography color='main.black' variant='h3' sx={{ fontSize: 16 }}>
            Report content
          </Typography>
          <Typography color='main.secondaryText' variant='bodySmall'>
            Logo will be displayed in header, other fields in footer
          </Typography>
        </div>
      </Grid>
      <FieldContainer item xs='auto' alignSelf='start'>
        <InputLabel htmlFor='logo'>Logo</InputLabel>
        <DtUploadImage
          base64File={values?.reportConfig?.reportLogo}
          imageHeight={72}
          imageWidth={120}
          disabled={isSubmitting}
          onChange={onLogoChange}
        />
      </FieldContainer>
      <FieldContainer item xs alignSelf='start'>
        <Grid container spacing={24} justifyContent='flex-start'>
          <FieldContainer item xs={6}>
            <InputLabel htmlFor='name'>Name</InputLabel>
            <DtFormTextInput id='name' name='reportConfig.reportName' placeholder='Name' fullWidth />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <InputLabel htmlFor='phone'>Phone Number</InputLabel>
            <DtFormTextInput
              id='phone'
              type='tel'
              name='reportConfig.reportPhone'
              placeholder='(000) 000-0000'
              fullWidth
            />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <InputLabel htmlFor='address'>Address</InputLabel>
            <DtFormTextInput
              id='address'
              name='reportConfig.reportAddress'
              placeholder='Address'
              fullWidth
              multiline
              InputProps={{ sx: { padding: '10px 16px' } }}
            />
          </FieldContainer>
          <FieldContainer item xs={6}>
            <InputLabel htmlFor='fax'>Fax</InputLabel>
            <DtFormTextInput id='fax' name='reportConfig.reportFax' placeholder='Fax' fullWidth />
          </FieldContainer>
        </Grid>
      </FieldContainer> */}
    </Grid>
  );
};

export default DtCreateUpdateSiteForm;
