export enum DtSupportedDispatchStatuses {
  Open = 'Open',
  Overdue = 'Overdue',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  Done = 'Done',
}

export enum DtDispatchPriorityType {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum DtDispatchPriorityTypeByIndex {
  Low,
  Medium,
  High,
}

export enum DtDispatchDetailsTab {
  General,
  Assignees,
  Assets,
  Upload_Report,
}

export enum AssigneeStatusType {
  Completed = 'Completed',
  New = 'New',
  Declined = 'Declined',
}

export enum DueDateType {
  CME = 'CME',
  QME = 'QME',
  CHE = 'CHE',
  CYE = 'CYE',
  CUSTOM = 'Custom',
}
