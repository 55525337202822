import { FormControl } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import DtButton from '../../../components/dt-button/dt-button';

export const FormInputContainer = styled(FormControl)`
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const InputLabelContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const InputLabel = styled('label')`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`;

export const ForgotPasswordLabel = styled('span')`
  ${({ theme }) => theme.typography.bodySmallSemibold as CSSObject};
  color: ${({ theme }) => theme.palette.main.primary};
  cursor: pointer;
`;

export const NewPasswordFieldWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

export const SubmitButton = styled(DtButton)`
  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(38)}`};
`;

export const FormTitleAndSubtileWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;
