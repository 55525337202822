import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const TitleAndSubtitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const MainContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.black};
`;

export const MainContentSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;
