import React, { lazy } from 'react';

import DtLoginPageSkeleton from './dt-login-page-skeleton/dt-login-page-skeleton';
import { dtWithSuspense } from '../../../cdk/hocs/dt-with-suspense.hoc';

const DtLoginPageLazy = dtWithSuspense(
  lazy(() => import('./dt-login-page')),
  <DtLoginPageSkeleton />
);

export default DtLoginPageLazy;
