import { BaseTextFieldProps } from '@mui/material';

import { DtManageUsersRowItem } from '../../modules/dt-administration/dt-manage-users-tab/dt-manage-users.interface';
import { DtOrganizationsRowItem } from '../../modules/dt-administration/dt-organizations-tab/dt-organizations.slice';
import { DtSiteRowItem } from '../../modules/dt-administration/dt-sites-tab/dt-sites.slice';
import { DtMenuSelectOption } from '../../modules/dt-navigation/dt-navigation.interfaces';

export const DT_DEBOUNCE_DURATION = 300;

export interface DtInputStyles {
  height?: string;
  width?: string;
  padding?: string;
  position?: string;
}

export interface DtSearchInputGeneralProps extends BaseTextFieldProps {
  inputStyles?: DtInputStyles;
  icon?: boolean;
  iconSize?: number;
  iconColor?: keyof MainPalette;
  placeholder?: string;
}

export type DtOptionsType = DtMenuSelectOption<number> | DtOrganizationsRowItem | DtSiteRowItem | DtManageUsersRowItem;

export interface DtSearchInputProps extends DtSearchInputGeneralProps {
  onChange: (query: string) => void;
}
