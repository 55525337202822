import { chain, isEqual, sortBy } from 'lodash';
import React from 'react';

import { FiltersWrapper } from './dt-dashboard-filters.styled';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import { DtRootState } from '../../dt-store';
import {
  dtSelectDispatchesAndAssetsPropertyIdFilter,
  dtSelectDispatchesAndAssetsFormTypeFilter,
  dtSelectDispatchesAndAssetsFormSubtypeFilter,
  dtSetDispatchesAndAssetsFormSubtypeFilter,
  dtSetDispatchesAndAssetsFormTypeFilter,
  dtSetDispatchesAndAssetsPropertyIdFilter,
} from '../../modules/dt-dashboard/dt-dispatches.slice';
import { dtSelectFormTypesForFilter, dtSelectProperties } from '../../modules/dt-properties/dt-properties.slice';
import DtSelect from '../dt-selects/dt-select/dt-select';

interface DtDashboardFiltersProps {
  isLoading: boolean;
}

const DtDashboardFilters: React.FC<DtDashboardFiltersProps> = ({ isLoading }) => {
  const dispatch = dtUseAppDispatch();

  const propertiesOptions = dtUseAppSelector(dtSelectProperties, isEqual);
  const propertyIdFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsPropertyIdFilter);
  const formTypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormTypeFilter);
  const formSubtypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormSubtypeFilter);
  const getFormTypesBySelectedProperties = dtUseAppSelector((state: DtRootState) =>
    dtSelectFormTypesForFilter(state, propertyIdFilter)
  );

  const sortedAvailableProperties = sortBy(
    propertiesOptions.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    'label'
  );

  const sortedAvailableFormTypes = chain(getFormTypesBySelectedProperties)
    .map((formType) => ({
      value: formType.type,
      label: formType.type,
    }))
    .uniqBy('value')
    .sortBy('label')
    .value();

  const getAvailableFormSubtypesBySelectedType = getFormTypesBySelectedProperties.flatMap((formType) => {
    if (formTypeFilter) {
      if (formType.type === formTypeFilter) {
        return formType.formItems;
      }
      return [];
    }
    return formType.formItems;
  });

  const sortedAvailableFormSubtypes = chain(getAvailableFormSubtypesBySelectedType)
    .uniqBy('name')
    .map(({ id, name }) => ({
      value: { id, name },
      label: name,
    }))
    .sortBy('label')
    .value();

  function handleSiteChange(selectedOption: number | null | undefined): void {
    dispatch(dtSetDispatchesAndAssetsPropertyIdFilter(selectedOption ?? undefined));
    resetOrKeepFormType(selectedOption);
    resetOrKeepFormSubType(selectedOption, formTypeFilter);
  }

  function handleTypeChange(selectedOption: string | null | undefined): void {
    dispatch(dtSetDispatchesAndAssetsFormTypeFilter(selectedOption ?? undefined));
    resetOrKeepFormSubType(propertyIdFilter, selectedOption);
  }

  function handleSubtypeChange(selectedOption: { id: number; name: string } | null | undefined): void {
    dispatch(dtSetDispatchesAndAssetsFormSubtypeFilter(selectedOption ?? undefined));
  }

  function resetOrKeepFormType(newProperty: number | null | undefined): void {
    if (!newProperty) {
      return;
    }
    if (formTypeFilter) {
      const formTypes = propertiesOptions.find((i) => i.id === newProperty)?.formTypes ?? [];
      const selectedOptionHasSelectedFormType = formTypes.some((i) => i.type === formTypeFilter);
      if (!selectedOptionHasSelectedFormType) {
        dispatch(dtSetDispatchesAndAssetsFormTypeFilter(undefined));
      }
      // otherwise - keep selected value
    }
  }

  function resetOrKeepFormSubType(
    selectedProperty: number | null | undefined,
    selectedFormType: string | null | undefined
  ): void {
    if (!selectedProperty && !selectedFormType) {
      return;
    }
    if (formSubtypeFilter) {
      const formTypes = selectedProperty
        ? propertiesOptions.find((i) => i.id === selectedProperty)?.formTypes ?? []
        : propertiesOptions.flatMap((i) => i.formTypes);

      const subtypes = !selectedFormType
        ? formTypes.flatMap((i) => i.formItems)
        : formTypes.find((i) => i.type === selectedFormType)?.formItems ?? [];

      const selectedOptionHasSelectedFormSubtype = subtypes.some((i) => i.name === formSubtypeFilter.name);
      if (!selectedOptionHasSelectedFormSubtype) {
        dispatch(dtSetDispatchesAndAssetsFormSubtypeFilter(undefined));
      }
      // otherwise - keep selected value
    }
  }

  return (
    <FiltersWrapper>
      {sortedAvailableProperties.length > 1 && (
        <DtSelect<number | null>
          disabled={isLoading}
          value={propertyIdFilter}
          label='Property'
          labelPosition='inside'
          options={sortedAvailableProperties}
          onChange={handleSiteChange}
          placeholder='Any'
          fullWidth
        />
      )}
      <DtSelect<string | null>
        disabled={isLoading}
        value={formTypeFilter}
        label='Type'
        labelPosition='inside'
        options={sortedAvailableFormTypes}
        onChange={handleTypeChange}
        placeholder='Any'
        fullWidth
      />
      <DtSelect<{ id: number; name: string } | null>
        disabled={isLoading}
        value={formSubtypeFilter}
        label='Subtype'
        labelPosition='inside'
        options={sortedAvailableFormSubtypes}
        onChange={handleSubtypeChange}
        placeholder='Any'
        fullWidth
      />
    </FiltersWrapper>
  );
};

export default DtDashboardFilters;
