import { Box, Grid, InputAdornment } from '@mui/material';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import React from 'react';

import { DtUserDetailsInitialValues } from './dt-administration-details-user-form.interface';
import {
  ProvideAccessButton,
  ProvideAccessText,
  UsernameTextInput,
} from './dt-administration-details-user-form.styled';
import { dtUseAppDispatch } from '../../../../../../cdk/hooks/dt-store.hooks';
import DtButton from '../../../../../../components/dt-button/dt-button';
import DtIcon from '../../../../../../components/dt-icon/dt-icon';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { CellSubtitle, CellTitle } from '../../../../../../components/dt-table/table-cell.styled';
import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { UserDetailsDto, UserRole } from '../../../../../../repositories/__generated__/v2';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import { CellContentWrapper } from '../../../../components/dt-table-cell-content-wrapper/dt-table-cell-content-wrapper.styled';
import { DT_USER_ROLE_OPTIONS } from '../../../../dt-administration.constants';
import { dtClearAssignments } from '../../../../dt-assignments.slice';

interface DtUserDetailsFormProps {
  user: UserDetailsDto | null;
}

const DtAdministrationDetailsUserForm: React.FC<DtUserDetailsFormProps> = ({ user }) => {
  const dispatch = dtUseAppDispatch();
  const { values, setFieldValue, isSubmitting } = useFormikContext<DtUserDetailsInitialValues>();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={24} justifyContent='flex-start'>
          <FieldContainer item xs={3}>
            <InputLabel htmlFor='firstName'>First name</InputLabel>
            <DtFormTextInput id='first-name' name='firstName' placeholder='First name' />
          </FieldContainer>
          <FieldContainer item xs={3}>
            <InputLabel htmlFor='secondName'>Second name</InputLabel>
            <DtFormTextInput id='second-name' name='lastName' placeholder='Second name' />
          </FieldContainer>
          <FieldContainer item xs={3}>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <DtFormTextInput
              id='email'
              name='email'
              placeholder='Email'
              disabled={isSubmitting || Boolean(user?.email)}
            />
          </FieldContainer>
          <FieldContainer item xs={3}>
            <InputLabel htmlFor='phone'>Phone number</InputLabel>
            <DtFormTextInput id='phone' type='tel' name='phone' placeholder='(000) 000-0000' />
          </FieldContainer>
          {values.hasMobileAccess ? (
            <FieldContainer item xs={3} style={{ alignSelf: 'flex-start' }}>
              <InputLabel htmlFor='username'>Username</InputLabel>
              <UsernameTextInput
                disabled={isSubmitting}
                id='username'
                name='mobileUsername'
                placeholder='Username'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <DtButton
                        css={{ borderRadius: '50%', padding: '8px', marginRight: '4px' }}
                        variant='transparent'
                        onClick={() => {
                          setFieldValue('hasMobileAccess', false);
                          setFieldValue('mobileUsername', '');
                        }}
                      >
                        <DtIcon icon='close' size={14} color='secondaryIcon' />
                      </DtButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FieldContainer>
          ) : (
            <FieldContainer item xs={3}>
              <ProvideAccessButton
                disabled={isSubmitting}
                variant='secondary'
                onClick={() => {
                  setFieldValue('mobileUsername', values.email);
                  setFieldValue('hasMobileAccess', true);
                }}
              >
                <DtIcon icon='mob-invite' size={14} color='primary' />
                <ProvideAccessText>Provide access to Mobile App</ProvideAccessText>
              </ProvideAccessButton>
            </FieldContainer>
          )}
          {values.hasWebAccess ? (
            <>
              <FieldContainer item xs={3} style={{ display: 'flex', alignSelf: 'flex-start' }}>
                <DtFormikSelect
                  fullWidth
                  disabled={isSubmitting}
                  labelPosition='top'
                  label='User Type'
                  name='role'
                  options={DT_USER_ROLE_OPTIONS}
                  placeholder='Select User Type'
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue('hasWebAccess', false);
                    }
                    if (value === UserRole.SuperAdmin) {
                      dispatch(dtClearAssignments());
                    }
                  }}
                  withFlexProp={true}
                />
              </FieldContainer>
            </>
          ) : (
            <FieldContainer item xs={3}>
              <ProvideAccessButton
                variant='secondary'
                onClick={() => setFieldValue('hasWebAccess', true)}
                disabled={isSubmitting}
              >
                <DtIcon icon='desktop-invite' size={14} color='primary' />
                <ProvideAccessText>Provide access to Web App</ProvideAccessText>
              </ProvideAccessButton>
            </FieldContainer>
          )}
          {user?.lastLogin && (
            <FieldContainer item xs={3} style={{ alignSelf: 'flex-start', display: 'flex', flexDirection: 'column' }}>
              <InputLabel style={{ marginBottom: '5px' }}>Last login</InputLabel>
              <CellContentWrapper>
                <CellTitle titleBold={false}>
                  {format(new Date(user.lastLogin as string), 'MM-dd-yyyy') ?? ''}
                </CellTitle>
                <CellSubtitle subtitleBold={false}>
                  {user.amountOfLoggedInTimes} {user.amountOfLoggedInTimes > 1 ? 'logins' : 'login'}
                </CellSubtitle>
              </CellContentWrapper>
            </FieldContainer>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default DtAdministrationDetailsUserForm;
