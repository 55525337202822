import React from 'react';

import DtSkeleton from '../../../components/dt-skeleton/dt-skeleton';
import { NotFoundContainer } from '../dt-not-found.styled';

const DtNotFoundSkeleton: React.FC = () => {
  return (
    <NotFoundContainer>
      <DtSkeleton width={114} />
      <DtSkeleton width={126} />
    </NotFoundContainer>
  );
};

export default DtNotFoundSkeleton;
