import { Typography } from '@mui/material';
import { styled, CSSObject } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE } from '../../modules/dt-dashboard/dt-dispatches.constants';

export const TableContainer = styled('section', {
  shouldForwardProp: (prop) => prop !== 'tableHeight' && prop !== 'tableMaxHeight' && prop !== 'dataGridHeight',
})<{ tableHeight?: string; tableMaxHeight?: string; dataGridHeight?: string }>`
  max-height: ${({ tableMaxHeight }) => tableMaxHeight};
  height: ${({ tableHeight }) => tableHeight};
  padding: ${({ theme }) => theme.spacing(23)};
  background-color: ${({ theme }) => theme.palette.main.white};
  ${({ theme }) => theme.mixins.boxShadowMixin};

  & .MuiGrid-root {
    width: fit-content;
  }

  &.MuiDataGrid-root {
    border: none;
    width: fit-content;
  }

  & .MuiDataGrid-columnsContainer {
    border-bottom: none;
  }

  & .MuiDataGrid-root.MuiDataGrid-autoHeight {
    height: ${({ dataGridHeight }) => dataGridHeight ?? 'auto'};
  }

  & .MuiDataGrid-autoHeight {
    border: none;
  }

  & .MuiBadge-badge {
    display: none;
  }

  & .MuiDataGrid-virtualScroller {
    overflow: hidden;
  }
`;

export const TableItems = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'isEmpty' && prop !== 'loading' && prop !== 'pageHeight',
})<{ isEmpty: number; loading?: boolean; pageHeight?: number }>`
  & .MuiDataGrid-main {
    height: ${({ pageHeight, isEmpty, loading }) => (!isEmpty || loading ? '600px' : `${pageHeight}px`)};
    margin-bottom: ${({ theme, isEmpty }) =>
      isEmpty && isEmpty > DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE ? theme.spacing(30) : 0};
  }

  & .MuiDataGrid-withBorderColor {
    border: none;
  }

  & .MuiDataGrid-columnsContainer {
    display: ${({ isEmpty }) => !isEmpty && 'none'};
  }

  & .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
  }

  & .MuiDataGrid-LastLoginColumnHeader {
    margin-left: 24px;
  }

  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  & .MuiDataGrid-columnHeaderTitle {
    ${({ theme }) => theme.typography.bodySmallSemibold as CSSObject}
    color: ${({ theme }) => theme.palette.main.secondaryText};
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }

  & .MuiDataGrid-cell {
    /* stylelint-disable-next-line declaration-no-important */
    line-height: unset !important;
    border-bottom: none;
    cursor: pointer;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-iconSeparator {
    display: none;
  }

  & .MuiDataGrid-row:hover {
    background-color: ${({ theme }) => theme.palette.main.hover};
    border-radius: 3px;
  }

  & .MuiDataGrid-row.Mui-selected {
    background-color: ${({ theme }) => theme.palette.main.control};
    border-radius: 3px;
  }

  & .MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: ${({ theme }) => theme.palette.main.emerald};
  }

  & .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
    color: ${({ theme }) => theme.palette.main.emerald};
  }

  & .MuiDataGrid-footerContainer {
    display: ${({ isEmpty }) => (isEmpty && isEmpty > DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE ? 'flex' : 'none')};
    width: 100%;
  }

  & .MuiDataGrid-overlayWrapper {
    position: initial;
  }
`;

export const CellSubtype = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const TabPanelContent = styled('section')`
  background-color: ${({ theme }) => theme.palette.main.white};
`;

export const NoDataText = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};
  ${({ theme }) => theme.typography.bodyBold as CSSObject}
`;

export const StyledGridOverlay = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
