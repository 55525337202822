import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomSwitcher = styled(Switch)`
  height: 32px;
  width: 56px;
  padding: 0;

  & .MuiSwitch-track {
    border-radius: ${({ theme }) => theme.spacing(16)};
    border: solid 1.5px rgba(35 31 32 / 10%);
    background-color: ${({ theme }) => theme.palette.main.background};
    opacity: 100%;
  }

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: ${({ theme }) => theme.spacing(2)};
    height: 27px;
    transform: translateX(4px);

    & .MuiSwitch-thumb {
      width: 24px;
      height: 24px;
      ${({ theme }) => theme.mixins.switcherBoxShadowMixin}
    }

    &.Mui-checked {
      color: ${({ theme }) => theme.palette.main.white};
      transform: translateX(26px);

      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.palette.main.emerald};
        opacity: 100%;
      }

      &.Mui-disabled {
        color: ${({ theme }) => theme.palette.main.white};
        opacity: 80%;
      }
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 40%;
    }

    &.Mui-disabled .MuiSwitch-thumb {
      background: ${({ theme }) => theme.palette.main.white};
      opacity: 80%;
    }
  }
`;
