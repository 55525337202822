import { dtUserService } from './dt-user.service';
import { dtSetCurrentCompany, dtSetLoadingStatus, dtSetUserCompanyData, dtSetUserData } from './dt-user.slice';
import { DtAppThunk } from '../../dt-store';
import {
  dtSetDispatchesAndAssetsPropertyIdFilter,
  dtSetDispatchesAndAssetsFormSubtypeFilter,
  dtSetDispatchesAndAssetsFormTypeFilter,
} from '../dt-dashboard/dt-dispatches.slice';
import { dtNavigationService } from '../dt-navigation/dt-navigation.service';
import { dtSetPropertyOptions, dtUpdateNavigationPropertiesAndFormTypes } from '../dt-navigation/dt-navigation.slice';
import { dtSetProperties } from '../dt-properties/dt-properties.slice';

export const dtLoadUser =
  (reconnect?: boolean): DtAppThunk =>
  async (dispatch) => {
    try {
      dispatch(dtSetLoadingStatus('loading'));
      const userData = await dtUserService.getAuthenticatedUserData(reconnect);

      if (userData.currentUser) {
        dispatch(dtSetUserData(userData.currentUser));
      }

      if (userData.companyData && userData.defaultCompany) {
        dispatch(dtSetUserCompanyData(userData.companyData));
        dispatch(dtSetProperties(userData.defaultCompany.propertyList));
        dispatch(dtSetCurrentCompany(userData.defaultCompany.id));
      } else {
        dispatch(dtSetUserCompanyData(userData.companyData));
      }
    } catch (e) {
      console.error('Failed to get user data', e);
      dispatch(dtSetUserData(null));
    }
  };

export const dtRefreshCurrentUserData = (): DtAppThunk => async (dispatch) => {
  try {
    const userData = await dtUserService.getAuthenticatedUserData();

    if (userData.currentUser) {
      dispatch(dtSetUserData(userData.currentUser));
    }

    if (userData.companyData && userData.defaultCompany) {
      dispatch(dtSetUserCompanyData(userData.companyData));
      dispatch(dtSetCurrentCompany(userData.defaultCompany.id));
      dispatch(dtSetProperties(userData.defaultCompany.propertyList));
      dispatch(dtSetDispatchesAndAssetsPropertyIdFilter(undefined));
      dispatch(dtSetDispatchesAndAssetsFormSubtypeFilter(undefined));
      dispatch(dtSetDispatchesAndAssetsFormTypeFilter(undefined));
      dispatch(
        dtSetPropertyOptions(dtNavigationService.mapPropertiesToMenuSelectOptions(userData.defaultCompany.propertyList))
      );
      dispatch(dtUpdateNavigationPropertiesAndFormTypes());
    }
  } catch (e) {
    console.error('Failed to get user data', e);
    dispatch(dtSetUserData(null));
  }
};
