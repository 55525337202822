import { IconButton } from '@mui/material';
import React from 'react';

import DtIcon from '../../../../../components/dt-icon/dt-icon';
import { dtToastService } from '../../../../../services/dt-toast.service';

interface DtCopyButtonProps {
  textToCopy: string;
}

const DtCopyButton: React.FC<DtCopyButtonProps> = ({ textToCopy }) => {
  return (
    <IconButton
      sx={{ width: '24px', height: '24px', padding: '2px', borderRadius: 0 }}
      onClick={(e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(textToCopy);
        dtToastService.success('Copied to clipboard');
      }}
    >
      <DtIcon icon='copy' size={16} color='secondaryIcon' />
    </IconButton>
  );
};

export default DtCopyButton;
