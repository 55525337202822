import {
  GridInputRowSelectionModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';

import dtGenerateDispatchesTableColumnsConfig from './dt-generate-dispatches-table-columns-config';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import DtTable from '../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../dt-constants';
import { dtSelectUserRole } from '../../../dt-user/dt-user.slice';
import { DT_TABLE_ROW_HEIGHT } from '../../dt-dispatches.constants';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';

interface DtDispatchesTableProps {
  dispatchStatus: DtSupportedDispatchStatuses;
  rows: (DtDispatchRowItem | DtScheduleDispatchItem)[];
  onRowClick: (row: DtDispatchRowItem) => void;
  isLoading: boolean;
  openDeleteConfirmation: (id: string, formType: string) => void;
  onRowSelectionChange?: (selectionModel: GridRowSelectionModel) => void;
  selectionModel?: GridInputRowSelectionModel;
  onScheduledRowClick: (row: DtScheduleDispatchItem) => void;
}

const DtDispatchesTable: React.FC<DtDispatchesTableProps> = ({
  rows,
  onRowClick,
  dispatchStatus,
  isLoading,
  openDeleteConfirmation,
  onRowSelectionChange,
  selectionModel,
  onScheduledRowClick,
}) => {
  const userRole = dtUseAppSelector(dtSelectUserRole);
  const dtDispatchesTableColumns = dtGenerateDispatchesTableColumnsConfig(
    dispatchStatus,
    isLoading,
    openDeleteConfirmation,
    userRole
  );

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  function tableSortingBasedOnDispatchStatus(dtDispatchStatus: DtSupportedDispatchStatuses): GridSortItem[] {
    switch (dtDispatchStatus) {
      case DtSupportedDispatchStatuses.Open:
        return [{ field: 'status', sort: 'asc' }];
      case DtSupportedDispatchStatuses.Overdue:
        return [{ field: 'status', sort: 'asc' }];
      case DtSupportedDispatchStatuses.Completed:
        return [{ field: 'status', sort: 'desc' }];
      case DtSupportedDispatchStatuses.Scheduled:
        return [{ field: 'next dispatch', sort: 'desc' }];
      default:
        return [{ field: 'type', sort: 'asc' }];
    }
  }

  useEffect(() => {
    setSortModel(tableSortingBasedOnDispatchStatus(dispatchStatus));
  }, [dispatchStatus]);

  return (
    <DtTable
      rows={
        isLoading
          ? ([
              { id: -1, assigner: {}, assignee: {} },
              { id: -2, assigner: {}, assignee: {} },
              { id: -3, assigner: {}, assignee: {} },
              { id: -4, assigner: {}, assignee: {} },
              { id: -5, assigner: {}, assignee: {} },
              { id: -6, assigner: {}, assignee: {} },
              { id: -7, assigner: {}, assignee: {} },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any)
          : rows
      }
      loading={isLoading}
      columns={dtDispatchesTableColumns}
      onRowClick={(params: GridRowParams) => {
        if (params.row.status !== 'Scheduled') {
          onRowClick(params.row as DtDispatchRowItem);
        } else {
          onScheduledRowClick(params.row as DtScheduleDispatchItem);
        }
      }}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      onSelectionModelChange={onRowSelectionChange}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      selectionModel={selectionModel}
      sortModel={sortModel}
      onSortModelChange={(newSortingModel) => setSortModel(newSortingModel)}
      checkboxSelection={true}
      columnVisibilityModel={
        dispatchStatus !== DtSupportedDispatchStatuses.Scheduled
          ? { 'next dispatch': false }
          : { 'next dispatch': true }
      }
    />
  );
};

export default DtDispatchesTable;
