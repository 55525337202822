import React from 'react';

import DtSkeleton from './../../../../../components/dt-skeleton/dt-skeleton';
import { CellTitle, TableCellContainer } from './dt-dispatch-skeleton-table-cell.styled';

const DtDispatchSkeletonTableCell: React.FC = () => {
  return (
    <TableCellContainer>
      <CellTitle>
        <DtSkeleton variant='text' />
      </CellTitle>
      <DtSkeleton variant='text' />
    </TableCellContainer>
  );
};

export default DtDispatchSkeletonTableCell;
