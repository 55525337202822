import React from 'react';

import { GridContainer, TabButtonSubtitle, TabButtonTitle } from './dt-tab-button-content.styled';
import DtSkeleton from '../../../dt-skeleton/dt-skeleton';

interface DtTabButtonContentProps {
  counter?: number | string;
  subtitle?: string;
  icon?: JSX.Element;
  isDataLoading?: boolean;
}

const DtTabButtonContent: React.FC<DtTabButtonContentProps> = ({ counter, subtitle, icon, isDataLoading }) => {
  return (
    <>
      <TabButtonTitle variant='h2'>{isDataLoading ? <DtSkeleton variant='text' width={24} /> : counter}</TabButtonTitle>
      <GridContainer container>
        {icon}
        <TabButtonSubtitle variant='bodySemibold'>{subtitle}</TabButtonSubtitle>
      </GridContainer>
    </>
  );
};

export default DtTabButtonContent;
