import { Action } from 'history';
import React from 'react';

import { BackButton, HeaderWrapper } from './dt-administration-details-header.styled';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import DtPageTitleAndSubtitle from '../../../../components/dt-page-title-and-subtitle/dt-page-title-and-subtitle';
import { DtAppRoutes } from '../../../../dt-enums';
import { dtRouter } from '../../../../dt-routes';

interface DtAdministrationDetailsHeaderProps {
  pageTitle: string;
  pageSubtitle: string;
  backButtonRoute?: DtAppRoutes;
  marginBottom?: number;
}

const DtAdministrationDetailsHeader: React.FC<DtAdministrationDetailsHeaderProps> = (props) => {
  function handleBackButtonClick(): void {
    // Assumed that we do not use history go functions(goBack, goForward, go),
    // so POP used to identify when visiting the route via URL, or page refresh
    if (
      props.backButtonRoute &&
      (dtRouter.state.historyAction === Action.Pop || dtRouter.state.historyAction === Action.Replace)
    ) {
      dtRouter.navigate(props.backButtonRoute);
    } else {
      dtRouter.navigate(-1);
    }
  }

  return (
    <HeaderWrapper marginBottom={props.marginBottom}>
      {props.backButtonRoute && (
        <BackButton variant='secondary' padding={10} onClick={handleBackButtonClick}>
          <DtIcon icon='back' size={20} color='secondaryIcon' />
        </BackButton>
      )}
      <DtPageTitleAndSubtitle title={props.pageTitle} subtitle={props.pageSubtitle} mb={0} />
    </HeaderWrapper>
  );
};

export default DtAdministrationDetailsHeader;
