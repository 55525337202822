import { GridSortDirection } from '@mui/x-data-grid';
import { addDays, endOfWeek, previousDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';

import { DtDatePickerRange } from './components/dt-date-pickers/dt-date-pickers.enums';

export const DT_GLOBAL_TIME_INTERVAL = 30; // time in minutes
export const DT_ONE_SECOND_MS = 1000;
// eslint-disable-next-line no-magic-numbers
export const DT_ONE_MINUTE_MS = DT_ONE_SECOND_MS * 60;

export const DT_BASE_URL = process.env.REACT_APP_BASE_URL;
export const DT_FEATURE_NOT_IMPLEMENTED_MESSAGE = 'This feature is not implemented yet.';
export const DT_TABLE_SORTING_ORDER: GridSortDirection[] = ['desc', 'asc'];

const getLastThirtyDays = (): DtDateRangeValue => {
  const THIRTY_DAYS = 30;
  const lastThirtyDays = new Date(new Date().setDate(new Date().getDate() - THIRTY_DAYS));

  return {
    predefinedRangeName: DtDatePickerRange.LastThirtyDays,
    from: lastThirtyDays,
    to: new Date(),
  };
};

export const DT_PICKER_DAYS_RANGE: Record<DtDatePickerRange, DtDateRangeValue> = {
  [DtDatePickerRange.Today]: {
    predefinedRangeName: DtDatePickerRange.Today,
    from: new Date(),
    to: new Date(),
  },
  [DtDatePickerRange.Yesterday]: {
    predefinedRangeName: DtDatePickerRange.Yesterday,
    from: addDays(new Date(), -1),
    to: addDays(new Date(), -1),
  },
  [DtDatePickerRange.ThisWeek]: {
    predefinedRangeName: DtDatePickerRange.ThisWeek,
    from: startOfWeek(new Date()),
    to: new Date(),
  },
  [DtDatePickerRange.LastWeek]: {
    predefinedRangeName: DtDatePickerRange.LastWeek,
    from: startOfWeek(previousDay(new Date(), -1)),
    to: endOfWeek(previousDay(new Date(), -1)),
  },
  [DtDatePickerRange.LastThirtyDays]: getLastThirtyDays(),

  [DtDatePickerRange.ThisMonth]: {
    predefinedRangeName: DtDatePickerRange.ThisMonth,
    from: startOfMonth(new Date()),
    to: new Date(),
  },
  [DtDatePickerRange.ThisYear]: {
    predefinedRangeName: DtDatePickerRange.ThisYear,
    from: startOfYear(new Date()),
    to: new Date(),
  },
};

export const DT_API_SUCCESS_CODE = 1;
export const DT_API_ERROR_CODE = 0;

export const DT_WEEKDAYS = {
  Sun: 1,
  Mon: 2,
  Tue: 3,
  Wed: 4,
  Thu: 5,
  Fri: 6,
  Sat: 7,
};

export enum STORAGE_KEYS {
  INSPECTION_LAST_SELECTED_SCHEMA = 'INSPECTION_LAST_SELECTED_SCHEMA',
}
