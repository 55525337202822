import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { DayModifiers, DayPicker } from 'react-day-picker';
import { v4 as uuidv4 } from 'uuid';

import DtIcon from '../../dt-icon/dt-icon';
import { DtDatePickersCommonProps } from '../dt-date-pickers.interfaces';
import { DateRangeInput, InputLabel, PopoverContainer } from '../dt-date-pickers.styled';

export interface DtDatePickerProps extends DtDatePickersCommonProps {
  value?: Date;
  /**
   * Date picker change handler
   * @param value
   */
  onChange: (value: Date) => void;
  /**
   * Field name
   */
  name?: string;
  /**
   * Apply a full width to a date picker
   */
  fullWidth?: boolean;
  /**
   * Field placeholder
   */
  placeholder?: string;
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
  /**
   * @param {boolean} default - determine the disabled state of day picker
   */
  disabled?: boolean;
  error?: string;
}

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

const DtDatePicker: React.FC<DtDatePickerProps> = ({
  value,
  onChange,
  label,
  dateFormat = DEFAULT_DATE_FORMAT,
  minMonth,
  maxMonth,
  disabledDays,
  name,
  fullWidth,
  placeholder,
  className,
  disabled,
  error,
}) => {
  const pickerInputRef = useRef();
  const [opened, setOpened] = useState(false);
  const [uniqueId] = useState((name && label && `${name}_${label}`) ?? uuidv4());

  function handleInputClick(): void {
    if (!disabled) {
      setOpened(true);
    }
  }

  function handlePopoverClose(): void {
    setOpened(false);
  }

  function handleDayClick(day: Date, { disabled }: DayModifiers): void {
    if (!disabled) {
      onChange(day);
    }

    setOpened(false);
  }

  return (
    <>
      <DateRangeInput
        id={uniqueId}
        fullWidth={fullWidth}
        value={value && format(value, dateFormat)}
        onClick={handleInputClick}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          ref: pickerInputRef,
          className: `${className} ${opened ? 'focused' : ''}`,
          readOnly: true,
          endAdornment: (
            <IconButton sx={{ width: '24px', height: '24px', padding: '2px' }}>
              <DtIcon icon={opened ? 'drop-up' : 'drop-down'} size='20px' />
            </IconButton>
          ),
          startAdornment: label && <InputLabel variant='bodySemibold'>{label}</InputLabel>,
        }}
      />
      <PopoverContainer
        anchorEl={pickerInputRef.current}
        open={opened}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DayPicker
          modifiersClassNames={{
            selected: 'day-selected',
          }}
          onDayClick={handleDayClick}
          selected={value}
          disabled={disabledDays}
          fromMonth={minMonth}
          toMonth={maxMonth}
        />
      </PopoverContainer>
    </>
  );
};

export default DtDatePicker;
