import React, { FC, LazyExoticComponent, ReactElement, Suspense } from 'react';

import DtSkeleton from '../../components/dt-skeleton/dt-skeleton';

const DEFAULT_LOADER = <DtSkeleton variant='rectangular' />;

/**
 * This HOC intended to wrap components that you want to lazy load {@link https://reactjs.org/docs/code-splitting.html}
 *
 * @param {React.LazyExoticComponent<React.FC>} Component Component that you want to exclude from bundle and lazy load on demand
 * @param {React.ReactElement} loader Component that will be displayed till component load
 *
 * @example const componentToBeLazyLoaded = withSuspense(lazy(() => import(pathToComponent)));
 */
export const dtWithSuspense =
  (Component: LazyExoticComponent<FC>, loader: ReactElement | undefined = DEFAULT_LOADER) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any): ReactElement => {
    return (
      <Suspense fallback={loader}>
        <Component {...props} />
      </Suspense>
    );
  };
