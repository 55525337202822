import { List } from '@mui/material';
import React from 'react';

import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';
import DtDispatchAssignee from '../dt-dispatch-assignee/dt-dispatch-assignee';

interface DtDispatchAssigneesProps {
  assignees: DtDispatchItemDetailsAssign[];
  status: DtSupportedDispatchStatuses;
  onDelete: (userEmail: string) => void;
  onReassign: (userEmail: string) => void;
}

const DtDispatchAssignees: React.FC<DtDispatchAssigneesProps> = ({ assignees, status, onDelete, onReassign }) => {
  return (
    <List>
      {assignees.map((item) => (
        <DtDispatchAssignee
          key={item.assigneeEmail}
          assignee={item}
          status={status}
          onDelete={onDelete}
          onReassign={onReassign}
        />
      ))}
    </List>
  );
};

export default DtDispatchAssignees;
