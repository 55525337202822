import { CSSObject, styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';

export const ToggleButtonsContainer = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'alignTabs' && prop !== 'stretchTabs',
})<{
  fullWidth?: boolean;
  alignTabs?: boolean;
  stretchTabs?: boolean;
}>`
  text-transform: none;
  min-height: 40px;
  height: 40px;
  border-radius: 3px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.main.control};

  & .MuiTabs-flexContainer {
    height: 100%;
    align-items: center;
    justify-content: ${({ alignTabs }) => alignTabs && 'space-between'};
    ${({ stretchTabs }) => (stretchTabs ? '& > * { flex: 1 1 0% ; }' : undefined)};
  }

  & .MuiTabs-indicator {
    display: none;
  }
`;

export const CustomToggleButton = styled(Tab, { shouldForwardProp: (prop) => prop !== 'minWidth' })<{
  minWidth?: string;
}>`
  text-transform: none;
  min-height: 32px;
  height: 32px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '142px')};
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.main.secondaryText};
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};

  &:hover {
    color: ${({ theme }) => theme.palette.main.primary};
  }

  &.Mui-selected {
    ${({ theme }) => theme.mixins.boxShadowMixin};
    color: ${({ theme }) => theme.palette.main.black};
    background-color: ${({ theme }) => theme.palette.main.white};
  }

  &.Mui-disabled {
    opacity: 40%;
  }
`;
