import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DtRootState } from '../../../dt-store';
import { FormsListResponseDto, FormTypeListWithFormSchemasDto } from '../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../repositories/dt-api.repository';

export interface DtSitesState {
  status: DtStatus;
  forms: DtFormsRowItem[];
  availableFormTypes: FormTypeListWithFormSchemasDto[];
}

export interface DtFormsRowItem extends FormsListResponseDto {
  isLoading?: boolean;
}

const initialState: DtSitesState = {
  status: 'waiting-to-load',
  forms: [],
  availableFormTypes: [],
};

export const dtLoadAllAvailableFormTypes = createAsyncThunk(
  'dtForms/dtLoadAllAvailableFormTypes',
  async (companyId: number): Promise<FormTypeListWithFormSchemasDto[]> => {
    if (companyId) {
      const availableFormTypesData = (
        await dtApiRepository.AdminFormApi.adminFormControllerGetAllAvailableFormTypesWithFormSchemas(companyId)
      )?.data;

      if (availableFormTypesData) {
        return availableFormTypesData;
      }
    }
    return initialState.availableFormTypes;
  }
);

const dtFormsSlice = createSlice({
  name: 'dtForms',
  initialState,
  reducers: {
    dtSetFormsData: (state, action: PayloadAction<DtFormsRowItem[]>) => {
      state.forms = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetAllAvailableFormTypesData: (state, action: PayloadAction<FormTypeListWithFormSchemasDto[]>) => {
      state.availableFormTypes = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
    dtUpdateLoadingStatusOfTypeRow: (state, action: PayloadAction<{ id: string; status: boolean }>) => {
      const dtFormTypeRow = _.find(state.forms, { id: action.payload.id });

      if (dtFormTypeRow) {
        dtFormTypeRow.isLoading = action.payload.status;
      }
    },
    dtClearFormTypes: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dtLoadAllAvailableFormTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dtLoadAllAvailableFormTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.availableFormTypes = action.payload;
      })
      .addCase(dtLoadAllAvailableFormTypes.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = 'failed';
        }
      });
  },
});

export const dtSelectForms = (state: DtRootState): DtFormsRowItem[] => state.dtForms.forms;

export const dtSelectAllAvailableFormTypes = (state: DtRootState): FormTypeListWithFormSchemasDto[] =>
  state.dtForms.availableFormTypes;

export const dtSelectFormsStatus = (state: DtRootState): DtStatus => state.dtForms.status;

export const dtSelectFormsLoading = (state: DtRootState): boolean => state.dtForms.status === 'loading';

export const {
  dtSetFormsData,
  dtSetAllAvailableFormTypesData,
  dtSetLoadingStatus,
  dtClearFormTypes,
  dtUpdateLoadingStatusOfTypeRow,
} = dtFormsSlice.actions;

export default dtFormsSlice.reducer;
