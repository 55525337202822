import React from 'react';

import {
  AssignmentHeader,
  AssignmentHeaderCounter,
  AssignmentHeaderTitle,
  Button,
} from '../../dt-assignment-blocks.styled';

interface DtColumnHeaderProps {
  title: string;
  counter: number;
  onAssign?: () => void;
  disableAssignButton?: boolean;
  hideAssignButton?: boolean;
  selectedListItem?: string | null;
  childrenCounterWhenItemHighlighted?: number;
  searchedListItemsCounter?: number;
  isListItemSearched?: boolean;
  listItemsLength?: number;
}

const DtAssignmentColumnHeader: React.FC<DtColumnHeaderProps> = ({
  title,
  counter,
  onAssign,
  disableAssignButton,
  selectedListItem,
  childrenCounterWhenItemHighlighted,
  searchedListItemsCounter,
  isListItemSearched,
  hideAssignButton,
  listItemsLength,
}) => {
  const showCounterBasedOnSelectedParentItem =
    selectedListItem && listItemsLength
      ? `${childrenCounterWhenItemHighlighted} of ${counter}`
      : isListItemSearched
      ? `${searchedListItemsCounter} of ${counter}`
      : counter;

  const disabled = !onAssign || disableAssignButton;

  return (
    <AssignmentHeader>
      <AssignmentHeaderTitle>{title} </AssignmentHeaderTitle>
      <AssignmentHeaderCounter label={showCounterBasedOnSelectedParentItem} />
      {!hideAssignButton && (
        <Button variant='transparent' onClick={onAssign ? onAssign : () => ({})} disabled={disabled}>
          <span
            style={{
              top: '-1px',
              position: 'relative',
            }}
          >
            +
          </span>{' '}
          Assign
        </Button>
      )}
    </AssignmentHeader>
  );
};

export default DtAssignmentColumnHeader;
