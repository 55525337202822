import { GridRow } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DtPagination from './../dt-assignment-blocks/components/dt-pagination/dt-pagination';
import DtSortedAscendingIcon from './dt-sorted-ascending-icon/dt-sorted-ascending-icon';
import DtSortedDescendingIcon from './dt-sorted-descending-icon/dt-sorted-descending-icon';
import { DtTableProps } from './dt-table.interface';
import { StyledGridOverlay, NoDataText, TableContainer, TableItems } from './dt-table.styled';
import { DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE } from '../../modules/dt-dashboard/dt-dispatches.constants';
import EmptyTableImg from '../../resources/assets/table-empty.png';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DtTable<T extends { [key: string]: any }>({
  rows,
  getRowId,
  onRowClick,
  columns,
  selectionModel,
  rowHeight,
  onSelectionModelChange,
  sortModel,
  onSortModelChange,
  sortingOrder,
  loading,
  checkboxSelection,
  actionsComponent,
  removeShadow,
  columnVisibilityModel,
  tableHeight,
  tableMaxHeight,
  dataGridHeight,
  onRowHover,
}: DtTableProps<T>): React.ReactElement {
  const { pathname } = useLocation();

  const [rowsPerPage, setRowsPerPage] = useState(DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE);
  const [page, setPage] = useState(0);

  const rowsOnPage = Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // Reset table when it is loading or page is changed
  useEffect(() => {
    setPage(0);
  }, [pathname, loading]);

  function handlePageSizeChange(newPage: number): void {
    setPage(newPage - 1);
  }

  function handleRowsPerPageChange(newRowsPerPage: number): void {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  function CustomNoRowsOverlay(): JSX.Element {
    return (
      <>
        {!loading && rows.length === 0 && (
          <StyledGridOverlay>
            <img src={EmptyTableImg} alt='empty table img' width='50' height='50' />
            <NoDataText>No data available</NoDataText>
          </StyledGridOverlay>
        )}
      </>
    );
  }

  return (
    <TableContainer
      style={{ boxShadow: removeShadow ? 'none' : undefined }}
      tableHeight={tableHeight}
      tableMaxHeight={tableMaxHeight}
      dataGridHeight={dataGridHeight}
    >
      <div>{actionsComponent ? actionsComponent : null}</div>
      <TableItems
        autoHeight
        pagination
        sortingOrder={sortingOrder}
        paginationModel={{ page: page, pageSize: rowsPerPage }}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        isRowSelectable={() => !loading}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        rowHeight={rowHeight}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: () => <></>,
          columnSortedDescendingIcon: () => <DtSortedDescendingIcon />,
          columnSortedAscendingIcon: () => <DtSortedAscendingIcon />,
          row: (props) => (
            <GridRow
              {...props}
              onMouseEnter={() => onRowHover && onRowHover(props.row.id)}
              onMouseLeave={() => onRowHover && onRowHover('')}
            />
          ),
          pagination: () => (
            <DtPagination
              totalRows={rows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageSizeChange}
            />
          ),
        }}
        onRowClick={loading ? () => undefined : onRowClick}
        onRowSelectionModelChange={onSelectionModelChange}
        rowSelectionModel={selectionModel}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        loading={loading}
        isEmpty={rows.length}
        pageHeight={(rowsOnPage + 1) * rowHeight}
        columnVisibilityModel={columnVisibilityModel}
      />
    </TableContainer>
  );
}

export default DtTable;
