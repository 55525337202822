import { Field, FieldProps } from 'formik';
import React from 'react';

import DtDatePicker, { DtDatePickerProps } from '../dt-date-picker/dt-date-picker';

const DtFormikDatePicker: React.FC<Partial<DtDatePickerProps>> = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <DtDatePicker
          value={field.value}
          onChange={(value) => {
            form.setFieldError(field.name, undefined);
            form.setFieldValue(field.name, value);
            if (props.onChange && value) {
              props.onChange(value);
            }
          }}
          disabled={props.disabled}
          label={props.label}
          className={props.className}
          fullWidth={props.fullWidth}
          placeholder={props.placeholder}
          name={field.name}
          error={meta.error}
          dateFormat={props.dateFormat}
          disabledDays={props.disabledDays}
          maxMonth={props.maxMonth}
          minMonth={props.minMonth}
        />
      )}
    </Field>
  );
};

export default DtFormikDatePicker;
