import { CSSObject, Theme } from '@mui/material/styles';
import { MixinsOptions } from '@mui/material/styles/createMixins';

export const DT_MIXINS_OPTIONS: MixinsOptions = {
  /**
   * Mixin for animated item fade
   * @param {boolean} visible - boolean indicating visibility of item
   * @param {string | undefined} visibleDisplay - optional value to define the display property of visible item
   * @example ${({ visible, theme }) => theme.mixins.fadeMixin(visible, 'flex')};
   */
  fadeMixin: (visible: boolean, visibleDisplay?: string): CSSObject => {
    const display = visibleDisplay || 'initial';
    return { transition: 'display 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', display: visible ? display : 'none' };
  },
  /**
   * Mixin for adding box-shadow to element
   * @example ${({ theme }) => theme.mixins.boxShadowMixin};
   */
  boxShadowMixin: {
    boxShadow: '0 16px 32px 0 rgba(15, 65, 107, 8%), 0 2px 8px 0 rgba(15, 65, 107, 9%)',
  },
  /**
   * Mixin for adding box-shadow to primary button
   * @example ${({ theme }) => theme.mixins.primaryButtonBoxShadowMixin};
   */
  primaryButtonBoxShadowMixin: {
    boxShadow: 'inset 0 -2px 0 0 rgba(35, 31, 32, 0.15)',
  },
  /**
   * Mixin for adding box-shadow to flat and tran button
   * @example ${({ theme }) => theme.mixins.primaryButtonBoxShadowMixin};
   */
  flatButtonShadowMixin: {
    boxShadow: 'inset 0 0 6px 0 rgba(0, 0, 0, 0.12)',
  },
  switcherBoxShadowMixin: {
    boxShadow: '0 4px 8px 0 rgba(35, 31, 32, 0.25), 0 2px 8px 0 rgba(35, 31, 32, 0.1)',
  },
  /**
   * Mixin for adding box-shadow to accordion component
   * @example ${({ theme }) => theme.mixins.accordionBoxShadowMixin};
   */
  accordionBoxShadowMixin: {
    boxShadow: ' 0 8px 16px 0 rgba(15, 65, 107, 0.08), 0 2px 4px 0 rgba(15, 65, 107, 0.09)',
  },
  commonItemsSpacingMixin: (theme: Theme, spacing: number): CSSObject => ({
    [' &:not(:last-child)']: {
      marginRight: theme.spacing(spacing),
    },
  }),
  textInputCommonStylesMixin: (theme: Theme): CSSObject => ({
    ['& .MuiOutlinedInput-root']: {
      color: theme.palette.main.secondaryText,
      backgroundColor: theme.palette.main.control,
      ...theme.typography.body,
      ['&:hover']: {
        backgroundColor: '#e6edf5',
        fieldset: {
          border: 'solid 1.5px rgba(116, 123, 153, 0.2)',
        },
      },
      ['& input']: {
        borderRadius: '3px',
        padding: `${theme.spacing(10)} ${theme.spacing(16)}`,
        ['&:disabled']: {
          cursor: 'not-allowed',
        },
      },
      ['& fieldset']: {
        border: 'solid 1.5px rgba(116, 123, 153, 0.2)',
      },
      ['&.Mui-focused']: {
        color: theme.palette.main.black,
        backgroundColor: theme.palette.main.white,
        fieldset: {
          border: `1.5px solid ${theme.palette.main.primary}`,
        },
      },
      ['&.Mui-disabled']: {
        opacity: 0.4,
        backgroundColor: theme.palette.main.control,
        fieldset: {
          border: 'solid 1.5px rgba(116, 123, 153, 0.2)',
        },
      },
    },
  }),
};
