import { Typography } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import DtPopup from '../../../../components/dt-popup/dt-popup';
import { DialogButton } from '../../../../components/dt-popup/dt-popup.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { UserDetailsDtoStatusEnum } from '../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../services/dt-toast.service';

interface DtManageUsersResendInviteButtonProps {
  userEmail: string;
  userStatus: string;
}

const DtManageUsersResendInviteButton = ({
  userEmail,
  userStatus,
}: DtManageUsersResendInviteButtonProps): ReactElement | null => {
  const [showConfirmationMessage, updateShowConfirmationMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetPasswordStatuses = [
    UserDetailsDtoStatusEnum.Unconfirmed,
    UserDetailsDtoStatusEnum.ResetRequired,
    UserDetailsDtoStatusEnum.ForceChangePassword,
  ];
  const showResetPasswordButton = resetPasswordStatuses.includes(userStatus as UserDetailsDtoStatusEnum);

  async function handleResetPasswordSending(): Promise<void> {
    try {
      setIsLoading(true);

      await dtApiRepository.MeApi.userControllerResetPasswordForUser(userEmail);

      setIsLoading(false);
      updateShowConfirmationMessage(true);
    } catch (e) {
      dtToastService.error('Failed to send reset password email.');
    }
  }

  function handleCloseConfirmationPopup(): void {
    updateShowConfirmationMessage(false);
  }

  return (
    <>
      {showConfirmationMessage && (
        <DtPopup
          opened={showConfirmationMessage}
          onClose={handleCloseConfirmationPopup}
          title='Reset Password email sent successfully'
          icon='check'
          color='emerald'
        >
          <DialogButton variant='primary' onClick={handleCloseConfirmationPopup}>
            Ok
          </DialogButton>
        </DtPopup>
      )}

      {showResetPasswordButton ? (
        isLoading ? (
          <DtSkeleton variant='rectangular' width={166} height={40} />
        ) : (
          <DtButton variant='secondary' onClick={handleResetPasswordSending}>
            <DtIcon icon='invite' size={14} color='primary' />
            <Typography variant='bodySemibold' marginLeft='5px'>
              Reset Password
            </Typography>
          </DtButton>
        )
      ) : null}
    </>
  );
};

export default DtManageUsersResendInviteButton;
