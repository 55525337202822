import { GridInputRowSelectionModel, GridRowParams, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import React, { ReactElement } from 'react';

import dtGenerateAssetsTableColumnsConfig from './dt-generate-assets-table-columns-config';
import DtTable from '../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../dt-constants';
import { DT_TABLE_ROW_HEIGHT } from '../../../dt-dashboard/dt-dispatches.constants';

export interface DtAssetRowItem {
  id: string;
  propertyId: number;
  propertyName: string;
  propertyAddress: string;
  formType: string;
  formId: number;
  formName: string;
  assetName: string;
  assetFloor: string;
  assetLocation: string;
  pfFormId?: string;
  reportTemplateName?: string;
  lastSubmitter: { name: string; email: string };
  lastSubmissionDateTime: string;
  lastReportURL: string;
  isLoading: boolean;
}

interface DtAssetsTableProps {
  rows: DtAssetRowItem[];
  isLoading: boolean;
  selectionModel?: GridInputRowSelectionModel;
  actionsComponent?: ReactElement;
  onRowClick: (row: DtAssetRowItem) => void;
  openDeleteConfirmation: (id: string) => void;
  onRowSelectionChange?: (selectionModel: GridRowSelectionModel) => void;
}

const DtAssetsTable: React.FC<DtAssetsTableProps> = ({
  rows,
  isLoading,
  selectionModel,
  actionsComponent,
  onRowClick,
  openDeleteConfirmation,
  onRowSelectionChange,
}) => {
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const dtDispatchesTableColumns = dtGenerateAssetsTableColumnsConfig(isLoading, openDeleteConfirmation);

  return (
    <DtTable
      rows={
        isLoading
          ? ([
              { id: -1 },
              { id: -2 },
              { id: -3 },
              { id: -4 },
              { id: -5 },
              { id: -6 },
              { id: -7 },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any)
          : rows
      }
      loading={isLoading}
      columns={dtDispatchesTableColumns}
      onRowClick={(params: GridRowParams) => {
        onRowClick(params.row as DtAssetRowItem);
      }}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      onSelectionModelChange={onRowSelectionChange}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      selectionModel={selectionModel}
      sortModel={sortModel}
      onSortModelChange={(newSortingModel) => setSortModel(newSortingModel)}
      checkboxSelection={true}
      actionsComponent={actionsComponent}
    />
  );
};

export default DtAssetsTable;
