import { Drawer } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import DtButton from '../../../../components/dt-button/dt-button';

const DRAWER_OPENED_WIDTH = 292;
const DRAWER_CLOSED_WIDTH = 80;

const openedSidebarMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_OPENED_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
});

const closedSidebarMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_CLOSED_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
});

const expandDrawerDividerMixin = (position: 'top' | 'bottom'): CSSObject => ({
  position: 'absolute',
  content: '""',
  left: '50%',
  height: 28,
  width: 2,
  backgroundImage: `
    linear-gradient(
      to ${position},
      rgba(130, 136, 163, 0),
      rgba(130, 136, 163, 0.15) 27%,
      rgba(130, 136, 163, 0.15) 72%,
      rgba(130, 136, 163, 0)
    );
  `,
});

export const DrawerContainer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })`
  position: relative;
  ${({ theme, open }) => (open ? openedSidebarMixin(theme) : closedSidebarMixin(theme))};
  overflow: initial;

  & .MuiDrawer-paper {
    position: fixed;
    overflow: initial;
    padding: ${({ theme }) => `${theme.spacing(16)} 0`};
    ${({ theme, open }) => (open ? openedSidebarMixin(theme) : closedSidebarMixin(theme))};
    background-color: ${({ theme }) => theme.palette.main.checkboxRegular};
    border: none;
  }
`;

export const ExpandDrawerButton = styled(DtButton)`
  position: absolute;
  top: 60px;
  right: -14px;

  &::after {
    bottom: 2px;
    transform: translate(-50%, 100%);
    ${expandDrawerDividerMixin('top')}
  }

  &::before {
    bottom: 8px;
    transform: translate(-50%, -50%);
    ${expandDrawerDividerMixin('bottom')};
  }
`;

export const NavbarSection = styled('section')`
  padding: ${({ theme }) => `0 ${theme.spacing(16)}`};
`;

export const Footer = styled(NavbarSection)`
  margin-top: auto;
`;
