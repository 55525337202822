import { styled } from '@mui/material/styles';
import { Form } from 'formik';

export const FormContainer = styled('section')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const ModalFormSkeleton = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(32)} ${theme.spacing(32)} ${theme.spacing(0)} ${theme.spacing(32)}`};
`;

export const ModalForm = styled(Form)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(32)} ${theme.spacing(32)} ${theme.spacing(0)} ${theme.spacing(32)}`};
`;
