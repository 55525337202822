import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import { NotesFieldLabel, TabTitle } from './dt-new-dispatch-details.styled';
import { FrequencyType } from '../../../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import DtToggleButton, { DtTabsItem } from '../../../../../../components/dt-toggle-button/dt-toggle-button';
import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import { DtDispatchPriorityTypeByIndex } from '../../../../dt-dispatches.enums';
import { DtFrequencyEditorFormRecurrenceType } from '../../dt-frequency-editor-form.enums';
import { DtNewDispatchFormInitialValues } from '../../dt-new-dispatch-modal-form-interface';
import { DT_TAB_ITEMS } from '../../dt-new-dispatch-modal.constants';

const DtNewDispatchDetails: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<DtNewDispatchFormInitialValues>();

  const toggleButtonItems: DtTabsItem<DtDispatchPriorityTypeByIndex>[] = [
    {
      value: DtDispatchPriorityTypeByIndex.Low,
      label: <TabTitle>Low</TabTitle>,
    },
    {
      value: DtDispatchPriorityTypeByIndex.Medium,
      label: <TabTitle>Medium</TabTitle>,
    },
    {
      value: DtDispatchPriorityTypeByIndex.High,
      label: <TabTitle>High</TabTitle>,
    },
  ];

  function handlePriorityChange(newValue: DtDispatchPriorityTypeByIndex): void {
    setFieldValue('details.priority', newValue);
  }

  function handleFrequencyToggleValueChange(newTabValue: FrequencyType): void {
    setFieldValue('frequency', {
      frequencyType: newTabValue,
      day:
        newTabValue === FrequencyType.Daily || newTabValue === FrequencyType.Monthly
          ? 1
          : newTabValue === FrequencyType.Weekly
          ? []
          : undefined,
      weekRepeats: newTabValue === FrequencyType.Weekly ? 1 : undefined,
      month: newTabValue === FrequencyType.Monthly ? 1 : undefined,
      monthRepeats: newTabValue === FrequencyType.Monthly ? 1 : undefined,
      repeatEvery: newTabValue === FrequencyType.Monthly ? DtFrequencyEditorFormRecurrenceType.Each : undefined,
      startDate: values.frequency.startDate,
      endDate: newTabValue !== FrequencyType.None ? values.frequency.endDate : undefined,
      dueDate: values.frequency.dueDate,
      customDueDate:
        values.frequency.dueDate === 'custom' || values.frequency.dueDate === 'Custom'
          ? values.frequency.customDueDate
          : undefined,
      reminder: newTabValue >= FrequencyType.Weekly ? values.frequency.reminder : undefined,
    });
  }

  return (
    <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
      <Grid item xs={12}>
        <InputLabel>Frequency</InputLabel>
        <DtToggleButton
          value={values.frequency.frequencyType ?? FrequencyType.None}
          tabsItems={DT_TAB_ITEMS}
          onChange={handleFrequencyToggleValueChange}
        />
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Priority</InputLabel>
        <DtToggleButton
          tabsItems={toggleButtonItems}
          value={values.details.priority}
          onChange={handlePriorityChange}
          fullWidth={true}
          alignTabs={true}
        />
      </Grid>

      <Grid item xs={12}>
        <InputLabel htmlFor='notes'>
          Notes <NotesFieldLabel htmlFor='notes'>(optional)</NotesFieldLabel>
        </InputLabel>
        <DtFormTextInput name='details.notes' id='notes' placeholder='Add any notes here' />
      </Grid>
    </Grid>
  );
};

export default DtNewDispatchDetails;
