import React from 'react';

import { PriorityContainer, PriorityLabel } from './dt-dispatch-priority.styled';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { DtSupportedIcon } from '../../../../components/dt-icon/gen/dt-supported-icons';
import { DtDispatchPriorityType } from '../../dt-dispatches.enums';

const PriorityIconNames: Record<DtDispatchPriorityType, DtSupportedIcon> = {
  [DtDispatchPriorityType.High]: 'prio-high',
  [DtDispatchPriorityType.Medium]: 'prio-medium',
  [DtDispatchPriorityType.Low]: 'prio-low',
};

const PriorityIconColors: Record<DtDispatchPriorityType, keyof MainPalette> = {
  [DtDispatchPriorityType.High]: 'red',
  [DtDispatchPriorityType.Medium]: 'yellow',
  [DtDispatchPriorityType.Low]: 'emerald',
};

interface DtDispatchPriorityProps {
  priority: DtDispatchPriorityType;
}

const DtDispatchPriority: React.FC<DtDispatchPriorityProps> = ({ priority }) => {
  const iconName = PriorityIconNames[priority];
  const iconColor = PriorityIconColors[priority];

  return (
    <PriorityContainer>
      <DtIcon icon={iconName} size={16} color={iconColor} />
      <PriorityLabel variant='body'>{priority}</PriorityLabel>
    </PriorityContainer>
  );
};

export default DtDispatchPriority;
