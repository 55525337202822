import { styled } from '@mui/material/styles';

export const MenuContainer = styled('div')`
  padding: ${({ theme }) => `${theme.spacing(8)} 0 ${theme.spacing(8)} 0`};
`;

export const NewDispatchButtonContainer = styled(`div`)`
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const MenuItemContainer = styled('div')`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(14)};
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(16)}`};
`;

export const MenuPropertiesSelectContainer = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(24)} 0 ${theme.spacing(16)} 0`};
`;

export const MenuItemIconContainer = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(12)};
`;

export const FooterContent = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(16)};
`;
