import React from 'react';

import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { NewPasswordFieldWrapper } from '../../components/dt-form-common-styles.styled';
import { LoginInputContainer } from '../dt-login-page-skeleton/dt-login-page-skeleton.styled';

const DtResetPasswordFormSkeleton: React.FC = () => {
  return (
    <>
      <LoginInputContainer>
        <DtSkeleton variant='rectangular' height={40} />
      </LoginInputContainer>
      <LoginInputContainer>
        <DtSkeleton variant='rectangular' height={40} />
      </LoginInputContainer>
      <NewPasswordFieldWrapper>
        <LoginInputContainer>
          <DtSkeleton variant='rectangular' height={40} />
        </LoginInputContainer>
        <LoginInputContainer>
          <DtSkeleton variant='rectangular' height={40} />
        </LoginInputContainer>
      </NewPasswordFieldWrapper>
      <DtSkeleton variant='rectangular' width={148} height={40} />
    </>
  );
};

export default DtResetPasswordFormSkeleton;
