import { Button } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

export const CustomButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'padding' })<{
  padding?: number;
}>`
  min-width: auto;
  width: fit-content;
  text-transform: none;
  padding: ${({ theme, padding }) => padding && theme.spacing(padding)};
  border-radius: 3px;
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};

  &:disabled {
    opacity: 40%;
  }
`;
