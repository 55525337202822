import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import DtNewDispatchModalActions from './components/dt-new-dispatch-modal-actions/dt-new-dispatch-modal-actions';
import { DtNewDispatchFormInitialValues } from './dt-new-dispatch-modal-form-interface';
import {
  DT_DISPATCH_FORM,
  DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE,
} from './dt-new-dispatch-modal-form-validation-schema';
import { FormContainer, ModalForm, ModalFormSkeleton } from './dt-new-dispatch-modal-form.styled';
import DtNewDispatchModalSkeleton from './dt-new-dispatch-modal-skeleton';
import {
  DialogHeader,
  DialogHeaderSubtitle,
  DialogHeaderTitle,
  DialogStep,
  DialogWrapper,
} from './dt-new-dispatch-modal.styled';
import { dtValidateFormGroup } from './dt-new-dispatch-modal.utils';
import DtNewDispatchAssignees from './steps/dt-new-dispatch-assignees/dt-new-dispatch-assignees';
import DtNewDispatchDetails from './steps/dt-new-dispatch-details/dt-new-dispatch-details';
import DtNewDispatchFrequency from './steps/dt-new-dispatch-frequency/dt-new-dispatch-frequency';
import DtNewDispatchGeneralInformation from './steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information';
import { dtUseAppDispatch } from '../../../../cdk/hooks/dt-store.hooks';
import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import DtStepper from '../../../../components/dt-stepper/dt-stepper';
import { DispatchDetailsDto } from '../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../repositories/dt-api.repository';
import {
  dtCreateNewDispatch,
  dtRefreshCompletedDtDispatches,
  dtRefreshDtDispatchesCount,
  dtRefreshOpenDtDispatches,
  dtRefreshOverdueDtDispatches,
  dtRefreshScheduledDtDispatches,
  dtUpdateScheduledDispatch,
} from '../../dt-dispatches.slice';

enum Steps {
  GeneralInfo,
  Assignees,
  Details,
  Schedule,
}

interface DtNewDispatchModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  dispatchRowItem?: DtScheduleDispatchItem;
}

export interface DtNewDispatchStepConfig {
  id: number;
  label: string;
  getStepContent: () => JSX.Element;
  onStepSubmit: () => void;
}

const DtNewDispatchModal: React.FC<DtNewDispatchModalProps> = ({
  opened,
  title,
  subtitle,
  onClose,
  dispatchRowItem,
}) => {
  const dispatch = dtUseAppDispatch();
  const [activeStep, setActiveStep] = useState<Steps>(0);
  const [isCompletedStep, setIsCompletedStep] = useState(false);
  const [scheduledDispatchData, setScheduledDispatchData] = useState<DispatchDetailsDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialFormData] = useState(DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE(scheduledDispatchData));

  const isEditFlow = Boolean(scheduledDispatchData);

  useEffect(() => {
    if (dispatchRowItem?.id) {
      getSingleScheduledDispatch();
    } else {
      setScheduledDispatchData(null);
      setInitialFormData(DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE());
    }
  }, [dispatchRowItem?.id]);

  // const [newDispatchFormValues, setNewDispatchFormValues] = useState<DtNewDispatchFormInitialValues>(initialValues);

  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);

  async function getSingleScheduledDispatch(): Promise<null | void> {
    try {
      if (opened && dispatchRowItem) {
        setIsLoading(true);
        const { data } = await dtApiRepository.DispatchApi.dispatchControllerGetScheduled(dispatchRowItem.id);
        setScheduledDispatchData(data);
        setInitialFormData(DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE(data));
        setActiveStep(Steps.Schedule);
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
      return null;
    }
  }

  function handleNextStep(): void {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setValidationAfterSubmit(false);
  }

  function handlePreviousStep(): void {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleClose(): void {
    // setNewDispatchFormValues(initialValues);
    setActiveStep(0);
    onClose();
  }

  async function handleSubmit(values: DtNewDispatchFormInitialValues): Promise<void> {
    setIsCompletedStep(true);

    if (scheduledDispatchData) {
      // is edit flow
      await dispatch(
        dtUpdateScheduledDispatch({
          payload: values,
          id: scheduledDispatchData.scheduleId,
        })
      );
      dispatch(dtRefreshDtDispatchesCount());
      dispatch(dtRefreshOpenDtDispatches());
      dispatch(dtRefreshOverdueDtDispatches());
      dispatch(dtRefreshCompletedDtDispatches());
      dispatch(dtRefreshScheduledDtDispatches());
      onClose();
    } else {
      await dispatch(dtCreateNewDispatch(values));
      dispatch(dtRefreshDtDispatchesCount());
      dispatch(dtRefreshOpenDtDispatches());
      dispatch(dtRefreshOverdueDtDispatches());
      dispatch(dtRefreshCompletedDtDispatches());
      dispatch(dtRefreshScheduledDtDispatches());
      setValidationAfterSubmit(false);
      setIsCompletedStep(false);
      handleClose();
    }
  }

  return (
    <DialogWrapper open={opened} transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogHeader>
        <section>
          <DialogHeaderTitle>{title}</DialogHeaderTitle>
          <DialogHeaderSubtitle>{subtitle}</DialogHeaderSubtitle>
        </section>
        <DtButton variant='transparent' onClick={handleClose}>
          <DtIcon icon='close' size={21} />
        </DtButton>
      </DialogHeader>

      <FormContainer>
        {isLoading && (
          <ModalFormSkeleton>
            <DtNewDispatchModalSkeleton />
          </ModalFormSkeleton>
        )}
        {!isLoading && (
          <Formik
            initialValues={initialData}
            onSubmit={handleSubmit}
            validationSchema={DT_DISPATCH_FORM}
            enableReinitialize={true}
            validateOnChange={validationAfterSubmit}
            validateOnBlur={validationAfterSubmit}
          >
            {(formikProps) => {
              return (
                <>
                  <DtStepper
                    onClick={async (nextStep) => {
                      if (
                        (activeStep === Steps.GeneralInfo && (await dtValidateFormGroup(formikProps, 'generalInfo'))) ||
                        (activeStep === Steps.Assignees && (await dtValidateFormGroup(formikProps, 'assignees'))) ||
                        (activeStep === Steps.Details && (await dtValidateFormGroup(formikProps, 'additional'))) ||
                        (activeStep === Steps.Schedule && (await dtValidateFormGroup(formikProps, 'frequency')))
                      ) {
                        setActiveStep(nextStep);
                      }
                    }}
                    activeStep={activeStep}
                    steps={[
                      {
                        id: Steps.GeneralInfo,
                        label: 'General',
                      },
                      {
                        id: Steps.Assignees,
                        label: 'Assignees',
                      },
                      {
                        id: Steps.Details,
                        label: 'Details',
                      },
                      {
                        id: Steps.Schedule,
                        label: 'Schedule',
                      },
                    ]}
                    isCompletedStep={isCompletedStep}
                  />
                  <ModalForm>
                    <DialogStep isActive={activeStep === Steps.GeneralInfo}>
                      <DtNewDispatchGeneralInformation isEditFlow={isEditFlow} />
                      <DtNewDispatchModalActions
                        onCancel={handleClose}
                        onNextStep={async () => {
                          if (await dtValidateFormGroup(formikProps, 'generalInfo')) {
                            handleNextStep();
                          }
                        }}
                      />
                    </DialogStep>

                    <DialogStep isActive={activeStep === Steps.Assignees}>
                      <DtNewDispatchAssignees
                        isEditFlow={isEditFlow}
                        siteId={formikProps.values.generalInfo.site}
                        formType={formikProps.values.generalInfo.formType}
                      />
                      <DtNewDispatchModalActions
                        onPreviousStep={handlePreviousStep}
                        onNextStep={async () => {
                          if (await dtValidateFormGroup(formikProps, 'assignees')) {
                            handleNextStep();
                          }
                        }}
                      />
                    </DialogStep>

                    <DialogStep isActive={activeStep === Steps.Details}>
                      <DtNewDispatchDetails />
                      <DtNewDispatchModalActions
                        onPreviousStep={handlePreviousStep}
                        onNextStep={async () => {
                          if (await dtValidateFormGroup(formikProps, 'details')) {
                            handleNextStep();
                          }
                        }}
                      />
                    </DialogStep>

                    <DialogStep isActive={activeStep === Steps.Schedule}>
                      <DtNewDispatchFrequency isEditFlow={isEditFlow} />
                      <DtNewDispatchModalActions
                        onPreviousStep={handlePreviousStep}
                        onSubmit={async () => {
                          const errors = await formikProps.validateForm();
                          if (isEmpty(errors)) {
                            formikProps.submitForm();
                          }
                        }}
                      />
                    </DialogStep>
                  </ModalForm>
                </>
              );
            }}
          </Formik>
        )}
      </FormContainer>
    </DialogWrapper>
  );
};

export default DtNewDispatchModal;
