import { AssigneeStatusType, DtDispatchPriorityType, DtSupportedDispatchStatuses } from './dt-dispatches.enums';

export const DT_WIDTH_WITH_TWO_ICONS = 135;
export const DT_WIDTH_WITH_ONE_ICON = 80;
export const DT_TABLE_ROW_HEIGHT = 80;
// eslint-disable-next-line no-magic-numbers
export const DT_ROWS_PER_PAGE_OPTIONS = [5, 10, 15];
export const DT_DEFAULT_NUMBER_OF_ROWS_IN_ONE_PAGE = DT_ROWS_PER_PAGE_OPTIONS[1];
export const DT_ASSIGNEE_COLUMN_WIDTH = 0.75;
export const DT_SCHEDULED_DISPATCH_COLUMNS_WIDTH = 0.65;
export const DT_SCHEDULED_DISPATCH_TYPE_COLUMN_WIDTH = 1.2;
export const DT_MULTIPLESELECT_CHIP_ITEMS_COUNT = 3;

export const DT_ASSIGNEE_STATUS_TYPE_MAPPING: Record<AssigneeStatusType, string> = {
  [AssigneeStatusType.Completed]: 'Submitted',
  [AssigneeStatusType.New]: 'Assigned',
  [AssigneeStatusType.Declined]: 'Declined',
};

export const DT_DISPATCHES_STATUS_MAPPING: Record<string, string> = {
  OPEN: DtSupportedDispatchStatuses.Open.toUpperCase(),
  OVERDUE: DtSupportedDispatchStatuses.Overdue.toUpperCase(),
  DONE: DtSupportedDispatchStatuses.Completed.toUpperCase(),
  COMPLETED: DtSupportedDispatchStatuses.Completed.toUpperCase(),
  SCHEDULED: DtSupportedDispatchStatuses.Scheduled.toUpperCase(),
};

export const DT_DISPATCH_PRIORITY_TYPE_MAPPING: Record<number, DtDispatchPriorityType> = {
  0: DtDispatchPriorityType.Low,
  1: DtDispatchPriorityType.Medium,
  2: DtDispatchPriorityType.High,
};

export const DT_DISPATCH_PRIORITY_EDITING_MODE_MAPPING: Record<DtDispatchPriorityType, number> = {
  [DtDispatchPriorityType.Low]: 0,
  [DtDispatchPriorityType.Medium]: 1,
  [DtDispatchPriorityType.High]: 2,
};
