import { Chip, ListItemButton } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import DtCreateButton from '../../modules/dt-administration/components/dt-create-button/dt-create-button';
import DtTextInput from '../text-input/dt-text-input/dt-text-input';
import DtScrollbar from './../dt-scrollbar/dt-scrollbar';

export const AssignmentsWrapper = styled('main')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
`;

export const AssignmentContent = styled('div')`
  &:not(:last-child) {
    padding-right: ${({ theme }) => theme.spacing(24)};
    border-right: ${({ theme }) => ` 1.5px solid ${theme.palette.main.transparentSecondaryText}`};
  }
`;

export const AssignmentHeader = styled('header')`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const AssignmentHeaderTitle = styled('h3')`
  ${({ theme }) => theme.typography.subtitle as CSSObject}
  color: ${({ theme }) => theme.palette.main.black};
  margin-right: ${({ theme }) => theme.spacing(16)};
  margin-top: 0;
  margin-bottom: 0;
`;

export const AssignmentHeaderCounter = styled(Chip)`
  ${({ theme }) => theme.typography.bodySmallSemibold as CSSObject};
  border-radius: 3px;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(9)}`};
  color: ${({ theme }) => theme.palette.main.secondaryText};
  height: auto;

  & .MuiChip-label {
    padding: 0;
  }
`;

export const Button = styled(DtCreateButton)`
  color: ${({ theme }) => theme.palette.main.buttonPrimary};
  padding: 0;
  margin-bottom: 0;

  &:hover {
    background-color: transparent;
  }
`;

export const AssignmentSearchInput = styled(DtTextInput)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const ListItemsScrollbar = styled(DtScrollbar)`
  max-height: 600px;
`;

export const ListItemWrapper = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'withArrow' && prop !== 'disableEditing',
})<{
  withArrow?: boolean;
  disableEditing?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: ${({ withArrow }) => (withArrow ? 'calc(100% - 12px)' : '100%')};
  min-height: 62px;
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(16)} `};
  border: none;
  cursor: ${({ disableEditing }) => (disableEditing ? 'default' : 'pointer')};
  transition: none;

  button {
    display: none;
  }

  &:hover {
    background-color: transparent;

    button {
      display: inherit;
    }
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.main.transparentButtonPrimary};
    ${({ theme, withArrow }) =>
      withArrow
        ? `&::after {
      content: '';
      border-top: 32px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 12px solid ${theme.palette.main.transparentButtonPrimary};
      position: absolute;
      right: -12px;
      top: 0;
      z-index: 1;
    }`
        : null};

    &:hover {
      background-color: ${({ theme }) => theme.palette.main.transparentButtonPrimary};
    }

    &:hover::after {
      border-top: 32px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: ${({ theme }) => `12px solid ${theme.palette.main.transparentButtonPrimary}`};
    }
  }
`;

export const ListItemTitle = styled('h3')`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject}
  margin: 0;
  color: ${({ theme }) => theme.palette.main.black};
  text-align: left;
`;

export const ListItemSubtitle = styled('p')`
  ${({ theme }) => theme.typography.bodySmall as CSSObject}
  margin: 0;
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;

export const ListItemsAction = styled('div')`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const FallBackMessageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(71)};
`;

export const FallBackMessageTitle = styled('p')`
  ${({ theme }) => theme.typography.subtitle as CSSObject}
  margin: 0;
  color: ${({ theme }) => theme.palette.main.black};
`;

export const FallBackMessageSubtitle = styled(FallBackMessageTitle)`
  width: 282px;
  text-align: center;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing(35)};
`;
