import { LabelDisplayedRowsArgs } from '@mui/material';
import React from 'react';

import { CustomTablePagination, PaginationWrapper, CustomPagination } from './dt-pagination.styled';
import { DT_ROWS_PER_PAGE_OPTIONS } from '../../../../modules/dt-dashboard/dt-dispatches.constants';

export interface DtPaginationProps {
  totalRows: number;
  rowsPerPage: number;
  page: number;
  onRowsPerPageChange: (rows: number) => void;
  onPageChange: (pageSize: number) => void;
}

const DtPagination: React.FC<DtPaginationProps> = (props) => {
  const amountOfPages = Math.ceil(props.totalRows / props.rowsPerPage);

  function displayedRowsLabel(paginationInfo: LabelDisplayedRowsArgs): string {
    return `Records: ${paginationInfo.from}-${paginationInfo.to} of ${paginationInfo.count}`;
  }

  return (
    <PaginationWrapper>
      <CustomTablePagination
        count={props.totalRows}
        onPageChange={(e, page) => props.onPageChange(page)}
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        rowsPerPageOptions={DT_ROWS_PER_PAGE_OPTIONS}
        onRowsPerPageChange={(e) => props.onRowsPerPageChange(Number(e.target.value))}
        labelDisplayedRows={(paginationInfo) => displayedRowsLabel(paginationInfo)}
        labelRowsPerPage='Show:'
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                width: 140,
                transform: 'translateX(-28px) !important',
                marginTop: '8px',
              },
            },
          },
        }}
      />
      <CustomPagination count={amountOfPages} page={props.page + 1} onChange={(e, page) => props.onPageChange(page)} />
    </PaginationWrapper>
  );
};

export default DtPagination;
