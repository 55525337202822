import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

import { DtOptionItem } from '../../components/dt-selects/dt-selects.interfaces';
import { DtRootState } from '../../dt-store';
import { UserRole } from '../../repositories/__generated__/v2';

export interface DtUserState {
  data: DtUser | null;
  status: DtStatus;
  companyData: DtCompanyData[] | null;
  currentCompanyId: number | null;
}

const initialState: DtUserState = {
  data: null,
  // Because we need to wait until user data will be loaded or loading process will fail
  status: 'loading',
  companyData: null,
  currentCompanyId: null,
};

export const dtUserSlice = createSlice({
  name: 'dtUser',
  initialState,
  reducers: {
    dtSetUserData: (state, action: PayloadAction<DtUser | null>) => {
      state.data = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetUserCompanyData: (state, action: PayloadAction<DtCompanyData[] | null>) => {
      state.companyData = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetCurrentCompany: (state, action: PayloadAction<number | null>) => {
      state.currentCompanyId = action.payload;
      state.status = action.payload ? 'idle' : 'failed';
    },
    dtSetLoadingStatus: (state, action: PayloadAction<DtStatus>) => {
      state.status = action.payload;
    },
    dtClearUser: (state) => {
      state.data = null;
      state.companyData = null;
      state.currentCompanyId = null;
      state.status = 'idle';
    },
  },
});

export const dtSelectIsAuthenticated = (state: DtRootState): boolean => Boolean(state?.dtUser?.data);
export const dtSelectUser = (state: DtRootState): DtUser | null => state.dtUser.data;
export const dtSelectUserCompanies = (state: DtRootState): DtCompanyData[] | null => state.dtUser.companyData;
export const dtSelectSortedAvailableCompaniesOptions = createSelector(
  dtSelectUserCompanies,
  (availableUserCompanies): DtOptionItem<number>[] => {
    return sortBy(
      availableUserCompanies?.map((company) => ({
        value: company.id,
        label: company.name,
      })),
      'label'
    );
  }
);
export const dtSelectUserStatus = (state: DtRootState): DtStatus => state.dtUser.status;
export const dtSelectUserLoading = (state: DtRootState): boolean => state.dtUser.status === 'loading';
export const dtSelectUserRole = (state: DtRootState): UserRole => state.dtUser.data?.userRole as UserRole;
export const dtSelectCurrentCompanyId = (state: DtRootState): number | null => state.dtUser.currentCompanyId;

export const { dtSetUserData, dtClearUser, dtSetLoadingStatus, dtSetUserCompanyData, dtSetCurrentCompany } =
  dtUserSlice.actions;

export default dtUserSlice.reducer;
