import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { lowerCase, upperFirst } from 'lodash';
import React from 'react';

import { DtUploadReportValues } from './dt-dispatch-upload-report-form.interface';
import DtFormikDatePicker from '../../../../../../components/dt-date-pickers/dt-formik-date-picker/dt-formik-date-picker';
import DtDispatchDetailsSummaryRow from '../../../../../../components/dt-details-summary-row/dt-details-summary-row';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import DtFormikTimePicker from '../../../../../../components/dt-time-picker/dt-formik-time-picker';
import DtFormikUploadFile from '../../../../../../components/dt-upload-button/dt-formik-upload-file';
import { UserRole } from '../../../../../../repositories/__generated__/v2';

interface DtDispatchUploadReportFormProps {
  userRole: string;
  assignees: DtDispatchItemDetailsAssign[];
}

const DtDispatchUploadReportForm: React.FC<DtDispatchUploadReportFormProps> = ({ userRole, assignees }) => {
  const { values } = useFormikContext<DtUploadReportValues>();

  return (
    <>
      <DtDispatchDetailsSummaryRow label='Upload'>
        <DtFormikUploadFile name='reportToUpload' fullWidth />
      </DtDispatchDetailsSummaryRow>

      {userRole !== upperFirst(lowerCase(UserRole.User)) && (
        <DtDispatchDetailsSummaryRow label='Completed by'>
          <DtFormikSelect
            name='reportAuthor'
            fullWidth
            placeholder='Select assignee'
            options={assignees.map((i) => ({
              value: i.assigneeEmail,
              label: i.assigneeName,
            }))}
          />
        </DtDispatchDetailsSummaryRow>
      )}

      <DtDispatchDetailsSummaryRow label='Completed at'>
        <DtFormikDatePicker
          name='reportUploadDate'
          fullWidth
          placeholder='Choose date'
          disabledDays={{
            after: new Date(),
          }}
        />
      </DtDispatchDetailsSummaryRow>

      <DtDispatchDetailsSummaryRow label='Completed time'>
        <DtFormikTimePicker
          name='reportUploadTime'
          fullWidth
          placeholder='Choose time'
          disableFuture={
            values.reportUploadDate &&
            format(values.reportUploadDate as Date, 'MM/dd/yy') === format(new Date(), 'MM/dd/yy')
          }
        />
      </DtDispatchDetailsSummaryRow>
    </>
  );
};

export default DtDispatchUploadReportForm;
