import { CSSObject, styled, Theme } from '@mui/material/styles';
import { UserRole } from '../../../../../repositories/__generated__/v2';

const coloredStatusWrapper = (theme: Theme, userRole: UserRole) => {
  switch (userRole) {
    case UserRole.User:
      return theme.palette.main.transparentSecondaryText;
    case UserRole.Manager:
      return theme.palette.main.transparentButtonPrimary;
    case UserRole.SuperAdmin:
      return theme.palette.main.transparentRed;
  }
};

const coloredStatusLabel = (theme: Theme, userRole: UserRole) => {
  switch (userRole) {
    case UserRole.User:
      return theme.palette.main.secondaryText;
    case UserRole.Manager:
      return theme.palette.main.primary;
    case UserRole.SuperAdmin:
      return theme.palette.main.red;
  }
};

export const StatusContainer = styled('div')`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(16)}`};
  border-radius: 3px;
  width: fit-content;
`;

export const ColoredStatusWrapper = styled(StatusContainer, {
  shouldForwardProp: (prop) => prop !== 'userRole',
})<{ userRole: UserRole }>`
  background-color: ${({ theme, userRole }) => coloredStatusWrapper(theme, userRole)};
`;

export const ColoredStatusLabel = styled('span', {
  shouldForwardProp: (prop) => prop !== 'userRole',
})<{ userRole: UserRole }>`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject}
  color: ${({ theme, userRole }) => coloredStatusLabel(theme, userRole)};
  text-transform: capitalize;
  margin-left: ${({ theme }) => `${theme.spacing(10)}`};
  vertical-align: middle;
`;

export const IconsDivider = styled('span')`
  color: ${({ theme }) => theme.palette.main.secondaryIcon};
  font-weight: bold;
  vertical-align: middle;
  font-size: 18px;
`;
