import { styled } from '@mui/material/styles';

export const DtAdministrationDetailsContent = styled('div')`
  background-color: ${({ theme }) => theme.palette.main.white};
  ${({ theme }) => theme.mixins.boxShadowMixin};
  padding: ${({ theme }) => `${theme.spacing(24)} ${theme.spacing(40)} ${theme.spacing(32)}`};
  width: 100%;
  border-radius: 3px 3px 0 0;
`;

export const DtAssignmentBlocksWrapper = styled(DtAdministrationDetailsContent)`
  flex: 1;
`;

export const DtAdministrationDetailsContentDivider = styled('div')`
  background-image: linear-gradient(to bottom, rgb(15 65 107 / 3%), rgb(15 65 107/ 0%));
  background-color: ${({ theme }) => theme.palette.main.white};
  height: 12px;
  z-index: 1;
`;

export const DtAdministrationDetailsHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;
