import { styled } from '@mui/material/styles';

export const AssetsTableWrapper = styled('div')`
  margin-bottom: 20px;
`;

export const AssetsTableHeaderWrapper = styled('div')`
  display: grid;
  flex-direction: row;
  grid-template-columns: 3fr 1fr 3fr 40px;
  gap: 10px;
  margin-right: 15px;
`;

export const AssetsTableRow = styled('div')`
  margin-bottom: 4px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 3fr 1fr 3fr 40px;
  gap: 10px;
`;

export const AssetsTableAddButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;
