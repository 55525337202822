import React from 'react';

import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import { dtDaySelectOptions, dtTimeSelectOptions } from '../../dt-frequency-editor-form.utils';

const DtNewDispatchFrequencyDaily: React.FC = ({}) => {
  const inputsCommonWidth = '220px';

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <DtFormikSelect<number>
          fullWidth
          name='frequency.day'
          label='Every day(s)'
          labelPosition='top'
          options={dtDaySelectOptions}
          minWidth={inputsCommonWidth}
        />
      </div>
      <div>
        <DtFormikMultipleSelect
          fullWidth
          name='frequency.time'
          label='At time'
          labelPosition='top'
          options={dtTimeSelectOptions}
          placeholder='Select time'
          minWidth={inputsCommonWidth}
        />
      </div>
    </>
  );
};

export default DtNewDispatchFrequencyDaily;
