/**
 * Check if string character is number.
 * @param character
 */
export const dtStringCharacterIsNumeric = (character: string): boolean => {
  return /^\d+$/.test(character);
};

/**
 * Makes words in string capitalized.
 * @param string
 */
export const dtCapitalizeString = (string: string): string => {
  return string && string.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
