import { useState, useEffect, DependencyList } from 'react';

interface DtUseFetchDataParams<T> {
  isLoading: boolean;
  data: T | null;
  loadData: () => Promise<void>;
}

/**
 * Hook for fetching data from server
 * @param deps - dependency array used for lifecycle method.
 * @param fetchCallback - callback to fetch data from API.
 */
export const dtUseFetchData = <T,>(
  deps: DependencyList,
  fetchCallback: () => Promise<T | null>
): DtUseFetchDataParams<T> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, deps);

  async function loadData(): Promise<void> {
    setIsLoading(true);
    const resp = await fetchCallback();
    setData(resp);
    setIsLoading(false);
  }

  return {
    data,
    isLoading,
    loadData,
  };
};
