import { styled } from '@mui/material/styles';
import { Dialog, List, ListItemButton } from '@mui/material';
import DtButton from '../../../dt-button/dt-button';

export const DialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    width: 640px;
    min-height: 690px;
    border-radius: 4px;
    ${({ theme }) => theme.mixins.boxShadowMixin};
  }
`;

export const AssignmentsList = styled(List)`
  width: 100%;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.main.transparentButtonPrimary};
  }

  span.MuiTouchRipple-root {
    display: none;
  }
`;

export const OrganizationWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const SelectAllListItem = styled(ListItemButton)`
  &:hover {
    background-color: transparent;
  }
`;

export const AssignmentsListHeader = styled('div')`
  padding: 10px 0;
  display: flex;
`;

export const AssignModalCancelButton = styled(DtButton)`
  margin-right: ${({ theme }) => theme.spacing(24)};
`;
