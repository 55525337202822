import { Grid } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

export const DropDownSelectValueContainer = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'threeColumns',
})<{ threeColumns: boolean | undefined }>`
  display: grid;
  grid-template-columns: ${({ threeColumns }) => (threeColumns ? '1fr 1fr 1fr' : '1fr 1fr')};
  column-gap: ${({ theme }) => theme.spacing(40)};
  row-gap: ${({ theme }) => theme.spacing(12)};
  padding-left: 0;
  margin: 0;
  max-height: 170px;
`;

export const OptionLabel = styled('span')`
  ${({ theme }) => theme.typography.body as CSSObject};
`;

export const FieldContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'alignSelf',
})<{ alignSelf?: string }>`
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'end')};
`;
