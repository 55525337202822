import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useState } from 'react';

import { DtCellContentWithAnswer } from './dt-cell-content-with-answer';
import {
  DtDateItem,
  DtHeaderRowTableCell,
  DtRowTableCell,
  DtStickyHeaderTableCell,
  DtStickyTableCell,
  DtTableCellText,
  DtTimeItem,
} from './dt-questions-table.styled';
import { dtDispatchService } from '../../modules/dt-dashboard/dt-dispatch.service';
import { DispatchInspectionCombinedDataDto, QuestionDto } from '../../repositories/__generated__/v2';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';
import DtScrollbar from '../dt-scrollbar/dt-scrollbar';
import DtSkeleton from '../dt-skeleton/dt-skeleton';

interface Props {
  combinedData: DispatchInspectionCombinedDataDto;
}

const ReportDownloadIcon: React.FC<{ formDispatchGroup: string }> = ({ formDispatchGroup }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function handleDownloadReport(): Promise<void> {
    setIsLoading(true);
    const dtDispatchReport = await dtDispatchService.getDtDispatchReport(formDispatchGroup, true);
    if (dtDispatchReport) {
      await dtDispatchService.downloadDtDispatchReport(dtDispatchReport);
    }
    setIsLoading(false);
  }

  return isLoading ? (
    <DtSkeleton variant='rectangular' width={32} height={32} style={{ margin: '0 auto' }} />
  ) : (
    <DtButton
      variant='transparent'
      disabled={!formDispatchGroup}
      onClick={handleDownloadReport}
      padding={4}
      type='button'
    >
      <DtIcon size={24} icon='download' />
    </DtButton>
  );
};

const DtQuestionsTable: React.FC<Props> = ({ combinedData }) => {
  const [isScrollAtStart, setScrollAtStart] = useState(true);

  let isHeaderFirstRowHighlighted = false;
  let isHeaderSecondRowHighlighted = false;
  const pinnedQuestions = combinedData.questions.filter((i) => i.isPinned);
  const defaultQuestions = combinedData.questions.filter((i) => !i.isPinned);
  const pinnedQuestionsNumber = pinnedQuestions.length + 1;

  function checkIsGroupColorChanged(itemsArray: QuestionDto[], item: QuestionDto, itemIndex: number): boolean {
    const isSameGroup = itemIndex > 0 && item.pageId === itemsArray[itemIndex - 1].pageId;
    return !isSameGroup;
  }

  return (
    <TableContainer
      style={{
        maxHeight: '100%',
        height: 'calc(100vh - 255px)',
      }}
    >
      <DtScrollbar
        horizontal
        height='inherit'
        ignoredSelector='.dt-sticky-table-cell'
        onScrollChange={(value) => setScrollAtStart(value)}
        leftHorizontalMargin={`calc(${pinnedQuestionsNumber} * 130px)`}
      >
        <Table aria-label='simple table' id='my-table-id'>
          <TableHead style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 0, zIndex: 2 }}>
            <TableRow>
              <DtStickyHeaderTableCell
                align='center'
                isScrollAtStart={isScrollAtStart}
                className='dt-sticky-table-cell'
              />
              {pinnedQuestions.map((question, index) => (
                <DtStickyHeaderTableCell
                  key={index}
                  align='center'
                  style={{ left: `calc(130px * ${index + 1})` }}
                  isScrollAtStart={isScrollAtStart}
                />
              ))}
              {defaultQuestions.map((question, index) => {
                const prev = _.get(defaultQuestions, index - 1) || {};
                let nextNewItemIndex = _.findIndex(
                  defaultQuestions,
                  (nextQuestion) => {
                    return !_.isEqual(
                      `${question.page} / ${question.section}`,
                      `${nextQuestion.page} / ${nextQuestion.section}`
                    );
                  },
                  index
                );
                nextNewItemIndex = nextNewItemIndex === -1 ? defaultQuestions.length : nextNewItemIndex;

                const isNewPage = !_.isEqual(`${question.page}`, `${prev.page}`);
                const isNewItem = !_.isEqual(
                  `${question.page} / ${question.section}`,
                  `${prev.page} / ${prev.section}`
                );

                if (!isNewItem) {
                  return;
                }

                const isGroupColorChanged = checkIsGroupColorChanged(defaultQuestions, question, index);
                if (isGroupColorChanged) {
                  isHeaderFirstRowHighlighted = !isHeaderFirstRowHighlighted;
                }

                const colspan = Math.max(nextNewItemIndex - index, 1);

                return (
                  <DtHeaderRowTableCell
                    key={question.id}
                    style={{
                      verticalAlign: 'bottom',
                      width: `calc(${colspan} * 230px)`,
                      minWidth: `calc(${colspan} * 230px)`,
                      maxWidth: `calc(${colspan} * 230px)`,
                      backgroundColor: isHeaderFirstRowHighlighted ? '#f6f9fb' : '#fffff',
                    }}
                    colSpan={colspan}
                  >
                    {isNewPage && (
                      <Typography variant='bodySemibold' color='black'>
                        {question.page}
                        {' / '}
                      </Typography>
                    )}
                    <Typography variant='body' color='main.secondaryText'>
                      {question.section}
                    </Typography>
                  </DtHeaderRowTableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DtStickyHeaderTableCell
                align='center'
                style={{ verticalAlign: 'baseline' }}
                isScrollAtStart={isScrollAtStart}
                className='dt-sticky-table-cell'
              >
                <DtTableCellText>Date and Time</DtTableCellText>
              </DtStickyHeaderTableCell>
              {pinnedQuestions.map((question, index) => (
                <DtStickyHeaderTableCell
                  key={index}
                  align='center'
                  style={{ verticalAlign: 'baseline', left: `calc(130px * ${index + 1})` }}
                  isScrollAtStart={isScrollAtStart}
                  className='dt-sticky-table-cell'
                >
                  <DtTableCellText>
                    {question.question === 'Engineer' ? 'Submitted By' : question.question}
                  </DtTableCellText>
                </DtStickyHeaderTableCell>
              ))}
              {defaultQuestions.map((questionItem, index) => {
                const isGroupColorChanged = checkIsGroupColorChanged(defaultQuestions, questionItem, index);
                if (isGroupColorChanged) {
                  isHeaderSecondRowHighlighted = !isHeaderSecondRowHighlighted;
                }

                return (
                  <DtHeaderRowTableCell
                    key={questionItem.id}
                    style={{
                      verticalAlign: 'baseline',
                    }}
                    align='center'
                    isHighlighted={isHeaderSecondRowHighlighted}
                  >
                    <DtTableCellText>{questionItem.question}</DtTableCellText>
                  </DtHeaderRowTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody style={{ display: 'flex', flexDirection: 'column' }}>
            {combinedData.answers.map((row, answerIndex) => {
              let isCellHighlighted = false;
              let isDefaultQuestions = true;
              const date = format(new Date(row.timestamp), 'MM/dd/yy');
              const time = format(new Date(row.timestamp), 'p');
              const rowEntries = _.entries(row.answers);

              /// Change color every time when report changes
              const isSameReportRepeats =
                answerIndex > 0 && row.reportOrderIndex === combinedData.answers[answerIndex - 1].reportOrderIndex;
              const isReportChanged = !isSameReportRepeats;

              const pinnedQuestions = combinedData.questions.filter((i) => i.isPinned);

              return (
                <TableRow key={answerIndex}>
                  <DtStickyTableCell
                    component='th'
                    scope='row'
                    align='center'
                    isHighlighted={isCellHighlighted}
                    isNew={isReportChanged}
                    isScrollAtStart={isScrollAtStart}
                    className='dt-sticky-table-cell'
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '0 8px',
                      }}
                    >
                      {isSameReportRepeats ? null : (
                        <ReportDownloadIcon formDispatchGroup={row.formDispatchGroupToDownloadReport} />
                      )}
                      <div style={{ flex: '1' }}>
                        {isSameReportRepeats ? null : (
                          <>
                            <DtDateItem>{date}</DtDateItem>
                            <DtTimeItem>{time}</DtTimeItem>
                          </>
                        )}
                      </div>
                    </div>
                  </DtStickyTableCell>
                  {pinnedQuestions.map((question, index) => (
                    <DtStickyTableCell
                      key={index}
                      component='th'
                      scope='row'
                      align='center'
                      isHighlighted={isCellHighlighted}
                      isNew={isReportChanged}
                      isScrollAtStart={isScrollAtStart}
                      className='dt-sticky-table-cell'
                      style={{ left: `calc(130px * ${index + 1})` }}
                    >
                      <Typography variant='body' color='black'>
                        <>{row.answers[question.id].answerContent}</>
                      </Typography>
                    </DtStickyTableCell>
                  ))}
                  {rowEntries.map(function ([key, answer], entryIndex) {
                    // Skip pinned questions
                    if (pinnedQuestions.find((i) => i.id === key)) {
                      return <></>;
                    }

                    if (isDefaultQuestions) {
                      isCellHighlighted = !isCellHighlighted;
                      isDefaultQuestions = false;
                    } else {
                      const isSameGroup = entryIndex > 0 && answer.pageId === rowEntries[entryIndex - 1][1].pageId;
                      const isGroupColorChanged = !isSameGroup;
                      if (isGroupColorChanged) {
                        isCellHighlighted = !isCellHighlighted;
                      }
                    }

                    return (
                      <DtRowTableCell
                        key={key}
                        isHighlighted={isCellHighlighted}
                        isNew={isReportChanged}
                        align='center'
                      >
                        <DtCellContentWithAnswer answer={answer} />
                      </DtRowTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DtScrollbar>
    </TableContainer>
  );
};

export default DtQuestionsTable;
