import { differenceInMinutes } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { DT_GLOBAL_TIME_INTERVAL } from '../../dt-constants';
import { DtOneMinuteObservedIntervalService } from '../../services/dt-one-minute-observed-interval.service';

/**
 * Returns current date, and updates its value every minute
 */
export function dtUseGlobalNowWithMinuteInterval(func?: (time?: Date) => void): Date {
  const [lastUpdateTime, setLastUpdateTime] = useState(
    DtOneMinuteObservedIntervalService.observableInstance.readLatestValue()
  );
  const [now, setNow] = useState(DtOneMinuteObservedIntervalService.observableInstance.readLatestValue());

  const update = useCallback(
    (value: Date) => {
      if (func && differenceInMinutes(value, lastUpdateTime) >= DT_GLOBAL_TIME_INTERVAL) {
        func(value);
        setLastUpdateTime(value);
      }
      setNow(value);
    },
    [lastUpdateTime, func]
  );

  useEffect(() => {
    DtOneMinuteObservedIntervalService.observableInstance.unregister(update);
    DtOneMinuteObservedIntervalService.observableInstance.register(update);

    return () => {
      DtOneMinuteObservedIntervalService.observableInstance.unregister(update);
    };
  }, [lastUpdateTime, func]);

  return now;
}
