import { createSlice, createEntityAdapter, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { DtPropertiesState } from './dt-properties.interfaces';
import { DtRootState } from '../../dt-store';

const dtPropertiesAdapter = createEntityAdapter<DtPropertyItem>();

const SELECTED_PROPERTY_KEY = 'DT_SELECTED_PROPERTY_ID';
const storedId = Number(sessionStorage.getItem(SELECTED_PROPERTY_KEY));

const initialState = dtPropertiesAdapter.getInitialState<DtPropertiesState>({
  status: 'idle',
  selectedPropertyId: isFinite(storedId) ? storedId : undefined,
});

export const dtPropertiesSlice = createSlice({
  name: 'dtProperties',
  initialState,
  reducers: {
    dtSetSelectedPropertyId: (state, action: PayloadAction<number>) => {
      state.selectedPropertyId = action.payload;
      sessionStorage.setItem(SELECTED_PROPERTY_KEY, String(action.payload));
    },
    dtSetProperties: (state, action: PayloadAction<DtPropertyItem[]>) => {
      dtPropertiesAdapter.setAll(state, action.payload);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!state.ids.includes(state.selectedPropertyId as any)) {
        state.selectedPropertyId = Number(state.ids[0]);
        sessionStorage.setItem(SELECTED_PROPERTY_KEY, String(state.selectedPropertyId));
      }
    },
    dtClearProperties: () => initialState,
  },
});

export const dtSelectPropertyStatus = (state: DtRootState): DtStatus => state.dtProperties.status;
export const {
  selectAll: dtSelectProperties,
  selectIds: dtSelectPropertiesIds,
  selectById: dtSelectPropertyById,
} = dtPropertiesAdapter.getSelectors<DtRootState>((state) => state.dtProperties);
export const dtSelectPropertiesLoading = (state: DtRootState): boolean => state.dtProperties.status === 'loading';

export const dtSelectSelectedPropertyId = (state: DtRootState): null | number =>
  state.dtProperties.selectedPropertyId ?? null;

export const dtSelectSelectedProperty = (state: DtRootState): DtPropertyItem | undefined =>
  dtPropertiesAdapter.getSelectors().selectById(state.dtProperties, state.dtProperties.selectedPropertyId ?? -1);

export const dtSelectFormTypesForFilter = createSelector(
  [dtSelectProperties, (state: DtRootState, siteId?: number | null) => siteId],
  (allProperties, siteId) => {
    return (siteId ? allProperties.filter((item) => item.id === siteId) : allProperties).flatMap(
      (property) => property.formTypes
    );
  }
);

export const dtSelectAvailableFormTypesBySelectedProperty = createSelector(
  [dtSelectProperties, (state: DtRootState, siteId?: number) => siteId],
  (allProperties, siteId) => {
    return allProperties.flatMap((site) => {
      return siteId && site.id !== siteId ? [] : site.formTypes;
    });
  }
);

export const dtSelectAvailableFormSubtypesBySelectedType = createSelector(
  [
    (state: DtRootState, type?: string, siteId?: number) => dtSelectAvailableFormTypesBySelectedProperty(state, siteId),
    (state: DtRootState, type?: string) => type,
  ],
  (availableFormTypesBySelectedProperty, type) => {
    return availableFormTypesBySelectedProperty.flatMap((formType) => {
      return type && formType.id !== type ? [] : formType.formItems;
    });
  }
);

export const dtSelectAvailableAssetsByFormSubtype = createSelector(
  [
    (state: DtRootState, subtype?: number, site?: number, type?: string) =>
      dtSelectAvailableFormSubtypesBySelectedType(state, type, site),
    (state: DtRootState, subtype?: number) => subtype,
  ],
  (availableFormSubtypesBySelectedType, subtype) => {
    return availableFormSubtypesBySelectedType.flatMap((formSubtype) => {
      return subtype && formSubtype.id !== subtype ? [] : formSubtype.assetList ?? [];
    });
  }
);

export const dtSelectGetAvailableFormUsers =
  (propertyId?: number, formTypeId?: string) =>
  (state: DtRootState): DtFormUser[] => {
    if (!propertyId || !formTypeId) {
      return [];
    }

    return (
      dtSelectPropertyById(state, propertyId)?.formTypes?.find((formType) => {
        return formTypeId && formType.id === formTypeId;
      })?.formUsers ?? []
    );
  };

export const { dtClearProperties, dtSetSelectedPropertyId, dtSetProperties } = dtPropertiesSlice.actions;

export default dtPropertiesSlice.reducer;
