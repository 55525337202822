/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyListDto } from '../entities';
// @ts-ignore
import { CompanyMutationDTO } from '../entities';
/**
 * AdminCompanyApi - axios parameter creator
 * @export
 */
export const AdminCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerCreate: async (
      companyMutationDTO: CompanyMutationDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyMutationDTO' is not null or undefined
      assertParamExists('adminCompanyControllerCreate', 'companyMutationDTO', companyMutationDTO);
      const localVarPath = `/admin/company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companyMutationDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminCompanyControllerDelete', 'id', id);
      const localVarPath = `/admin/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminCompanyControllerGet', 'id', id);
      const localVarPath = `/admin/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerGetAllCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerUpdate: async (
      id: number,
      companyMutationDTO: CompanyMutationDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminCompanyControllerUpdate', 'id', id);
      // verify required parameter 'companyMutationDTO' is not null or undefined
      assertParamExists('adminCompanyControllerUpdate', 'companyMutationDTO', companyMutationDTO);
      const localVarPath = `/admin/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companyMutationDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminCompanyApi - functional programming interface
 * @export
 */
export const AdminCompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminCompanyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCompanyControllerCreate(
      companyMutationDTO: CompanyMutationDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyControllerCreate(
        companyMutationDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCompanyControllerDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyControllerDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCompanyControllerGet(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMutationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyControllerGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCompanyControllerGetAllCompanies(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyListDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyControllerGetAllCompanies(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCompanyControllerUpdate(
      id: number,
      companyMutationDTO: CompanyMutationDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMutationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyControllerUpdate(
        id,
        companyMutationDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminCompanyApi - factory interface
 * @export
 */
export const AdminCompanyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminCompanyApiFp(configuration);
  return {
    /**
     *
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerCreate(companyMutationDTO: CompanyMutationDTO, options?: any): AxiosPromise<number> {
      return localVarFp
        .adminCompanyControllerCreate(companyMutationDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.adminCompanyControllerDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerGet(id: number, options?: any): AxiosPromise<CompanyMutationDTO> {
      return localVarFp.adminCompanyControllerGet(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerGetAllCompanies(options?: any): AxiosPromise<Array<CompanyListDto>> {
      return localVarFp.adminCompanyControllerGetAllCompanies(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CompanyMutationDTO} companyMutationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCompanyControllerUpdate(
      id: number,
      companyMutationDTO: CompanyMutationDTO,
      options?: any
    ): AxiosPromise<CompanyMutationDTO> {
      return localVarFp
        .adminCompanyControllerUpdate(id, companyMutationDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminCompanyApi - interface
 * @export
 * @interface AdminCompanyApi
 */
export interface AdminCompanyApiInterface {
  /**
   *
   * @param {CompanyMutationDTO} companyMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApiInterface
   */
  adminCompanyControllerCreate(
    companyMutationDTO: CompanyMutationDTO,
    options?: AxiosRequestConfig
  ): AxiosPromise<number>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApiInterface
   */
  adminCompanyControllerDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApiInterface
   */
  adminCompanyControllerGet(id: number, options?: AxiosRequestConfig): AxiosPromise<CompanyMutationDTO>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApiInterface
   */
  adminCompanyControllerGetAllCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<CompanyListDto>>;

  /**
   *
   * @param {number} id
   * @param {CompanyMutationDTO} companyMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApiInterface
   */
  adminCompanyControllerUpdate(
    id: number,
    companyMutationDTO: CompanyMutationDTO,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompanyMutationDTO>;
}

/**
 * AdminCompanyApi - object-oriented interface
 * @export
 * @class AdminCompanyApi
 * @extends {BaseAPI}
 */
export class AdminCompanyApi extends BaseAPI implements AdminCompanyApiInterface {
  /**
   *
   * @param {CompanyMutationDTO} companyMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApi
   */
  public adminCompanyControllerCreate(companyMutationDTO: CompanyMutationDTO, options?: AxiosRequestConfig) {
    return AdminCompanyApiFp(this.configuration)
      .adminCompanyControllerCreate(companyMutationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApi
   */
  public adminCompanyControllerDelete(id: number, options?: AxiosRequestConfig) {
    return AdminCompanyApiFp(this.configuration)
      .adminCompanyControllerDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApi
   */
  public adminCompanyControllerGet(id: number, options?: AxiosRequestConfig) {
    return AdminCompanyApiFp(this.configuration)
      .adminCompanyControllerGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApi
   */
  public adminCompanyControllerGetAllCompanies(options?: AxiosRequestConfig) {
    return AdminCompanyApiFp(this.configuration)
      .adminCompanyControllerGetAllCompanies(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CompanyMutationDTO} companyMutationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCompanyApi
   */
  public adminCompanyControllerUpdate(
    id: number,
    companyMutationDTO: CompanyMutationDTO,
    options?: AxiosRequestConfig
  ) {
    return AdminCompanyApiFp(this.configuration)
      .adminCompanyControllerUpdate(id, companyMutationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
