import { Typography } from '@mui/material';
import React from 'react';

import { CreateButton } from './dt-create-button.styled';
import { DtButtonProps } from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';

export interface DtCreateButtonProps extends DtButtonProps {
  iconSize?: number;
  iconColor?: keyof MainPalette;
}

const DtCreateButton: React.FC<DtCreateButtonProps> = (props) => {
  return (
    <CreateButton
      variant={props.variant}
      onClick={props.onClick}
      startIcon={
        props.iconSize ? (
          <DtIcon icon='plus' color={props.disabled ? 'secondaryText' : props.iconColor} size={props.iconSize} />
        ) : (
          <></>
        )
      }
      className={props.className}
      disabled={props.disabled}
    >
      <Typography variant='bodySemibold'>{props.children}</Typography>
    </CreateButton>
  );
};

export default DtCreateButton;
