import { useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';

import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtAssignmentModal from '../../../../../components/dt-assignment-blocks/components/dt-assignment-modal/dt-assignment-modal';
import { DtAssignmentModalProps } from '../../../../../components/dt-assignment-blocks/components/dt-assignment-modal/dt-assignment-modal.interface';
import DtAssignmentBlocks from '../../../../../components/dt-assignment-blocks/dt-assignment-blocks';
import { AssignmentItem, AssignmentItemType } from '../../../../../repositories/__generated__/v2';
import { dtReplaceChildren, dtSelectAssignments } from '../../../dt-assignments.slice';
import { dtLoadAllAvailableFormTypes, dtSelectAllAvailableFormTypes } from '../../../dt-forms-tab/dt-forms.slice';
import { DtManageUsersRowItem } from '../../../dt-manage-users-tab/dt-manage-users.interface';
import { dtLoadUsers, dtSelectUsers } from '../../../dt-manage-users-tab/dt-manage-users.slice';
import { DtAssignmentBlocksWrapper } from '../../dt-administration-details-layout/dt-administration-details-layout.styled';

const siteAssignmentsPopupConfig: DtAssignmentModalProps = {
  title: '',
  subtitle: '',
  onSubmit: noop,
  onClose: noop,
  options: [],
};

interface DtSiteAssignmentsProps {
  organizationId?: number | null;
  siteId?: number;
}

const DtSiteAssignments: React.FC<DtSiteAssignmentsProps> = ({ organizationId, siteId }) => {
  const dispatch = dtUseAppDispatch();
  const data = dtUseAppSelector(dtSelectAssignments);
  const { isSubmitting, setFieldValue } = useFormikContext();

  function triggerAssignmentsChange(): void {
    setFieldValue('assignments', true);
  }

  const allAvailableFormType = dtUseAppSelector(dtSelectAllAvailableFormTypes);
  const allAvailableUsers = dtUseAppSelector(dtSelectUsers);

  const [showModal, setShowModal] = useState(false);
  const [popupConfig, setPopupConfig] = useState(siteAssignmentsPopupConfig);

  useEffect(() => {
    if (organizationId) {
      const loadAllAvailableFormTypesDispatch = dispatch(dtLoadAllAvailableFormTypes(organizationId));
      const loadUsersDispatch = dispatch(dtLoadUsers({ companyId: organizationId }));

      return () => {
        loadAllAvailableFormTypesDispatch.abort();
        loadUsersDispatch.abort();
      };
    }
  }, []);

  function handleChildAssign(parentId: string): void {
    const subtitleItems = allAvailableFormType.find(
      (formType) => formType.formType === parentId && formType.siteId === siteId
    )?.formSchemas;

    const usersOptions = (allAvailableUsers as DtManageUsersRowItem[]).map((item) => {
      return {
        id: item.email,
        displayValue: item.fullName ?? '',
        subtitleItems,
      };
    });

    setShowModal(true);
    setPopupConfig({
      title: 'Users',
      subtitle: 'Users can be granted access to sites under the selected organization',
      onSubmit: (items) => {
        const childrenItems = items.length
          ? items.map((item) => ({
              id: String(item.id),
              displayValue: item.displayValue,
              type: AssignmentItemType.User,
              subtitleItems: item.subtitleItems,
            }))
          : [];
        updateChildrenInParent(parentId, childrenItems);
      },
      onClose: () => setShowModal(false),
      options: usersOptions,
      selectedIds: data.find((i) => i.id === parentId)?.children.map((i) => i.id) ?? [],
    });
  }

  function updateChildrenInParent(parentId: string, items: AssignmentItem[]): void {
    dispatch(
      dtReplaceChildren({
        parentId,
        children: items,
      })
    );
    triggerAssignmentsChange();
  }

  return (
    <DtAssignmentBlocksWrapper>
      <DtAssignmentBlocks
        parentTitle='Types'
        childTitle='Users'
        data={data}
        onChildAssign={handleChildAssign}
        hideEditButton
        hideParentAssignButton
        tooltipTitle='Types'
        disabled={isSubmitting}
      />
      {showModal && (
        <DtAssignmentModal
          title={popupConfig.title}
          subtitle={popupConfig.subtitle}
          onSubmit={popupConfig.onSubmit}
          onClose={popupConfig.onClose}
          options={popupConfig.options}
          selectedIds={popupConfig.selectedIds}
        />
      )}
    </DtAssignmentBlocksWrapper>
  );
};

export default DtSiteAssignments;
