import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from 'react';

const normalFontWeightMixin: CSSProperties = {
  fontWeight: 500,
};

const semiboldMixin: CSSProperties = {
  fontWeight: 600,
};

const typographyCommonMixin: CSSProperties = {
  fontStretch: 'normal',
  fontStyle: 'normal',
};

export const DT_TYPOGRAPHY_OPTIONS: TypographyOptions = {
  fontFamily: '"Inter-Regular", sans-serif',
  h2: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '1.17',
    letterSpacing: 'normal',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
  h3: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.22',
    letterSpacing: '-0.1px',
    ...typographyCommonMixin,
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '1.25',
    letterSpacing: '-0.1px',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
  body: {
    fontSize: '14px',
    lineHeight: '1.43',
    ...normalFontWeightMixin,
    ...typographyCommonMixin,
  },
  bodyBold: {
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    ...typographyCommonMixin,
  },
  bodySemibold: {
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '-0.1px',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
  bodySmall: {
    fontSize: '13px',
    lineHeight: '1.38',
    letterSpacing: '-0.1px',
    ...normalFontWeightMixin,
    ...typographyCommonMixin,
  },
  bodySmallSemibold: {
    fontSize: '13px',
    lineHeight: '1.38',
    letterSpacing: '-0.1px',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    ...normalFontWeightMixin,
    ...typographyCommonMixin,
  },
  captionSemibold: {
    fontSize: '12px',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
  captionSmall: {
    fontSize: '11px',
    lineHeight: '1.52',
    letterSpacing: '0.1px',
    ...normalFontWeightMixin,
    ...typographyCommonMixin,
  },
  captionSmallSemibold: {
    fontSize: '11px',
    lineHeight: '1.52',
    letterSpacing: '0.1px',
    ...semiboldMixin,
    ...typographyCommonMixin,
  },
};
