import { styled } from '@mui/system';

export const LayoutContainer = styled('section')`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.main.background};
  justify-content: flex-start;
`;
