import { styled } from '@mui/material/styles';
import DtButton from '../dt-button/dt-button';

export const ActionButtonsWrapper = styled('section', {
  shouldForwardProp: (prop) => prop !== 'gap',
})<{
  gap?: number;
}>`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: ${({ theme, gap }) => theme.spacing(gap ? gap : 24)};

  & .MuiButton-root {
    min-width: fit-content;

    &:hover {
      background-color: ${({ theme }) => theme.palette.main.control};
    }
  }
`;

export const ActionSkeletonWrapper = styled(ActionButtonsWrapper, {
  shouldForwardProp: (prop) => prop !== 'gap',
})<{
  gap?: number;
}>`
  gap: ${({ theme, gap }) => theme.spacing(gap ? gap : 50)};
  margin-right: 12px;
`;

export const IconPlaceholder = styled('div')`
  width: 40px;
  height: 40px;
`;

export const CenteredButton = styled(DtButton)`
  margin: 0 auto;
`;
