import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { DtSelectOption } from './dt-administration-details-forms.interface';
import { dtMapReportTemplatesNamesToOptions } from './dt-administration-details-forms.utils';
import { dtUseAppSelector } from '../../../../../../cdk/hooks/dt-store.hooks';
import DtFormikCreatableSelect from '../../../../../../components/dt-selects/dt-formik-creatable-select';
import DtFormikSelect from '../../../../../../components/dt-selects/dt-formik-select';
import DtFormTextInput from '../../../../../../components/text-input/dt-form-text-input/dt-form-text-input';
import { dtStore } from '../../../../../../dt-store';
import { dtApiRepository } from '../../../../../../repositories/dt-api.repository';
import { InputLabel } from '../../../../../dt-auth/components/dt-form-common-styles.styled';
import { FieldContainer } from '../../../../../dt-dashboard/modals/dt-new-dispatch-modal/steps/dt-new-dispatch-general-details/dt-new-dispatch-general-information.styled';
import { dtSelectCurrentCompanyId } from '../../../../../dt-user/dt-user.slice';
import { dtLoadSites, dtSelectSites } from '../../../../dt-sites-tab/dt-sites.slice';

interface DtAdministrationDetailsTypeFormProps {
  isCreationFlow: boolean;
}

const DtAdministrationDetailsForms: React.FC<DtAdministrationDetailsTypeFormProps> = ({ isCreationFlow }) => {
  const { isSubmitting } = useFormikContext();

  const sites = dtUseAppSelector(dtSelectSites);
  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const [uniqueFormTypes, setUniqueFormTypes] = useState<string[] | null>(null);
  const [reportTemplatesOptions, setReportTemplatesOptions] = useState<DtSelectOption[]>([]);

  const sitesBySelectedOrganization = sites.filter((site) => {
    if (currentCompanyId) {
      if (site.organizationId === currentCompanyId) {
        return site;
      }
    } else {
      return site;
    }
  });

  useEffect(() => {
    if (currentCompanyId && !sites.length) {
      dtStore.dispatch(dtLoadSites({ companyId: currentCompanyId }));
    }

    try {
      dtApiRepository.AdminFormApi.adminFormControllerGetAllAvailableFormTypes().then(({ data }) => {
        setUniqueFormTypes(data);
      });
    } catch (e) {
      throw new Error('Failed to get unique form types');
    }
  }, []);

  useEffect(() => {
    dtApiRepository.AdminFormSchemaApi.adminFormSchemaControllerGetReportTemplates().then(({ data }) => {
      setReportTemplatesOptions(dtMapReportTemplatesNamesToOptions(data));
    });
  }, []);

  const formTypeFieldOptions = sortBy(
    uniqueFormTypes?.map((formType) => ({
      value: formType,
      label: formType,
    })),
    'label'
  );

  const siteFiledOptions = sortBy(
    sitesBySelectedOrganization.map((site) => ({
      value: site.id,
      label: `${site.name}, ${site.city}, ${site.state}`,
    })),
    'label'
  );

  return (
    <Grid container spacing={24} justifyContent='flex-start'>
      {!isCreationFlow ? (
        <FieldContainer item xs={4}>
          <InputLabel htmlFor='formType'>Form Type</InputLabel>
          <DtFormTextInput id='formType' name='formType' placeholder='Enter form type' />
        </FieldContainer>
      ) : (
        <FieldContainer item xs={4}>
          <DtFormikCreatableSelect
            label='Form Type'
            name='formType'
            labelPosition='top'
            options={formTypeFieldOptions}
            placeholder='Select Form Type'
            fullWidth
          />
        </FieldContainer>
      )}
      <FieldContainer item xs={4}>
        <InputLabel htmlFor='formName'>Form Name</InputLabel>
        <DtFormTextInput id='formName' name='formName' placeholder='Enter form name' fullWidth />
      </FieldContainer>

      <FieldContainer item xs={4}>
        <InputLabel htmlFor='pfFormId'>Pronto Form ID</InputLabel>
        <DtFormTextInput id='pfFormId' name='pfFormId' placeholder='Enter pronto form id' fullWidth />
      </FieldContainer>

      <FieldContainer item xs={4}>
        <DtFormikSelect
          fullWidth
          labelPosition='top'
          label='Report Template Name'
          name='reportTemplateName'
          options={reportTemplatesOptions}
          placeholder='Select report template name'
          disabled={isSubmitting}
        />
      </FieldContainer>
      <FieldContainer item xs={4}>
        <DtFormikSelect<number | null>
          label='Site'
          name='siteId'
          labelPosition='top'
          options={siteFiledOptions}
          placeholder='Select Site'
          fullWidth
          disabled={!isCreationFlow || isSubmitting}
        />
      </FieldContainer>
    </Grid>
  );
};

export default DtAdministrationDetailsForms;
