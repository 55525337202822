import { useFormikContext } from 'formik';
import React from 'react';

import { DtOrganizationDetailsInitialValues } from './dt-administration-details-organization-form/dt-administration-details-organization-form.interface';
import { dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtAssignmentBlocks from '../../../../../components/dt-assignment-blocks/dt-assignment-blocks';
import { RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { dtSelectAssignments } from '../../../dt-assignments.slice';
import { DtAssignmentBlocksWrapper } from '../../dt-administration-details-layout/dt-administration-details-layout.styled';

const DtOrganizationAssignments: React.FC = () => {
  const { isSubmitting } = useFormikContext<DtOrganizationDetailsInitialValues>();

  const data = dtUseAppSelector(dtSelectAssignments);

  function handleParentItemEdit(parentId: string): void {
    dtRouter.navigate(RoutesResolver.SiteDetails(parentId));
  }

  function handleChildItemEdit(childId: string): void {
    dtRouter.navigate(RoutesResolver.UserDetails(childId));
  }

  return (
    <DtAssignmentBlocksWrapper>
      <DtAssignmentBlocks
        hideParentAssignButton
        hideChildAssignButton
        parentTitle='Sites'
        childTitle='Users'
        data={data}
        onParentEdit={handleParentItemEdit}
        onChildEdit={handleChildItemEdit}
        hideDeleteButton
        hideSubtitle
        disabled={isSubmitting}
      />
    </DtAssignmentBlocksWrapper>
  );
};

export default DtOrganizationAssignments;
