import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';

import { DialogContainer, DialogContentContainer } from './dt-assets-details-modal.styled';
import DtCreateUpdateAssetsForm from './dt-create-updated-assets-form/dt-create-update-assets-form';
import { DT_CREATE_UPDATE_ASSETS_FORM_VALIDATION } from './dt-create-updated-assets-form/dt-create-update-assets-form.schema';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import { dtUseQuery } from '../../../../cdk/hooks/dt-use-query';
import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { FormMutationDto, FormSchemaAssetDto } from '../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../services/dt-toast.service';
import DtModalActions from '../../../dt-dashboard/components/dt-modal-actions/dt-modal-actions';
import {
  DialogActionsContainer,
  DialogTitleContainer,
  ModalTitleMain,
  TitleSecondary,
} from '../../../dt-dashboard/modals/dt-dispatch-details-modal/dt-dispatch-details-modal.styled';
import { dtSelectCurrentCompanyId } from '../../../dt-user/dt-user.slice';
import { DtAssetRowItem } from '../../components/dt-assets-table/dt-assets-table';

interface DtAssetsDetailsModalProps {
  open: boolean;
  assetRowItem: DtAssetRowItem | null;
  form: FormMutationDto | null;
  assetList: DtAssetRowItem[];
  onSubmit: () => void;
  onCancel: (shouldRefresh?: boolean) => void;
}

const DtAssetsDetailsModal: React.FC<DtAssetsDetailsModalProps> = ({
  open,
  assetRowItem,
  form,
  assetList,
  onSubmit,
  onCancel,
}) => {
  const query = dtUseQuery();

  const [validationAfterSubmit, setValidationAfterSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);

  const isCreationFlow = !assetRowItem;

  const currentSiteId = Number(query.get('site'));
  const currentFormTypeId = Number(query.get('formType'));
  const currentFormSubtypeId = Number(query.get('formSubtype'));

  function mapAssetToDto(asset: DtAssetRowItem): FormSchemaAssetDto {
    return {
      assetKey: asset.assetName,
      floorKey: asset.assetFloor,
      locationKey: asset.assetLocation,
    };
  }

  async function handleSubmitAsset(newAsset: FormSchemaAssetDto, id?: string): Promise<void> {
    const assets = isCreationFlow
      ? [...assetList.map((asset) => mapAssetToDto(asset)), newAsset]
      : assetList.map((item) => (item.id === id ? newAsset : mapAssetToDto(item)));

    const payload: FormMutationDto = {
      formType: form?.formType ?? '',
      formName: form?.formName ?? '',
      pfFormId: form?.pfFormId ?? '',
      reportTemplateName: form?.reportTemplateName ?? '',
      formAssetList: assets,
      siteId: currentSiteId,
    };

    try {
      if (currentCompanyId) {
        setIsLoading(true);
        await dtApiRepository.AdminFormApi.adminFormControllerUpdate(
          Number(currentFormTypeId),
          currentCompanyId as number,
          currentSiteId,
          {
            ...payload,
            formSchemaId: currentFormSubtypeId,
          }
        );
        dtToastService.success(`Successfully ${isCreationFlow ? 'created' : 'updated'} asset`);
        onCancel();
      }
    } catch (error) {
      dtToastService.error(`Failed to ${!isCreationFlow ? 'update' : 'create new'} form`);
    }
    onSubmit();
    setIsLoading(false);
  }

  return (
    <DialogContainer open={open} onClose={() => onCancel()} transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogTitleContainer>
        <section>
          <ModalTitleMain variant='h3'>{assetRowItem?.assetName ?? 'Create asset'}</ModalTitleMain>
          <TitleSecondary variant='bodySmall'>{assetRowItem?.assetFloor}</TitleSecondary>
        </section>
        <section>
          <DtButton variant='transparent' onClick={onCancel}>
            <DtIcon icon='close' size={20} />
          </DtButton>
        </section>
      </DialogTitleContainer>
      <Formik
        initialValues={{
          assetKey: assetRowItem?.assetName ?? null,
          floorKey: assetRowItem?.assetFloor ?? null,
          locationKey: assetRowItem?.assetLocation ?? null,
        }}
        validationSchema={DT_CREATE_UPDATE_ASSETS_FORM_VALIDATION}
        onSubmit={async (values) => {
          await handleSubmitAsset(
            { assetKey: values.assetKey ?? '', floorKey: values.floorKey ?? '', locationKey: values.locationKey ?? '' },
            assetRowItem?.id
          );
        }}
        validateOnBlur={validationAfterSubmit}
        validateOnChange={validationAfterSubmit}
        enableReinitialize
      >
        {({ isValid, submitForm }) => (
          <>
            <DialogContentContainer>
              <DtCreateUpdateAssetsForm />
            </DialogContentContainer>
            <DialogActionsContainer>
              {isLoading ? (
                <>
                  <Box marginRight={24}>
                    <DtSkeleton variant='rectangular' height={40} width={128} />
                  </Box>
                  <DtSkeleton variant='rectangular' height={40} width={128} />
                </>
              ) : (
                <DtModalActions
                  onClose={onCancel}
                  onSubmit={() => {
                    submitForm();
                    setValidationAfterSubmit(true);
                  }}
                  disableSaveButton={!isValid}
                />
              )}
            </DialogActionsContainer>
          </>
        )}
      </Formik>
    </DialogContainer>
  );
};

export default DtAssetsDetailsModal;
