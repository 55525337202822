import React, { useState } from 'react';

import DtIcon from '../../../../../components/dt-icon/dt-icon';
import DtSkeleton from '../../../../../components/dt-skeleton/dt-skeleton';
import {
  ActionButtonsWrapper,
  ActionSkeletonWrapper,
  CenteredButton,
} from '../../../../../components/dt-table/dt-actions-table-cell.styled';
import { dtDispatchService } from '../../../dt-dispatch.service';
import { DtSupportedDispatchStatuses } from '../../../dt-dispatches.enums';

interface DtDispatchActionsTableCellProps {
  dtDispatchStatus: DtSupportedDispatchStatuses;
  dtDispatchId: string;
  dtDispatchType: string;
  openDeleteConfirmation: (id: string, formType: string) => void;
  isAdmin: boolean;
}

const DtDispatchActionsTableCell: React.FC<DtDispatchActionsTableCellProps> = ({
  dtDispatchStatus,
  dtDispatchId,
  dtDispatchType,
  openDeleteConfirmation,
  isAdmin,
}) => {
  const isCompletedStatus = dtDispatchStatus === DtSupportedDispatchStatuses.Completed;

  const [isLoading, setIsLoading] = useState(false);

  function openDeleteConfirmationPopup(): void {
    openDeleteConfirmation(dtDispatchId, dtDispatchType);
  }

  async function handleDownloadReport(): Promise<void> {
    setIsLoading(true);
    const dtDispatchReport = await dtDispatchService.getDtDispatchReport(dtDispatchId, true);
    if (dtDispatchReport) {
      await dtDispatchService.downloadDtDispatchReport(dtDispatchReport);
    }
    setIsLoading(false);
  }

  return (
    <ActionButtonsWrapper>
      {isCompletedStatus ? (
        <>
          {isLoading ? (
            <ActionSkeletonWrapper>
              <DtSkeleton variant='rectangular' width={20} height={20} />
              {isAdmin ? <DtSkeleton variant='rectangular' width={20} height={20} /> : null}
            </ActionSkeletonWrapper>
          ) : (
            <>
              <CenteredButton variant='transparent' onClick={handleDownloadReport}>
                <DtIcon icon='download' size={20} color='secondaryIcon' />
              </CenteredButton>
              {isAdmin ? (
                <CenteredButton variant='transparent' onClick={openDeleteConfirmationPopup}>
                  <DtIcon icon='trash' size={20} color='secondaryIcon' />
                </CenteredButton>
              ) : null}
            </>
          )}
        </>
      ) : (
        <CenteredButton variant='transparent' onClick={openDeleteConfirmationPopup}>
          <DtIcon icon='trash' size={20} color='secondaryIcon' />
        </CenteredButton>
      )}
    </ActionButtonsWrapper>
  );
};

export default DtDispatchActionsTableCell;
