import { Dialog, DialogActions } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';

export const DialogContainer = styled(Dialog, { shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' })<{
  width?: string;
  height?: string;
}>`
  & .MuiPaper-root {
    &.MuiDialog-paper {
      width: ${({ width }) => width ?? '500px'};
      height: ${({ height }) => height ?? 'auto'};
      align-items: center;
      justify-content: center;
      padding: 28px 0;
      margin: 0;
      ${({ theme }) => theme.mixins.boxShadowMixin};
    }
  }
`;

export const DialogIcon = styled(DtIcon)`
  margin-bottom: ${({ theme }) => theme.spacing(28)};
`;

export const DialogTitle = styled('h3')`
  ${({ theme }) => theme.typography.h3 as CSSObject};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  margin-top: 0;
`;

export const DialogSubtitle = styled('p')`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  margin: 0;
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-bottom: ${({ theme }) => theme.spacing(40)};
`;

export const DialogActionsContainer = styled(DialogActions)`
  justify-content: center;
  padding: 0;
  height: 40px;

  &.MuiDialogActions-root > :not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing(24)};
  }
`;

export const DialogButton = styled(DtButton)`
  width: 128px;
  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(33)}`};
`;
