/* eslint-disable no-magic-numbers */
/* eslint-disable filenames/match-exported */
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import {
  DtHeaderRowTableCell,
  DtRowTableCell,
  DtStickyHeaderTableCell,
  DtStickyTableCell,
  DtTableCellText,
} from './dt-questions-table.styled';
import DtScrollbar from '../dt-scrollbar/dt-scrollbar';
import DtSkeleton from '../dt-skeleton/dt-skeleton';

interface DtHeaderRowSkeletonProps {
  width?: number | string;
  isBottom?: boolean;
  isHighlighted?: boolean;
  style?: Record<string, unknown>;
}

const DtHeaderRowSkeleton: React.FC<DtHeaderRowSkeletonProps> = ({ width, isBottom, isHighlighted, style }) => {
  return (
    <DtHeaderRowTableCell
      align={isBottom ? 'left' : 'center'}
      isHighlighted={isHighlighted}
      style={{
        verticalAlign: isBottom ? 'bottom' : 'inherit',
        ...style,
      }}
    >
      <DtTableCellText>
        <DtSkeleton width={width} height={isBottom ? 30 : undefined} style={{ display: 'inline-block' }} />
      </DtTableCellText>
    </DtHeaderRowTableCell>
  );
};

const DtQuestionsTableSkeleton: React.FC<{
  //
}> = ({}) => {
  // 292 - width of the nav bar
  // 130 - width of the pinned column
  // 60 - paddings
  const tableContentWidth = window.innerWidth - 130 - 60 - 292;

  /// Define amount of columns when the single column width is 230px
  const amountOfColumns = Math.floor(tableContentWidth / 230) * 2;
  const amountOfColumnsGroups = 2;

  const groupsColumns = Array(amountOfColumnsGroups)
    .fill(0)
    .map((_, i) => i + 10);

  const columns = Array(amountOfColumns)
    .fill(0)
    .map((_, i) => i + 10);

  // 86 - height of the header
  // 56 - height of the row
  const rows = Array(Math.floor((screen.height - 86) / 56))
    .fill(0)
    .map((_, i) => i);

  return (
    <TableContainer
      style={{
        maxHeight: '100%',
        height: 'calc(100vh - 255px)',
      }}
    >
      <DtScrollbar
        horizontal
        ignoredSelector='.dt-sticky-table-cell'
        height='inherit'
        leftHorizontalMargin={`calc(${1} * 130px)`}
      >
        <Table aria-label='simple table' style={{ overflowX: 'auto' }}>
          <TableHead style={{ display: 'flex', flexDirection: 'column' }}>
            <TableRow>
              <DtStickyHeaderTableCell align='center' className='dt-sticky-table-cell' isScrollAtStart={true} />
              {groupsColumns.map((i) => (
                <DtHeaderRowSkeleton
                  key={i}
                  isHighlighted={i % 2 === 0}
                  width='60%'
                  style={{
                    width: `calc(${4} * 230px)`,
                    minWidth: `calc(${4} * 230px)`,
                    maxWidth: `calc(${4} * 230px)`,
                  }}
                  isBottom={true}
                />
              ))}
            </TableRow>
            <TableRow>
              <DtStickyHeaderTableCell align='center' style={{ verticalAlign: 'baseline' }}>
                <DtSkeleton width={100} style={{ margin: '0 auto' }} />
              </DtStickyHeaderTableCell>
              {columns.map((i) => (
                <DtHeaderRowSkeleton key={i} isHighlighted={i <= 13} width='60%' />
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ display: 'flex', flexDirection: 'column' }}>
            {
              // eslint-disable-next-line no-magic-numbers
              rows.map((i) => {
                return (
                  <TableRow key={i}>
                    <DtStickyTableCell component='th' scope='row' align='center' style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '8px',
                        }}
                        className='dt-sticky-table-cell'
                      >
                        <DtSkeleton variant='rectangular' width={24} height={24} />
                        <div
                          style={{
                            flex: 1,
                            marginLeft: '8px',
                          }}
                        >
                          <DtSkeleton variant='text' width={60} style={{ margin: '0 auto' }} />
                          <DtSkeleton variant='text' width={50} style={{ margin: '0 auto' }} />
                        </div>
                      </div>
                    </DtStickyTableCell>
                    {columns.map((j) => (
                      <DtRowTableCell key={j} isHighlighted={j <= 13} align='center' style={{}}>
                        <DtSkeleton variant='text' width={60} style={{ display: 'inline-block' }} />
                      </DtRowTableCell>
                    ))}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </DtScrollbar>
    </TableContainer>
  );
};

export default DtQuestionsTableSkeleton;
