import { dtSetFormSubtypeData, dtSetLastUpdatedDatetime, dtSetLoadingStatus } from './dt-inspection-details.slice';
import { DtInspectionDetailsFilters } from './dt-inspection-details.types';
import { DtStatus } from '../../dt-enums';
import { DtAppThunk } from '../../dt-store';
import { dtApiRepository } from '../../repositories/dt-api.repository';
import { dtToastService } from '../../services/dt-toast.service';

export const dtLoadFormSubtypeDataAction =
  (filters: DtInspectionDetailsFilters): DtAppThunk =>
  async (dispatch, getState) => {
    const root = getState();

    try {
      dispatch(dtSetLoadingStatus(DtStatus.Loading));

      if (filters.schemaName === null || !filters.propertyId || !filters.formSubtypeId) {
        return Promise.resolve();
      }

      const formSubtypeData =
        (
          await dtApiRepository.DispatchApi.dispatchControllerGetInspection(
            filters.propertyId,
            filters.formSubtypeId,
            filters.size,
            filters.offset,
            filters.schemaName ?? '',
            filters.assetName ?? '',
            {
              from: filters.dateRange.from.toJSON(),
              to: filters.dateRange.to.toJSON(),
            }
          )
        )?.data ?? {};

      if (formSubtypeData.paggination.offset === 0) {
        dispatch(dtSetFormSubtypeData(formSubtypeData));
      } else {
        dispatch(
          dtSetFormSubtypeData({
            ...formSubtypeData,
            combinedData: {
              questions: formSubtypeData.combinedData.questions,
              answers: [
                ...(root.dtInspectionDetails.formSubtypeData?.combinedData.answers || []),
                ...formSubtypeData.combinedData.answers,
              ],
            },
          })
        );
      }
      dispatch(dtSetLastUpdatedDatetime(new Date()));
    } catch (error) {
      dtToastService.error('Failed to get inspection data for that view');
    }
  };
