import styled from '@emotion/styled';
import Lottie from 'lottie-react';
import React, { ReactNode, useEffect, useState } from 'react';

import logoAnimation from './dt-app-logo-animation.json';
import { dtAppLifecycleService } from './services/dt-app-lifecycle.service';

const Wrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'visible' })<{
  visible?: boolean;
}>`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)} !important;
  transition: opacity 300ms;
  z-index: 999999999999;

  .animated-loader {
    position: fixed;
    top: calc(50% - 800px / 4 / 2);
    left: calc(50% - 1600px / 4 / 2);
    height: calc(800px / 4);
    width: calc(1600px / 4);
  }
`;

interface DtAppLoaderProps {
  children: ReactNode;
}

const DtAppLoader: React.FC<DtAppLoaderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [isVisible, setVisible] = useState(true);

  // Every 100 ms check if app was loaded
  useEffect(() => {
    const reviewTimeout = 100;
    const id = window.setInterval(() => {
      if (dtAppLifecycleService.isReady) {
        window.clearInterval(id);
        setLoading(false);
      }
    }, reviewTimeout);
  }, []);

  // Hide loader gracefully, when app is loaded
  useEffect(() => {
    if (!isLoading) {
      const now = new Date();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loadingTimeInMs = now.getTime() - (window as any).INITAL_LOADING_TIMESTAMP.getTime();
      // 4 seconds to wait, total duration is 6 seconds
      const defaultAnimationDuration = 4000;
      const timeToWait = Math.max(0, defaultAnimationDuration - loadingTimeInMs);
      setTimeout(() => {
        setShowLoader(false);
        const opacityTransitionDuration = 300;
        setTimeout(() => {
          setVisible(false);
          window.document.body.style.position = 'initial';
        }, opacityTransitionDuration);
      }, timeToWait);
    }
  }, [isLoading]);

  return (
    <>
      {isVisible && (
        <Wrapper visible={showLoader}>
          <Lottie animationData={logoAnimation} loop={true} className='animated-loader' />
        </Wrapper>
      )}
      {children}
    </>
  );
};

export default DtAppLoader;
