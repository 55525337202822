import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React from 'react';

import DtDispatchActionsTableCell from './dt-dispatch-actions-table-cell/dt-dispatch-actions-table-cell';
import DtDispatchAssigneeTableCell from './dt-dispatch-assignee-table-cell/dt-dispatch-assignee-table-cell';
import DtDispatchAssignerTableCell from './dt-dispatch-assigner-table-cell/dt-dispatch-assigner-table-cell';
import { CellContentWrapper } from './dt-dispatch-assigner-table-cell/dt-dispatch-assigner-table-cell.styled';
import DtDispatchSkeletonTableCell from './dt-dispatch-skeleton-table-cell/dt-dispatch-skeleton-table-cell';
import DtDispatchStatusTableCell from './dt-dispatch-status-table-cell/dt-dispatch-status-table-cell';
import DtDispatchTypeTableCell from './dt-dispatch-type-table-cell/dt-dispatch-type-table-cell';
import { FrequencyType } from '../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import { ActionSkeletonWrapper } from '../../../../components/dt-table/dt-actions-table-cell.styled';
import { CellSubtitle, CellTitle } from '../../../../components/dt-table/table-cell.styled';
import { UserRole } from '../../../../repositories/__generated__/v2';
import {
  DT_ASSIGNEE_COLUMN_WIDTH,
  DT_SCHEDULED_DISPATCH_TYPE_COLUMN_WIDTH,
  DT_SCHEDULED_DISPATCH_COLUMNS_WIDTH,
  DT_WIDTH_WITH_TWO_ICONS,
} from '../../dt-dispatches.constants';
import { DtSupportedDispatchStatuses } from '../../dt-dispatches.enums';

export function dtGenerateDispatchesTableColumnsConfig(
  supportedStatus: DtSupportedDispatchStatuses,
  isLoading: boolean,
  openDeleteConfirmation: (id: string, formType: string) => void,
  role?: UserRole
): GridColDef[] {
  const isCompletedStatus = supportedStatus === DtSupportedDispatchStatuses.Completed;
  const isScheduledStatus = supportedStatus === DtSupportedDispatchStatuses.Scheduled;

  const submittedOrAssigneeHeaderLabel = isCompletedStatus ? 'Submitted' : 'Assignee';

  const baseColumnConfig: GridColDef[] = [
    {
      field: 'property',
      headerName: 'Property',
      // flex: isScheduledStatus ? DT_SCHEDULED_DISPATCH_TYPE_COLUMN_WIDTH : 1,
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.propertyName,
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtDispatchSkeletonTableCell />
        ) : (
          <CellContentWrapper>
            <CellTitle titleBold={false}>{params.row.propertyName}</CellTitle>
            <CellSubtitle subtitleBold={false}>{params.row.propertyAddress}</CellSubtitle>
          </CellContentWrapper>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type / Subtype',
      flex: isScheduledStatus ? DT_SCHEDULED_DISPATCH_TYPE_COLUMN_WIDTH : 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.type,
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtDispatchSkeletonTableCell />
        ) : (
          <DtDispatchTypeTableCell
            assetsCount={params.row.assets}
            dispatchType={params.row.type}
            dispatchSubtype={params.row.subtype}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: isScheduledStatus ? DT_SCHEDULED_DISPATCH_COLUMNS_WIDTH : 1,
      disableColumnMenu: true,
      // type: 'date',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      valueGetter: (params) =>
        params.row.status === DtSupportedDispatchStatuses.Scheduled
          ? params.row.frequencyType
          : params.row.status === DtSupportedDispatchStatuses.Completed
          ? params.row.completedDate
          : params.row.createdDate,
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtDispatchSkeletonTableCell />
        ) : (
          <DtDispatchStatusTableCell
            dueDate={params.row.dueDate}
            createdDate={params.row.createdDate}
            completedDate={params.row.completedDate}
            scheduledFrequency={
              params.row.frequencyType === FrequencyType.OnceFuture ? FrequencyType.None : params.row.frequencyType
            }
            dtDispatchStatus={params.row.status}
          />
        );
      },
    },
    {
      field: 'next dispatch',
      headerName: 'Next Dispatch',
      flex: isScheduledStatus ? DT_SCHEDULED_DISPATCH_COLUMNS_WIDTH : 1,
      disableColumnMenu: true,
      type: 'date',
      valueGetter: (params) => new Date(params.row.nextDispatch),
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        if (isLoading || params.row.isLoading) {
          return <DtSkeleton variant='text' width={95} height={40} />;
        }

        const nextDispatchDate = format(new Date(params.row.nextDispatch ?? 0), 'M/d/yyyy');
        const nextDispatchTime = format(new Date(params.row.nextDispatch ?? 0), 'h:mm a');

        return (
          <CellContentWrapper style={{ alignItems: 'center' }}>
            <CellTitle titleBold={false}>{nextDispatchDate}</CellTitle>
            <CellSubtitle subtitleBold={false}>{nextDispatchTime}</CellSubtitle>
          </CellContentWrapper>
        );
      },
    },

    {
      field: 'assigner',
      headerName: 'Assigner',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.assigner.name || params.row.assigner.email,
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtDispatchSkeletonTableCell />
        ) : (
          <DtDispatchAssignerTableCell
            assignerName={params.row.assigner.name}
            assignerEmail={params.row.assigner.email}
          />
        );
      },
    },
    {
      field: 'assignee',
      headerName: submittedOrAssigneeHeaderLabel,
      flex: isScheduledStatus ? 1 : DT_ASSIGNEE_COLUMN_WIDTH,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.assignee.name || params.row.totalAssigned,
      renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
        return isLoading || params.row.isLoading ? (
          <DtDispatchSkeletonTableCell />
        ) : (
          <DtDispatchAssigneeTableCell
            totalAssigned={params.row.totalAssigned}
            totalDeclined={params.row.totalDeclined ?? 0}
            assigneeName={params.row.assignee.name}
            assigneeEmail={params.row.assignee.email}
          />
        );
      },
    },
  ];

  const actionsColumn: GridColDef = {
    field: 'actions',
    headerName: '',
    width: DT_WIDTH_WITH_TWO_ICONS,
    sortable: false,
    align: 'right',
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<DtDispatchRowItem | DtScheduleDispatchItem>) => {
      const isAdmin = role === UserRole.SuperAdmin;
      let loadingContent = <DtSkeleton variant='rectangular' width={20} height={20} />;

      if (isCompletedStatus) {
        loadingContent = (
          <ActionSkeletonWrapper>
            <DtSkeleton variant='rectangular' width={20} height={20} />
            {isAdmin ? <DtSkeleton variant='rectangular' width={20} height={20} /> : null}
          </ActionSkeletonWrapper>
        );
      }

      return isLoading || params.row.isLoading ? (
        loadingContent
      ) : (
        <DtDispatchActionsTableCell
          dtDispatchStatus={params.row.status}
          dtDispatchId={params.row.id.toString()}
          dtDispatchType={params.row.type}
          openDeleteConfirmation={openDeleteConfirmation}
          isAdmin={isAdmin}
        />
      );
    },
  };

  return role && role === UserRole.User ? baseColumnConfig : [...baseColumnConfig, actionsColumn];
}

export default dtGenerateDispatchesTableColumnsConfig;
