import { styled } from '@mui/material/styles';

export const DtFilterPanelContainer = styled('section')`
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const DtFiltersWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(16)};
`;
