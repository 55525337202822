/* eslint-disable no-magic-numbers */
import { styled } from '@mui/material/styles';
import React from 'react';

import DtButton from '../../../../components/dt-button/dt-button';

const DialogActionButton = styled(DtButton)`
  min-width: 128px;
  ${({ theme }) => theme.mixins.commonItemsSpacingMixin(theme, 24)};
`;

interface DtModalActionsProps {
  onClose: () => void;
  onSubmit: () => void;
  onDelete?: () => void;
  dispatchCompleted?: boolean;
  disableSaveButton?: boolean;
}

const DtModalActions: React.FC<DtModalActionsProps> = ({
  dispatchCompleted,
  disableSaveButton,
  onClose,
  onSubmit,
  onDelete,
}) => {
  if (dispatchCompleted) {
    return (
      <DialogActionButton onClick={onClose} variant='primary'>
        Close
      </DialogActionButton>
    );
  }

  return (
    <>
      {onDelete && (
        <DialogActionButton onClick={onClose} variant='transparent'>
          Delete
        </DialogActionButton>
      )}
      <DialogActionButton onClick={onClose} variant='secondary'>
        Cancel
      </DialogActionButton>
      <DialogActionButton onClick={onSubmit} variant='primary' disabled={disableSaveButton}>
        Save
      </DialogActionButton>
    </>
  );
};

export default DtModalActions;
