import { UserRole } from '../../repositories/__generated__/v2';

export const DT_TABLE_ROW_HEIGHT = 80;
export const DT_ACTIONS_COLUMN_WIDTH = 130;

export const DT_USER_ROLE_OPTIONS = [
  { label: 'Administrator', value: UserRole.SuperAdmin },
  { label: 'Manager', value: UserRole.Manager },
  { label: 'User', value: UserRole.User },
];
