import React from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';

import DtLayoutWithNavigation from './components/dt-layout-with-navigation/dt-layout-with-navigation';
import DtAdministrationRoute from './components/dt-routes/dt-administration-route';
import DtAssetsRoute from './components/dt-routes/dt-assets-route';
import DtAuthorizedRoute from './components/dt-routes/dt-authorized-route';
import DtUnauthorizedRoute from './components/dt-routes/dt-unauthorized-route';
import { DtAppRoutes } from './dt-enums';
import DtAdministrationDetailsFormsPage from './modules/dt-administration/dt-administration-details/pages/dt-administration-details-forms-page/dt-administration-details-forms-page';
import DtAdministrationDetailsOrganizationPage from './modules/dt-administration/dt-administration-details/pages/dt-administration-details-organization-page/dt-administration-details-organization-page';
import DtAdministrationDetailsSitePage from './modules/dt-administration/dt-administration-details/pages/dt-administration-details-site-page/dt-administration-details-site-page';
import DtAdministrationDetailsUserPage from './modules/dt-administration/dt-administration-details/pages/dt-administration-details-user-page/dt-administration-details-user-page';
import DtAdministrationLayout from './modules/dt-administration/dt-administration-layout';
import DtFormsTable from './modules/dt-administration/dt-forms-tab/components/dt-forms-table/dt-forms-table';
import DtManageUsersTable from './modules/dt-administration/dt-manage-users-tab/components/dt-manage-users-table/dt-manage-users-table';
import DtSitesTable from './modules/dt-administration/dt-sites-tab/components/dt-sites-table/dt-sites-table';
import DtAssets from './modules/dt-assets/dt-assets';
import DtAssetsDetails from './modules/dt-assets/dt-assets-details';
import DtLoginPageLazy from './modules/dt-auth/pages/dt-login-page-lazy';
import DtResetPasswordPageLazy from './modules/dt-auth/pages/dt-reset-password-page-lazy';
import DtDashboard from './modules/dt-dashboard/dt-dashboard';
import DtDashboardLayout from './modules/dt-dashboard/dt-dashboard-layout';
import { DtSupportedDispatchStatuses } from './modules/dt-dashboard/dt-dispatches.enums';
import DtInspectionDetailsWrapper from './modules/dt-inspection-details/dt-inspection-details-wrapper';
import DtNotFoundLazy from './modules/dt-not-found/pages/dt-not-found-lazy';

export const dtRouter = createBrowserRouter([
  {
    element: <DtUnauthorizedRoute />,
    children: [
      {
        path: DtAppRoutes.Login,
        element: <DtLoginPageLazy />,
      },
      {
        path: DtAppRoutes.ResetPassword,
        element: <DtResetPasswordPageLazy />,
      },
    ],
  },
  {
    element: <DtAuthorizedRoute />,
    children: [
      {
        element: <DtLayoutWithNavigation />,
        children: [
          {
            path: '/dashboard',
            element: <DtDashboardLayout />,
            children: [
              {
                path: 'open',
                element: <DtDashboard status={DtSupportedDispatchStatuses.Open} />,
              },
              {
                path: 'overdue',
                element: <DtDashboard status={DtSupportedDispatchStatuses.Overdue} />,
              },
              {
                path: 'completed',
                element: <DtDashboard status={DtSupportedDispatchStatuses.Completed} />,
              },
              {
                path: 'scheduled',
                element: <DtDashboard status={DtSupportedDispatchStatuses.Scheduled} />,
              },
              {
                path: '*',
                element: <div />,
              },
            ],
          },
          {
            path: DtAppRoutes.Actions,
            element: <>Actions</>,
          },
          {
            element: <DtAdministrationRoute />,
            children: [
              {
                path: DtAppRoutes.UserCreate,
                element: <DtAdministrationDetailsUserPage />,
              },
              {
                path: DtAppRoutes.UserDetails,
                element: <DtAdministrationDetailsUserPage />,
              },
              {
                path: DtAppRoutes.SiteCreate,
                element: <DtAdministrationDetailsSitePage />,
              },
              {
                path: DtAppRoutes.SiteDetails,
                element: <DtAdministrationDetailsSitePage />,
              },
              {
                path: DtAppRoutes.FormCreate,
                element: <DtAdministrationDetailsFormsPage />,
              },
              {
                path: DtAppRoutes.FormDetails,
                element: <DtAdministrationDetailsFormsPage />,
              },
              {
                path: DtAppRoutes.OrganizationCreate,
                element: <DtAdministrationDetailsOrganizationPage isCreationFlow />,
              },
              {
                element: <DtAdministrationLayout />,
                children: [
                  {
                    path: DtAppRoutes.OrganizationAdministration,
                    element: <DtAdministrationDetailsOrganizationPage />,
                  },
                  {
                    path: DtAppRoutes.SitesAdministration,
                    element: <DtSitesTable />,
                  },
                  {
                    path: DtAppRoutes.FormsAdministration,
                    element: <DtFormsTable />,
                  },
                  {
                    path: DtAppRoutes.UsersAdministration,
                    element: <DtManageUsersTable />,
                  },
                ],
              },
            ],
          },
          {
            path: DtAppRoutes.InspectionDetails,
            element: <DtInspectionDetailsWrapper />,
          },
          {
            element: <DtAssetsRoute />,
            children: [
              { path: DtAppRoutes.Assets, element: <DtAssets /> },
              { path: DtAppRoutes.AssetsDetails, element: <DtAssetsDetails /> },
            ],
          },
          {
            path: DtAppRoutes.Profile,
            element: <>Profile</>,
          },
          {
            path: '/',
            element: <Navigate replace to={DtAppRoutes.DashboardOpen} />,
          },
          {
            path: DtAppRoutes.NotFound,
            element: <DtNotFoundLazy />,
          },
        ],
      },
    ],
  },
]);

const DtRoutes: React.FC = () => {
  return <RouterProvider router={dtRouter} />;
};

export default DtRoutes;
