import { Field, FieldProps } from 'formik';
import React from 'react';

import DtTimePicker from './dt-time-picker';
import { DtFormikTimePickerProps } from './dt-time-picker.interfaces';

const DtFormikTimePicker: React.FC<Partial<DtFormikTimePickerProps>> = ({
  name,
  fullWidth,
  placeholder,
  disableFuture,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <DtTimePicker
          value={field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
          fullWidth={fullWidth}
          placeholder={placeholder}
          error={meta.error}
          disableFuture={disableFuture}
        />
      )}
    </Field>
  );
};

export default DtFormikTimePicker;
