type IStorage = Omit<Storage, 'length' | 'key'>;
const separator = '|';

function factory(storage: Storage): IStorage {
  try {
    const testKey = '__some_random_key_you_are_not_going_to_use__';
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return storage;
  } catch (e) {
    console.warn(`${storage} is not supported`);
    return new MemoryStorage();
  }
}

class MemoryStorage implements IStorage {
  private inMemoryStorage: Record<string, string> = {};

  clear(): void {
    this.inMemoryStorage = {};
  }

  getItem(name: string): string | null {
    if (this.inMemoryStorage.hasOwnProperty(name)) {
      return this.inMemoryStorage[name];
    }
    return null;
  }

  removeItem(name: string): void {
    delete this.inMemoryStorage[name];
  }

  setItem(name: string, value: string): void {
    this.inMemoryStorage[name] = value;
  }
}

class StorageAdapter implements IStorage {
  storage: IStorage;
  constructor(storage: IStorage) {
    this.storage = storage;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  setArray(key: string, array: number[]): void {
    this.storage.setItem(key, array.join(separator));
  }

  getArray(key: string): number[] {
    const string = this.storage.getItem(key) ?? '';
    return string
      .split(separator)
      .map((it: string) => parseInt(it, 10))
      .filter((num: number) => !isNaN(num));
  }

  setObject<T>(key: string, data: T): void {
    this.setItem(key, JSON.stringify(data));
  }

  getObject<T>(key: string): T | null {
    const string = this.getItem(key) || '{}';
    try {
      return JSON.parse(string);
    } catch {
      return null;
    }
  }

  setBoolean(key: string, data: boolean): void {
    this.setItem(key, data.toString());
  }

  /**
   * Returns false by default
   */
  getBoolean(key: string): boolean {
    const string = this.getItem(key);
    try {
      return string === 'true';
    } catch {
      return false;
    }
  }
}

export const dtPersistentStorageService = new StorageAdapter(factory(window.localStorage));
