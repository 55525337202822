import React, { ReactElement } from 'react';

import { MainContentSubtitle, MainContentTitle, TitleAndSubtitleWrapper } from './dt-page-title-and-subtitle.styled';

export interface DtPageTitleAndSubtitleProps {
  title: string;
  subtitle: string | ReactElement;
  mb?: number;
}

const DtPageTitleAndSubtitle: React.FC<DtPageTitleAndSubtitleProps> = ({ title, subtitle, mb }) => {
  return (
    <TitleAndSubtitleWrapper>
      <MainContentTitle variant='h3'>{title}</MainContentTitle>
      <MainContentSubtitle variant='bodySmall' style={mb !== undefined ? { marginBottom: mb } : undefined}>
        {subtitle}
      </MainContentSubtitle>
    </TitleAndSubtitleWrapper>
  );
};

export default DtPageTitleAndSubtitle;
