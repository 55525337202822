import { Accordion, AccordionSummary } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

export const CustomAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.main.white};
  color: ${({ theme }) => theme.palette.main.black};
  border-radius: 3px;
  margin-bottom: ${({ theme }) => theme.spacing(16)};
  ${({ theme }) => theme.mixins.accordionBoxShadowMixin};

  &::before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0 0 16px;
  }
`;

export const AccordionHeader = styled(AccordionSummary)`
  padding: ${({ theme }) => `${theme.spacing(22)} ${theme.spacing(24)}`};

  & .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const AccordionHeaderTitle = styled('h3')`
  ${({ theme }) => theme.typography.subtitle as CSSObject};
  margin: 0;
`;
