import { addBreadcrumb, captureException } from '@sentry/react';
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import {
  UserApi,
  GeneralApi,
  AdminCompanyApi,
  AdminSiteApi,
  AdminFormApi,
  DispatchApi,
  Configuration,
  AdminFormTypesApi,
  AdminFormSchemaApi,
  AdminAssignmentApi,
  AssetApi,
} from './__generated__/v2';
import { DT_BASE_URL } from '../dt-constants';
import { dtAuthService } from '../services/dt-auth.service';

interface ApiConfig {
  configuration?: Configuration;
  baseApiUrl?: string;
  axiosInstance: AxiosInstance;
}

const API_CONFIG: ApiConfig = {
  configuration: undefined,
  baseApiUrl: DT_BASE_URL,
  axiosInstance: axios.create(),
};

const commonApiConfig: [Configuration | undefined, string | undefined, AxiosInstance] = [
  API_CONFIG.configuration,
  API_CONFIG.baseApiUrl,
  API_CONFIG.axiosInstance,
];

// Add interceptors api instance
API_CONFIG.axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await dtAuthService.getAccessToken();
    const axiosConfig = config;

    if (token) {
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

API_CONFIG.axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.isAxiosError) {
      let requestBody = undefined;
      try {
        requestBody = JSON.parse(error.config!.data);
      } catch (error) {
        addBreadcrumb({
          level: 'info',
          message: `Failed to parse body`,
        });
      }
      // debugger;
      addBreadcrumb({
        level: 'error',
        message: `Request body [${error.config!.method}] ${error.config!.url}`,
        data: requestBody,
      });
      addBreadcrumb({
        level: 'error',
        message: `Response body [${error.config?.method}] ${error.config?.url}`,
        data: error.response!.data as
          | {
              [key: string]: unknown;
            }
          | undefined,
      });
      captureException(new Error(`Request failed: [${error.config!.method}] ${error.config!.url}`));
    } else {
      addBreadcrumb({ level: 'error', message: JSON.stringify(error) });
      captureException(error);
    }
    return Promise.reject(error);
  }
);

export const dtApiRepository = {
  MeApi: new UserApi(...commonApiConfig),
  GeneralApi: new GeneralApi(...commonApiConfig),
  AdminCompanyApi: new AdminCompanyApi(...commonApiConfig),
  AdminSiteApi: new AdminSiteApi(...commonApiConfig),
  AdminFormApi: new AdminFormApi(...commonApiConfig),
  DispatchApi: new DispatchApi(...commonApiConfig),
  AdminFormTypeApi: new AdminFormTypesApi(...commonApiConfig),
  AdminFormSchemaApi: new AdminFormSchemaApi(...commonApiConfig),
  AdminAssignmentApi: new AdminAssignmentApi(...commonApiConfig),
  AssetApi: new AssetApi(...commonApiConfig),
};
