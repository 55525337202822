import { useTheme } from '@mui/system';
import IcomoonReact from 'icomoon-react';
import React from 'react';

import { DtSupportedIcon } from './gen/dt-supported-icons';
import iconsSet from './gen/icomoon-icons-selection-processed.json';

export interface DtIconProps {
  /**
   * @param {string} color - custom fill-color for icon. color to a particular color in MainPalette interface
   * @default undefined
   * @example color="red", color="emerald"
   */
  color?: keyof MainPalette;
  /**
   * @param {string} size - custom size for icon
   * @default undefined
   * @example "1em", 10, "100px"
   */
  size?: string | number;
  icon: DtSupportedIcon;
  style?: React.CSSProperties;
}

const DtIcon: React.FC<DtIconProps> = ({ color, ...props }) => {
  const theme = useTheme();

  const iconColor = color && theme.palette.main[color];

  return <IcomoonReact iconSet={iconsSet} {...props} color={iconColor} />;
};

export default DtIcon;
