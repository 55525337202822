import React, { useState } from 'react';

import DtIcon from '../../../../../components/dt-icon/dt-icon';
import DtSkeleton from '../../../../../components/dt-skeleton/dt-skeleton';
import {
  ActionButtonsWrapper,
  ActionSkeletonWrapper,
  CenteredButton,
} from '../../../../../components/dt-table/dt-actions-table-cell.styled';

interface DtAssetsActionsTableCellProps {
  dtAssetId: string;
  openDeleteConfirmation: (id: string) => void;
}

const DtAssetsActionsTableCell: React.FC<DtAssetsActionsTableCellProps> = ({ dtAssetId, openDeleteConfirmation }) => {
  const [isLoading, setIsLoading] = useState(false);

  function openDeleteConfirmationPopup(): void {
    openDeleteConfirmation(dtAssetId);
  }

  // TODO: Replace with real download function when FJ api will be ready
  async function handleDownloadReport(): Promise<void> {
    setIsLoading(true);
    setIsLoading(false);
  }

  return (
    <ActionButtonsWrapper>
      <>
        {isLoading ? (
          <ActionSkeletonWrapper>
            <DtSkeleton variant='rectangular' width={20} height={20} />
            <DtSkeleton variant='rectangular' width={20} height={20} />
          </ActionSkeletonWrapper>
        ) : (
          <>
            <CenteredButton variant='transparent' onClick={handleDownloadReport}>
              <DtIcon icon='download' size={20} color='secondaryIcon' />
            </CenteredButton>
            <CenteredButton variant='transparent' onClick={openDeleteConfirmationPopup}>
              <DtIcon icon='trash' size={20} color='secondaryIcon' />
            </CenteredButton>
          </>
        )}
      </>
    </ActionButtonsWrapper>
  );
};

export default DtAssetsActionsTableCell;
