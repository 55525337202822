import { styled } from '@mui/material/styles';
import DtButton from '../../../../components/dt-button/dt-button';

export const CancelButton = styled(DtButton)`
  margin-left: ${({ theme }) => theme.spacing(24)};
  display: flex;
  width: 140px;
  height: 40px;
  justify-content: center;
`;

export const SaveButton = styled(DtButton)`
  display: flex;
  width: 140px;
  height: 40px;
  justify-content: center;
`;
