/* tslint:disable */
/* eslint-disable */
/**
 * Thread server API
 * The Thread API description. Click [link](/api-json) to open JSON format.    If you want to get access to the FormJunction API, use `/api-proxy` endpoint.    *Example:* GET `/api-proxy/Company/forms/user1@digitalthreadtech.com`    For other examples - use confluence documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FormCreateMutationResponseDto } from '../entities';
// @ts-ignore
import { FormMutationDto } from '../entities';
// @ts-ignore
import { FormResponseDto } from '../entities';
// @ts-ignore
import { FormTypeListWithFormSchemasDto } from '../entities';
// @ts-ignore
import { FormUpdateMutationDto } from '../entities';
// @ts-ignore
import { FormsListResponseDto } from '../entities';
/**
 * AdminFormApi - axios parameter creator
 * @export
 */
export const AdminFormApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} companyId
     * @param {FormMutationDto} formMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerCreate: async (
      companyId: number,
      formMutationDto: FormMutationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminFormControllerCreate', 'companyId', companyId);
      // verify required parameter 'formMutationDto' is not null or undefined
      assertParamExists('adminFormControllerCreate', 'formMutationDto', formMutationDto);
      const localVarPath = `/admin/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formMutationDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerDelete: async (
      formSchemaId: number,
      companyId: number,
      siteId: number,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formSchemaId' is not null or undefined
      assertParamExists('adminFormControllerDelete', 'formSchemaId', formSchemaId);
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminFormControllerDelete', 'companyId', companyId);
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('adminFormControllerDelete', 'siteId', siteId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormControllerDelete', 'id', id);
      const localVarPath = `/admin/form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (formSchemaId !== undefined) {
        localVarQueryParameter['formSchemaId'] = formSchemaId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (siteId !== undefined) {
        localVarQueryParameter['siteId'] = siteId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGet: async (
      id: number,
      formSchemaId: number,
      companyId: number,
      siteId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormControllerGet', 'id', id);
      // verify required parameter 'formSchemaId' is not null or undefined
      assertParamExists('adminFormControllerGet', 'formSchemaId', formSchemaId);
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminFormControllerGet', 'companyId', companyId);
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('adminFormControllerGet', 'siteId', siteId);
      const localVarPath = `/admin/form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (formSchemaId !== undefined) {
        localVarQueryParameter['formSchemaId'] = formSchemaId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (siteId !== undefined) {
        localVarQueryParameter['siteId'] = siteId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllAvailableFormTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/form/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllAvailableFormTypesWithFormSchemas: async (
      orgId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('adminFormControllerGetAllAvailableFormTypesWithFormSchemas', 'orgId', orgId);
      const localVarPath = `/admin/form/all-associations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (orgId !== undefined) {
        localVarQueryParameter['orgId'] = orgId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {string} [formSubtype]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllForms: async (
      companyId: number,
      siteId?: number,
      formType?: string,
      formSubtype?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminFormControllerGetAllForms', 'companyId', companyId);
      const localVarPath = `/admin/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (siteId !== undefined) {
        localVarQueryParameter['siteId'] = siteId;
      }

      if (formType !== undefined) {
        localVarQueryParameter['formType'] = formType;
      }

      if (formSubtype !== undefined) {
        localVarQueryParameter['formSubtype'] = formSubtype;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerRefresh: async (
      formSchemaId: number,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formSchemaId' is not null or undefined
      assertParamExists('adminFormControllerRefresh', 'formSchemaId', formSchemaId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormControllerRefresh', 'id', id);
      const localVarPath = `/admin/form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (formSchemaId !== undefined) {
        localVarQueryParameter['formSchemaId'] = formSchemaId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} companyId
     * @param {number} siteId
     * @param {FormUpdateMutationDto} formUpdateMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerUpdate: async (
      id: number,
      companyId: number,
      siteId: number,
      formUpdateMutationDto: FormUpdateMutationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFormControllerUpdate', 'id', id);
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('adminFormControllerUpdate', 'companyId', companyId);
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('adminFormControllerUpdate', 'siteId', siteId);
      // verify required parameter 'formUpdateMutationDto' is not null or undefined
      assertParamExists('adminFormControllerUpdate', 'formUpdateMutationDto', formUpdateMutationDto);
      const localVarPath = `/admin/form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (siteId !== undefined) {
        localVarQueryParameter['siteId'] = siteId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(formUpdateMutationDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminFormApi - functional programming interface
 * @export
 */
export const AdminFormApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminFormApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} companyId
     * @param {FormMutationDto} formMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerCreate(
      companyId: number,
      formMutationDto: FormMutationDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormCreateMutationResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerCreate(
        companyId,
        formMutationDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerDelete(
      formSchemaId: number,
      companyId: number,
      siteId: number,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerDelete(
        formSchemaId,
        companyId,
        siteId,
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerGet(
      id: number,
      formSchemaId: number,
      companyId: number,
      siteId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerGet(
        id,
        formSchemaId,
        companyId,
        siteId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerGetAllAvailableFormTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerGetAllAvailableFormTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerGetAllAvailableFormTypesWithFormSchemas(
      orgId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormTypeListWithFormSchemasDto>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFormControllerGetAllAvailableFormTypesWithFormSchemas(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} companyId
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {string} [formSubtype]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerGetAllForms(
      companyId: number,
      siteId?: number,
      formType?: string,
      formSubtype?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormsListResponseDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerGetAllForms(
        companyId,
        siteId,
        formType,
        formSubtype,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerRefresh(
      formSchemaId: number,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerRefresh(formSchemaId, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {number} companyId
     * @param {number} siteId
     * @param {FormUpdateMutationDto} formUpdateMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFormControllerUpdate(
      id: number,
      companyId: number,
      siteId: number,
      formUpdateMutationDto: FormUpdateMutationDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminFormControllerUpdate(
        id,
        companyId,
        siteId,
        formUpdateMutationDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminFormApi - factory interface
 * @export
 */
export const AdminFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AdminFormApiFp(configuration);
  return {
    /**
     *
     * @param {number} companyId
     * @param {FormMutationDto} formMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerCreate(
      companyId: number,
      formMutationDto: FormMutationDto,
      options?: any
    ): AxiosPromise<FormCreateMutationResponseDto> {
      return localVarFp
        .adminFormControllerCreate(companyId, formMutationDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerDelete(
      formSchemaId: number,
      companyId: number,
      siteId: number,
      id: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .adminFormControllerDelete(formSchemaId, companyId, siteId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} formSchemaId
     * @param {number} companyId
     * @param {number} siteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGet(
      id: number,
      formSchemaId: number,
      companyId: number,
      siteId: number,
      options?: any
    ): AxiosPromise<FormResponseDto> {
      return localVarFp
        .adminFormControllerGet(id, formSchemaId, companyId, siteId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllAvailableFormTypes(options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .adminFormControllerGetAllAvailableFormTypes(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllAvailableFormTypesWithFormSchemas(
      orgId: number,
      options?: any
    ): AxiosPromise<Array<FormTypeListWithFormSchemasDto>> {
      return localVarFp
        .adminFormControllerGetAllAvailableFormTypesWithFormSchemas(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {number} [siteId]
     * @param {string} [formType]
     * @param {string} [formSubtype]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerGetAllForms(
      companyId: number,
      siteId?: number,
      formType?: string,
      formSubtype?: string,
      options?: any
    ): AxiosPromise<Array<FormsListResponseDto>> {
      return localVarFp
        .adminFormControllerGetAllForms(companyId, siteId, formType, formSubtype, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} formSchemaId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerRefresh(formSchemaId: number, id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminFormControllerRefresh(formSchemaId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} companyId
     * @param {number} siteId
     * @param {FormUpdateMutationDto} formUpdateMutationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFormControllerUpdate(
      id: number,
      companyId: number,
      siteId: number,
      formUpdateMutationDto: FormUpdateMutationDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .adminFormControllerUpdate(id, companyId, siteId, formUpdateMutationDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminFormApi - interface
 * @export
 * @interface AdminFormApi
 */
export interface AdminFormApiInterface {
  /**
   *
   * @param {number} companyId
   * @param {FormMutationDto} formMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerCreate(
    companyId: number,
    formMutationDto: FormMutationDto,
    options?: AxiosRequestConfig
  ): AxiosPromise<FormCreateMutationResponseDto>;

  /**
   *
   * @param {number} formSchemaId
   * @param {number} companyId
   * @param {number} siteId
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerDelete(
    formSchemaId: number,
    companyId: number,
    siteId: number,
    id: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {number} formSchemaId
   * @param {number} companyId
   * @param {number} siteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerGet(
    id: number,
    formSchemaId: number,
    companyId: number,
    siteId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<FormResponseDto>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerGetAllAvailableFormTypes(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

  /**
   *
   * @param {number} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerGetAllAvailableFormTypesWithFormSchemas(
    orgId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<FormTypeListWithFormSchemasDto>>;

  /**
   *
   * @param {number} companyId
   * @param {number} [siteId]
   * @param {string} [formType]
   * @param {string} [formSubtype]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerGetAllForms(
    companyId: number,
    siteId?: number,
    formType?: string,
    formSubtype?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<FormsListResponseDto>>;

  /**
   *
   * @param {number} formSchemaId
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerRefresh(formSchemaId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {number} companyId
   * @param {number} siteId
   * @param {FormUpdateMutationDto} formUpdateMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApiInterface
   */
  adminFormControllerUpdate(
    id: number,
    companyId: number,
    siteId: number,
    formUpdateMutationDto: FormUpdateMutationDto,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * AdminFormApi - object-oriented interface
 * @export
 * @class AdminFormApi
 * @extends {BaseAPI}
 */
export class AdminFormApi extends BaseAPI implements AdminFormApiInterface {
  /**
   *
   * @param {number} companyId
   * @param {FormMutationDto} formMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerCreate(companyId: number, formMutationDto: FormMutationDto, options?: AxiosRequestConfig) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerCreate(companyId, formMutationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} formSchemaId
   * @param {number} companyId
   * @param {number} siteId
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerDelete(
    formSchemaId: number,
    companyId: number,
    siteId: number,
    id: number,
    options?: AxiosRequestConfig
  ) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerDelete(formSchemaId, companyId, siteId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {number} formSchemaId
   * @param {number} companyId
   * @param {number} siteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerGet(
    id: number,
    formSchemaId: number,
    companyId: number,
    siteId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerGet(id, formSchemaId, companyId, siteId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerGetAllAvailableFormTypes(options?: AxiosRequestConfig) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerGetAllAvailableFormTypes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerGetAllAvailableFormTypesWithFormSchemas(orgId: number, options?: AxiosRequestConfig) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerGetAllAvailableFormTypesWithFormSchemas(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {number} [siteId]
   * @param {string} [formType]
   * @param {string} [formSubtype]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerGetAllForms(
    companyId: number,
    siteId?: number,
    formType?: string,
    formSubtype?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerGetAllForms(companyId, siteId, formType, formSubtype, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} formSchemaId
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerRefresh(formSchemaId: number, id: number, options?: AxiosRequestConfig) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerRefresh(formSchemaId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {number} companyId
   * @param {number} siteId
   * @param {FormUpdateMutationDto} formUpdateMutationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFormApi
   */
  public adminFormControllerUpdate(
    id: number,
    companyId: number,
    siteId: number,
    formUpdateMutationDto: FormUpdateMutationDto,
    options?: AxiosRequestConfig
  ) {
    return AdminFormApiFp(this.configuration)
      .adminFormControllerUpdate(id, companyId, siteId, formUpdateMutationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
