import { MenuItemType } from './components/dt-navbar/dt-navbar.enums';
import { DtMenuItemDropdownType, DtMenuSelectOption } from './dt-navigation.interfaces';
import { DtSupportedIcon } from '../../components/dt-icon/gen/dt-supported-icons';
import { DtAppRoutes } from '../../dt-enums';
import { dtRouter } from '../../dt-routes';

class DtNavigationService {
  mapPropertyFormTypesToNavigationItems(form?: DtPropertyItem): DtMenuItemDropdownType[] {
    return form?.formTypes
      ? form.formTypes.map((formType: DtFormType) => ({
          id: formType.id,
          type: MenuItemType.Dropdown,
          title: formType.type,
          // TODO: add icons after investigation the gaps in current API contract
          icon: 'note-2',
          children: formType.formItems?.map((sybType) => {
            const assets = sybType.assetList;
            let location = '';
            let iconName: DtSupportedIcon = 'note-2';

            if (assets && assets.length) {
              location = assets[0].location || location;
              iconName = (assets[0].assetName as DtSupportedIcon) || iconName;
            }

            return {
              id: String(sybType.id),
              title: sybType.name,
              type: MenuItemType.Link,
              url: location,
              icon: iconName,
              onClick: () => {
                if (Object.values(DtAppRoutes).includes(location as DtAppRoutes)) {
                  dtRouter.navigate(location);
                }
              },
            };
          }),
        }))
      : [];
  }

  mapPropertiesToMenuSelectOptions(properties: DtPropertyItem[]): DtMenuSelectOption<number>[] {
    return properties.map((form) => ({
      label: form.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: form.id!,
      description: `${form.address}, ${form.region}`,
    }));
  }
}

export const dtNavigationService = new DtNavigationService();
