import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';

export const GridContainer = styled(Grid)`
  align-items: center;
  justify-content: center;
`;

export const TabButtonTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.black};
  text-transform: capitalize;
`;

export const DispatchStatus = styled(Typography)`
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.main.secondaryText};
  margin-left: ${({ theme }) => theme.spacing(10)};
`;
