import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { DT_THEME } from './app/cdk/theme/dt-theme';
import DtApp from './app/dt-app';
import DtAppLoader from './app/dt-app-loader';
import { dtStore } from './app/dt-store';
import { dtAppLifecycleService } from './app/services/dt-app-lifecycle.service';
import { CustomToastContainer } from './intex.styled';
import reportWebVitals from './report-web-vitals';

import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-day-picker/dist/style.css';
import './app/components/dt-date-pickers/dt-date-pickers.css';

if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: 'https://526110f5c05847bc99dcefb88ec9679d@o1387461.ingest.sentry.io/6708596',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
} else {
  console.warn('Sentry disabled');
}

dtAppLifecycleService.init();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ThemeProvider theme={DT_THEME}>
      <Provider store={dtStore}>
        <DtAppLoader>
          <CssBaseline />
          <DtApp />
          <CustomToastContainer hideProgressBar={true} position='top-center' closeOnClick={false} />
        </DtAppLoader>
      </Provider>
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
