import React from 'react';

import DtDetailsSummaryRow from '../../../../../components/dt-details-summary-row/dt-details-summary-row';
import DtFormTextInput from '../../../../../components/text-input/dt-form-text-input/dt-form-text-input';

const DtCreateUpdateAssetsForm: React.FC = () => {
  return (
    <>
      <DtDetailsSummaryRow label='Asset Name'>
        <DtFormTextInput id='assetKey' name='assetKey' placeholder='Enter asset name' />
      </DtDetailsSummaryRow>

      <DtDetailsSummaryRow label='Asset Floor'>
        <DtFormTextInput id='floorKey' name='floorKey' placeholder='Enter asset floor' />
      </DtDetailsSummaryRow>

      <DtDetailsSummaryRow label='Asset Location'>
        <DtFormTextInput id='locationKey' name='locationKey' placeholder='Enter asset location' />
      </DtDetailsSummaryRow>
    </>
  );
};

export default DtCreateUpdateAssetsForm;
