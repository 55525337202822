import { useFormikContext } from 'formik';
import React from 'react';

import DtFrequencyMonthlyWithEveryRecurrence from './layouts/dt-frequency-monthly-with-every-recurrence';
import DtFrequencyMonthlyWithSpecificRecurrence from './layouts/dt-frequency-monthly-with-specific-recurrence';
import DtFormikMultipleSelect from '../../../../../../components/dt-selects/dt-formik-multiple-select';
import { DtFrequencyEditorFormRecurrenceType } from '../../dt-frequency-editor-form.enums';
import { dtTimeSelectOptions } from '../../dt-frequency-editor-form.utils';
import {
  DtMonthlyFrequencyFormValues,
  DtNewDispatchFormInitialValues,
} from '../../dt-new-dispatch-modal-form-interface';

const DtNewDispatchFrequencyMonthly: React.FC = () => {
  const { values } = useFormikContext<DtNewDispatchFormInitialValues>();

  return (
    <>
      {(values.frequency as DtMonthlyFrequencyFormValues).repeatEvery === DtFrequencyEditorFormRecurrenceType.Each ? (
        <DtFrequencyMonthlyWithEveryRecurrence />
      ) : (
        <DtFrequencyMonthlyWithSpecificRecurrence />
      )}
      <DtFormikMultipleSelect
        fullWidth
        minWidth='180px'
        name='frequency.time'
        label='At time'
        labelPosition='top'
        options={dtTimeSelectOptions}
        placeholder='Select time'
      />
    </>
  );
};

export default DtNewDispatchFrequencyMonthly;
