import { Box, Chip, CSSObject, Grid, styled, Typography } from '@mui/material';
import _, { differenceWith, isEmpty, isEqual } from 'lodash';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import { DtModalActions } from './components/dt-modal-actions';
import { dtUseAppSelector } from '../../../../cdk/hooks/dt-store.hooks';
import { dtUseFetchData } from '../../../../cdk/hooks/dt-use-fetch-data.hook';
import DtAccordion from '../../../../components/dt-accordion/dt-accordion';
import DtButton from '../../../../components/dt-button/dt-button';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { DialogActionsContainer, DialogContainer } from '../../../../components/dt-popup/dt-popup.styled';
import DtSkeleton from '../../../../components/dt-skeleton/dt-skeleton';
import DtSwitcher from '../../../../components/dt-switcher/dt-switcher';
import DtTextInput from '../../../../components/text-input/dt-text-input/dt-text-input';
import { DtRootState } from '../../../../dt-store';
import {
  FormSchemaPageDto,
  FormSchemaSectionDto,
  FormSchemaViewDto,
  ReportingType,
} from '../../../../repositories/__generated__/v2';
import { dtApiRepository } from '../../../../repositories/dt-api.repository';
import { dtToastService } from '../../../../services/dt-toast.service';
import { InputLabel } from '../../../dt-auth/components/dt-form-common-styles.styled';
import {
  DialogTitleContainer,
  ModalTitleMain,
  TitleSecondary,
  DialogContentContainer,
} from '../../../dt-dashboard/modals/dt-dispatch-details-modal/dt-dispatch-details-modal.styled';
import { dtSelectAvailableAssetsByFormSubtype } from '../../../dt-properties/dt-properties.slice';

function buildDummyView(
  formSchemaName: string | undefined,
  formSubtypeId: number,
  propertyId: number,
  hasAssets: boolean
): FormSchemaViewDto {
  return {
    formName: '',
    viewName: formSchemaName ?? '',
    formIdentifier: '',
    formId: formSubtypeId,
    propertyId: propertyId,
    reportType: hasAssets ? ReportingType.Assets : ReportingType.Regular,
    pageList: [],
  };
}

const CustomChip = styled(Chip)`
  ${({ theme }) => theme.typography.bodySmallSemibold as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
  border-radius: 3px;
  height: 20px;

  & .MuiChip-label {
    padding: 0 8px;
  }
`;

interface Props {
  open: boolean;
  propertyId: number;
  formType: string;
  formSubtypeId: number;
  formSchemaName?: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const DtViewSchemaConfigModal: React.FC<Props> = ({
  open,
  propertyId,
  formType,
  formSubtypeId,
  formSchemaName,
  onCancel,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [expandedPage, setExpandedPage] = useState<string>();
  const hasAssets =
    dtUseAppSelector((state: DtRootState) =>
      dtSelectAvailableAssetsByFormSubtype(state, formSubtypeId, propertyId, formType)
    ).length > 0;
  const DUMMY_VIEW = buildDummyView(formSchemaName, formSubtypeId, propertyId, hasAssets);
  const [data, setData] = useState<FormSchemaViewDto>(DUMMY_VIEW);

  const { data: originalData, isLoading } = dtUseFetchData<FormSchemaViewDto | undefined | null>(
    [open, propertyId, formSubtypeId, formSchemaName],
    () => {
      if (!open) {
        return Promise.resolve(undefined);
      }
      return dtApiRepository.DispatchApi.dispatchControllerGetInspectionSchemaConfig(
        propertyId,
        formSubtypeId,
        formSchemaName ?? ''
      ).then((response) => response.data);
    }
  );

  const isCreationFlow = !Boolean(formSchemaName);

  useEffect(() => {
    if (originalData) {
      if (hasAssets) {
        setData({ ...originalData, reportType: ReportingType.Assets });
      } else {
        setData({ ...originalData, reportType: ReportingType.Regular });
      }
    } else {
      setData(DUMMY_VIEW);
    }
    setLoading(false);
  }, [originalData]);

  async function handleDelete(): Promise<void> {
    try {
      setLoading(true);
      await dtApiRepository.DispatchApi.dispatchControllerDeleteInspectionSchemaConfig(
        propertyId,
        formSubtypeId,
        formSchemaName ?? ''
      );
      onSubmit();
      dtToastService.info('Successfully deleted view');
    } catch (error) {
      dtToastService.error('Failed to delete view');
    }
    setLoading(false);
  }

  async function handleSubmit(): Promise<void> {
    if (isCreationFlow) {
      try {
        setLoading(true);
        await dtApiRepository.DispatchApi.dispatchControllerCreateInspectionSchemaConfig(data);
        dtToastService.success('Successfully created view');
      } catch (error) {
        dtToastService.error('Failed to create view');
      }
    } else {
      try {
        setLoading(true);
        await dtApiRepository.DispatchApi.dispatchControllerCreateInspectionSchemaConfig(data);
        dtToastService.success('Successfully updated view');
      } catch (error) {
        dtToastService.error('Failed to update view');
      }
    }
    onSubmit();
    setLoading(false);
  }

  function handleBackdropClick(): void {
    const changedAssigness = differenceWith([], [], isEqual);

    if (changedAssigness.length > 0) {
      dtToastService.info("Popup can't be closed because you made some changes, Save or Cancel them");
    } else {
      onCancel();
    }
  }

  const toggleAccordeonPage = (id: string) => (_: SyntheticEvent, isExpanded: boolean) => {
    setExpandedPage(isExpanded ? id : undefined);
  };

  function setViewName(value: string): void {
    setData({ ...data, viewName: value });
  }

  function togglePage(pageIndex: number): void {
    const clone = _.cloneDeep(data);
    const value = !clone.pageList[pageIndex].visible;

    clone.pageList[pageIndex].visible = value;
    for (const section of clone.pageList[pageIndex].sectionList) {
      section.visible = value;
      for (const question of section.questionList) {
        question.visible = value;
      }
    }

    setData(clone);
  }

  function toggleQuestion(pageIndex: number, sectionIndex: number, questionIndex: number): void {
    const questionPath = `pageList[${pageIndex}].sectionList[${sectionIndex}].questionList[${questionIndex}].visible`;
    const sectionPath = `pageList[${pageIndex}].sectionList[${sectionIndex}]`;
    const pagePath = `pageList[${pageIndex}]`;
    const clone = _.cloneDeep(data);
    _.update(clone, questionPath, (value: boolean) => !value);
    _.update(clone, sectionPath, (value: FormSchemaSectionDto) => ({
      ...value,
      visible: _.some(value.questionList, 'visible'),
    }));
    _.update(clone, pagePath, (value: FormSchemaPageDto) => ({
      ...value,
      visible: _.some(value.sectionList, 'visible'),
    }));

    setData(clone);
  }

  const showSkeleton = !data || isLoading;

  if (!isLoading && !originalData) {
    return (
      <DialogContainer open={open} onClose={handleBackdropClick} width='640px' height='700px'>
        <DialogTitleContainer sx={{ marginTop: '-28px', width: '100%' }}>
          <section>
            <ModalTitleMain variant='h3'>{isCreationFlow ? 'Create View' : 'Update View'}</ModalTitleMain>
            <TitleSecondary variant='bodySmall'>Select which questions apply to this view</TitleSecondary>
          </section>
          <section>
            <DtButton variant='transparent' onClick={onCancel}>
              <DtIcon icon='close' size={20} />
            </DtButton>
          </section>
        </DialogTitleContainer>
        <DialogContentContainer>
          <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
            <Grid item xs={12}>
              <br />
              <InputLabel>Cannot create views yet. Please submit a form first.</InputLabel>
            </Grid>
          </Grid>
        </DialogContentContainer>
      </DialogContainer>
    );
  }

  return (
    <DialogContainer open={open} onClose={handleBackdropClick} width='640px' height='700px'>
      <DialogTitleContainer sx={{ marginTop: '-28px', width: '100%' }}>
        <section>
          <ModalTitleMain variant='h3'>{isCreationFlow ? 'Create View' : 'Update View'}</ModalTitleMain>
          <TitleSecondary variant='bodySmall'>Select which questions apply to this view</TitleSecondary>
        </section>
        <section>
          <DtButton variant='transparent' onClick={onCancel}>
            <DtIcon icon='close' size={20} />
          </DtButton>
        </section>
      </DialogTitleContainer>
      <DialogContentContainer>
        {showSkeleton ? (
          <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
            <Grid item xs={12}>
              <InputLabel>View Name</InputLabel>
              <DtSkeleton variant='rectangular' height={40} />
            </Grid>
            {/* {hasAssets && (
              <Grid item xs={12}>
                <InputLabel>Pin Column</InputLabel>
                <DtSkeleton variant='rectangular' height={40} />
              </Grid>
            )} */}
            <Grid item xs={12}>
              {[{}, {}, {}].map((_, i) => {
                return (
                  <DtSkeleton key={i} variant='rectangular' height={72} width={536} style={{ marginBottom: '16px' }} />
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={24} justifyContent='flex-start' alignItems='flex-end'>
            <Grid item xs={12}>
              <InputLabel>View Name</InputLabel>
              <DtTextInput
                id='viewName'
                placeholder='Enter custom view name'
                fullWidth
                value={data.viewName}
                onChange={(i) => setViewName(i.target.value)}
              />
            </Grid>
            {/* TODO: remove */}
            {/* {hasAssets && (
              <Grid item xs={12}>
                <DtSchemaReportingTypeInput value={data.reportType} onChange={setReportingType} />
              </Grid>
            )} */}
            <Grid item xs={12}>
              {data.pageList.map((page, pageIndex) => {
                let amountOfVisibleQuestions = 0;
                let amountOfInvisibleQuestions = 0;

                for (const section of page.sectionList) {
                  for (const question of section.questionList) {
                    if (question.visible) {
                      amountOfVisibleQuestions += 1;
                    } else {
                      amountOfInvisibleQuestions += 1;
                    }
                  }
                }

                return (
                  <DtAccordion
                    contentPadding='6px 16px 32px 16px'
                    customTitle={
                      <Box sx={{ display: 'flex', gap: 16, alignItems: 'center' }} width='100%'>
                        <Box sx={{ flex: '1', minWidth: '0px' }}>
                          <Typography variant='bodySemibold' fontSize={16}>
                            {page.label}
                          </Typography>
                        </Box>
                        <CustomChip
                          sx={{ flex: 'none' }}
                          label={`${amountOfVisibleQuestions} of ${
                            amountOfVisibleQuestions + amountOfInvisibleQuestions
                          }`}
                        />
                        <Box sx={{ flex: 'none' }} marginRight={16}>
                          <DtButton variant='transparent' onClick={() => togglePage(pageIndex)} padding={4}>
                            <DtIcon icon={amountOfVisibleQuestions > 0 ? 'show' : 'hide'} size={20} />
                          </DtButton>
                        </Box>
                      </Box>
                    }
                    key={page.identifier}
                    expanded={expandedPage === page.identifier}
                    onChange={toggleAccordeonPage(page.identifier)}
                  >
                    {page.sectionList.map((section, sectionIndex) => {
                      return (
                        <Box
                          key={section.identifier}
                          sx={{ display: 'flex', gap: 16, alignItems: 'stretch' }}
                          flexDirection='column'
                          width='100%'
                        >
                          <Typography variant='bodySemibold'>{section.label}</Typography>
                          {section.questionList.map((question, questionIndex) => (
                            <label key={question.identifier}>
                              <Box
                                sx={{ cursor: 'pointer' }}
                                padding={12}
                                bgcolor={question.visible ? 'main.hover' : ''}
                                borderRadius='3px'
                                display='flex'
                                alignItems='center'
                              >
                                <Typography
                                  flex='1'
                                  variant='body'
                                  color={question.visible ? '' : 'main.secondaryText'}
                                >
                                  {question.lable}
                                </Typography>
                                <DtSwitcher
                                  checked={question.visible}
                                  onChange={() => {
                                    toggleQuestion(pageIndex, sectionIndex, questionIndex);
                                  }}
                                />
                              </Box>
                            </label>
                          ))}
                        </Box>
                      );
                    })}
                  </DtAccordion>
                );
              })}
            </Grid>
          </Grid>
        )}
      </DialogContentContainer>
      <DialogActionsContainer>
        {loading ? (
          <>
            <Box marginRight={24}>
              <DtSkeleton variant='rectangular' height={40} width={128} />
            </Box>
            <DtSkeleton variant='rectangular' height={40} width={128} />
          </>
        ) : (
          <DtModalActions
            onDelete={isCreationFlow || showSkeleton ? undefined : handleDelete}
            onClose={onCancel}
            onSubmit={showSkeleton ? undefined : !isEmpty(data.viewName) ? handleSubmit : undefined}
          />
        )}
      </DialogActionsContainer>
    </DialogContainer>
  );
};

export default DtViewSchemaConfigModal;
