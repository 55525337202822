import { styled } from '@mui/material/styles';
import DtTextInput from '../text-input/dt-text-input/dt-text-input';

export const CustomSearchInput = styled(DtTextInput, {
  shouldForwardProp: (prop) => prop !== 'height' && prop !== 'padding' && prop !== 'position' && prop !== 'width',
})<{ height?: string; padding?: string; position?: string; width?: string }>`
  padding: ${({ theme, padding }) => (padding ? padding : theme.spacing(0))};
  display: flex;
  position: ${({ position }) => (position ? position : 'relative')};
  top: 0;
  background-color: ${({ theme }) => theme.palette.main.white};
  z-index: 1;

  & .MuiInputBase-root {
    height: ${({ height }) => (height ? height : '40px')};
    width: ${({ width }) => (width ? width : '420px')};
    max-width: 100%;
  }
`;
