import { dtSetUsersData, dtUpdateLoadingStatusOfUserRow } from './dt-manage-users.slice';
import { DtAppThunk } from '../../../dt-store';
import { dtApiRepository } from '../../../repositories/dt-api.repository';
import { dtToastService } from '../../../services/dt-toast.service';
import { dtRefreshCurrentUserData } from '../../dt-user/dt-user.actions';

export const dtDeleteUserAction =
  (email: string): DtAppThunk =>
  async (dispatch, getState) => {
    const root = getState();

    try {
      dispatch(dtUpdateLoadingStatusOfUserRow({ email, status: true }));

      await dtApiRepository.MeApi.userControllerDelete(email);

      const users = root.dtManageUsers.users.filter((user) => user.email !== email);
      dispatch(dtSetUsersData(users));

      dtToastService.success('Successfully deleted user');

      dispatch(dtRefreshCurrentUserData());
    } catch (e) {
      dispatch(dtUpdateLoadingStatusOfUserRow({ email, status: false }));
      dtToastService.error('Failed to delete user');
    }
  };
