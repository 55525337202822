import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, createFilterOptions } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { ReactElement, ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AssignmentHeaderCounter } from '../../dt-assignment-blocks/dt-assignment-blocks.styled';
import { CustomCheckbox } from '../../dt-checkbox/dt-checkbox.styled';
import DtIcon from '../../dt-icon/dt-icon';
import { DtMultiSelectProps, DtOptionItem } from '../dt-selects.interfaces';
import { CustomChip, CustomSelect, CustomSelectContainer, CustomTextField, Label } from '../dt-selects.styled';

const DtMultipleSelect = <T extends string | number | Record<string, unknown>>({
  value,
  onChange,
  options,
  disabled = false,
  label,
  labelPosition,
  className,
  placeholder,
  fullWidth,
  name,
  error,
  minWidth,
  selectAllLabel = 'Select all',
  hideChips = false,
  disableSelectAll = false,
  tagsToPreview = 4,
}: DtMultiSelectProps<T>): ReactElement => {
  const [viewMore, setViewMore] = useState(false);
  const [uniqueId] = useState((name && label && `${name}_${label}`) ?? uuidv4());

  const disabledInput = disabled || options.length === 0;

  const allSelected = options.length === value?.length;
  const isIndeterminate = value && value?.length > 0 && value?.length < options.length;

  function toggleAll(): void {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options.map((item) => item.value));
    }
  }

  function handleOptionValueChange(newValue: readonly DtOptionItem<T>[]): void {
    onChange(newValue.map((item) => item.value));
  }

  function renderTags(values: DtOptionItem<T>[], getTagProps: AutocompleteRenderGetTagProps): ReactNode {
    if (hideChips) {
      return (
        <span style={{ paddingLeft: '4px', height: '38px', display: 'flex', alignItems: 'center' }}>{placeholder}</span>
      );
    }

    if (values.length > tagsToPreview) {
      return [
        ...values
          .slice(0, viewMore ? values.length : tagsToPreview)
          .map((option, index) => (
            <CustomChip
              {...getTagProps({ index })}
              size='small'
              variant='outlined'
              label={option.label}
              key={`chip-${option.value}`}
            />
          )),
        <span
          key='preview'
          style={{ paddingLeft: '4px', height: '32px', display: 'flex', alignItems: 'center' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setViewMore(!viewMore);
          }}
        >
          {viewMore ? 'Show less' : `+${values.length - tagsToPreview} items to preview`}
        </span>,
      ];
    }
    return values.map((option, index) => (
      <CustomChip
        {...getTagProps({ index })}
        size='small'
        variant='outlined'
        label={option.label}
        key={`chip-${index}`}
      />
    ));
  }

  function renderOptionWithCheckbox(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: DtOptionItem<T>,
    selected: boolean
  ): ReactNode {
    const isAllCheckbox = option.value === 'select-all';

    return (
      <li
        {...props}
        onClick={option.disabled ? () => ({}) : isAllCheckbox ? toggleAll : props.onClick}
        style={{ padding: '2px 0', fontSize: '14px' }}
      >
        <CustomCheckbox
          checked={isAllCheckbox ? allSelected : selected}
          indeterminate={isAllCheckbox ? isIndeterminate ?? false : undefined}
          size='small'
          disabled={option.disabled}
        />
        {isAllCheckbox ? <b>{option.label}</b> : option.label}
        {isAllCheckbox && (
          <AssignmentHeaderCounter
            label={value ? `${value?.length} of ${options.length}` : options.length}
            style={{ marginLeft: 'auto', height: 'fit-content', alignSelf: 'center', marginRight: '30px' }}
          />
        )}
      </li>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams): ReactNode {
    return (
      <CustomTextField
        {...params}
        fullWidth
        variant='outlined'
        name={name}
        error={Boolean(error)}
        helperText={error}
        labelPosition={labelPosition}
        onKeyDown={(event) => {
          event.preventDefault();
        }}
        minWidth={minWidth}
        placeholder={isEmpty(value) ? placeholder : ''}
      />
    );
  }

  const filter = createFilterOptions<DtOptionItem<T>>();
  const valueToPass = options.filter((item) => value?.includes(item.value));

  return (
    <CustomSelectContainer>
      {labelPosition === 'top' && label && (
        <div>
          <Label disabled={disabledInput}>{label}</Label>
        </div>
      )}
      <CustomSelect<DtOptionItem<T>, true>
        multiple
        id={uniqueId}
        options={options}
        className={className}
        value={valueToPass ?? []}
        defaultValue={valueToPass ?? []}
        hasSomeValues={valueToPass?.length > 0 ?? false}
        selectOnFocus
        openOnFocus
        disableCloseOnSelect
        fullWidth={fullWidth}
        getOptionLabel={(option) => option.label}
        disabled={disabledInput}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(_, newValue) => handleOptionValueChange(newValue as any)}
        popupIcon={<DtIcon icon='drop-down' size={20} />}
        getOptionDisabled={(i) => i.disabled ?? false}
        // PaperComponent={({ children }) => <DtSelectDropdown>{children}</DtSelectDropdown>}
        renderTags={(_, getTagProps) => renderTags(valueToPass, getTagProps)}
        renderInput={renderInput}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: DtOptionItem<any>[] = disableSelectAll
            ? filtered
            : [{ label: selectAllLabel, value: 'select-all' }, ...filtered];
          return result;
        }}
        renderOption={(props, option, {}) =>
          renderOptionWithCheckbox(props, option, value?.includes(option.value) ?? false)
        }
      />
    </CustomSelectContainer>
  );
};

export default DtMultipleSelect;
