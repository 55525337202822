import { PaletteOptions } from '@mui/material';

export const DT_PALETTE_OPTIONS: PaletteOptions = {
  main: {
    primary: '#28a0db',
    black: '#231f20',
    white: '#ffffff',
    emerald: '#05ccb1',
    transparentEmerald: 'rgba(5, 204, 177, 0.1)',
    yellow: '#feb71f',
    red: '#f31a1e',
    transparentRed: 'rgba(243, 26, 30, 0.1)',
    background: '#f9fbfc',
    control: '#edf3f7',
    hover: '#f6f9fb',
    secondaryText: '#747b99',
    transparentSecondaryText: 'rgba(116, 123, 153, 0.1)',
    secondaryIcon: '#8288a3',
    buttonPrimary: '#28a0db',
    buttonPrimaryHover: '#39a8de',
    buttonPrimaryPressed: '#2493ca',
    transparentButtonPrimary: 'rgba(40, 160, 219, 0.1)',
    secondaryButtonHover: 'rgba(40, 160, 219, 0.08)',
    secondaryButtonPressed: 'rgba(40, 160, 219, 0.2)',
    flatButtonHover: '#e6edf5',
    switcherOn: '#05ccb1',
    checkboxSelected: '#05ccb1',
    checkboxRegular: '#f9fbfc',
    scrollbar: '#cdd0da',
  },
};
