import React, { useEffect } from 'react';

import DtAssetsFilterPanel from './components/dt-assets-filter-panel/dt-assets-filter-panel';
import DtFormAssetsTable from './components/dt-form-assets-table/dt-form-assets-table';
import { dtUseAppDispatch, dtUseAppSelector } from '../../cdk/hooks/dt-store.hooks';
import DtPageTitleAndSubtitle from '../../components/dt-page-title-and-subtitle/dt-page-title-and-subtitle';
import { dtLoadFormsAction } from '../dt-administration/dt-forms-tab/dt-forms.actions';
import { dtSelectFormsLoading } from '../dt-administration/dt-forms-tab/dt-forms.slice';
import { MainContent } from '../dt-dashboard/dt-dashboard.styled';
import {
  dtSelectDispatchesAndAssetsFormSubtypeFilter,
  dtSelectDispatchesAndAssetsFormTypeFilter,
  dtSelectDispatchesAndAssetsPropertyIdFilter,
} from '../dt-dashboard/dt-dispatches.slice';
import { dtSelectCurrentCompanyId } from '../dt-user/dt-user.slice';

const DtAssets: React.FC = () => {
  const dispatch = dtUseAppDispatch();

  const isLoading = dtUseAppSelector(dtSelectFormsLoading);
  const currentCompanyId = dtUseAppSelector(dtSelectCurrentCompanyId);
  const propertyIdFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsPropertyIdFilter);
  const formTypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormTypeFilter);
  const formSubtypeFilter = dtUseAppSelector(dtSelectDispatchesAndAssetsFormSubtypeFilter);

  useEffect(() => {
    if (currentCompanyId) {
      dispatch(
        dtLoadFormsAction(
          currentCompanyId,
          propertyIdFilter ?? undefined,
          formTypeFilter ?? undefined,
          formSubtypeFilter?.name ?? undefined
        )
      );
    }
  }, [currentCompanyId, propertyIdFilter, formTypeFilter, formSubtypeFilter]);

  return (
    <MainContent>
      <DtPageTitleAndSubtitle title='Assets' subtitle='Manage assets' />
      <DtAssetsFilterPanel isLoading={isLoading} />
      <DtFormAssetsTable />
    </MainContent>
  );
};

export default DtAssets;
