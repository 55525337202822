import { CSSObject, styled } from '@mui/material/styles';
import { TableCell, Typography } from '@mui/material';

export const DtStickyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted' && prop !== 'isNew' && prop !== 'isScrollAtStart',
})<{ isNew?: boolean; isHighlighted?: boolean; isScrollAtStart?: boolean }>`
  position: sticky;
  padding: 8px;
  left: 0;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted && isHighlighted ? theme.palette.main.hover : theme.palette.main.white};
  border-top: ${({ theme, isNew }) => (isNew ? `1px solid ${theme.palette.divider};` : 'none')};
  border-bottom: none;
  z-index: 1;

  &::after {
    content: ' ';
    position: absolute;
    right: -24px;
    top: 0;
    bottom: 0;
    width: 24px;
    opacity: ${({ isScrollAtStart }) => (isScrollAtStart ? '0%' : '100%')};
    background-image: linear-gradient(to right, rgb(15 65 107 / 3%), rgb(15 65 107/ 0%));
  }
`;

export const DtStickyHeaderTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isScrollAtStart',
})<{ isScrollAtStart?: boolean }>`
  position: sticky;
  padding: 8px;
  left: 0;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  border-bottom: none;
  background-color: ${({ theme }) => theme.palette.main.white};
  z-index: 1;

  &::after {
    content: ' ';
    position: absolute;
    right: -24px;
    top: 0;
    bottom: 0;
    width: 24px;
    opacity: ${({ isScrollAtStart }) => (isScrollAtStart ? '0%' : '100%')};
    background-image: linear-gradient(to right, rgb(15 65 107 / 3%), rgb(15 65 107/ 0%));
  }
`;

export const DtHeaderRowTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'isHighlighted' })<{
  isHighlighted?: boolean;
}>`
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.palette.main.hover : theme.palette.main.white};
  border-bottom: none;
  flex: 0 0 30%;
  padding: 8px 16px;
  width: 230px;
  min-width: 230px;
  max-width: 230px;
`;

export const DtRowTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted' && prop !== 'isNew',
})<{ isNew?: boolean; isHighlighted?: boolean }>`
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted && isHighlighted ? theme.palette.main.hover : theme.palette.main.white};
  border-top: ${({ theme, isNew }) => (isNew ? `1px solid ${theme.palette.divider};` : 'none')};
  border-bottom: none;
  flex: 0 0 30%;
  padding: 8px 16px;
  width: 230px;
  min-width: 230px;
  max-width: 230px;
`;

export const DtTableCellText = styled('span')`
  ${({ theme }) => theme.typography.bodySemibold as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;

export const DtDateItem = styled(Typography)`
  ${({ theme }) => theme.typography.body as CSSObject};
  color: ${({ theme }) => theme.palette.main.black};
`;

export const DtTimeItem = styled(Typography)`
  ${({ theme }) => theme.typography.bodySmall as CSSObject};
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;
