import { GridRowParams, GridSortModel } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';

import { dtGenerateSitesTableColumnsConfig } from './dt-generate-sites-table-columns-config';
import { dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtSearchInput from '../../../../../components/dt-search-input/dt-search-input';
import DtTable from '../../../../../components/dt-table/dt-table';
import { DT_TABLE_SORTING_ORDER } from '../../../../../dt-constants';
import { DtAppRoutes, RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import DtCreateButton from '../../../components/dt-create-button/dt-create-button';
import { DT_TABLE_ROW_HEIGHT } from '../../../dt-administration.constants';
import { dtSelectSites, dtSelectSitesLoading } from '../../dt-sites.slice';

const DtSitesTable: React.FC = () => {
  const sites = dtUseAppSelector(dtSelectSites);
  const isLoading = dtUseAppSelector(dtSelectSitesLoading);

  const dtSitesTableColumns = dtGenerateSitesTableColumnsConfig(isLoading);
  const showLoaderWhenNoDataYet = isLoading && !sites.length;

  const [searchQuery, setSearchQuery] = useState('');

  const matchedSites = useMemo(
    () =>
      sites.filter((site) => {
        const siteInfo = site.name + site.city + site.state + site.organizationName;
        return siteInfo.toLowerCase().includes(searchQuery.toLowerCase());
      }),
    [sites, searchQuery]
  );

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'site name',
      sort: 'asc',
    },
  ]);

  function handleSiteCreationRedirect(): void {
    dtRouter.navigate(DtAppRoutes.SiteCreate);
  }

  function handleEditSite(params: GridRowParams): void {
    dtRouter.navigate(RoutesResolver.SiteDetails(params.row.id.toString()));
  }

  const tableActionsComponent = (
    <div style={{ display: 'flex' }}>
      <DtSearchInput onChange={setSearchQuery} />
      <DtCreateButton onClick={handleSiteCreationRedirect} variant='primary' iconSize={16} iconColor='white'>
        New Site
      </DtCreateButton>
    </div>
  );

  return (
    <DtTable
      rows={matchedSites}
      columns={dtSitesTableColumns}
      rowHeight={DT_TABLE_ROW_HEIGHT}
      sortingOrder={DT_TABLE_SORTING_ORDER}
      loading={showLoaderWhenNoDataYet}
      sortModel={sortModel}
      onSortModelChange={(newSortModel: GridSortModel) => setSortModel(newSortModel)}
      actionsComponent={tableActionsComponent}
      onRowClick={handleEditSite}
    />
  );
};

export default DtSitesTable;
