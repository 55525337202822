import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
// import { connectRouter, routerMiddleware } from 'connected-react-router';

import dtAssignmentsReducer from './modules/dt-administration/dt-assignments.slice';
import dtFormsReducer from './modules/dt-administration/dt-forms-tab/dt-forms.slice';
import dtManageUsersReducer from './modules/dt-administration/dt-manage-users-tab/dt-manage-users.slice';
import dtOrganizationsReducer from './modules/dt-administration/dt-organizations-tab/dt-organizations.slice';
import dtSitesReducer from './modules/dt-administration/dt-sites-tab/dt-sites.slice';
import dtDispatchesReducer from './modules/dt-dashboard/dt-dispatches.slice';
import DtInspectionDetailsReducer from './modules/dt-inspection-details/dt-inspection-details.slice';
import dtNavigationReducer from './modules/dt-navigation/dt-navigation.slice';
import dtPropertiesReducer from './modules/dt-properties/dt-properties.slice';
import dtUserReducer from './modules/dt-user/dt-user.slice';

export const dtStore = configureStore({
  reducer: {
    dtUser: dtUserReducer,
    dtManageUsers: dtManageUsersReducer,
    dtDispatches: dtDispatchesReducer,
    dtProperties: dtPropertiesReducer,
    dtNavigation: dtNavigationReducer,
    dtOrganizations: dtOrganizationsReducer,
    dtSites: dtSitesReducer,
    dtForms: dtFormsReducer,
    dtAssignments: dtAssignmentsReducer,
    dtInspectionDetails: DtInspectionDetailsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type DtAppDispatch = typeof dtStore.dispatch;
export type DtRootState = ReturnType<typeof dtStore.getState>;

export type DtAppThunk<T = void> = ThunkAction<T, DtRootState, unknown, AnyAction>;
