import * as yup from 'yup';

import { DtUploadReportValues } from './dt-dispatch-upload-report-form.interface';

export const DT_DISPATCH_UPLOAD_REPORT_INITIAL_FORM_VALUES: DtUploadReportValues = {
  reportToUpload: null,
  reportAuthor: undefined,
  reportUploadDate: undefined,
  reportUploadTime: undefined,
};

export const DT_DISPATCH_UPLOAD_REPORT_FORM_VALIDATION: yup.ObjectSchema<DtUploadReportValues> = yup.object().shape({
  reportToUpload: yup.mixed<File>().required('Choose file'),
  reportAuthor: yup.string().required('Choose assignee'),
  reportUploadDate: yup.date().required('Choose date when report was completed'),
  reportUploadTime: yup
    .date()
    .nullable()
    .typeError('Invalid Date')
    .when('reportUploadDate', ([reportUploadDate], schema) => {
      const currentDate = new Date();
      currentDate.setSeconds(0, 0); // Reset seconds and milliseconds

      if (reportUploadDate && reportUploadDate.toDateString() === currentDate.toDateString()) {
        return schema.max(currentDate, 'Time cannot be in the future');
      }

      return schema;
    })
    .required('Choose time when report was completed')
    .default(undefined),
});
