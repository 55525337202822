import React from 'react';

import { FooterContainer, CompanyName, Copyright } from './dt-copyright-footer.styled';

export interface DtFooterProps {
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
}

const DtCopyrightFooter: React.FC<DtFooterProps> = ({ className }) => {
  const getCurrentYear = new Date().getFullYear();

  return (
    <FooterContainer className={className} container>
      <CompanyName> &copy; {getCurrentYear} Digital Thread Technologies</CompanyName>
      <Copyright> All Rights Reserved</Copyright>
    </FooterContainer>
  );
};

export default DtCopyrightFooter;
