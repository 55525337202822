import { lowerCase } from 'lodash';
import React from 'react';

import { FallBackMessageTitle, FallBackMessageWrapper } from '../../dt-assignment-blocks.styled';

interface DtColumnFallbackMessageProps {
  title: string;
}

export const DtAssignmentFallbackMessage: React.FC<DtColumnFallbackMessageProps> = ({ title }) => {
  return (
    <FallBackMessageWrapper>
      <br />
      <FallBackMessageTitle>No {lowerCase(title)} available</FallBackMessageTitle>
      <br />
    </FallBackMessageWrapper>
  );
};
