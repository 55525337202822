import { Field, FieldProps } from 'formik';
import React, { ReactElement } from 'react';

import DtMultipleSelect from './dt-multiple-select/dt-multiple-select';
import { DtMultiSelectProps } from './dt-selects.interfaces';

const DtFormikMultipleSelect = <T extends string | number | Record<string, unknown>>({
  name,
  ...props
}: Partial<DtMultiSelectProps<T>>): ReactElement => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <DtMultipleSelect<T>
          value={field.value}
          onChange={(value?: T[]) => {
            form.setFieldError(field.name, undefined);
            form.setFieldValue(field.name, value);
            if (props.onChange && value) {
              props.onChange(value);
            }
          }}
          renderOption={props.renderOption}
          disabled={props.disabled}
          selectAllLabel={props.selectAllLabel}
          hideChips={props.hideChips}
          label={props.label}
          labelPosition={props.labelPosition}
          className={props.className}
          fullWidth={props.fullWidth}
          minWidth={props.minWidth}
          placeholder={props.placeholder}
          name={field.name}
          disableClearable={props.disableClearable}
          options={props.options ?? []}
          error={meta.error}
          disableSelectAll={props.disableSelectAll}
          tagsToPreview={props.tagsToPreview}
        />
      )}
    </Field>
  );
};

export default DtFormikMultipleSelect;
