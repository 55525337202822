import React from 'react';

import { DispatchStatus, GridContainer, TabButtonTitle } from './dt-tab-button-content.styled';
import DtSkeleton from '../../../../../components/dt-skeleton/dt-skeleton';

interface DtTabButtonContentProps {
  /**
   * @param { number } counter- Tab button's counter (dispatches quantity based on status)
   * @default undefined
   * @example "10"
   */
  counter?: number | string;
  /**
   * @param {string} subtitle - Tab button's subtitle (dispatches status name)
   * @default undefined
   * @example "Open", "Overdue"
   */
  subtitle?: string;
  /**
   * @param {string} icon - relates to a specific icon based on dispatch status
   * @default undefined
   * @example <DtIcon icon='chevron-left' size={14} />
   */
  icon?: JSX.Element;
  isDataLoading: boolean;
}

const DtTabButtonContent: React.FC<DtTabButtonContentProps> = ({ counter, subtitle, icon, isDataLoading }) => {
  return (
    <>
      <TabButtonTitle variant='h2'>{isDataLoading ? <DtSkeleton variant='text' width={24} /> : counter}</TabButtonTitle>
      <GridContainer container>
        {icon}
        <DispatchStatus variant='bodySemibold'>{subtitle}</DispatchStatus>
      </GridContainer>
    </>
  );
};

export default DtTabButtonContent;
