import { List } from '@mui/material';
import React, { useState } from 'react';

import { EmptyResultsText } from '../../../dt-search-input-for-select/dt-search-input-for-select.styled';
import { DtAssignmentListItemData } from '../../dt-assignment-blocks.interfaces';
import { AssignmentContent, AssignmentSearchInput, ListItemsScrollbar } from '../../dt-assignment-blocks.styled';
import DtAssignmentColumnHeader from '../dt-assignment-column-header/dt-assignment-column-header';
import { DtAssignmentFallbackMessage } from '../dt-assignment-fallback-message/dt-assignment-fallback-message';
import DtAssignmentListItem from '../dt-assignment-list-item/dt-assignment-list-item';

interface DtAssignmentColumnProps {
  title: string;
  counter: number;
  onAssign?: () => void;
  disableEditing?: boolean;
  disableAssignButton?: boolean;
  hideAssignButton?: boolean;
  hideDeleteButton?: boolean;
  hideEditButton?: boolean;
  onEdit: (id: string, parentId?: string) => void;
  onUnassign: (id: string, parentId?: string) => void;
  onChildAssign?: (parentId: string) => void;
  listItems: DtAssignmentListItemData[];
  selectedListItem?: string | null;
  selectedItem?: string | null;
  handleListItemSelection?: (id: string) => void;
  withArrow?: boolean;
  disabled?: boolean;
}

const DtAssignmentColumn: React.FC<DtAssignmentColumnProps> = ({
  title,
  counter,
  onAssign,
  disableEditing,
  hideDeleteButton,
  hideEditButton,
  disableAssignButton,
  hideAssignButton,
  onEdit,
  onChildAssign,
  onUnassign,
  listItems,
  selectedListItem,
  selectedItem,
  handleListItemSelection,
  withArrow,
  disabled,
}) => {
  const [searchText, setSearchText] = useState('');

  const listItemsBySearchedText = listItems.filter((filteredItem) => {
    return searchText === '' ? filteredItem : filteredItem.title.toLowerCase().includes(searchText.toLowerCase());
  });

  const childrenCounterWhenItemHighlightedOrChosen = listItems.filter(
    (item) => item.highlighted === true || item.chosen === true
  ).length;

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    setSearchText(e.target.value);
  }

  return (
    <AssignmentContent>
      <DtAssignmentColumnHeader
        title={title}
        counter={counter}
        onAssign={onAssign}
        hideAssignButton={hideAssignButton}
        disableAssignButton={disableAssignButton || !onAssign || disabled}
        selectedListItem={selectedItem}
        childrenCounterWhenItemHighlighted={childrenCounterWhenItemHighlightedOrChosen}
        searchedListItemsCounter={listItemsBySearchedText.length}
        isListItemSearched={Boolean(searchText)}
        listItemsLength={listItems.length}
      />
      {listItems.length ? (
        <>
          <AssignmentSearchInput placeholder='Search' disabled={disabled} onChange={handleInputChange} />
          {!listItemsBySearchedText.length && <EmptyResultsText>Not found</EmptyResultsText>}
          <List>
            <ListItemsScrollbar>
              {listItemsBySearchedText.map((item) => {
                return (
                  <DtAssignmentListItem
                    hideTrashButton={Boolean(hideDeleteButton)}
                    hideEditButton={Boolean(hideEditButton)}
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    subtitle={item.subtitle}
                    withArrow={withArrow}
                    disableEditing={disableEditing}
                    tooltipContent={item.tooltipContent}
                    tooltipTitle={item.tooltipTitle}
                    onEdit={onEdit}
                    onUnassign={onUnassign}
                    highlighted={item.highlighted}
                    selectedListItem={selectedListItem}
                    disabled={disabled}
                    handleListItemSelection={handleListItemSelection}
                    selectedParentId={selectedItem}
                    onChildAssign={onChildAssign}
                  />
                );
              })}
            </ListItemsScrollbar>
          </List>
        </>
      ) : (
        <DtAssignmentFallbackMessage title={title} />
      )}
    </AssignmentContent>
  );
};

export default DtAssignmentColumn;
