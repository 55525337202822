import { Typography } from '@mui/material';
import React from 'react';

import { AssigneeStatusContainer, AssigneeStatusIconContainer } from './dt-dispatch-assignee-status.styled';
import DtIcon from '../../../../components/dt-icon/dt-icon';
import { DT_ASSIGNEE_STATUS_TYPE_MAPPING } from '../../dt-dispatches.constants';
import { AssigneeStatusType } from '../../dt-dispatches.enums';

interface DtDispatchAssigneeStatusProps {
  assignee: DtDispatchItemDetailsAssign;
}

interface AssigneeStatusItem {
  color: keyof MainPalette;
  label: string;
  icon: 'assign' | 'check' | 'close';
}

const DISPATCH_ASSIGNEE_STATUS_CONFIG: Record<AssigneeStatusType, AssigneeStatusItem> = {
  [AssigneeStatusType.Completed]: {
    color: 'emerald',
    label: DT_ASSIGNEE_STATUS_TYPE_MAPPING[AssigneeStatusType.Completed],
    icon: 'check',
  },
  [AssigneeStatusType.New]: {
    color: 'secondaryText',
    label: DT_ASSIGNEE_STATUS_TYPE_MAPPING[AssigneeStatusType.New],
    icon: 'assign',
  },
  [AssigneeStatusType.Declined]: {
    color: 'red',
    label: DT_ASSIGNEE_STATUS_TYPE_MAPPING[AssigneeStatusType.Declined],
    icon: 'close',
  },
};

const DtDispatchAssigneeStatus: React.FC<DtDispatchAssigneeStatusProps> = ({ assignee }) => {
  // related to the bug with typescript enum declaration https://stackoverflow.com/questions/38335668/how-to-refer-to-typescript-enum-in-d-ts-file-when-using-amd/48159049#48159049
  const assigneeStatusItem = DISPATCH_ASSIGNEE_STATUS_CONFIG[assignee.status as AssigneeStatusType];

  return (
    <AssigneeStatusContainer color={assigneeStatusItem.color}>
      <AssigneeStatusIconContainer>
        <DtIcon icon={assigneeStatusItem.icon} color={assigneeStatusItem.color} size={16} />
      </AssigneeStatusIconContainer>
      <Typography variant='bodySemibold'>{assigneeStatusItem.label}</Typography>
    </AssigneeStatusContainer>
  );
};

export default DtDispatchAssigneeStatus;
