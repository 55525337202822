import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

export const CustomToastContainer = styled(ToastContainer)`
  width: fit-content;

  /* stylelint-disable-next-line selector-class-pattern */
  & .Toastify__close-button {
    align-self: center;
    margin-left: 10px;
  }
`;
