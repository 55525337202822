import { styled } from '@mui/material/styles';
import { ListItem, Typography } from '@mui/material';

export const AssigneeNameContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const AssigneeEmail = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const AssigneesListItem = styled(ListItem)`
  display: grid;
  grid-template-columns: 230px 1fr auto;
  grid-gap: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(16)}`};

  &:hover {
    background-color: ${({ theme }) => theme.palette.main.hover};
  }
`;
