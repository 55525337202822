import React, { useCallback, useEffect, useState } from 'react';

import { dtUseGlobalNowWithMinuteInterval } from '../../cdk/hooks/dt-use-global-now-with-minute-interval';
import { dtDateDiff, dtRtf } from '../../cdk/utils/dt-date.utils';
import DtButton from '../dt-button/dt-button';
import DtIcon from '../dt-icon/dt-icon';

export interface DtLastUpdatedProps {
  lastUpdatedDatetime: Date;
  buttonOnly?: boolean;
  onRefresh: () => void;
}

const DtLastUpdated: React.FC<DtLastUpdatedProps> = ({ lastUpdatedDatetime, buttonOnly, onRefresh }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refresh = useCallback(() => {
    setIsLoading(true);
    onRefresh();
  }, [onRefresh]);
  const now = dtUseGlobalNowWithMinuteInterval(refresh);
  const isRefreshDisabled = isLoading || dtDateDiff(now, lastUpdatedDatetime) < 1;

  useEffect(() => {
    setIsLoading(false);
  }, [lastUpdatedDatetime]);

  return (
    <>
      {!buttonOnly && (
        <>
          Last updated:&nbsp;
          {isLoading ? 'loading...' : isRefreshDisabled ? 'less than a minute ago' : dtRtf(lastUpdatedDatetime)}&nbsp;
        </>
      )}
      <DtButton variant='transparent' disabled={isRefreshDisabled} onClick={refresh} padding={4} type='button'>
        <DtIcon size={16} icon='refresh' />
      </DtButton>
    </>
  );
};

export default DtLastUpdated;
