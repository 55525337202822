import React, { useState } from 'react';

import { dtUseAppDispatch } from '../../../../../cdk/hooks/dt-store.hooks';
import DtDeleteConfirmationPopup from '../../../../../components/dt-delete-confirmation-popup/dt-delete-confirmation-popup';
import DtIcon from '../../../../../components/dt-icon/dt-icon';
import {
  ActionButtonsWrapper,
  CenteredButton,
  IconPlaceholder,
} from '../../../../../components/dt-table/dt-actions-table-cell.styled';
import { dtDeleteSiteAction } from '../../dt-sites.actions';

interface DtSitesTableCellActionsProps {
  siteId: number;
}

const DtSitesTableCellActions: React.FC<DtSitesTableCellActionsProps> = ({ siteId }) => {
  const dispatch = dtUseAppDispatch();
  const [showConfirmationMessage, updateShowConfirmationMessage] = useState(false);

  function handleOpenPopup(): void {
    updateShowConfirmationMessage(true);
  }

  function handleDeleteSite(): void {
    dispatch(dtDeleteSiteAction(siteId));
    updateShowConfirmationMessage(false);
  }

  return (
    <ActionButtonsWrapper>
      <DtDeleteConfirmationPopup
        opened={showConfirmationMessage}
        onClose={() => {
          updateShowConfirmationMessage(false);
        }}
        onSubmit={handleDeleteSite}
        title='Delete selected site?'
        subtitle='You will not be able to recover it.'
        icon='trash'
        color='red'
      />
      <IconPlaceholder style={{ marginRight: '28px' }} />
      <CenteredButton variant='transparent' onClick={handleOpenPopup}>
        <DtIcon icon='trash' size={20} color='secondaryIcon' />
      </CenteredButton>
    </ActionButtonsWrapper>
  );
};

export default DtSitesTableCellActions;
