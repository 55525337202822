/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-magic-numbers */
import { isArray, isEmpty, isUndefined, keys } from 'lodash';
import * as yup from 'yup';

import { DtFrequencyEditorFormRecurrenceType } from './dt-frequency-editor-form.enums';
import { DtNewDispatchFormInitialValues } from './dt-new-dispatch-modal-form-interface';
import { dtCalculateDueDateFromDiffAndStartDate } from './dt-new-dispatch-modal.utils';
import { dtCronExpressionUtils } from '../../../../cdk/utils/dt-cron/dt-cron-expression.utils';
import { DtCronModel, FrequencyType } from '../../../../cdk/utils/dt-cron/dt-cron.interfaces';
import { DispatchDetailsDto } from '../../../../repositories/__generated__/v2';
import { DT_DISPATCH_PRIORITY_EDITING_MODE_MAPPING } from '../../dt-dispatches.constants';
import { DtDispatchPriorityType, DtDispatchPriorityTypeByIndex, DueDateType } from '../../dt-dispatches.enums';

export const DT_ORGANIZATION_DETAILS_INITIAL_FORM_VALUE = (
  data?: DispatchDetailsDto | null
): DtNewDispatchFormInitialValues => {
  const startDateInitialValue = new Date();

  const cronModel: Partial<DtCronModel> = data?.frequency
    ? dtCronExpressionUtils.dtCronExpressionToCronModel(data?.frequency)
    : {};

  return {
    generalInfo: {
      site: data?.propertyId,
      formType: data?.formType,
      formSubtype: data?.formId,

      hasAssets: !isEmpty(data?.assetList ?? []),
      assets: data?.assetList.map((asset) => asset.toString()) ?? [],
    },
    assignees: {
      assigner: data?.assigner ?? '',
      assignee: data?.assignTo ?? [],
      enableEmailNotificaition: isUndefined(data?.emailOff) ? true : !data?.emailOff,
    },
    details: {
      priority: data?.priority
        ? DT_DISPATCH_PRIORITY_EDITING_MODE_MAPPING[data.priority as DtDispatchPriorityType]
        : DtDispatchPriorityTypeByIndex.Medium,
      notes: data?.notes,
    },
    frequency: {
      // frequencyType field is placed on details tab,
      // but in order to use yup.when() it's need to be in frequency object
      frequencyType:
        data?.frequencyType === FrequencyType.OnceFuture
          ? FrequencyType.None
          : (data?.frequencyType as any) ?? FrequencyType.None,

      day:
        data?.frequencyType === FrequencyType.Daily || data?.frequencyType === FrequencyType.Monthly
          ? (cronModel.day?.repeatEvery as any) ?? (cronModel.day?.strictValue as any) ?? 1
          : undefined,

      weekday:
        data?.frequencyType === FrequencyType.Weekly
          ? cronModel.week?.strictValue
            ? ([cronModel.week?.strictValue] as any)
            : (cronModel.week?.selection as any)
          : undefined,

      weekRepeats: data?.frequencyType === FrequencyType.Weekly ? (cronModel.week?.repeatEvery as any) ?? 1 : undefined,

      month: data?.frequencyType === FrequencyType.Monthly ? (cronModel.month?.repeatEvery as any) ?? 1 : undefined,

      time: (cronModel.hour?.selection ??
        (!isUndefined(cronModel.hour?.strictValue) ? [cronModel.hour?.strictValue] : undefined)) as any,

      monthRepeats: data?.frequencyType === FrequencyType.Monthly ? (cronModel.week?.repeatEvery as any) : undefined,

      repeatEvery:
        data?.frequencyType === FrequencyType.Monthly
          ? cronModel.week?.repeatEvery
            ? (DtFrequencyEditorFormRecurrenceType.Specific as any)
            : (DtFrequencyEditorFormRecurrenceType.Each as any)
          : undefined,

      // frequency: data?.frequency ?? undefined,
      startDate: data?.startDate ? new Date(data.startDate) : startDateInitialValue,

      dueDate: data?.dueDate
        ? keys(DueDateType).includes(data.dueDate)
          ? (data.dueDate as DueDateType)
          : DueDateType.CUSTOM
        : DueDateType.CME,
      customDueDate: data ? dtCalculateDueDateFromDiffAndStartDate(data?.startDate, data?.dueDate) : undefined,

      endDate: data?.endDate ? new Date(data.endDate) : undefined,

      reminder:
        data?.reminders?.map((i) => {
          if (i === '2-W') {
            return '14-D';
          }
          if (i === '1-W') {
            return '7-D';
          }
          return i;
        }) ?? undefined,
    },
  };
};

export const DT_DISPATCH_FORM: yup.ObjectSchema<DtNewDispatchFormInitialValues> = yup.object().shape({
  generalInfo: yup.object().shape({
    site: yup.number().label('Site').required(),
    formType: yup.string().label('Form Type').required().trim(),
    formSubtype: yup.number().label('Form Subtype').required(),
    hasAssets: yup.boolean(),
    assets: yup
      .array()
      .label('Assets')
      .when('hasAssets', {
        is: true,
        then: () => yup.array().min(1, 'Choose at least 1 asset').required(),
      })
      .required(),
  }),
  assignees: yup.object().shape({
    assigner: yup.string().label('Assigner').required(),
    assignee: yup.array().label('Assignee').min(1).required(),
    enableEmailNotificaition: yup.bool().label('Email Notifications').required(),
  }),
  details: yup.object().shape({
    priority: yup.number().label('Priority').required(),
    notes: yup.string().label('Notes'),
  }),
  frequency: yup.object().shape({
    // start of type related configurations

    // frequencyType field is placed on details tab,
    // but in order to use yup.when() it's need to be in frequency object
    frequencyType: yup
      .mixed<FrequencyType>()
      .oneOf([FrequencyType.None, FrequencyType.Daily, FrequencyType.Weekly, FrequencyType.Monthly])
      .label('Frequency Type')
      .required(),
    time: yup
      .array()
      .of(yup.number().required())
      .label('Time')
      .required()
      .test({
        message: 'Choose at least 1 option',
        test: (arr) => isArray(arr) && !isEmpty(arr),
      }),
    day: yup
      .mixed<number>()
      .label('Day')
      .when('frequencyType', {
        is: FrequencyType.Daily,
        then: () => yup.number().required(),
      })
      .when('frequencyType', {
        is: FrequencyType.Monthly,
        then: () => yup.number().required().min(1).max(31),
      }),
    weekday: yup
      .mixed<number[]>()
      .label('Weekday')
      .when('frequencyType', {
        is: FrequencyType.Weekly,
        then: () =>
          yup
            .array()
            .of(yup.number().required())
            .required()
            .test({
              message: 'Choose at least 1 option',
              test: (arr) => isArray(arr) && !isEmpty(arr),
            }),
      }),
    weekRepeats: yup
      .mixed<number>()
      .label('Week repeats')
      .when('frequencyType', {
        is: FrequencyType.Weekly,
        then: () => yup.number().required().min(1),
      }),
    repeatEvery: yup
      .mixed<DtFrequencyEditorFormRecurrenceType>()
      .label('Recurrence')
      .when('frequencyType', {
        is: FrequencyType.Monthly,
        then: () =>
          yup
            .mixed()
            .oneOf([DtFrequencyEditorFormRecurrenceType.Each, DtFrequencyEditorFormRecurrenceType.Specific])
            .required(),
      }),
    month: yup
      .mixed<number>()
      .label('Month')
      .when('frequencyType', {
        is: FrequencyType.Monthly,
        then: () => yup.number().required().min(1).max(12),
      }),
    monthRepeats: yup
      .mixed<number>()
      .label('Month repeats')
      .when('repeatEvery', {
        is: DtFrequencyEditorFormRecurrenceType.Specific,
        then: () => yup.number().required(),
      }),
    // end of type related configurations
    startDate: yup.date().label('Start Date').required(),
    dueDate: yup
      .mixed<DueDateType>()
      .oneOf([DueDateType.CHE, DueDateType.CME, DueDateType.CYE, DueDateType.QME, DueDateType.CUSTOM])
      .label('Due Date')
      .required(),
    customDueDate: yup
      .date()
      .label('Custom Due Date')
      .when('dueDate', {
        is: DueDateType.CUSTOM,
        then: () => yup.date().required(),
      }),
    endDate: yup.date().label('End Date'),
    reminder: yup.array().label('Reminder'),
  }),
});
