import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FrequencyNoneFormContainer = styled('div')`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const NoteMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(24)};
  background-color: ${({ theme }) => theme.palette.main.background};
  text-align: center;
`;

export const NoteText = styled(Typography)`
  color: ${({ theme }) => theme.palette.main.secondaryText};
`;

export const FrequencyInputRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columnsGridTemplate',
})<{ columnsGridTemplate: string }>`
  display: grid;
  grid-template-columns: ${({ columnsGridTemplate }) => columnsGridTemplate};
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
`;

export const FrequencyRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columnsGridTemplate' && prop !== 'alignItems',
})<{ columnsGridTemplate?: string; alignItems?: string }>`
  display: grid;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  grid-template-columns: ${({ columnsGridTemplate }) => columnsGridTemplate};
  grid-gap: ${({ theme }) => theme.spacing(16)};
`;

export const FrequencyInputLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textAlign',
})<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign || 'initial'};
`;
