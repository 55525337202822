import { styled } from '@mui/material/styles';
import DtButton from '../../../../components/dt-button/dt-button';

export const HeaderWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'marginBottom' })<{
  marginBottom?: number;
}>`
  display: flex;
  margin-bottom: ${({ theme, marginBottom }) => (marginBottom ? theme.spacing(marginBottom) : 0)};
`;

export const BackButton = styled(DtButton)`
  width: 40px;
  height: 40px;
  margin-right: ${({ theme }) => theme.spacing(16)};
`;
