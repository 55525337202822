import { useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';

import { dtUseAppDispatch, dtUseAppSelector } from '../../../../../cdk/hooks/dt-store.hooks';
import DtAssignmentModal from '../../../../../components/dt-assignment-blocks/components/dt-assignment-modal/dt-assignment-modal';
import {
  DtAssignmentModalOptions,
  DtAssignmentModalProps,
} from '../../../../../components/dt-assignment-blocks/components/dt-assignment-modal/dt-assignment-modal.interface';
import DtAssignmentBlocks from '../../../../../components/dt-assignment-blocks/dt-assignment-blocks';
import { RoutesResolver } from '../../../../../dt-enums';
import { dtRouter } from '../../../../../dt-routes';
import { AssignmentItem, AssignmentItemType, AssignmentParent } from '../../../../../repositories/__generated__/v2';
import { dtReplaceChildren, dtAssignParents, dtSelectAssignments } from '../../../dt-assignments.slice';
import { dtLoadAllAvailableFormTypes, dtSelectAllAvailableFormTypes } from '../../../dt-forms-tab/dt-forms.slice';
import { dtLoadSites, dtSelectSites } from '../../../dt-sites-tab/dt-sites.slice';
import { DtAssignmentBlocksWrapper } from '../../dt-administration-details-layout/dt-administration-details-layout.styled';

const userAssignmentsPopupConfig: DtAssignmentModalProps = {
  title: '',
  subtitle: '',
  onSubmit: noop,
  onClose: noop,
  options: [],
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DtUserAssignmentsProps {
  organizationId: number | undefined;
}

const DtUserAssignments: React.FC<DtUserAssignmentsProps> = ({ organizationId }) => {
  const dispatch = dtUseAppDispatch();
  const data = dtUseAppSelector(dtSelectAssignments);
  const { isSubmitting, setFieldValue } = useFormikContext();

  function triggerAssignmentsChange(): void {
    setFieldValue('assignments', true);
  }

  const allAvailableSites = dtUseAppSelector(dtSelectSites);
  const allAvailableFormType = dtUseAppSelector(dtSelectAllAvailableFormTypes);

  const [showModal, setShowModal] = useState(false);
  const [popupConfig, setPopupConfig] = useState(userAssignmentsPopupConfig);

  const hasSitesToAssign = allAvailableSites.length > data.length;

  useEffect(() => {
    if (organizationId) {
      const loadSitesDispatch = dispatch(dtLoadSites({ companyId: organizationId }));
      const loadAllAvailableFormTypesDispatch = dispatch(dtLoadAllAvailableFormTypes(organizationId));

      return () => {
        loadAllAvailableFormTypesDispatch.abort();
        loadSitesDispatch.abort();
      };
    }
  }, []);

  function handleParentAssign(): void {
    const siteOptions = allAvailableSites
      .filter((site) => !data.find((d) => d.id === site.id.toString()))
      .map((site) => {
        return {
          id: site.id,
          displayValue: site.name,
        };
      });

    setShowModal(true);
    setPopupConfig({
      title: 'Sites',
      subtitle: '',
      onSubmit: (items: DtAssignmentModalOptions[]) => {
        addParents(
          items.map((item) => ({
            id: String(item.id),
            displayValue: item.displayValue,
            type: AssignmentItemType.Site,
            children: [],
          }))
        );
      },
      onClose: () => setShowModal(false),
      options: siteOptions,
    });
  }

  function handleChildAssign(parentId: string): void {
    const typesOptions = allAvailableFormType
      .filter((item) => item.siteId === Number(parentId))
      .map((item) => {
        return {
          id: item.formType,
          displayValue: item.formType,
          subtitleItems: item.formSchemas,
        };
      });

    setShowModal(true);
    setPopupConfig({
      title: 'Types',
      subtitle: '',
      onSubmit: (items) => {
        const childrenItems = items.length
          ? items.map((item) => ({
              id: String(item.id),
              displayValue: item.displayValue,
              type: AssignmentItemType.FormType,
              subtitleItems: item.subtitleItems,
            }))
          : [];
        updateChildrenInParent(parentId, childrenItems);
      },
      onClose: () => setShowModal(false),
      options: typesOptions,
      selectedIds: data.find((i) => i.id === parentId)?.children.map((i) => i.id) ?? [],
    });
  }

  function addParents(items: AssignmentParent[]): void {
    dispatch(dtAssignParents(items));
    triggerAssignmentsChange();
  }

  function updateChildrenInParent(parentId: string, items: AssignmentItem[]): void {
    dispatch(
      dtReplaceChildren({
        parentId,
        children: items,
      })
    );
    triggerAssignmentsChange();
  }

  function handleParentItemEdit(parentId: string): void {
    dtRouter.navigate(RoutesResolver.SiteDetails(parentId));
  }

  return (
    <DtAssignmentBlocksWrapper>
      <DtAssignmentBlocks
        parentTitle='Sites'
        childTitle='Types'
        tooltipTitle='Subtypes'
        childColumnType='Types'
        data={data}
        onParentEdit={handleParentItemEdit}
        onParentAssign={handleParentAssign}
        onChildAssign={handleChildAssign}
        disableParentAssign={!hasSitesToAssign}
        hideEditButton
        disabled={isSubmitting}
      />
      {showModal && (
        <DtAssignmentModal
          title={popupConfig.title}
          subtitle={popupConfig.subtitle}
          onSubmit={popupConfig.onSubmit}
          onClose={popupConfig.onClose}
          options={popupConfig.options}
          selectedIds={popupConfig.selectedIds}
        />
      )}
    </DtAssignmentBlocksWrapper>
  );
};

export default DtUserAssignments;
