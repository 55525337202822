import { SkeletonProps } from '@mui/material';
import React from 'react';

import { SkeletonContainer } from './dt-skeleton.styled';

const DtSkeleton: React.FC<SkeletonProps> = ({ children, ...props }) => {
  return <SkeletonContainer {...props}>{children}</SkeletonContainer>;
};

export default DtSkeleton;
