import { format } from 'date-fns';

/**
 * Returns date-range-picker input value formatted string, by given date range.
 */
export const dtGetDateRangePickerValue = (
  { from, to, predefinedRangeName }: DtDateRangeValue,
  dateFormat: string
): string => {
  if (predefinedRangeName) {
    return predefinedRangeName;
  }

  if (!from && !to) {
    return '';
  } else if (from && !to) {
    return `${format(from, dateFormat)}`;
  } else if (!from && to) {
    return `${format(to, dateFormat)}`;
  }

  return `${format(from as Date, dateFormat)} - ${format(to as Date, dateFormat)}`;
};
